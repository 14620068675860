/* eslint-disable import/prefer-default-export */
export const reebokDev = {
  BASE_URL: 'https://staging.reebok.com.tr/',
  COOKIE_BASE_URL: 'staging.reebok.com.tr',
  CHECKOUT_URL: 'https://checkout-be.staging.reebok.com.tr/',
  CHECKOUT_BASE_URL: 'https://checkout.staging.reebok.com.tr/',
  currency: 'TL',
  googleClientId: '885834410672-lclds48uo0mrba8rqvhf1ks84327ajnn.apps.googleusercontent.com',
  facebookAppId: '226119646490388',
  appleClientId: 'com.reebok.appleClient',
  locale: 'tr',
  symbol: false,
  quoteitemtotal: 20,
  logoPath: 'pub/assets/reebok/images/reebok-logo.svg',
  project: 'reebok',
  title: 'Reebok',
  appDeepLink: 'reebok://',
  failuresNumber: '3',
  manifest: 'pub/pwa/manifest-reebok.json',
  favicon: 'pub/assets/reebok/images/favicon.ico',
  loadingAnimation: 'pub/assets/reebok/img/loading/loading.gif',
  gtmAccountId: 'GTM-WHS7F3V',
  gtmMerchant: 'DeNn3obnC1T7kgZtif8LULmEH_Ka89vCrha32QjlQzc',
  siteKey: '6LcPQ64UAAAAAJv489J59h58XQ6t2X8cHODjm9NR',
  twitterCreator: '',
  appStoreId: '',
  alternateIos: '',
  alternateAndroid: '',
  facebookDomainVerification: '',
  siteFont: '',
  efilliScript: 'https://bundles.efilli.com/staging.reebok.com.tr.prod.js',
  segmentifyScript: '',
  siteName: 'REEBOK.COM.TR.',
  phoneCode: [{ name: '+90', mask: '(599) 999 99 99' }],
  isGlobal: false,
  cunda: true
};

export const reebokStaging = {
  BASE_URL: 'https://staging.reebok.com.tr/',
  COOKIE_BASE_URL: 'staging.reebok.com.tr',
  CHECKOUT_URL: 'https://checkout-be.staging.reebok.com.tr/',
  CHECKOUT_BASE_URL: 'https://checkout.staging.reebok.com.tr/',
  currency: 'TL',
  googleClientId: '885834410672-lclds48uo0mrba8rqvhf1ks84327ajnn.apps.googleusercontent.com',
  facebookAppId: '226119646490388',
  appleClientId: 'com.reebok.appleClient',
  locale: 'tr',
  symbol: false,
  quoteitemtotal: 20,
  logoPath: 'pub/assets/reebok/images/reebok-logo.svg',
  project: 'reebok',
  title: 'Reebok',
  appDeepLink: 'reebok://',
  failuresNumber: '3',
  manifest: 'pub/pwa/manifest-reebok.json',
  favicon: 'pub/assets/reebok/images/favicon.ico',
  loadingAnimation: 'pub/assets/reebok/img/loading/loading.gif',
  gtmAccountId: 'GTM-WHS7F3V',
  gtmMerchant: 'DeNn3obnC1T7kgZtif8LULmEH_Ka89vCrha32QjlQzc',
  siteKey: '6LcPQ64UAAAAAJv489J59h58XQ6t2X8cHODjm9NR',
  twitterCreator: '',
  appStoreId: '',
  alternateIos: '',
  alternateAndroid: '',
  facebookDomainVerification: '',
  robotoFont: '',
  efilliScript: 'https://bundles.efilli.com/staging.reebok.com.tr.prod.js',
  segmentifyScript: '',
  siteName: 'REEBOK.COM.TR.',
  phoneCode: [{ name: '+90', mask: '(599) 999 99 99' }],
  isGlobal: false,
  cunda: true
};

export const reebokPreprod = {
  BASE_URL: 'https://preprod.reebok.com.tr/',
  COOKIE_BASE_URL: 'preprod.reebok.com.tr',
  CHECKOUT_URL: 'https://checkout-be.preprod.reebok.com.tr/',
  CHECKOUT_BASE_URL: 'https://checkout.preprod.reebok.com.tr/',
  currency: 'TL',
  googleClientId: '885834410672-lclds48uo0mrba8rqvhf1ks84327ajnn.apps.googleusercontent.com',
  facebookAppId: '226119646490388',
  appleClientId: 'com.reebok.appleClient',
  locale: 'tr',
  symbol: false,
  quoteitemtotal: 20,
  logoPath: 'pub/assets/reebok/images/reebok-logo.svg',
  project: 'reebok',
  title: 'Reebok',
  appDeepLink: 'reebok://',
  failuresNumber: '3',
  manifest: 'pub/pwa/manifest-reebok.json',
  favicon: 'pub/assets/reebok/images/favicon.ico',
  loadingAnimation: 'pub/assets/reebok/img/loading/loading.gif',
  gtmAccountId: 'GTM-WHS7F3V',
  gtmMerchant: 'DeNn3obnC1T7kgZtif8LULmEH_Ka89vCrha32QjlQzc',
  siteKey: '6LcPQ64UAAAAAJv489J59h58XQ6t2X8cHODjm9NR',
  twitterCreator: '',
  appStoreId: '',
  alternateIos: '',
  alternateAndroid: '',
  facebookDomainVerification: '',
  robotoFont: '',
  efilliScript: 'https://bundles.efilli.com/staging.reebok.com.tr.prod.js',
  segmentifyScript: '',
  siteName: 'REEBOK.COM.TR.',
  phoneCode: [{ name: '+90', mask: '(599) 999 99 99' }],
  isGlobal: false,
  cunda: true
};

export const reebokProd = {
  BASE_URL: 'https://www.reebok.com.tr/',
  COOKIE_BASE_URL: 'reebok.com.tr',
  CHECKOUT_URL: 'https://checkout-be.reebok.com.tr/',
  CHECKOUT_BASE_URL: 'https://checkout.reebok.com.tr/',
  currency: 'TL',
  googleClientId: '885834410672-lclds48uo0mrba8rqvhf1ks84327ajnn.apps.googleusercontent.com',
  facebookAppId: '226119646490388',
  appleClientId: 'com.reebok.appleClient',
  locale: 'tr',
  symbol: false,
  quoteitemtotal: 20,
  logoPath: 'pub/assets/reebok/images/reebok-logo.svg',
  project: 'reebok',
  title: 'Reebok',
  appDeepLink: 'reebok://',
  failuresNumber: '3',
  manifest: 'pub/pwa/manifest-reebok.json',
  favicon: 'pub/assets/reebok/images/favicon.ico',
  loadingAnimation: 'pub/assets/reebok/img/loading/loading.gif',
  gtmAccountId: 'GTM-WHS7F3V',
  gtmMerchant: 'DeNn3obnC1T7kgZtif8LULmEH_Ka89vCrha32QjlQzc',
  siteKey: '6LcPQ64UAAAAAJv489J59h58XQ6t2X8cHODjm9NR',
  twitterCreator: '',
  appStoreId: '',
  alternateIos: '',
  alternateAndroid: '',
  facebookDomainVerification: '',
  robotoFont: '',
  efilliScript: 'https://bundles.efilli.com/staging.reebok.com.tr.prod.js',
  segmentifyScript: '',
  siteName: 'REEBOK.COM.TR.',
  phoneCode: [{ name: '+90', mask: '(599) 999 99 99' }],
  isGlobal: false,
  cunda: false
};
