const data = {
  "‘si FLO Para’dır": "Si fla - бұл пара",
  "’den alışveriş yaptığınız için teşekkür ederiz.": "Сауда-саттық үшін рахмет.",
  "’ı Aktif edin siz d": "Сені іске қосыңыз d",
  "’ı Aktif edin siz de": "Сізді де қосыңыз",
  "’ni kabul ediyorum.": "Мен «Ниге» келісемін.",
  "’ni okudum.": "Мен оқимын.",
  "’si Kapıda Ödeme işlemi olduğu için hesabınıza nakit olarak aktarılabilir. Ücret iadenizi nakit olarak almak istiyorsanız lütfen": "Есіктегі төлем процесі болғандықтан, оны сіздің шотыңызға қолма-қол ақшамен аударуға болады. Егер сіз ақшаңызды қайтаруды қаласаңыз, өтінемін",
  "’ten alışveriş yaptığınız için teşekkür ederiz.": "Сауда-саттық үшін рахмет.",
  "“Fatura Adresim” “Teslimat Adresim” ile Aynı": "Менің төлем мекен-жайы менің мекен-жайыммен бірдей",
  "/aydinlatma-metni": "/aydinlatma-metni",
  "/flo-cuzdan-kullanici-sozlesmesi": "/flo-cuzdan-kullanici-sozlesmesi",
  "/page?url=sss-desktop&tab=cuzdan": "/page?url=sss-desktop&tab=cuzdan",
  "1- Siparişiniz kargo aracılığıyla ilgili mağazamıza teslim edilecektir.": "1- Сіздің тапсырысыңыз біздің дүкенге жүк арқылы жеткізіледі.",
  "2- Mağazadan teslimat seçeneği sadece belirli mağazalarımızda geçerlidir.": "2- Дүкеннен жеткізу мүмкіндігі тек біздің нақты дүкендерде ғана жарамды.",
  "3- Mağazadan teslimat seçeneğinde ödemelerinizi sadece kredi kartı ile yapabilirsiniz": "3- Сіз тек төлемді тек несие картасымен жасай аласыз",
  "4- Siparişiniz talep ettiğiniz mağazaya ulaştığında otomatik SMS ve e-mail gönderimi ile bilgi verilecektir.": "4- Тапсырысыңыз сіз сұраған дүкенге жеткенде автоматты SMS және электрондық пошта жіберу арқылы хабарланады.",
  "5- Mağazalarımızdan siparişinizi teslim alırken sipariş no, ad, soyad, TC kimlik no ve size gönderilen güvenlik kodunuzu mağaza personeline bildirmeniz gerekmektedir.": "5- Сіз өзіңіздің тапсырысымызды біздің дүкендерден тапсырған кезде, сіз өзіңіздің тапсырысыңыз жоқ, аты, соя атауы, TC ID және сізге жіберілген қауіпсіздік коды туралы хабарлауыңыз керек.",
  "6 haneli doğrulama kodunu almak için telefon numaranızı giriniz.": "6 таңбалы растау кодын алу үшін телефон нөмірін енгізіңіз.",
  "6- Mağazalarımıza teslim edilen siparişleriniz '3 iş günü içerisinde teslim alınmalıdır.'": "6- Біздің дүкендерімізге тапсырылған тапсырысыңыз 3 жұмыс күні ішінде жеткізілуі керек.",
  "7- Mağazalarımıza ulaşan siparişleriniz zamanında teslim alınmadığında iptal edilecektir.": "7- Біздің дүкендерімізге жеткен тапсырыстар уақытында қабылданбайды.",
  "8 karakter": "Кем дегенде 8 таңба",
  "8- Mağazalarımızdan teslimat hergün 10:00 - 20:00 saatleri arasında yapılmaktadır.": "8- Дүкендерімізден жеткізу күн сайын 10: 00-ден 8: 00-ге дейін.",
  "Ad": "Есім",
  "AD SOYAD": "Аты-жөні",
  "Ad ve Soyad": "Аты-жөні",
  "ADET:": "Qty:",
  "Adınız": "Сіздің атыңыз",
  "Adres": "Айналу",
  "Adres Başlığı": "Мекенжай атауы",
  "Adrese Teslimat": "Үйге жеткізу",
  "Adrese Teslim": "Үйге жеткізу",
  "Ağustos": "Тамыз",
  "aktarabilirsiniz.": "бере алады.",
  "Aktarmak İstediğiniz Tutar Giriniz": "Тасымалданғыңыз келетін соманы енгізіңіз",
  "ALIŞVERİŞE BAŞLA": "Сатып алуды бастаңыз",
  "ALIŞVERİŞE DEVAM ET": "Сатып алуды жалғастырыңыз",
  "Alışveriş tutarınıza kargo firması tarafından talep edilen": "Жүкті фирмадан сіздің сатып алу сомасына сұрайды",
  "Apple bilgileriniz doğrulanamadı, lütfen tekrar deneyiniz.": "Apple ақпаратын тексеру мүмкін болмады, қайталап көріңіз.",
  "Apple ile Giriş Yap": "Apple-ге кіріңіз",
  "Apple ile Kayıt Ol": "Apple-мен тіркеліңіз",
  "Aralık": "Желтоқсан",
  "Aşağıdaki ürün / ürünlerin stoğu tükendiği için, sepetinizden çıkarılmıştır.": "Себеттен келесі өнім / өнімдер шығарылды, өйткені олар жоқ, өйткені олар жоқ.",
  "Ay": "Ай",
  "aydinlatma-metni-mobile": "Түсініктеме-мәтін-мобильді",
  "Aynı Gün Mağazadan Teslimat": "Дүкеннен сол күні жеткізу",
  "Bakiye": "Баланс",
  "BAKİYE YÜKLE": "Жүктеу балансы",
  "Bakiye:": "Баланс:",
  "Başka Bir Kart İle Ödeme Yap": "Басқа картамен төлеңіз",
  "Başka Kredi Kartı İle Öde": "Басқа несие картасымен төлеңіз",
  "Beden": "Дене",
  "Beden:": "Көлемі:",
  "Beğendiğiniz ürünleri kolayca sepetinize ekleyebilir ve arkadaşınızla paylaşabilirsiniz.": "Translate Yok (Beğendiğiniz ürünleri kolayca sepetinize ekleyebilir ve arkadaşınızla paylaşabilirsiniz.)",
  "binlerce ürün ve model seni bekliyor.": "Сізді мыңдаған өнім мен үлгі күтуде.",
  "Bir tutar giriniz.": "Соманы енгізіңіз.",
  "Bu alanın doldurulması zorunludur.": "Бұл керек аймақ.",
  "Bu ekranı gördüğünüz için üzgünüz!": "Бұл экранды көру үшін кешіріңіз!",
  "Bu ürünler": "Бұл өнімдер",
  "Bu ürünün iadesini yalnızca Kargo ile gerçekleştirebilirsiniz.": "Сіз тек осы өнімнің қайтарылуын тек жүкпен жасай аласыз.",
  "buraya": "Мұнда",
  "büyük harf": "бас әріп",
  "Cep Telefonu": "Ұялы телефон",
  "Cüzdan Detayları": "Әмиян туралы мәліметтер",
  "Cüzdana yüklemek istediğiniz tutarı giriniz.": "Әмиянға жүктегіңіз келетін соманы енгізіңіз.",
  "CVV": "CVV",
  "değerinde": "баға",
  "DEMO": "Көрсету",
  "DENEME": "Әрекет жасау",
  "DEVAM ET": "Жалғастырыңыз",
  "Devam Et": "Жалғастырыңыз",
  "Devam etmek ister misiniz?": "Жалғастырғыңыз келе ме?",
  "Diğer Adreslerim": "Менің басқа мекен-жайларым",
  "Düzenle": "Редакциялау",
  "E-Posta Adresi": "Электрондық пошта",
  "E-Posta Adresiniz": "Электрондық пошта",
  "Ekim": "Қазан",
  "eklenecektir.": "қосылады.",
  "En az": "Минимум",
  "En fazla": "Максимум",
  "En fazla 20 adet ürün ekleyebiliriz": "Біз 20-ға дейін өнім қосуға болады",
  "Erkek": "Ұл",
  "Eylül": "Қыркүйек",
  "Facebook bilgileriniz doğrulanamadı, lütfen tekrar deneyiniz.": "Сіздің Facebook ақпаратын тексере алмады, қайталап көріңіз.",
  "Facebook ile Giriş Yap": "Facebook-пен кіріңіз",
  "Facebook ile Kayıt Ol": "Facebook-те тіркеліңіз",
  "Fatura Adresi": "Төлеушінің Мекен-жайы",
  "Faturamı aynı adrese gönder": "Translate Yok (Faturamı aynı adrese gönder)",
  "FLO Cüzdan": "Фрос әмиян",
  "FLO Cüzdan ile Öde": "Парф әмиянмен төлейді",
  "FLO Cüzdan ile Ödendi": "Flo Waller-мен төленеді",
  "Flo Cüzdan Kullanıcı Sözleşme": "Фросальды әмиянның пайдаланушысы",
  "Flo Cüzdan Kullanıcı Sözleşmesi": "Фросальды әмиянның пайдаланушысы",
  "FLO Cüzdan Oluştur": "Фрос әмиянын жасаңыз",
  "FLO Cüzdana Yüklendi ve Ödendi": "егістік және егістік көшірмесінен төледі",
  "FLO Cüzdanı Aktif Et ve Öde": "Фрос-әмиянды іске қосыңыз және төлем жасаңыз",
  "Flo Cüzdanım": "Менің жүзім әмияным",
  "FLO Cüzdanım": "Менің жүзім әмияным",
  "FLO Cüzdanım’a Git": "Қалтаға барыңыз",
  "FLO Cüzdanınız´a": "сіздің қалтаңызға",
  "FLO Cüzdanınızı aktif etmek için": "FLON әмиянын іске қосу үшін",
  "FLO Mağazacılık ve Pazarlama A.Ş. Müşteri Aydınlatma Metni": "FLOR STARE және Marketing Inc. Тұтынушыларды жарықтандыру мәтіні",
  "Flo Müşteri Aydınlatma Metni": "Тұтынушыларды жарықтандыру мәтіні",
  "FLO Para": "Штетте",
  "FLO Para Geri Kazan": "Қолма-қол ақшаны қайтару",
  "Flo Para Kazan": "Flo ақша",
  "FLO PARA KAZANDIN!": "Сіз FLOW ақшаңызды жеңдіңіз!",
  "FLO Para Kullan": "FLOW ақшасын пайдаланыңыз",
  "FLO Paralarınız karta iade edilemez.": "Сіздің айналаңыздағы монеталар картаға қайтарылмайды.",
  "flo-cuzdan-kullanici-sozlesmesi-app": "Flo-Cuzdan-user-spleslation-app",
  "Geçerli telefon numarası giriniz.": "Ағымдағы телефон нөмірін енгізіңіз.",
  "Geçerliliğini Kaybedecek FLO Paralarım": "Менің дұрыстығыңызды жоғалтатын егістік монеталарым",
  "geçerlilik": "заңдылық",
  "Geçerlilik süresini yitirecek olan FLO Paralar aşağıda aylık olarak listelenmektedir.": "Жарамдылық уақытын жоғалту үшін еден монеталары төмендегі айларда көрсетілген.",
  "Genel Toplam": "Жалпы жиыны",
  "Giriş": "Кіру",
  "Giriş Yap": "Кіру",
  "GİRİŞ YAP": "КІРУ",
  "GÖNDER": "Жіберу",
  "Google bilgileriniz doğrulanamadı, lütfen tekrar deneyiniz.": "Google ақпаратын тексеру мүмкін болмады, қайталап көріңіз.",
  "Google ile Giriş Yap": "Google-ге кіріңіз",
  "Google ile Kayıt Ol": "Google-ге тіркеліңіз",
  "Güncel": "Ағымдағы",
  "Hata! Lütfen tekrar deneyiniz.": "Қате! Қайталап көріңіз.",
  "Haziran": "Маусым",
  "Hemen Aktif Et": "Дереу белсенді",
  "Hemen Üye Ol": "Қазір тіркеліңіз",
  "Hesabınıza en fazla": "Сіздің шотыңызға ең көп",
  "Hesap Özetim": "Менің есептік жазбамның қысқаша мазмұны",
  "Hızlı Yükle": "Тез жүктеңіз",
  "İade Edilecek Kart": "Картаны қайтарады",
  "İade İşlemini Onayla": "Қайтару процесін растаңыз",
  "İade işlemlerinde fatura ibrazı zorunludur, faturasız iade kabul edilmemektedir.": "Шот-фактураны ұсыну қайтарыммен жүзеге асырылады, есепшоттардың қайтарымы қабылданбайды.",
  "IBAN Numaranız": "Сіздің IBAN нөміріңіз",
  "IBAN numaranız yukarıda vermiş olduğunuz ‘’Ad ve Soyad’’ bilgileriyle eşleşmelidir.": "Сіздің IBAN нөміріңіз жоғарыда келтірілген «аты мен тегі» ақпаратына сәйкес келуі керек.",
  "İl Seçiniz": "Провинцияны таңдаңыз",
  "İlçe Seçiniz": "Округті таңдаңыз",
  "İndirim kodunuz varsa lütfen giriniz": "Translate Yok (İndirim kodunuz varsa lütfen giriniz)",
  "İptal": "Күшін жою",
  "İşlem Gerçekleştiriliyor...": "Өңдеу...",
  "Kadın": "Әйел",
  "Kalan Süreniz:": "Сіздің қалған кезеңіңіз:",
  "Kampanya": "Ұсыну",
  "Kampanya dönemlerinde yapılan sürpriz indirimlerden, stoklar ve bedenler tükenmeden ilk sizin haberiniz olsun.": "Науқандар, акциялар мен органдарда жасалған таңданыс, акциялар мен органдар сіздің жаңалықтарыңыздан шыққан.",
  "Kampanya Kodu / Kupon": "Науқан коды / купон",
  "Kapıda kredi kartı ile ödeme kabul edilmemektedir": "Есікке несие картасымен төлем қабылданбайды",
  "Kapıda Nakit Ödeme": "Есік алдында ақша",
  "Kapıda Ödeme": "Есікке төлеңіз",
  "Kapıda ödeme seçeneğinde sadece nakit ödeme kabul edilmektedir.": "Есіктегі төлем опциясында тек ақшалай төлем қабылданады.",
  "Kapıda ödeme seçeneğiyle alınan ürünlerin iadesi mağazalarımızdan veya iade adresimize gönderilerek yapılabilir": "Есікке төлем опциясымен алынған өнімдерді біздің дүкендерімізге немесе қайтару мекен-жайымызға қайтаруға болады",
  "kapıda ödeme ücreti": "Есіктің төлем ақысы",
  "Kargo Bedava": "Тегін жүк",
  "Kargo Ücreti": "Жеткізу құны",
  "Kargo ücreti ödememiz için sepetinizdeki tüm ürünlerin <strong>Kargo Bedava</strong> olması gerekmektedir.": "Себеттегі барлық заттар сізге жеткізу үшін төлемеу үшін <strong> Тегін жеткізу </strong> болуы керек.",
  "Kart Bilgileri": "Карталық ақпарат",
  "Kart Numarası": "Карточка нөмірі",
  "Karta Yüklenebilir FLO Para": "Жүктеу экраны арбада",
  "Kartıma Geri Yükle": "Менің картаңызға қалпына келтіріңіз",
  "Kartınıza en fazla": "Картаңызға дейін",
  "Kartınıza geri yükleme süresi bankalara bağlı olarak 2-10 iş günü arasında sürmektedir.": "Сіздің картаңызға қайта қосу уақыты банктерге байланысты 2-10 жұмыс күніне дейін.",
  "Kartınızın arka yüzündeki son 3 rakamı yazınız.": "Картаның артқы жағындағы соңғы 3 санды жазыңыз.",
  "Kasım": "Қараша",
  "KAYDET": "Жинау",
  "Kayıtlı Kart İle Devam Et": "Тіркелген картаны жалғастырыңыз",
  "kazanabilirdiniz.": "Сіз жеңе аласыз.",
  "Kazandın!": "Жеңді!",
  "Kazanmaya Başlayın!": "Жеңіске кірісу!",
  "Kendim": "Өзім",
  "Kodu Tekrar Gönder": "Кодты қайта жіберіңіз",
  "Kolay İade Seçeneği": "Оңай қайтару мүмкіндігі",
  "Kolay İade' Butonuyla hızlıca yapabilirsiniz.": "Сіз «Оңай қайтару» түймесін тез арада жасай аласыз.",
  "Kredi Kartı ile Yükleme Yap": "Несиелік картамен жүктеме",
  "Kredi Kartımı Sakla": "Менің несие картамды сақтаңыз",
  "Kupon Kodunu Yazınız": "Купон кодын енгізіңіз",
  "Kurumsal Fatura İstiyorum": "Мен корпоративтік шот-фактураны қалаймын",
  "Lütfen 3 karakter uzunluğunda bir değer giriniz": "Ұзындығы 3 таңбадан тұратын мәнді енгізіңіз",
  "Lütfen bilgileri eksiksiz doldurunuz": "Ақпаратты толтырыңыз",
  "Lütfen bu alanı doldurunuz": "Осы өрісті толтырыңыз",
  "Lütfen geçerli bir telefon girin.": "Жарамды телефонды енгізіңіз.",
  "Lütfen geçerli bir tutar giriniz.": "Жарамды соманы енгізіңіз.",
  "Lütfen IBAN Numaranızı Girin": "IBAN нөміріңізді енгізіңіз",
  "Lütfen Kredi Kart Bilgilerinizi Yazın": "Несиелік карта туралы ақпаратты теріңіз",
  "Lütfen sayfayı yeniden yükleyin.": "Бетті қайта жүктеңіз.",
  "Lütfen T.C. kimlik numaranızı kontrol ediniz!": "Жеке куәлік нөміріңізді тексеріңіз!",
  "Lütfen vergi kimlik numaranızı kontrol ediniz!": "Салық сәйкестендіру нөмірін тексеріңіз!",
  "Mağaza Adresi": "Дүкен мекен-жайы",
  "Mağazadan": "Дүкеннен",
  "Mağazadan nasıl teslim alırım?": "Дүкеннен қалай жинауға болады?",
  "Mağazadan Teslimat Bilgileri": "Дүкеннен жеткізу туралы ақпарат",
  "Mağazalardan nakit iade yapılmamaktadır": "Кассалық кірістер дүкендерден жасалмайды",
  "Mağazasından": "Дүкеннен",
  "Mahalle, cadde, sokak ve diğer bilgilerinizi giriniz.": "Ауданыңызды, көше және басқа мекен-жай туралы ақпаратты енгізіңіз.",
  "Mart": "Сапта жүру",
  "Mayıs": "Мамыр",
  "Mesafeli Satış Sözleşmesini": "Қашықтықтан сату туралы келісім",
  "Mobil Uygulamamız ile Sürpriz Avantajlar": "Біздің мобильді қосымшамызбен күтпеген артықшылықтар",
  "Mobil Uygulamasına özel yaptığımız etkinliklerden ilk sizin haberiniz olsun.": "Мобильді қосымшаға ерекше бар іс-шаралар туралы жаңалықтарыңыз бар.",
  "Nisan": "Сәуір",
  "numaralı telefonuza gönderilen 6 haneli doğrulama kodunu giriniz.": "Нөмірленген телефонға жіберілген 6 таңбалы растау кодын енгізіңіз.",
  "Ocak": "Қаңтар",
  "Ödeme": "Төлем",
  "ÖDEME": "Төлем",
  "ÖDEME ADIMINA GEÇ": "Төлемге өту",
  "Ödeme Seçenekleri": "Төлем опциялары",
  "Ödeme Yöntemi": "Translate Yok (Ödeme Yöntemi)",
  "ÖDEMEYİ TAMAMLA": "Аяқталған",
  "Ödenecek Tutar": "Жалпы сома",
  "okudum onaylıyorum": "Мен оқып, қабылдадым",
  "olması gerekmektedir.": "Болу керек.",
  "Ön Bilgilendirme Formunu": "Алдын-ала ақпараттық нысан",
  "Onay Kodu": "Растау коды",
  "ONAYLA": "Мақұлдау",
  "Önceden Sepete Eklediklerim": "Алдын-ала қосылады",
  "rakam giriniz": "нөмір",
  "Satın aldığınız ürünlerin iadelerini": "Олардың сатып алған өнімдердің қайтарымдары",
  "Sayfa Bulunamadı": "Бет табылмады",
  "Sepet": "Арба",
  "Sepete Ekle": "Себетке қосу",
  "SEPETİ ONAYLA": "Арбаны растаңыз",
  "Sepetim": "Арба",
  "Sepetini Arkadaşınla Paylaş": "Translate Yok (Sepetini Arkadaşınla Paylaş)",
  "Sepetinizde ürün bulunmamaktadır.": "Себетте ешқандай заттар жоқ",
  "Sepetinizdeki ürünü sepetten çıkardıktan sonra favorilerinize eklemek ister misiniz?": "Арбадан шығарғаннан кейін осы затты таңдаулыларға қосқыңыз келе ме?",
  "Sepetinizdeki Ürünün Stoğu Tükendi!": "Сіздің арбаңыздағы өнім жоқ!",
  "Sepette": " ",
  "Şifre": "Пароль",
  "Şifremi Unuttum": "Мен паролімді ұмытып қалдым",
  "Şifreniz": "Пароль",
  "Sil": "Кетіру",
  "Sil ve Favorilere Ekle": "Таңдаулыларға қосып, қосыңыз",
  "Sipariş No:": "Тапсырыс жоқ:",
  "Sipariş Özeti": "Тапсырыс туралы қысқаша ақпарат",
  "SİPARİŞİNİZ BAŞARI İLE TAMAMLANMIŞTIR": "Сіздің тапсырысыңыз сәтті аяқталды",
  "SİPARİŞLERİM": "Менің тапсырысым",
  "Şirket Adı": "Компанияның Аты",
  "Size Özel Kupon Fırsatları": "Сізге арнайы купондық мүмкіндіктер",
  "Son Kullanma Tarihi": "Мерзімнің өту күні",
  "SONRAKİ ADIMA DEVAM ET": "Келесі қадамды жалғастырыңыз",
  "Soyad": "Тегі",
  "Soyadınız": "Сіздің тегіңіз",
  "Standart Teslimat": "Translate Yok (Standart Teslimat)",
  "Şubat": "Ақпан",
  "Tahmini Kargoya Teslimat :": "Eta :",
  "Tahmini Kargoya Veriliş Tarihi: ": "Translate Yok (Tahmini Kargoya Veriliş Tarihi: )",
  "Taksit": "Қондырғы",
  "Taksit Seçenekleri": "Төлем опциялары",
  "Taksit seçenekleri kart bilgilerinizi girdikten sonra güncellenecektir.": "Картаның ақпаратын енгізгеннен кейін, орнату параметрлері жаңартылады.",
  "TAMAM": "ЖАРАЙДЫ МА",
  "tarafından gönderilecektir.": "жібереді.",
  "Tek Çekim": "Біржолғы төлем",
  "Tekrar SMS Gönder": "SMS жіберіңіз",
  "Telefon Numaranız": "Сіздің телефон нөміріңіз",
  "Telefon numaranızı kontrol ediniz.": "Телефон нөмірі жарамсыз.",
  "telefon numarasına gönderilen 6 haneli kodu giriniz.": "Телефон нөміріне жіберілген 6 таңбалы кодты енгізіңіз.",
  "Temmuz": "Шілде",
  "Teşekkürler": "Рахмет",
  "Teslim Alacağım": "Мен жеткізілім аламын",
  "teslim edilecektir": "Ол жеткізіледі",
  "Teslimat Adresi": "Жеткізу мекенжайы",
  "Teslimat Bilgileri": "Жеткізу туралы ақпарат",
  "Teslimat Seçenekleri": "Жеткізу нұсқалары",
  "TEST": "Сынама",
  "tıklayın.": "Нұқыңыз.",
  "TL": "Теңгені құрайды",
  "TL ’den": "Теңгеге",
  "TL bakiye yükleyebilirsiniz.": "Теңге жүктеуге болады.",
  "TL en fazla": "Теңге",
  "TL hesabınıza iade edebilirsiniz.": "Теңге сіздің шотыңызға қайтарылуы мүмкін.",
  "TL iade edilebilir.": "Теңге қайтарылуы мүмкін.",
  "TL kartınıza iade edebilirsiniz.": "Теңге сіздің картаңызға қайтарылуы мүмкін.",
  "TL’ye yükselmiştir.": "Теңгені құрады.",
  "tr": "kk",
  "TR99 9999 9999 9999 9999 9999 99": "TR99 9999 9999 9999 9999 99",
  "TRY": "ТЫРЫСУ",
  "tutarında yükleme yapıldı ve ödeme işlemi tamamlandı.:": "Сома жасалды және төлем процесі аяқталды.",
  "ÜCRETSİZ": "ТЕГІН",
  "Ücretsiz": "ТЕГІН",
  "Ücretsiz Kargo": "Тегін жеткізілім",
  "Ürün": "Заттар",
  "ürün daha ekleyin Kargo Bedava olsun.": "Өнім көбірек жеткізілімге тегін жеткізілім ала алады.",
  "Ürün Var": "арбадағы заттар",
  "Ürünlerinizi aşağıda adresi bulunan mağazamızdan teslim alabilirsiniz.": "Төмендегі дүкеннен өнімдеріңізді ала аласыз.",
  "uyarınca işlenecektir.": "сәйкес өңделеді.",
  "ÜYE OL": "ТІРКЕЛУ",
  "HESAP OLUŞTUR": "eсептік жазба жасау",
  "Üye olmadan": "Мүшесіз",
  "ÜYE OLMADAN ALIŞVERİŞ": "Мүшесіз сатып алу",
  "Üye Olmadan Devam Et": "Мүшесіз жалғастырыңыз",
  "üyeleri kapıda nakit ödeme avantajından yararlanabilirler.": "Мүшелер есік алдында қолма-қол ақшаны төлеудің артықшылығын пайдалана алады.",
  "Üyeleri, hiç kullanmadığı ürünleri 14 gün içerisinde hızlı ve kolay bir işlemle iade edebilme avantajından yararlanırlar.": "Мүшелер 14 күнде өнімді кез-келген уақытта тез және оңай транзакциямен қайтаруға болатын артықшылықтардан пайда көреді.",
  "Üyelerimize özel yaptığımız kupon etkinliklerinden faydalanın": "Біз өз мүшелерімізді ерекше етіп жасайтын купондық әрекеттерді пайдаланыңыз",
  "ÜYELİK AVANTAJLARI": "Мүшелік артықшылықтары",
  "uyelik_sozlesmesi": "uyelik_sozlesmesi",
  "Uygula": "Өтініш беру",
  "Uyguladığınız kupon, sepetinizdeki indirim ile geçerli olmadığından sepet tutarınız": "Себебі сіз өтініш бергендіктен, сіздің арбаңыздағы жеңілдікпен жарамсыз болғандықтан, сіздің арбаңыздың мөлшері көтерілді",
  "VAZGEÇ": "БЕРІЛУ",
  "ve": "және",
  "ve üzeri ücretsiz kargo": " Translate Yok ( ve üzeri ücretsiz kargo)",
  "Vergi Dairesi": "Салық басқармасы",
  "Vergi/ TC Kimlik No": "Салық / идентификатор жоқ",
  "Vergi/ TC Kimlik No hatalı kontrol ediniz.": "Салық / идентификатор ЖОҚ жарамсыз.",
  "veya": "немесе",
  "ya Giriş Yapın": "Кіру",
  "Yapmış olduğun alışverişten": "Сіз дүкеннен жасадыңыз",
  "Yeni Adres Ekle": "Жаңа мекен-жай қосу",
  "Yıl": "Жыл",
  "Yüklediğin Para": "Ақша салдың",
  "Yükleniyor": "Тиеу",
  "Tahmini Teslimat :": "Eta :",
  "Kargo ücreti ödememiz için sepetinizdeki tüm ürünlerin": "ТЕГІН жеткізу үшін, сіздің арбаңыздағы барлық заттар болуы керек",
  "SEPETE GİT": "Арбаны қарау",
  "SİPARİŞİ TAMAMLA": "Толық тапсырыс",
  "Lütfen geçerli bir eposta adresi yazın": "Жарамды электрондық пошта мекенжайын жазыңыз",
  "Kayıtlı kullanıcı bulunamadı": "Пайдаланушы табылмады",
  "istenilen miktarda ürün bulunmamaktadır.": "Өнімдердің қажет мөлшері жоқ.",
  "Kredi / Banka Kartı": "Несиелік / банктік карта",
  "Lütfen en az 3 karakter uzunluğunda bir değer giriniz.": "Кем дегенде 3 таңбадан тұратын мәтінді енгізіңіз."
};

export default data;
