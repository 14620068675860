import * as React from 'react'
import { memo } from 'react'
const SvgCloseIcon = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 24 24'
    width='1em'
    height='1em'
    fill='currentColor'
    {...props}
  >
    <path d='m13 12 5-5-1-1-5 5-5-5-1 1 5 5-5 5 1 1 5-5 5 5 1-1z' />
  </svg>
)
const Memo = memo(SvgCloseIcon)
export default Memo
