/* eslint-disable quote-props */
/* eslint-disable comma-dangle */
/* eslint-disable strict */
import config from '@Config';
import kk from './kk';
import en from './en';
import ru from './ru';
import it from './it';
import Cookie from 'js-cookie';

const { locale } = config;

// for multi language..
const activeLangFromCookies = Cookie.get('language');
const activeLangFromConfig = typeof locale === 'object' ? locale.find(loc => loc === activeLangFromCookies) : null

const getLocate = () => activeLangFromConfig !== null ? activeLangFromConfig : locale || 'tr';
/* let locale = window.GENERAL_CONFIG?.locale || 'tr';

  if (locale.indexOf('_')) {
    locale = locale.split('_')[0];
  }

return locale; */

/* eslint-disable strict */
const translate = {
  kk,
  en,
  ru,
  it,
};

export const gettext = (text) => {
  let trimmedText = text;
  if (typeof text === 'string'){
    trimmedText = text.trim()
  }
  if (translate[getLocate()]) {
    if (typeof translate[getLocate()][trimmedText] !== 'undefined') {
      return translate[getLocate()][trimmedText];
    }
  }

  return text;
};