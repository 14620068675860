import * as React from 'react'
import { memo } from 'react'
const SvgTimeIconPurple = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='1em'
    height='1em'
    data-name='Group 5831'
    viewBox='0 0 12.899 12.801'
    fill='currentColor'
    {...props}
  >
    <defs>
      <clipPath id='time-icon-purple_svg__a'>
        <path
          fill='#3D2070'
          d='M0 0h12.899v12.801H0z'
          data-name='Rectangle 1860'
        />
      </clipPath>
    </defs>
    <g
      fill='#3D2070'
      clipPath='url(#time-icon-purple_svg__a)'
      data-name='Group 4070'
    >
      <path
        d='M0 6.1c.032-.249.058-.5.1-.746a6.2 6.2 0 0 1 .7-2.009 6.4 6.4 0 0 1 1.937-2.167A6.3 6.3 0 0 1 5.18.131 6.33 6.33 0 0 1 9.9 1l.143.088q.187-.272.37-.533A.7.7 0 0 1 10.5.45a.124.124 0 0 1 .216.033.5.5 0 0 1 .04.117l.446 1.6c.076.275-.035.424-.321.449-.535.045-1.07.1-1.6.144a.1.1 0 0 1-.037 0c-.077-.02-.19-.018-.221-.068s.01-.158.049-.222c.116-.188.248-.367.376-.554a5.2 5.2 0 0 0-1.158-.587 5.41 5.41 0 0 0-5.044.732 5.34 5.34 0 0 0-2.074 3.162 5.2 5.2 0 0 0-.081 1.888 5.31 5.31 0 0 0 1.983 3.529 5.4 5.4 0 0 0 2.376 1.093.519.519 0 1 1-.166 1.023A6.4 6.4 0 0 1 .737 9.434a6.1 6.1 0 0 1-.715-2.509A1 1 0 0 0 0 6.828z'
        data-name='Path 2253'
      />
      <path
        d='M6.553 4.467V6.23a.164.164 0 0 0 .105.172q1.605.825 3.207 1.656a.42.42 0 0 1 .272.495.415.415 0 0 1-.534.3 1 1 0 0 1-.149-.067l-3.62-1.869a.18.18 0 0 1-.117-.192V2.733a.42.42 0 1 1 .835-.032v1.763'
        data-name='Path 2254'
      />
      <path
        d='M10.449 10.292v-.013a.96.96 0 0 1 .735-.784.508.508 0 0 1 .451.764 2 2 0 0 1-.338.419.47.47 0 0 1-.549.082.49.49 0 0 1-.3-.469'
        data-name='Path 2255'
      />
      <path
        d='M7.804 11.687a.54.54 0 0 1 .579.411.5.5 0 0 1-.325.583 2.6 2.6 0 0 1-.5.111.5.5 0 0 1-.536-.4.5.5 0 0 1 .3-.586 4 4 0 0 1 .487-.122'
        data-name='Path 2256'
      />
      <path
        d='M12.622 8.313a4 4 0 0 1-.231.556.48.48 0 0 1-.6.161.49.49 0 0 1-.3-.547 2.5 2.5 0 0 1 .178-.52.5.5 0 0 1 .578-.249.555.555 0 0 1 .383.6'
        data-name='Path 2257'
      />
      <path
        d='M11.436 4.293a.53.53 0 0 1 .382-.543.49.49 0 0 1 .578.241 2.6 2.6 0 0 1 .189.517.5.5 0 0 1-.29.546.506.506 0 0 1-.614-.14 1.7 1.7 0 0 1-.245-.621'
        data-name='Path 2258'
      />
      <path
        d='M11.859 6.393V6.23a.516.516 0 0 1 1.031-.039 3 3 0 0 1 0 .427.515.515 0 0 1-1.029-.024v-.2'
        data-name='Path 2259'
      />
      <path
        d='M9.479 12.094a.54.54 0 0 1-.553-.34.5.5 0 0 1 .168-.574 3 3 0 0 1 .416-.257.505.505 0 0 1 .587.115.49.49 0 0 1 .078.592 1.2 1.2 0 0 1-.7.464'
        data-name='Path 2260'
      />
    </g>
  </svg>
)
const Memo = memo(SvgTimeIconPurple)
export default Memo
