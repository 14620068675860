/* eslint-disable import/prefer-default-export */
export const butigoDev = {
  BASE_URL: 'https://staging.butigo.com.tr/',
  COOKIE_BASE_URL: 'staging.butigo.com.tr',
  CHECKOUT_URL: 'https://checkout-be.staging.butigo.com.tr/',
  CHECKOUT_BASE_URL: 'https://checkout.staging.butigo.com.tr/',
  currency: 'TL',
  googleClientId: '885834410672-nggp60l13utunuv4ikvbhdp82asom29s.apps.googleusercontent.com',
  facebookAppId: '5258346120899465',
  appleClientId: 'com.butigo.appleClient',
  locale: 'tr',
  symbol: false,
  quoteitemtotal: 20,
  logoPath: 'pub/assets/butigo/img/logo1.svg',
  project: 'butigo',
  title: 'Butigo',
  appDeepLink: 'butigo://',
  failuresNumber: '3',
  manifest: 'pub/pwa/manifest-butigo.json',
  favicon: 'pub/assets/butigo/img/favicon.png',
  loadingAnimation:'/pub/assets/butigo/img/loading/butigo_loading_white.gif',
  gtmAccountId: 'GTM-KSXNN7T',
  gtmMerchant: 'W7eVF8pD5BssgHH635rSj_23G1LtlDAlwJNrB2UM3g0',
  siteKey: '6LcPQ64UAAAAAJv489J59h58XQ6t2X8cHODjm9NR',
  twitterCreator: '@butigoturkiye',
  appStoreId: '',
  alternateIos: '',
  alternateAndroid: '',
  facebookDomainVerification:'j0qp5fko2grla15zhr9ihsq5d94bgo',
  robotoFont: '',
  efilliScript: 'https://bundles.efilli.com/staging.butigo.com.tr.prod.js',
  segmentifyScript: '',
  siteName: 'BUTIGO.COM.TR.',
  phoneCode: [{ name: '+90', mask: '(599) 999 99 99' }],
  isGlobal: false,
  cunda: true
};

export const butigoStaging = {
  BASE_URL: 'https://staging.butigo.com.tr/',
  COOKIE_BASE_URL: 'staging.butigo.com.tr',
  CHECKOUT_URL: 'https://checkout-be.staging.butigo.com.tr/',
  CHECKOUT_BASE_URL: 'https://checkout.staging.butigo.com.tr/',
  currency: 'TL',
  googleClientId: '885834410672-nggp60l13utunuv4ikvbhdp82asom29s.apps.googleusercontent.com',
  facebookAppId: '5258346120899465',
  appleClientId: 'com.butigo.appleClient',
  locale: 'tr',
  symbol: false,
  quoteitemtotal: 20,
  logoPath: 'pub/assets/butigo/img/logo1.svg',
  project: 'butigo',
  title: 'Butigo',
  appDeepLink: 'butigo://',
  failuresNumber: '3',
  manifest: 'pub/pwa/manifest-butigo.json',
  favicon: 'pub/assets/butigo/img/favicon.png',
  loadingAnimation:'/pub/assets/butigo/img/loading/butigo_loading_white.gif',
  gtmAccountId: 'GTM-KSXNN7T',
  gtmMerchant: 'W7eVF8pD5BssgHH635rSj_23G1LtlDAlwJNrB2UM3g0',
  siteKey: '6LcPQ64UAAAAAJv489J59h58XQ6t2X8cHODjm9NR',
  twitterCreator: '@butigoturkiye',
  appStoreId: '',
  alternateIos: '',
  alternateAndroid: '',
  facebookDomainVerification:'j0qp5fko2grla15zhr9ihsq5d94bgo',
  robotoFont: '',
  efilliScript: 'https://bundles.efilli.com/staging.butigo.com.tr.prod.js',
  segmentifyScript: '',
  siteName: 'BUTIGO.COM.TR.',
  phoneCode: [{ name: '+90', mask: '(599) 999 99 99' }],
  isGlobal: false,
  cunda: true
};

export const butigoPreprod = {
  BASE_URL: 'https://preprod.butigo.com.tr/',
  COOKIE_BASE_URL: 'preprod.butigo.com.tr',
  CHECKOUT_URL: 'https://checkout-be.preprod.butigo.com.tr/',
  CHECKOUT_BASE_URL: 'https://checkout.preprod.butigo.com.tr/',
  currency: 'TL',
  googleClientId: '885834410672-nggp60l13utunuv4ikvbhdp82asom29s.apps.googleusercontent.com',
  facebookAppId: '5258346120899465',
  appleClientId: 'com.butigo.appleClient',
  locale: 'tr',
  symbol: false,
  quoteitemtotal: 20,
  logoPath: 'pub/assets/butigo/img/logo1.svg',
  project: 'butigo',
  title: 'Butigo',
  appDeepLink: 'butigo://',
  failuresNumber: '3',
  manifest: 'pub/pwa/manifest-butigo.json',
  favicon: 'pub/assets/butigo/img/favicon.png',
  loadingAnimation:'/pub/assets/butigo/img/loading/butigo_loading_white.gif',
  gtmAccountId: 'GTM-KSXNN7T',
  gtmMerchant: 'W7eVF8pD5BssgHH635rSj_23G1LtlDAlwJNrB2UM3g0',
  siteKey: '6Lfw1iEgAAAAAAq4W85b6GsiXjmNFvIbrsi-mGW5',
  twitterCreator: '@butigoturkiye',
  appStoreId: '',
  alternateIos: '',
  alternateAndroid: '',
  facebookDomainVerification:'j0qp5fko2grla15zhr9ihsq5d94bgo',
  robotoFont: '',
  efilliScript: 'https://bundles.efilli.com/staging.butigo.com.tr.prod.js',
  segmentifyScript: '',
  siteName: 'BUTIGO.COM.TR.',
  phoneCode: [{ name: '+90', mask: '(599) 999 99 99' }],
  isGlobal: false,
  cunda: true
};

export const butigoProd = {
  BASE_URL: 'https://www.butigo.com.tr/',
  COOKIE_BASE_URL: 'butigo.com.tr',
  CHECKOUT_URL: 'https://checkout-be.butigo.com.tr/',
  CHECKOUT_BASE_URL: 'https://checkout.butigo.com.tr/',
  currency: 'TL',
  googleClientId: '885834410672-nggp60l13utunuv4ikvbhdp82asom29s.apps.googleusercontent.com',
  facebookAppId: '5258346120899465',
  appleClientId: 'com.butigo.appleClient',
  locale: 'tr',
  symbol: false,
  quoteitemtotal: 20,
  logoPath: 'pub/assets/butigo/img/logo1.svg',
  project: 'butigo',
  title: 'Butigo',
  appDeepLink: 'butigo://',
  failuresNumber: '3',
  manifest: 'pub/pwa/manifest-butigo.json',
  favicon: 'pub/assets/butigo/img/favicon.png',
  loadingAnimation:'/pub/assets/butigo/img/loading/butigo_loading_white.gif',
  gtmAccountId: 'GTM-KSXNN7T',
  gtmMerchant: 'W7eVF8pD5BssgHH635rSj_23G1LtlDAlwJNrB2UM3g0',
  siteKey: '6Lfw1iEgAAAAAAq4W85b6GsiXjmNFvIbrsi-mGW5',
  twitterCreator: '@butigoturkiye',
  appStoreId: '',
  alternateIos: '',
  alternateAndroid: '',
  facebookDomainVerification:'j0qp5fko2grla15zhr9ihsq5d94bgo',
  robotoFont: '',
  efilliScript: 'https://bundles.efilli.com/staging.butigo.com.tr.prod.js',
  segmentifyScript: '',
  siteName: 'BUTIGO.COM.TR.',
  phoneCode: [{ name: '+90', mask: '(599) 999 99 99' }],
  isGlobal: false,
  cunda: false
};
