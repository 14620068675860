import axios from 'axios';
import config from '@Config';
import {
  requestHandler,
  successHandler,
  errorHandler,
  paymentUrlRequestHandler,
} from '../interceptors';

const { BASE_URL, CHECKOUT_URL, BUNSAR_URL } = config;

export const axiosInstance = axios.create({
  baseURL: BASE_URL,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
    // 'X-Frame-Options': 'SAMEORIGIN',
    // 'Referrer-Policys': 'no-referrer',
    // 'Permissions-Policy': `geolocation 'self' ${window.location.origin}`,
    // eslint-disable-next-line no-nested-ternary
  },
});

// Handle request process
axiosInstance.interceptors.request.use((request) => requestHandler(request));
// Handle response process
axiosInstance.interceptors.response.use(
  (response) => successHandler(response),
  (error) => errorHandler(error),
);


export const axiosDefault = axios.create({
  baseURL: BASE_URL,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
  },
});

// Handle request process
axiosDefault.interceptors.request.use((request) => requestHandler(request));
// Handle response process
axiosDefault.interceptors.response.use(
  (response) => successHandler(response),
  (error) => errorHandler(error),
);


// Default Credit Card farklı domaine request atıyor
export const axiosDefaultCreditCard = axios.create({
  baseURL: CHECKOUT_URL,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
  },
});

// Handle request process
axiosDefaultCreditCard.interceptors.request.use((request) => paymentUrlRequestHandler(request));
// Handle response process
axiosDefaultCreditCard.interceptors.response.use(
  (response) => successHandler(response),
  (error) => errorHandler(error),
);


// payment success farklı domanine request atıyor
export const axiosCompleteOrder = axios.create({
  baseURL: CHECKOUT_URL,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
  },
});

// Handle request process
axiosCompleteOrder.interceptors.request.use((request) => paymentUrlRequestHandler(request));
// Handle response process
axiosCompleteOrder.interceptors.response.use(
  (response) => successHandler(response),
  (error) => errorHandler(error),
);


// wallet topup farklı domaine request atıyor
export const axiosWalletTopup = axios.create({
  baseURL: CHECKOUT_URL,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
  },
});

// Handle request process
axiosWalletTopup.interceptors.request.use((request) => paymentUrlRequestHandler(request));
// Handle response process
axiosWalletTopup.interceptors.response.use(
  (response) => successHandler(response),
  (error) => errorHandler(error),
);

export const axiosBunsar = axios.create({
  baseURL: BUNSAR_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

