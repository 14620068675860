import React from 'react';
import config from '@Config';
import {
  gettext,
} from '@Translate';
import floCardGif from '../../assets/images/flo-kart.gif';
import butigoCardGif from '../../assets/images/butigo-kart.gif';
import instreetCardGif from '../../assets/images/instreet-kart.gif';

import { UnionBlack, UnionWhite } from '@Icons';
import { CustomButton } from '@Components';

const SaveCardPopup = ({ callback, confirm}) => {

  const {project}  = config;
  
  let iconUrl = floCardGif;

  switch (project) {
    case 'butigo':
      iconUrl = butigoCardGif;
      break;
    case 'instreet':
      iconUrl = instreetCardGif;
      break;
  }

  return (
    <div>
      <div className={`fancybox-hidden-content save-card ${project}-card fancybox-content`}>
        <div className={`save-card__header ${project}`}>
        </div>
        <div className='save-card__detail'>
          {  project !== 'reebok' &&
            < img src={iconUrl} alt="Kart Gif" />
          }
          <div className={`save-card__heading ${project}`}>
            {
              project === 'reebok' ? 
              <>
                {gettext('HIZLI VE GÜVENİLİR ALIŞVERİŞ')}
              </>
                :
                <>
                {gettext('Hızlı ve Güvenli Alışveriş ')}
                {project === 'flo' && (
                  <>
                    <b>{gettext('FLO')}</b>
                    {gettext('’da')}
                  </>
                )}
                {project === 'ninewest' && (
                  <>
                    <b>{gettext('NineWest')}</b>
                    {gettext('’te')}
                  </>
                )}
                {project === 'instreet' && (
                  <>
                    <b>{gettext('Instreet')}</b>
                    {gettext('’te')}
                  </>
                )}
                {project === 'lumberjack' && (
                  <>
                    <b>{gettext('Lumberjack')}</b>
                    {gettext('’te')}
                  </>
                )}
                {project === 'butigo' && (
                  <>
                    <b>{gettext('Butigo')}</b>
                    {gettext('’da')}
                  </>
                )}
                </>
              }
          </div>
          <div className="save-card__content">
            <p>
              <span className="font-bold">{gettext('Onayla ve Kartı Kaydet')}</span>
              {gettext(' butonuna basarak Kredi/Banka kartımın sonraki alışverişler için kaydedilmesini kabul ediyorum.')}
            </p>
          </div>
          <div className={`save-card__buttons ${project}-save-card__buttons`}>
            <div className="row">
            <div className="col-12 pb-2">
                {
                  project === 'reebok' ?  <CustomButton
                      className="reebok-payment-step-button reebok-start-shopping-button reebok-add-favorite-button"
                      dataTest="click-to-save-card"
                      type="button"
                      onClick={confirm}
                      >
                        <div
                          className="reebok-payment-step-button-text-wrapper icons "
                        >
                          <span className="reebok-payment-step-button__text">
                            {gettext('Onayla ve Kartı Kaydet')}
                          </span>
                          <span className="">
                            <span className="icon-black">
                              <UnionBlack />
                            </span>
                            <span className="icon-white">
                              <UnionWhite />
                            </span>
                          </span>
                        </div>
                      </CustomButton>
                  :
                  <button type="button" onClick={confirm} className={`btn btn-green w-100`} data-test="click-to-save-card">
                    {gettext('Onayla ve Kartı Kaydet')}
                  </button>
                }
              </div>
              <div className="col-12">
              {
                project === 'reebok' ?
                <CustomButton
                className="btn btn-defult reebok-btn-default reebok-delete-product-button"
                dataTest="click-to-save-card-continue"
                type="button"
                onClick={callback}
                >
                  <div
                    className="reebok-default-button"
                  >
                    <span className="">
                      {gettext('Kartımı Kaydetmeden Devam Et')}
                    </span>
                    <span className="">
                      <span className="icon-black">
                        <UnionBlack />
                      </span>
                    </span>
                  </div>
                </CustomButton>
                :
                <button type="button" onClick={callback} className={`btn btn-default ${project}-btn-default cancel-btn w-100`} data-test="click-to-save-card-continue">
                  {gettext('Kartımı Kaydetmeden Devam Et')}
                </button>
              }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SaveCardPopup;
