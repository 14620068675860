import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { bonusUsageAction, paymenHeaderActiveAction, isPaymentWithCardAction } from '@Actions';
import  CreditCard  from '../../../PaymentOptions/PaymentOptionContents/CreditCard';

const Flowallet = ({item}) => {

  // ** -------------- GLOBAL STATES ------------------------ **
  const { useBonus } = useSelector((state) => state.order);
  const [floWalletCardActive, setFloWalletCardActive] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (useBonus !== undefined) {
      dispatch(bonusUsageAction(useBonus));
      if (item?.customerWalletActive) {
        if (item?.isPayableWithoutBonus) {
          setFloWalletCardActive(false);
          dispatch(paymenHeaderActiveAction(true));
          dispatch(isPaymentWithCardAction(false));
        } else if (
          useBonus && item?.isPayableWithBonus
        ) {
          setFloWalletCardActive(false);
          dispatch(paymenHeaderActiveAction(true));
          dispatch(isPaymentWithCardAction(false));
        } else {
          setFloWalletCardActive(true);
        }
      }
    }
    return () => {
      dispatch(paymenHeaderActiveAction(false));
      dispatch(isPaymentWithCardAction(true));
    };
  }, [useBonus]);

  return (
    <div>
      {floWalletCardActive && <CreditCard />}
    </div>
  );
};

export default Flowallet;
