import React, { useEffect } from 'react';
import { gettext } from '@Translate';
import {
  useLoyaltyAction,
  setLoyaltyCheck
} from '@Actions';
import { useDispatch, useSelector } from 'react-redux';
import LoyaltyBoxBody from './LoyaltyBoxBody'

const Loyalty = () => {
  const { shoppingCart } = useSelector((state) => state.cart);
  const { isLoyaltyCheck } = useSelector((state) => state.order);
  const dispatch = useDispatch();

  useEffect(() => {
    if (shoppingCart?.mindboxCustomerBalances?.length > 0) {
      if (shoppingCart.mindboxCustomerBalances[0].isLoyaltyPointsUsed) {
        dispatch(setLoyaltyCheck(true))
      } else {
        dispatch(setLoyaltyCheck(false))
      }
    }
  }, [shoppingCart])

  if (shoppingCart) {
    if (!shoppingCart.mindboxCustomerBalances || (shoppingCart.mindboxCustomerBalances?.length > 0 && shoppingCart.mindboxCustomerBalances[0].available <= 0)) {
      return null
    }
  }

  const handleLoyaltyCheck = e => {
    let inputChecked = e.target.checked;

    if (typeof inputChecked === 'boolean') {
      dispatch(useLoyaltyAction({ isActive: inputChecked }));
    }
  }

  return (
    <div className="loyalty-box-wrapper">
      <div className="loyalty-box-container">
        <span className="loyalty-box-container__title">
          {gettext('Loyalty')}
        </span>
        <LoyaltyBoxBody text={`У вас есть <span>${shoppingCart.mindboxCustomerBalances[0].available} баллов</span> Программы Лояльности.`} />
        <div className="loyalty-box-container__footer">
          <label htmlFor="loyalty-point-using" className="checkbox checkbox--gray">
            <input
              type="checkbox"
              id="loyalty-point-using"
              checked={isLoyaltyCheck}
              onChange={e => {
                handleLoyaltyCheck(e)
              }} 
            />
            <span />
            <p>{gettext('Puanı hemen kullan.')}</p>
          </label>
        </div>
      </div>
    </div>
  )
}

export default Loyalty;
