/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { useOutsideClick } from '@Hooks';
import { useDispatch, useSelector } from 'react-redux';
import { IsArray } from '../../../../../utils/IsArray';
import { gettext } from '@Translate';
import {
  toMoney,
} from '@Constants';

import {
  setNumberOfInstallmentsAction,
  setDijitalPaymentMethodAction,
  setAxessAction,
  setPaymentMethodAction,
  setCreditCardAction,
  setInstallmentAction,
} from '@Actions';

export default () => {
  const dispatch = useDispatch();
  const { project } = config;
  const {
    numberAxess,
    creditCartId,
    installments,
    numberOfInstallments,
    creditCardPlatform,
    savedCardList,
    hepsiPaySavedCardList,
    dijitalPaymentMethod,
    paymentMethods,
    paymentMethod
  } = useSelector((state) => state.order);

  const [installmentsActive, setInstallmentsActive] = useState(false);
  const [dropdown, setDropdown] = useState(false);

  useEffect(async () => {
    const options = {};
    if (hepsiPaySavedCardList && paymentMethod === 'flo_hepsipay') {
      options.creditCartInfo = {
        creditCartId: creditCartId || hepsiPaySavedCardList[0]?.id,
        numberOfInstallments,
      };
      await dispatch(setCreditCardAction(options));
    }
  }, [
    installments,
    numberOfInstallments,
    numberAxess,
    hepsiPaySavedCardList
  ]);

  useEffect(() => {
    if (installments.length > 0) {
      setInstallmentsActive(true);
    }
  }, [installments]);

  useEffect(async ()=> {
    if (!savedCardList && dijitalPaymentMethod === 'flo_hepsipay') {
      await dispatch(setInstallmentAction({ creditCardPlatform: creditCardPlatform }));
    }
  },[creditCardPlatform]);



  /* useEffect(async () => {
    if (addressLoaded === true && dijitalPaymentName.includes(paymentMethod)) {
      await dispatch(
        getAgreementsAction({
          method: paymentMethod,
          installment: numberAxess,
        }),
      );
    }
  }, [numberAxess]); */

  const installmentSelect = () => {
    setDropdown(!dropdown);
  };

  useEffect(() => {
    document.addEventListener('click', onClick, false);
    return () => {
      document.removeEventListener('click', onClick);
    }
  }, ['init']);

  const onClick = (event) => {
    if (!event.target.closest('.installment-wrapper')) {
      setDropdown(false);
    }
  };
  return (
    <>
    {installmentsActive ? (
        <div className="installment-wrapper">
            <div className="installment--mobile-header">{gettext('Taksit Seçenekleri')}</div>
            <div
            className={`installment__select ${project}-installment__select`}
            onClick={() => installmentSelect()}
            data-test="checkout-payment-installment-item-select-link"
            >
            <div className="installment-button js-installment-button">
                {IsArray(installments)
                ?.filter(
                    (installmentsItem) => installmentsItem?.installment == numberOfInstallments,
                )
                .map((item) => (
                    <React.Fragment
                    key={`installment-${item?.installment}`}
                    >
                    <span className="installment-button__text">
                        {item?.installment === 1
                        ? gettext('Tek Çekim')
                        : `${item?.installment
                        } ${gettext(
                            'Taksit',
                        )} (${toMoney(
                            item?.installment_amount,
                        )})`}
                    </span>
                    <span className="installment-button__sum">
                        {toMoney(
                        item?.installment_amount_sum,
                        )}
                    </span>
                    <i className="icon-chevron-down" />
                    </React.Fragment>
                ))}
            </div>
            </div>
            <div
            className={`${dropdown
                ? 'installment__body--active'
                : ''
                } installment__body`}
            >
            {IsArray(installments)?.map((item, index) => (
                <div
                key={`installment-${item?.installment
                    }-${index.toString()}`}
                className={`installment-button ${numberOfInstallments
                    === item?.installment
                    ? ' selected-installment'
                    : ''
                    }`}
                data-test="checkout-payment-installment-item-selected-link"
                onClick={ () => {
                    dispatch(setPaymentMethodAction('flo_hepsipay'));
                    dispatch(
                      setNumberOfInstallmentsAction(
                        item?.installment,
                      ),
                    );
                    dispatch(setAxessAction(item?.id));
                    setDropdown(false);
                  }}
                >
                <span className="installment-button__check" />
                <span className="installment-button__text">
                    {item?.installment === 1
                    ? gettext('Tek Çekim')
                    : `${item?.installment} ${gettext(
                        'Taksit',
                    )} (${toMoney(
                        item?.installment_amount,
                    )})`}
                </span>
                <span className="installment-button__sum">
                    {toMoney(item?.installment_amount_sum)}
                </span>
                </div>
            ))}
            </div>
        </div>
    ) : (
        <></>
    )}
    </>
  );
};
