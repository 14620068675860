import React, { useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { hidePopupAction } from '@Actions';
import config from '@Config';
import { IconClose, CloseIcon } from '@Icons';

export default ({ mobile }) => {
  const { project } = config;
  const { isOpen, Content, isTopPopup, isDisaster } = useSelector((state) => state.popup);
  const dispatch = useDispatch();
  const handleClose = () => {
    dispatch(hidePopupAction());
  };

  const escFunction = useCallback((event) => {
    if (event?.keyCode === 27) {
      handleClose();
    }
  }, []);

  useEffect(() => {
    document.addEventListener('keydown', escFunction, false);

    return () => {
      document.removeEventListener('keydown', escFunction, false);
    };
  }, []);

  return (
    <>
      {isOpen ? (
        <div
          className={`fancybox-container fancybox-is-open ${
            mobile ? 'mobile' : ''
          }`}
          role="dialog"
          tabIndex="-1"
          id="fancybox-container-1"
          style={{ transitionDuration: '336ms' }}
        >
          <div className="fancybox-bg" />
          <div className="fancybox-inner">
            <div className="fancybox-infobar">
              <span data-fancybox-index="">1</span>
&nbsp;/&nbsp;
              <span data-fancybox-count="">1</span>
            </div>
            <div className="fancybox-stage">
              <div className="fancybox-slide fancybox-slide--html fancybox-slide--current fancybox-slide--complete">
                <div
                  className={`auth__form popup-container ${project}-auth__form-popup-container fancybox-content auth__form--address ${project}-auth__form popup-container ${isTopPopup && 'address-popup-container'}`}
                >
                  <Content />
                  {!isDisaster && (
                    <button
                      type="button"
                      data-fancybox-close=""
                      className={`fancybox-button fancybox-close-small fancybox-close ${project}-fancybox-close`}
                      title="Close"
                      onClick={handleClose}
                      data-test="checkout-popup-close-button"
                    >{ project === 'reebok' ? <CloseIcon /> : <CloseIcon /> }
                    </button>
                  )}

                </div>
              </div>
            </div>
            <div className="fancybox-caption fancybox-caption--separate">
              <div className="fancybox-caption__body" />
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};
