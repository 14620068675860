import React from 'react';
import {
  gettext,
} from '@Translate';
import BarcodeScannerComponent from '../QrBarcodeScanner';
import { PlusWarning } from '@Icons';

const BarcodeScanner = ({ callback }) => (
  <div className="toastfy-message toastfy-message-barcode">
    <div className="fancybox-hidden-content remove-product">
      <div className="toastfy-message-barcode__header">
        <PlusWarning />
        <span>{gettext('Lütfen kameranızı barkodun tam üzerine konumlandırın')}</span>
      </div>
      <BarcodeScannerComponent
        width="100%"
        height="auto"
        onUpdate={(err, result) => {
          if (result) callback(result.text);
        }}
      />
    </div>
  </div>
);

export default BarcodeScanner;
