import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { gettext } from '@Translate';
import { toMoney, timeframe } from '@Constants';
import { IsArray } from '../../utils/IsArray';

const OrderSummary = ({
  products, basketCount, totalPrice, discounts,
}) => {
  const urlLocation = useLocation();

  // ** -------------- GLOBAL STATES ------------------------ **
  const {
    headerIsActive,
    paymentheaderIsActive,
  } = useSelector((state) => state.headerActive);

  const {
    paymentMethod,
    paymentMethods,
    useBonus,
    installments,
    numberOfInstallments,
    customerDefaultShippingAddress,
    deliveryOption,
    pickupSummary,
    orderSummary,
  } = useSelector((state) => state.order);

  const { shoppingCart } = useSelector((state) => state.cart);

  const {
    headerValidationWithCard,
    headerValidationWithMonth,
    headerValidationWithCVV,
    headerValidationWithYear,
  } = useSelector((state) => state.cardValidation);
  // ** --------------------- // ------------------- **

  // ** --------------------- PAYMENT OPTIONS HEADER VALIDATION ------------------- **
  useEffect(() => {
    if (paymentMethod === 'flopay') {
      if (headerValidationWithCard && headerValidationWithMonth && headerValidationWithCVV && headerValidationWithYear) {
        setHeaderActiveLocalState(true);
      } else {
        setHeaderActiveLocalState(false);
      }
    }
  }, [
    headerValidationWithCard,
    headerValidationWithMonth,
    headerValidationWithCVV,
    headerValidationWithYear,
  ]);
  // ** --------------------- // ------------------- **

  // ** -------------- LOCAL STATES ------------------------ **
  const [headerActiveLocalState, setHeaderActiveLocalState] = useState(false);
  const [deliveryOptionText, setDeliveryOptionText] = useState(null);
  const [paymentMethodDetail, setPaymentMethodDetail] = useState(null);
  const [maturityDifferenceRate, setMaturityDifferenceRate] = useState(null);


  // ** --------------------- // ------------------- **

  useEffect(() => {
    if (installments) {
      const installment = installments?.find(
        (item) => Number(item.code) === Number(numberOfInstallments),
      );
      if (installment) {
        setMaturityDifferenceRate(installment);
      } else {
        setMaturityDifferenceRate(null);
      }
    } else {
      setMaturityDifferenceRate(null);
    }
    return () => {
      setMaturityDifferenceRate(null);
    };
  }, [numberOfInstallments, installments]);

  const handleDeliveryTimeText = (element) => {
    let timeText = '';
    if (element?.type === 'SDD' && element?.countdown < 1) {
      timeText = element?.merchant?.estimatedDeliveryTimeTextAfterTitle ? element?.merchant?.estimatedDeliveryTimeTextAfterTitle : element?.title;
    } else if (element?.type === 'CC') {
      timeText = timeframe(element?.merchant?.estimatedDeliveryTimeStart, element?.merchant?.estimatedDeliveryTimeEnd);
    } else if (element?.type === 'SD') {
      if (urlLocation?.pathname === '/success') {
        timeText = timeframe(element?.merchant?.estimatedDeliveryDate, element?.merchant?.estimatedDeliveryDate);
      } else {
        timeText = timeframe(element?.merchant?.estimatedDeliveryTimeStart, element?.merchant?.estimatedDeliveryTimeEnd);
      }
    } else {
      timeText = element?.merchant?.estimatedDeliveryTimeText;
    }
    return timeText;
  };

  useEffect(() => {
    if (deliveryOption === 'default') {
      setDeliveryOptionText('Standart Teslimat');
    } else if (deliveryOption === 'store') {
      setDeliveryOptionText('Mağazadan Teslimat');
    }
  }, [deliveryOption]);

  useEffect(() => {
    setPaymentMethodDetail(
      paymentMethods?.find(
        (item) => item?.code === paymentMethod,
      ),
    );
  }, [paymentMethod, paymentMethodDetail, paymentMethods]);

  return (
    <div className="order-summary-container">
      <div className={`component-header order-summary__header ${paymentheaderIsActive || headerActiveLocalState ? 'active-header' : ''}`}>
        <span className="order-summary__header--step"> 3 </span>
        <span className="order-summary__header--title">
          {' '}
          {gettext('Sipariş Özeti')}
          {' '}
        </span>
      </div>

      {headerIsActive && (
        <>
          <div className="order-summary__basket">
            <div className="order-summary__basket-header">
              {gettext('Sepetim')}
              {' '}
              <span className="basket-count">
                {' ('}
                {basketCount}
                {' '}
                {gettext('Ürün')}
                {') '}
              </span>
            </div>
            <div className="order-summary__basket-products-wrapper">
              {IsArray(products)?.map((data, id) => (
                <div className="product-item" key={id}>
                  <div className="product-item__image">
                    <img src={data?.imageUrlVertical} alt="product_image" />
                  </div>
                  {deliveryOption !== 'store' && (
                    <div className="product-item__shipping-wrapper">
                      <span className="product-item__shipping-title">
                        {gettext('Tahmini Kargoya Veriliş Tarihi:')}
                        &nbsp;
                      </span>
                      <span className="product-item__shipping-date">
                        {handleDeliveryTimeText(data)}
                      </span>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
          {IsArray(discounts)?.map((item, index) => (
            item?.title === 'İndirim' && (
            <div key={index.toString()} className="order-summary__price-items">
              <div className="order-summary__price-items--title">
                {' '}
                {gettext(item?.title)}
                {' '}
              </div>
              {item?.info && (
                <div className="order-summary__price-items--discount">
                  {item?.info?.map((item, index) => (
                    <div key={index.toString()}>{item?.campaign_name}</div>
                  ))}
                </div>
              )}
            </div>
            )
          ))}
          <div className="order-summary__delivery-option">
            { deliveryOptionText && (
              <>
                <div className="order-summary__delivery-option--header">
                  { gettext(deliveryOptionText)}
                </div>
                { deliveryOption !== 'store'
                  && (
                  <div className="order-summary__delivery-option--detail">
                    {customerDefaultShippingAddress?.titleAddress}
                    {' '}
                    -
                    {customerDefaultShippingAddress?.countyName}
                    {' '}
                    /
                    {customerDefaultShippingAddress?.cityName}
                  </div>
                  )}
                { deliveryOption === 'store'
                  && (
                  <div className="order-summary__delivery-option--detail">
                    {pickupSummary?.storeName}
                  </div>
                  )}
              </>
            )}
          </div>
          <div className="order-summary__payment-method">
            { paymentMethodDetail
              && (
              <div className="order-summary__payment-method--header">
                {gettext('Ödeme Yöntemi')}
              </div>
              )}
            { paymentMethodDetail?.code === 'flowallet' && paymentMethodDetail?.walletShow
            && (
            <div className="order-summary__payment-method--detail">
              <div>
                {paymentMethodDetail?.isPayableWithoutBonus ? `${gettext('Flo Plus ile Öde')}` : `${gettext('Flo Plus’a Yükle ve Öde')}`}
              </div>
              <div>{useBonus ? toMoney(paymentMethodDetail?.walletBalance + paymentMethodDetail?.bonusBalance) : toMoney(paymentMethodDetail?.walletBalance) }</div>
            </div>
            )}
            { paymentMethodDetail && paymentMethodDetail?.code !== 'flowallet' ? (
              <div className="order-summary__payment-method--detail">
                <div>
                  {paymentMethodDetail?.title}
                </div>
                <div>
                {paymentMethod === 'cashondelivery'
                  ? toMoney(
                        shoppingCart?.total + orderSummary?.cashOnDeliveryFee?.value,
                  )
                  : toMoney(maturityDifferenceRate?.installment_amount_sum || shoppingCart?.total)}
                </div>
              </div>
            ) : (
              paymentMethodDetail?.code === 'flowallet'
              && paymentMethodDetail?.walletBalance > 0
              && paymentMethodDetail?.purchaseAmountWithoutBonus > 0
              && paymentMethodDetail?.walletShow && (
                <div className="order-summary__payment-method--detail">
                  <div>
                    {gettext('Kredi / Banka Kartı')}
                  </div>
                  <div>
                  { useBonus ? toMoney(paymentMethodDetail?.purchaseAmountWithBonus) : toMoney(paymentMethodDetail?.purchaseAmountWithoutBonus)}
                  </div>
                </div>
              )
            )}
          </div>
        </>
      )}
    </div>
  );
};

OrderSummary.propTypes = {
  products: PropTypes.array.isRequired,
  deliveryOption: PropTypes.string.isRequired,
  basketCount: PropTypes.string.isRequired,
  totalPrice: PropTypes.number.isRequired,
  discounts: PropTypes.array,
};

OrderSummary.defaultProps = {
  products: [],
  deliveryOption: '',
  basketCount: '',
  totalPrice: 0,
  discounts: [],
};

export default OrderSummary;
