import React, { useState, useRef, useEffect } from 'react';
import { toMoney } from '@Constants';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { cardValidationPopupAction, setAgreementCheck } from '@Actions';
import { gettext } from '@Translate';
import config from '@Config';
import { useHover } from '@Hooks';
import { UnionBlack, UnionWhite } from '@Icons';
import { isNumberOrString } from '../../../../utils/NumberStringControl';
import CustomToastMessage from '../../../CustomToastMessage';

const ReebokDesktopCartSummary = ({
  buttonText,
  onClick,
  isAgreementActive,
  executeAgreementsListScroll,
  orderSummary,
}) => {
  const { project } = config;
  const [hoverRef, isHovered] = useHover();
  const dispatch = useDispatch();
  const agreementCheckbox = useRef(null);
  // ** -------------- GLOBAL STATES ------------------------ **
  const { shoppingCart } = useSelector((state) => state.cart);
  const { installments, numberOfInstallments, isAgreementCheck, paymentMethod, deliveryOption } =
    useSelector((state) => state.order);
  const { checkboxValidation, cardValidationError } = useSelector(
    (state) => state.cardValidation
  );


  // ** -------------- GLOBAL STATES ------------------------ **
  const [maturityDifferenceRate, setMaturityDifferenceRate] =
    useState(null);

  useEffect(() => {
    if (installments?.length > 0) {
      const installment = installments?.find(
        (item) => Number(item?.code) === Number(numberOfInstallments)
      );
      if (installment) {
        setMaturityDifferenceRate(installment);
      } else {
        setMaturityDifferenceRate(null);
      }
    } else {
      setMaturityDifferenceRate(null);
    }
    return () => {
      setMaturityDifferenceRate(null);
    };
  }, [numberOfInstallments, installments, paymentMethod]);

  const handleClose = () => {
    if (cardValidationError) {
      dispatch(cardValidationPopupAction(false));
    }
  };

  return (
    <div className="reebok-cart-summary-container">
      <button
        className="reebok-payment-step-button"
        onClick={onClick}
        data-test="reebok-checkout-summary-complete-action-button"
      >
        <div
          ref={hoverRef}
          className="reebok-payment-step-button-text-wrapper"
        >
          <span className="reebok-payment-step-button__text">
            {gettext('Satın Al')}
          </span>
          <span className="">
            {isHovered ? <UnionBlack /> : <UnionWhite />}
          </span>
        </div>
      </button>
      <div className="reebok-cart-summary-content-wrapper">
        <div className="reebok-cart-summary-container__header">
          <div className="cart-summary-container__heading">
            {gettext('Sipariş Özeti')}
          </div>
        </div>
        <div className="reebok-cart-summary-container__body ">
          {shoppingCart?.orderSummary?.priceItems?.map(
            (item, index) => (
              <div
                key={index.toString()}
                className="cart-summary-container__review"
              >
                <div className="cart-summary-container__label">
                  {gettext(item?.title)}
                  {item?.info && (
                    <div className="cart-summary-container__discount">
                      {item?.info?.map((item, index) => (
                        <div key={index.toString()}>
                          {item?.campaign_name}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
                <div className="cart-summary-container__price">
                  {isNumberOrString(toMoney(item?.value))}
                </div>
              </div>
            )
          )}
          {shoppingCart?.orderSummary?.sddShippingFeeTotal && (
            <div className="cart-summary-container__review">
              <div className="cart-summary-container__label">
                {gettext(
                  shoppingCart?.orderSummary?.sddShippingFeeTotal
                    ?.title
                )}
              </div>
              <div className="cart-summary-container__price">
                <span className="cargomoney">
                  {shoppingCart?.orderSummary?.sddShippingFeeTotal
                    ?.value === 0 ? (
                    <>
                      <span className="cart-summary-container__free-text">
                        {gettext('Ücretsiz')}
                      </span>
                      <span className="cart-summary-container__free-price">
                        {isNumberOrString(
                          toMoney(
                            shoppingCart?.orderSummary?.shipping
                              ?.shippingValue
                          )
                        )}{' '}
                      </span>
                    </>
                  ) : (
                    isNumberOrString(
                      toMoney(
                        shoppingCart?.orderSummary
                          ?.sddShippingFeeTotal?.value
                      )
                    )
                  )}
                </span>
              </div>
            </div>
          )}
          <div className="cart-summary-container__review">
            <div className="cart-summary-container__label">
              {gettext(shoppingCart?.orderSummary?.shipping?.title)}
            </div>
            <div className="cart-summary-container__price">
              <span className="cargomoney">
                {shoppingCart?.orderSummary?.shipping?.value === 0 ? (
                  <>
                    <span className="cart-summary-container__free-text">
                      {gettext('Ücretsiz')}
                    </span>
                    <span className="cart-summary-container__free-price">
                      {' '}
                      {isNumberOrString(
                        toMoney(
                          shoppingCart?.orderSummary?.shipping
                            ?.shippingValue
                        )
                      )}
                    </span>
                  </>
                ) : (
                  <span className="no-free-cargo-wrapper">
                    {toMoney(shoppingCart?.orderSummary?.shipping?.value)}
                  </span>
                )}
              </span>
            </div>
          </div>
          {paymentMethod === 'cashondelivery' &&
            orderSummary?.cashOnDeliveryFee && (
              <div className="cart-summary-container__review">
                <div className="cart-summary-container__label">
                  {orderSummary?.cashOnDeliveryFee?.title}
                </div>
                <div className="cart-summary-container__price">
                  <span className="cargomoney">
                    {orderSummary?.cashOnDeliveryFee?.value > 0 ? (
                      <span className="no-free-cargo-wrapper">
                        {
                          isNumberOrString(
                            toMoney(orderSummary?.cashOnDeliveryFee?.value)
                          )
                        }
                      </span>

                    ) : (
                      <>
                        {' '}
                        <span className="cart__summary__free-text">
                          {gettext('Ücretsiz')}
                        </span>{' '}
                        <span className="cart__summary__free-price">
                          {isNumberOrString(
                            toMoney(orderSummary?.shipping?.value)
                          )}
                        </span>{' '}
                      </>
                    )}
                  </span>
                </div>
              </div>
            )}

          {paymentMethod === 'flopay' &&
            installments &&
            maturityDifferenceRate?.fee > 0 && (
              <div className="cart-summary-container__review">
                <div className="cart-summary-container__label">
                  Vade Farkı ({maturityDifferenceRate?.title})
                </div>
                <div className="cart-summary-container__price">
                  <span className="cargomoney">
                    {isNumberOrString(
                      toMoney(maturityDifferenceRate?.fee)
                    )}
                  </span>
                </div>
              </div>
            )}
        </div>
        <div className="reebok-cart-summary-container__footer">
          <div className="cart-summary-container__review">
            <div className="cart-summary-container__label">
              {gettext('Toplam')}
            </div>
            <div className="cart-summary-container__price">
              <span className="primary-color generic-total">
                {paymentMethod === 'cashondelivery'
                  ? isNumberOrString(
                      toMoney(
                        shoppingCart?.total +
                          orderSummary?.cashOnDeliveryFee?.value
                      )
                    )
                  : isNumberOrString(
                      toMoney(
                        maturityDifferenceRate?.installment_amount_sum ||
                          shoppingCart?.total
                      )
                    )}
              </span>
            </div>
          </div>
        </div>
      </div>
      {isAgreementActive && (
        <div className="cart__summary-agreement">
          <label
            className={`cart__summary-agreement-checkbox checkbox ${project}-checkbox js-agreement-required ${
              checkboxValidation
                ? 'checkbox--error'
                : 'checkbox--gray'
            }`}
            ref={agreementCheckbox}
            htmlFor="cc_check"
          >
            <input
              type="checkbox"
              name="cc_check"
              id="cc_check"
              checked={isAgreementCheck}
              onChange={() =>
                dispatch(setAgreementCheck(!isAgreementCheck))
              }
              data-test="checkout-card-summary-cc-check-change"
            />
            <span
              id="cc-check--icon"
              className="reebok-cart__summary-agreement--input"
              data-test="checkout-card-summary-check"
            />
            <div
              className={`summary-agreement-button-container ${
                !isAgreementCheck && checkboxValidation
                  ? 'mobile-summary-container__agreement--error'
                  : ' '
              }`}
            >
              <b
                className={`js-i-read-link ${project}-js-i-read-link`}
                onClick={() => executeAgreementsListScroll()}
                data-test="checkout-summary-aggrement-link"
              >
                {gettext('Mesafeli Satış Sözleşmesini')}
              </b>{' '}
              <p>{gettext('ve')}</p>
              <b
                className={`js-i-read-link ${project}-js-i-read-link`}
                onClick={() => executeAgreementsListScroll()}
                data-test="checkout-summary-aggrement-list-link"
              >
                {' '}
                {gettext('Ön Bilgilendirme Formunu')}
              </b>{' '}
              <p>
                {gettext('okudum onaylıyorum.')}
              </p>
            </div>
          </label>
        </div>
      )}
      { cardValidationError && <CustomToastMessage text={gettext('Siparişinizi onaylamak için eksik bilgilerinizi doldurmanız gerekmektedir.')} handleClose={handleClose} isMobile={false} />}
    </div>
  );
};

ReebokDesktopCartSummary.propTypes = {
  shoppingCart: PropTypes.object.isRequired,
  buttonText: PropTypes.string,
  executeAgreementsListScroll: PropTypes.func,
  isAgreementActive: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

ReebokDesktopCartSummary.defaultProps = {
  shoppingCart: {},
  onClick: () => {},
  isAgreementActive: false,
  buttonText: 'Sepeti Onayla',
  executeAgreementsListScroll: () => {},
};

export default ReebokDesktopCartSummary;
