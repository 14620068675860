import Cookie from 'js-cookie';
import { isMobile } from '@Constants';
import config from '@Config';

const HeaderHandler = () => {
  const { COOKIE_BASE_URL } = config;
  const cookies = Cookie.withConverter({
    write(value, name) {
      return value;
    },
    read(value, name) {
      return value;
    },
  });
  const platform = Cookie.get('platform') ? Cookie.get('platform') : isMobile() ? 'MOBILEWEB' : 'WEB';
  const header = {
    platform,
    ...(() => {
      if (Cookie.get('is_encrypted') === '1') {
        const _encrypToken = 'd8aa4b94ba5a823b53cde96be4a3aef7';

        cookies.set(_encrypToken, cookies.get(_encrypToken), {
          domain: COOKIE_BASE_URL,
        });
        cookies.set('is_encrypted', Cookie.get('is_encrypted'), {
          domain: COOKIE_BASE_URL,
        });

        if (!cookies.get(_encrypToken)) {
          return {
            shoppingCartId: Cookie.get('basket_hash') || '',
          };
        }
        return {
          authenticationToken: cookies.get(_encrypToken) || '',
        };
      }
      if (!Cookie.get('_token')) {
        return {
          shoppingCartId: Cookie.get('basket_hash') || '',
        };
      }
      return {
        authenticationToken: Cookie.get('_token') || '',
      };
    })(),
  };
  return header;
};

export default HeaderHandler;
