import React from 'react';

const GalleryModal = ({
  isOpen, onClick, src, name,
}) => {
  if (isOpen === false) {
    return null;
  }

  return (
    <div isOpen={isOpen} className="modal-overlay" onClick={onClick} name={name} data-test="checkout-galley-modal-close-button">
      <div className="modal-body d-flex justify-content-center">
        <button className="modal-close" type="button" style={{ background: 'transparent', border: 0 }}>
          <span className="icon-close" />
        </button>

        <img src={src} alt="" style={{ maxWidth: '300px' }} />
      </div>
    </div>
  );
};

export default GalleryModal;
