
import CreditCard from './CreditCard';
import Flowallet from './Flowallet';
import DijitalPayment from './DijitalPayment';
import CashOnDelivery from './CashOnDelivery';
import GiftCertificate from './GiftCertificate';
import GiftCreditCard from './GiftCreditCard';


export {
  CreditCard,
  Flowallet,
  GiftCertificate,
  DijitalPayment,
  CashOnDelivery,
  GiftCreditCard
};
