import { createSlice } from '@reduxjs/toolkit';

const INITIAL_STATE = {
  ccIsActive: false,
};

const slice = createSlice({
  name: 'CCActive',
  initialState: INITIAL_STATE,
  reducers: {
    setCCIsActiveAction : (state, action) => {
      state.ccIsActive = action.payload;
    },
  }
});

export default slice.reducer;

export const {
  setCCIsActiveAction,
} = slice.actions;