/* eslint-disable import/prefer-default-export */
export const lumberjackDev = {
  BASE_URL: 'https://staging.lumberjack.com.tr/',
  COOKIE_BASE_URL: 'staging.lumberjack.com.tr',
  CHECKOUT_URL: 'https://checkout-be.staging.lumberjack.com.tr/',
  CHECKOUT_BASE_URL: 'https://checkout.staging.lumberjack.com.tr/',
  currency: 'TL',
  googleClientId: '885834410672-ej54g47okrsu0dla08cgilkm1b55tnvv.apps.googleusercontent.com',
  facebookAppId: '1192783901216340',
  appleClientId: 'com.lumberjack.appleClient',
  locale: 'tr',
  symbol: false,
  quoteitemtotal: 20,
  logoPath: 'pub/assets/lumberjack/img/lumberjack-logo.svg',
  project: 'lumberjack',
  title: 'Lumberjack',
  appDeepLink: '',
  failuresNumber: '3',
  manifest: 'pub/pwa/manifest-lumberjack.json',
  favicon: 'pub/assets/lumberjack/img/logo.png',
  loadingAnimation: 'pub/assets/flo/img/loading/flo_loading_white.gif',
  gtmAccountId: 'GTM-NJV5KHH',
  gtmMerchant: 'OBhfeWU09w5IM5_WGrEh4N_3JLXd0eV6qnWPJMppQk0',
  siteKey: '6LcPQ64UAAAAAJv489J59h58XQ6t2X8cHODjm9NR',
  twitterCreator: '@lumberjackturkiye',
  appStoreId: '',
  alternateIos: '',
  alternateAndroid: '',
  facebookDomainVerification:'qdouruoh8cajo2d2nx6iuz1arhtthx',
  robotoFont: '',
  efilliScript: 'https://bundles.efilli.com/staging.lumberjack.com.tr.prod.js',
  segmentifyScript: '',
  siteName: 'LUMBERJACK.COM.TR.',
  phoneCode: [{ name: '+90', mask: '(599) 999 99 99' }],
  isGlobal: false,
  cunda: true
};

export const lumberjackStaging = {
  BASE_URL: 'https://staging.lumberjack.com.tr/',
  COOKIE_BASE_URL: 'staging.lumberjack.com.tr',
  CHECKOUT_URL: 'https://checkout-be.staging.lumberjack.com.tr/',
  CHECKOUT_BASE_URL: 'https://checkout.staging.lumberjack.com.tr/',
  currency: 'TL',
  googleClientId: '885834410672-ej54g47okrsu0dla08cgilkm1b55tnvv.apps.googleusercontent.com',
  facebookAppId: '1192783901216340',
  appleClientId: 'com.lumberjack.appleClient',
  locale: 'tr',
  symbol: false,
  quoteitemtotal: 20,
  logoPath: 'pub/assets/lumberjack/img/lumberjack-logo.svg',
  project: 'lumberjack',
  title: 'Lumberjack',
  appDeepLink: '',
  failuresNumber: '3',
  manifest: 'pub/pwa/manifest-lumberjack.json',
  favicon: 'pub/assets/lumberjack/img/logo.png',
  loadingAnimation: 'pub/assets/flo/img/loading/flo_loading_white.gif',
  gtmAccountId: 'GTM-NJV5KHH',
  gtmMerchant: 'OBhfeWU09w5IM5_WGrEh4N_3JLXd0eV6qnWPJMppQk0',
  siteKey: '6LcPQ64UAAAAAJv489J59h58XQ6t2X8cHODjm9NR',
  twitterCreator: '@lumberjackturkiye',
  appStoreId: '',
  alternateIos: '',
  alternateAndroid: '',
  facebookDomainVerification:'qdouruoh8cajo2d2nx6iuz1arhtthx',
  robotoFont: '',
  efilliScript: 'https://bundles.efilli.com/staging.lumberjack.com.tr.prod.js',
  segmentifyScript: '',
  siteName: 'LUMBERJACK.COM.TR.',
  phoneCode: [{ name: '+90', mask: '(599) 999 99 99' }],
  isGlobal: false,
  cunda: true
};

export const lumberjackPreprod = {
  BASE_URL: 'https://preprod.lumberjack.com.tr/',
  COOKIE_BASE_URL: 'preprod.lumberjack.com.tr',
  CHECKOUT_URL: 'https://checkout-be.preprod.lumberjack.com.tr/',
  CHECKOUT_BASE_URL: 'https://checkout.preprod.lumberjack.com.tr/',
  currency: 'TL',
  googleClientId: '885834410672-ej54g47okrsu0dla08cgilkm1b55tnvv.apps.googleusercontent.com',
  facebookAppId: '1192783901216340',
  appleClientId: 'com.lumberjack.appleClient',
  locale: 'tr',
  symbol: false,
  quoteitemtotal: 20,
  logoPath: 'pub/assets/lumberjack/img/lumberjack-logo.svg',
  project: 'lumberjack',
  title: 'Lumberjack',
  appDeepLink: 'lumberjack://',
  failuresNumber: '3',
  manifest: 'pub/pwa/manifest-lumberjack.json',
  favicon: 'pub/assets/lumberjack/img/logo.png',
  loadingAnimation: 'pub/assets/flo/img/loading/flo_loading_white.gif',
  gtmAccountId: 'GTM-NJV5KHH',
  gtmMerchant: 'OBhfeWU09w5IM5_WGrEh4N_3JLXd0eV6qnWPJMppQk0',
  siteKey: '6LcPQ64UAAAAAJv489J59h58XQ6t2X8cHODjm9NR',
  twitterCreator: '@lumberjackturkiye',
  appStoreId: '',
  alternateIos: '',
  alternateAndroid: '',
  facebookDomainVerification:'qdouruoh8cajo2d2nx6iuz1arhtthx',
  robotoFont: '',
  efilliScript: 'https://bundles.efilli.com/staging.lumberjack.com.tr.prod.js',
  segmentifyScript: '',
  siteName: 'LUMBERJACK.COM.TR.',
  phoneCode: [{ name: '+90', mask: '(599) 999 99 99' }],
  isGlobal: false,
  cunda: true
};

export const lumberjackProd = {
  BASE_URL: 'https://www.lumberjack.com.tr/',
  COOKIE_BASE_URL: 'lumberjack.com.tr',
  CHECKOUT_URL: 'https://checkout-be.lumberjack.com.tr/',
  CHECKOUT_BASE_URL: 'https://checkout.lumberjack.com.tr/',
  currency: 'TL',
  googleClientId: '885834410672-ej54g47okrsu0dla08cgilkm1b55tnvv.apps.googleusercontent.com',
  facebookAppId: '1192783901216340',
  appleClientId: 'com.lumberjack.appleClient',
  locale: 'tr',
  symbol: false,
  quoteitemtotal: 20,
  logoPath: 'pub/assets/lumberjack/img/lumberjack-logo.svg',
  project: 'lumberjack',
  title: 'Lumberjack',
  appDeepLink: 'lumberjack://',
  failuresNumber: '3',
  manifest: 'pub/pwa/manifest-lumberjack.json',
  favicon: 'pub/assets/lumberjack/img/logo.png',
  loadingAnimation: 'pub/assets/flo/img/loading/flo_loading_white.gif',
  gtmAccountId: 'GTM-NJV5KHH',
  gtmMerchant: 'OBhfeWU09w5IM5_WGrEh4N_3JLXd0eV6qnWPJMppQk0',
  siteKey: '6LcPQ64UAAAAAJv489J59h58XQ6t2X8cHODjm9NR',
  twitterCreator: '@lumberjackturkiye',
  appStoreId: '',
  alternateIos: '',
  alternateAndroid: '',
  facebookDomainVerification:'qdouruoh8cajo2d2nx6iuz1arhtthx',
  robotoFont: '',
  efilliScript: 'https://bundles.efilli.com/staging.lumberjack.com.tr.prod.js',
  segmentifyScript: '',
  siteName: 'LUMBERJACK.COM.TR.',
  phoneCode: [{ name: '+90', mask: '(599) 999 99 99' }],
  isGlobal: false,
  cunda: false
};
