import React from 'react';

const Loader = ({ type = 2 }) => (
  <div className="main-item">
    {type === 1 && (
      <div className="static-background">
        <div className="background-masker btn-divide-left" />
      </div>
    )}
    {type === 2 && (
      <div className="animated-background">
        {/* <div className="background-masker btn-divide-left"></div> */}
      </div>
    )}
    {type === 3 && (
      <div className="shared-dom">
        <div className="sub-rect pure-background" />
        <div className="sub-rect pure-background" />
        <div className="sub-rect pure-background" />
        <div className="sub-rect pure-background" />
        <div className="sub-rect pure-background" />
        <div className="sub-rect pure-background" />
        <div className="sub-rect pure-background" />
        <div className="sub-rect pure-background" />
      </div>
    )}
    {type === 4 && (
      <div className="css-dom" />
    )}
    {type === 'cart' && (
      <div className="" style={{ backgroundColor: '#f8f8f8' }}>
        <div className="checkout-header" style={{ marginBottom: 20, padding: 20 }}>
          <div className="container d-flex">
            {window?.innerWidth < 900 ? (
              <div className="o-media__figure" style={{ marginLeft: 'auto', marginRight: 'auto' }}>
                <span className="skeleton-box" style={{ width: 50, height: 25, marginBottom: 0 }} />
              </div>
            ) : (
              <div className="o-media__figure">
                <span className="skeleton-box" style={{ width: 150, height: 50, marginBottom: 0 }} />
              </div>
            )}
          </div>
        </div>
        <div className="container">
          <ul className="o-vertical-spacing o-vertical-spacing--l">
            {[1, 2, 3, 4].map((item) => (
              <li key={item?.toString()} className="blog-post o-media">
                <div className="o-media__figure">
                  <span className="skeleton-box" style={{ width: 100, height: 80 }} />
                </div>
                <div className="o-media__body">
                  <div className="o-vertical-spacing">
                    <h3 className="blog-post__headline">
                      <span className="skeleton-box" style={{ width: '55%' }} />
                    </h3>
                    <p>
                      <span className="skeleton-box" style={{ width: '80%' }} />
                      <span className="skeleton-box" style={{ width: '80%' }} />
                    </p>
                    <div className="blog-post__meta">
                      <span className="skeleton-box" style={{ width: '80%' }} />
                    </div>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    )}
  </div>
);

export default Loader;
