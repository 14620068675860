import Cookie from 'js-cookie';

export const isValidCredentials = ({ apiKey, nonce, token }) => {
  return apiKey && nonce && token && !Cookie.get('disableBunsarEvent');
};

export const buildEventActionPayload = ({
  credentials,
  visitorId,
  shopperId,
  eventType,
  additionalParams = {}
}) => {
  const { apiKey, nonce, token } = credentials;

  const baseParams = {
      apiKey,
      nonce,
      token,
      visitor: visitorId,
      store: "TR",
      shopper: shopperId,
      eventType
  };

  const allParams = { ...baseParams, ...additionalParams };

  return Object.entries(allParams)
          .filter(([, value]) => value !== undefined && value !== null && value !== '')
          .map(([key, value]) => `${key}=${value}`)
          .join('&');
};
