import { IsObject } from '../utils/IsObject';

const releatedEventSetCart = (productList, basketId) => {
  const productListItem = [];
  Object.keys(IsObject(productList)).forEach((i) => {
    const element = productList[i];
    productListItem.push({
      name: element?.name, // Required
      id: element?.sku, // Required
      price: element?.price, // Required
      stock: element?.stock, // Product Stock Required
      brand: element?.brand, // Brand Optional
      category: element?.categories ? element?.categories[0]?.name : '', // Product Category Optional
      item_group_id: '', // Product variantID Optional
    });
  });
  dataLayer.push({
    event: 'vlEvent',
    vl_label: 'VL-CartView',
    vl_ecommerce: {
      cart: {
        basket_id: basketId, // Required
        products: productListItem,
      },
    },
  });
};

const releatedEventPurchase = (productList, purchaseId, customerId) => {
  const productListItem = [];
  Object.keys(IsObject(productList)).forEach((i) => {
    const element = productList[i];
    productListItem.push({
      id: element?.sku, // Required
      qty: element?.quantity, // Required
      brand: element?.brand, // Brand Optional
      price: element?.price, // Required
      category: element?.categories ? element?.categories[0]?.name : '', // Product Category Optional
      item_group_id: '', // Product variantID Optional
    });
  });

  dataLayer.push({
    event: 'vlEvent',
    vl_label: 'VL-PurchaseView',
    vl_ecommerce: {
      checkout: {
        purchase_id: purchaseId, // Required
        vl_userID: customerId, // Required - Reference Value
        products: productListItem,
      },
    },
  });
};

const releatedFavoriteList = (productId, price) => {
  dataLayer.push({
    event: 'vlEvent',
    vl_label: 'VL-AddFav',
    vl_product: {
      id: productId, // Required
      qty: 1, // Required
      price, // Required
    },
  });
};

export { releatedEventSetCart, releatedFavoriteList, releatedEventPurchase };
