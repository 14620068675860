import { createSlice } from '@reduxjs/toolkit';


const slice = createSlice({
  name: 'dynamicBaseUrl',
  initialState: {
    url:null
  },
  reducers: {
    setDynamicBaseUrlAction: (state, action) => {
      state.url = action.payload;
    },
  },
});

export default slice.reducer;

export const {
  setDynamicBaseUrlAction,
} = slice.actions;
