import * as React from 'react'
import { memo } from 'react'
const SvgIconSecure = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='1em'
    height='1em'
    data-name='Component 25 \u2013 1'
    viewBox='0 0 13.93 18.123'
    fill='currentColor'
    {...props}
  >
    <path
      fill='#28a745'
      d='M13.407 7.318h-.353V5.665c0-3.057-2.892-5.61-5.923-5.665H6.8C3.768.055.877 2.608.877 5.665v1.653H.524c-.544 0-.522.562-.522 1.255v8.29a1.15 1.15 0 0 0 .988 1.26h11.953a1.15 1.15 0 0 0 .988-1.26v-8.29c0-.694.021-1.255-.523-1.255Zm-2.681 0H3.204V5.665c0-1.821 1.945-3.327 3.761-3.327s3.761 1.506 3.761 3.327Zm0 0'
      data-name='Path 313'
    />
    <g data-name='Group 24' transform='translate(2.87 8.641)'>
      <circle
        cx={4.096}
        cy={4.096}
        r={4.096}
        fill='#fff'
        data-name='Ellipse 2'
      />
      <path
        fill='#00a861'
        d='M6.738 2.053a.673.673 0 0 0-.953 0L4.161 3.677 3.127 4.71l-.721-.721a.674.674 0 0 0-.953.953l1.2 1.2a.674.674 0 0 0 .953 0l.557-.557 2.576-2.577a.673.673 0 0 0-.001-.955'
        data-name='Path 314'
      />
    </g>
  </svg>
)
const Memo = memo(SvgIconSecure)
export default Memo
