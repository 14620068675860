/* eslint-disable react/prop-types */
import React from 'react';
import config from '@Config';
import {
  gettext,
} from '@Translate';

import { UnionBlack, UnionWhite } from '@Icons';
import CustomButton from '../CustomButton';

const ToastfyConfirm = ({ callback, confirm }) => {

  const { project } = config;

  return (
    <div style={{ color: '#ffffff' }}>
      <div id="remove-add-wishlist-product-100482192002" className={`fancybox-hidden-content remove-product ${project}-remove-product fancybox-content`} style={{ display: 'inline-block' }}>
        <div className="remove-product__heading ---">
          {
            project === 'reebok' ?
              <span className="reebok-add-favorite-header">
                <span>SİL VE</span>
                <span>FAVORİLERE EKLE</span>
              </span>
              :
              <div className="basket-icon">
              <span className="heart-icon">
                <i className="icon-heart-o" />
              </span>
              <i className="icon-basket" />
            </div>
            }
        </div>
        <div className="remove-product__content">
          <p>{gettext('Sepetinizdeki ürünü sepetten çıkardıktan sonra favorilerinize eklemek ister misiniz?')}</p>
        </div>
        <div className={`remove-product__buttons ${project}-remove-product__buttons`}>
          <div className="row">
            <div className="col-4 col-sm-6">
            {
              project === 'reebok' ?
              <CustomButton
              className="btn btn-defult js-remove-product reebok-btn-default reebok-delete-product-button"
              dataTest="reebok-empty-cart-goto-shopping-button"
              type="button"
              onClick={callback}
              >
                <div
                  className="reebok-default-button"
                >
                  <span className="">
                    {gettext('Sil')}
                  </span>
                  <span className="">
                    <span className="icon-black">
                      <UnionBlack />
                    </span>
                  </span>
                </div>
              </CustomButton>
              :
              <button type="button" onClick={callback} className={`btn btn-default js-remove-product ${project}-btn-default`} data-test="click-to-cart-page-delete-product-from-cart">
                {gettext('Sil')}
              </button>
            }
            </div>
            <div className="col-8 col-sm-6 pl-0">
              {
                project === 'reebok' ?  <CustomButton
                    className="reebok-payment-step-button reebok-start-shopping-button reebok-add-favorite-button"
                    dataTest="reebok-empty-cart-goto-shopping-button"
                    type="button"
                    onClick={confirm}
                    >
                      <div
                        className="reebok-payment-step-button-text-wrapper icons "
                      >
                        <span className="reebok-payment-step-button__text">
                          {gettext('Sil ve Favorilere Ekle')}
                        </span>
                        <span className="">
                          <span className="icon-black">
                            <UnionBlack />
                          </span>
                          <span className="icon-white">
                            <UnionWhite />
                          </span>
                        </span>
                      </div>
                    </CustomButton>
                :
                <button type="button" onClick={confirm} className={`btn btn-orange js-cart-remove-add-wishlist`} data-test="click-to-cart-page-delete-from-cart-and-add-to-favorites">
                  {gettext('Sil ve Favorilere Ekle')}
                </button>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ToastfyConfirm;
