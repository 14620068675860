import config from '@Config';
import React from 'react';

const Logo = () => {
  const { project, BASE_URL, logoPath } = config;
  return(
    <img src={`${BASE_URL}${logoPath}`} className={`${project}-logo`} alt={project} />
  );
};

export default Logo;
