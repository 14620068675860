import * as React from 'react'
import { memo } from 'react'
const SvgOutOfStockIconButigo = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='1em'
    height='1em'
    viewBox='0 0 54.315 44.945'
    fill='currentColor'
    {...props}
  >
    <defs>
      <clipPath id='out-of-stock-icon-butigo_svg__a'>
        <path
          fill='none'
          d='M0 0h54.315v44.945H0z'
          data-name='Rectangle 1'
        />
      </clipPath>
    </defs>
    <g
      fill='#3d2070'
      clipPath='url(#out-of-stock-icon-butigo_svg__a)'
      data-name='Group 1'
    >
      <path
        d='M20.107 36.469a4.238 4.238 0 1 0 4.238 4.238 4.207 4.207 0 0 0-4.238-4.238m0 7a2.661 2.661 0 1 1 2.661-2.661 2.65 2.65 0 0 1-2.661 2.661'
        data-name='Path 1'
      />
      <path
        d='M34.793 36.469a4.238 4.238 0 1 0 4.238 4.238 4.206 4.206 0 0 0-4.238-4.238m0 7a2.661 2.661 0 1 1 2.661-2.661 2.65 2.65 0 0 1-2.661 2.661'
        data-name='Path 2'
      />
      <path
        d='M43.27 14.292a.45.45 0 0 0 .394-.493V9.462a.424.424 0 0 0-.394-.394c-.2 0-.493.2-.493.394V14a.54.54 0 0 0 .493.3'
        data-name='Path 3'
      />
      <path
        d='M44.157 0a10.35 10.35 0 0 0-10.35 10.35 6.5 6.5 0 0 0 .1 1.379H11.926l-.887-2.168C10.349 6.7 8.181 6.013 7.2 6.111H.788A.7.7 0 0 0 0 6.8a.85.85 0 0 0 .788.789H7.2c.2 0 1.675 0 2.267 2.365l5.815 21.98c0 .2.887 3.844 3.844 3.844h18.328a.788.788 0 1 0 0-1.577H19.121c-1.774 0-2.365-2.563-2.365-2.563l-.69-2.858h22.177a.74.74 0 0 0 .69-.493l3.055-7.687a13 13 0 0 0 1.971.2 10.35 10.35 0 0 0 10.35-10.349A10.085 10.085 0 0 0 44.157 0M37.75 27.2H15.77l-3.45-14H34.2a10.27 10.27 0 0 0 6.406 6.8Zm10.842-11.232a.424.424 0 0 1-.394.394H41.1a.424.424 0 0 1-.394-.394V7.59h7.885zm.887-9.561a.424.424 0 0 1-.394.394h-8.771a.424.424 0 0 1-.4-.394v-.69a.424.424 0 0 1 .4-.394h2.858a.36.36 0 0 1 .394-.394h2.267a.424.424 0 0 1 .394.394h2.859a.424.424 0 0 1 .394.394z'
        data-name='Path 4'
      />
      <path
        d='M46.128 14.292a.45.45 0 0 0 .394-.493V9.462a.424.424 0 0 0-.394-.394c-.2 0-.493.2-.493.394V14a.54.54 0 0 0 .493.3'
        data-name='Path 5'
      />
    </g>
  </svg>
)
const Memo = memo(SvgOutOfStockIconButigo)
export default Memo
