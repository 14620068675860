import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router';

import config from '@Config';

const MetaTags = () => {
  const [deepLink, setDeepLink] = useState('');
  const [title, setTitle] = useState('');

  const {
    appDeepLink,
    project,
    BASE_URL,
    androidPackage,
    appStoreId,
  } = config;

  const location = useLocation();
  const path = location.pathname;

  // canonical verisini oluşturur
  const canonical = `${BASE_URL}${path}`;

  useEffect(() => {
    if (path === '/basket') {
      setTitle((prevState) => prevState = 'Sepetim');
      setDeepLink((prevState) => prevState = `${appDeepLink}shopping.cart`);
    } else if (path === '/payment') {
      setTitle((prevState) => prevState = `Adresim | ${project}`);
      setDeepLink((prevState) => prevState = `${appDeepLink}checkout.address`);
    } else if (path === '/payment') {
      setTitle((prevState) => prevState = `Ödeme | ${project}`);
      setDeepLink((prevState) => prevState = `${appDeepLink}checkout.payment`);
    } else {
      setTitle((prevState) => prevState = 'Sepetim');
      setDeepLink((prevState) => prevState = `${appDeepLink}checkout.success`);
    }
  }, [path]);

  return (
    <>
      <Helmet>
        <meta name="description" content={title} />
        <meta name="title" content={title} />
        <link rel="canonical" href={canonical} />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={title} />
        {/* <meta name="twitter:image" content={metaData?.image} /> */}
        <meta property="og:title" content={title} />
        {/* <meta property="og:image" content={metaData?.image} /> */}
        <meta property="og:url" content={canonical} />
        <meta property="og:description" content={title} />
        {/* android */}
        <meta property="al:android:app_name" content={project} />
        <meta property="al:android:package" content={androidPackage} />
        <meta property="al:android:url" content={deepLink} />
        {/* ios */}
        <meta property="al:ios:app_name" content={project} />
        <meta property="al:ios:app_store_id" content={appStoreId} />
        <meta property="al:ios:url" content={deepLink} />
        <script
          type="text/javascript"
          src="https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js"
        />
      </Helmet>
    </>
  );
};

export default MetaTags;
