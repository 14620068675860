/* eslint-disable import/prefer-default-export */
export const instreetDev = {
  BASE_URL: 'https://staging.instreet.com.tr/',
  COOKIE_BASE_URL: 'staging.instreet.com.tr',
  CHECKOUT_URL: 'https://checkout-be.staging.instreet.com.tr/',
  CHECKOUT_BASE_URL: 'https://checkout.staging.instreet.com.tr/',
  currency: 'TL',
  googleClientId: '885834410672-4pjc4774hs5r8b6r5k347g2n1cfvajpc.apps.googleusercontent.com',
  facebookAppId: '286700772082040',
  appleClientId: 'com.instreet.appleClient',
  locale: 'tr',
  symbol: false,
  quoteitemtotal: 20,
  logoPath: 'pub/assets/instreet-v2/images/instreet-logo-v2-black.svg',
  project: 'instreet',
  title: 'Instreet',
  appDeepLink: 'instreet://',
  failuresNumber: '3',
  manifest: 'pub/pwa/manifest-is.json',
  favicon: 'pub/assets/instreet-v2/images/favicon-v2.ico',
  loadingAnimation: 'pub/assets/instreet-v2/img/loading/sis_loading.gif',
  gtmAccountId: 'GTM-MJDKK5',
  gtmMerchant: 'hTyJky8Bb4E3mRRWDm5BwDDENDDMj8CF1ovrOUNYGzI',
  siteKey: '6LcPQ64UAAAAAJv489J59h58XQ6t2X8cHODjm9NR',
  twitterCreator: '@instreet',
  appStoreId: '1436146422',
  androidPackage: 'app-id=tr.com.instreet',
  alternateIos: 'ios-app://1436146422/https/www.instreet.com',
  alternateAndroid: 'android-app://tr.com.instreet/https/www.instreet.com.tr',
  facebookDomainVerification:'rs942xs39zyvzbflwuslyo2erbd3u5',
  robotoFont: '',
  efilliScript: 'https://bundles.efilli.com/staging.instreet.com.tr.prod.js',
  segmentifyScript: '',
  siteName: 'INSTREET.COM.TR.',
  phoneCode: [{ name: '+90', mask: '(599) 999 99 99' }],
  isGlobal: false,
  cunda: true
};

export const instreetStaging = {
  BASE_URL: 'https://staging.instreet.com.tr/',
  COOKIE_BASE_URL: 'staging.instreet.com.tr',
  CHECKOUT_URL: 'https://checkout-be.staging.instreet.com.tr/',
  CHECKOUT_BASE_URL: 'https://checkout.staging.instreet.com.tr/',
  currency: 'TL',
  googleClientId: '885834410672-4pjc4774hs5r8b6r5k347g2n1cfvajpc.apps.googleusercontent.com',
  facebookAppId: '286700772082040',
  appleClientId: 'com.instreet.appleClient',
  locale: 'tr',
  symbol: false,
  quoteitemtotal: 20,
  logoPath: 'pub/assets/instreet-v2/images/instreet-logo-v2-black.svg',
  project: 'instreet',
  title: 'Instreet',
  appDeepLink: 'instreet://',
  failuresNumber: '3',
  manifest: 'pub/pwa/manifest-is.json',
  favicon: 'pub/assets/instreet-v2/images/favicon-v2.ico',
  loadingAnimation: 'pub/assets/instreet-v2/img/loading/sis_loading.gif',
  gtmAccountId: 'GTM-MJDKK5',
  gtmMerchant: 'hTyJky8Bb4E3mRRWDm5BwDDENDDMj8CF1ovrOUNYGzI',
  siteKey: '6LcPQ64UAAAAAJv489J59h58XQ6t2X8cHODjm9NR',
  twitterCreator: '@instreet',
  appStoreId: '1436146422',
  androidPackage: 'app-id=tr.com.instreet',
  alternateIos: 'ios-app://1436146422/https/www.instreet.com',
  alternateAndroid: 'android-app://tr.com.instreet/https/www.instreet.com.tr',
  facebookDomainVerification:'rs942xs39zyvzbflwuslyo2erbd3u5',
  robotoFont: '',
  efilliScript: 'https://bundles.efilli.com/staging.instreet.com.tr.prod.js',
  segmentifyScript: '',
  siteName: 'INSTREET.COM.TR.',
  phoneCode: [{ name: '+90', mask: '(599) 999 99 99' }],
  isGlobal: false,
  cunda: true
};

export const instreetPreprod = {
  BASE_URL: 'https://preprod.instreet.com.tr/',
  COOKIE_BASE_URL: 'preprod.instreet.com.tr',
  CHECKOUT_URL: 'https://checkout-be.preprod.instreet.com.tr/',
  CHECKOUT_BASE_URL: 'https://checkout.preprod.instreet.com.tr/',
  currency: 'TL',
  googleClientId: '885834410672-4pjc4774hs5r8b6r5k347g2n1cfvajpc.apps.googleusercontent.com',
  facebookAppId: '286700772082040',
  appleClientId: 'com.instreet.appleClient',
  locale: 'tr',
  symbol: false,
  quoteitemtotal: 20,
  logoPath: 'pub/assets/instreet-v2/images/instreet-logo-v2-black.svg',
  project: 'instreet',
  title: 'Instreet',
  appDeepLink: 'instreet://',
  failuresNumber: '3',
  manifest: 'pub/pwa/manifest-is.json',
  favicon: 'pub/assets/instreet-v2/images/favicon-v2.ico',
  loadingAnimation: 'pub/assets/instreet-v2/img/loading/sis_loading.gif',
  gtmAccountId: 'GTM-MJDKK5',
  gtmMerchant: 'hTyJky8Bb4E3mRRWDm5BwDDENDDMj8CF1ovrOUNYGzI',
  siteKey: '6LcPQ64UAAAAAJv489J59h58XQ6t2X8cHODjm9NR',
  twitterCreator: '@instreet',
  appStoreId: '1436146422',
  androidPackage: 'app-id=tr.com.instreet',
  alternateIos: 'ios-app://1436146422/https/www.instreet.com',
  alternateAndroid: 'android-app://tr.com.instreet/https/www.instreet.com.tr',
  facebookDomainVerification:'rs942xs39zyvzbflwuslyo2erbd3u5',
  robotoFont: '',
  efilliScript: 'https://bundles.efilli.com/staging.instreet.com.tr.prod.js',
  segmentifyScript: '',
  siteName: 'INSTREET.COM.TR.',
  phoneCode: [{ name: '+90', mask: '(599) 999 99 99' }],
  isGlobal: false,
  cunda: true
};

export const instreetProd = {
  BASE_URL: 'https://www.instreet.com.tr/',
  COOKIE_BASE_URL: 'instreet.com.tr',
  CHECKOUT_URL: 'https://checkout-be.instreet.com.tr/',
  CHECKOUT_BASE_URL: 'https://checkout.instreet.com.tr/',
  currency: 'TL',
  googleClientId: '885834410672-4pjc4774hs5r8b6r5k347g2n1cfvajpc.apps.googleusercontent.com',
  facebookAppId: '286700772082040',
  appleClientId: 'com.instreet.appleClient',
  locale: 'tr',
  symbol: false,
  quoteitemtotal: 20,
  logoPath: 'pub/assets/instreet-v2/images/instreet-logo-v2-black.svg',
  project: 'instreet',
  title: 'Instreet',
  appDeepLink: 'instreet://',
  failuresNumber: '3',
  manifest: 'pub/pwa/manifest-is.json',
  favicon: 'pub/assets/instreet-v2/images/favicon-v2.ico',
  loadingAnimation: 'pub/assets/instreet-v2/img/loading/sis_loading.gif',
  gtmAccountId: 'GTM-MJDKK5',
  gtmMerchant: 'hTyJky8Bb4E3mRRWDm5BwDDENDDMj8CF1ovrOUNYGzI',
  siteKey: '6LcPQ64UAAAAAJv489J59h58XQ6t2X8cHODjm9NR',
  twitterCreator: '@instreet',
  appStoreId: '1436146422',
  androidPackage: 'app-id=tr.com.instreet',
  alternateIos: 'ios-app://1436146422/https/www.instreet.com',
  alternateAndroid: 'android-app://tr.com.instreet/https/www.instreet.com.tr',
  facebookDomainVerification:'rs942xs39zyvzbflwuslyo2erbd3u5',
  robotoFont: '',
  efilliScript: 'https://bundles.efilli.com/staging.instreet.com.tr.prod.js',
  segmentifyScript: '',
  siteName: 'INSTREET.COM.TR.',
  phoneCode: [{ name: '+90', mask: '(599) 999 99 99' }],
  isGlobal: false,
  cunda: false
};
