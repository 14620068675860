import * as React from 'react'
import { memo } from 'react'
const SvgIconBonus = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='1em'
    height='1em'
    data-name='Group 14415'
    viewBox='0 0 20.003 34.105'
    fill='currentColor'
    {...props}
  >
    <path
      fill='#f60'
      d='M19.992 23.865a2.3 2.3 0 0 0-.927-1.861 2.48 2.48 0 0 0-1.423-.507v-3.336a1.454 1.454 0 0 0-1.543-1.537h-.766c-.261 0-.479 0-.685.008h-.017l-1.349.01h-1.206a6.2 6.2 0 0 0 .189-1.441 6.44 6.44 0 0 0-5.322-6.586c-2.989-.345-5.459 2.045-5.529 5.35a6.8 6.8 0 0 0 .564 2.841A2.84 2.84 0 0 0 .01 19.57C0 23.083.003 26.655.01 30.109v1.388a2.52 2.52 0 0 0 .526 1.577 2.63 2.63 0 0 0 2.226 1.033H17.67a2.35 2.35 0 0 0 2.322-2.351c.02-2.587.012-5.108 0-7.891m-17.52-9.81a4.1 4.1 0 0 1 4.481-4.238 5.2 5.2 0 0 1 4.229 5.3 4.122 4.122 0 0 1-4.459 4.272 5.2 5.2 0 0 1-4.251-5.334m-1.151 4.4a1.72 1.72 0 0 1 1.1-.764h.02a5.92 5.92 0 0 0 4.273 2.87 4.88 4.88 0 0 0 5.01-2.932h4.13c.168 0 .305 0 .43.012a.38.38 0 0 1 .364.386l.008 2.211v1.251h-.971l-1.536.011c-.385.005-.785.008-1.222.008-.373 0-.778 0-1.24-.006H8.979c-.36 0-.834 0-1.317-.007l-2.3.007H3.081a1.99 1.99 0 0 1-2.034-1.48 1.91 1.91 0 0 1 .276-1.564Zm17.702 12.684a5.3 5.3 0 0 1-.088.96 1.36 1.36 0 0 1-1.46 1.024H3.656v-4.482a2 2 0 0 0-.008-.224.457.457 0 0 0-.468-.428h-.016a.464.464 0 0 0-.481.432 1 1 0 0 0-.007.2v4.487h-.11a1.59 1.59 0 0 1-1.55-1.315 4 4 0 0 1-.035-.463v-9.616a3.18 3.18 0 0 0 2.182.763h14.236a1.44 1.44 0 0 1 1.465.8 1.8 1.8 0 0 1 .149.622c.018.227.014.457.01.7v.2h-1.4c-.4 0-.862 0-1.328.024a2.94 2.94 0 0 0-2.6 1.957 2.99 2.99 0 0 0 .9 3.316 3.13 3.13 0 0 0 2.067.7h2.387c-.017.123-.02.234-.026.343m-.024-5.349v4.01c-.253 0-.508 0-.756.007-.282 0-.573.007-.86.007-.425 0-.774-.008-1.1-.026a1.975 1.975 0 0 1-1.808-1.993 2 2 0 0 1 1.953-2c.3-.014.633-.02 1.031-.02q.399-.001.8.005.373.009.742.009Z'
      data-name='Path 13411'
    />
    <path
      fill='#f60'
      d='M3.17 25.137a.48.48 0 0 0-.486.512v1.321a.47.47 0 0 0 .468.493h.037a.47.47 0 0 0 .469-.5c.008-.423.008-.866 0-1.316a.473.473 0 0 0-.488-.51'
      data-name='Path 13412'
    />
    <path
      fill='#f60'
      d='M16.737 26.478h-.015a1.32 1.32 0 0 0-1.293 1.321 1.3 1.3 0 0 0 1.314 1.315h.011a1.32 1.32 0 0 0 .939-.4 1.3 1.3 0 0 0 .374-.935 1.33 1.33 0 0 0-1.33-1.301m.248 1.552a.34.34 0 0 1-.243.106v.066-.066a.338.338 0 0 1-.015-.676h.018a.346.346 0 0 1 .336.331.33.33 0 0 1-.097.243Z'
      data-name='Path 13413'
    />
    <path
      fill='#f60'
      d='M11.776 7.827c1.364-.7 1.637-3 .6-5.088S9.402-.492 8.036.205s-1.631 2.961-.6 5.072 2.985 3.249 4.34 2.55M8.432.964c1.1-.537 2.665.393 3.47 2.056.814 1.683.588 3.526-.5 4.063-1.105.546-2.679-.388-3.489-2.071s-.574-3.511.522-4.048Z'
      data-name='Path 13414'
    />
    <path
      fill='#f60'
      d='M8.37 3.193a.4.4 0 0 0 .112.021c.194.04.238.2.346.37q.377.761.733 1.532a1.1 1.1 0 0 1 .116.377c.018.18-.139.183-.065.353.119.275.375.163.666.014a2.2 2.2 0 0 0 .493-.33.34.34 0 0 0 .085-.115c.034-.1-.086-.287-.171-.266a.27.27 0 0 1-.184 0c-.072-.035-.137-.127-.267-.372a3 3 0 0 1-.233-.494.125.125 0 0 1 .074-.15.21.21 0 0 1 .181-.02.44.44 0 0 1 .224.221c.05.084.093.166.151.162.159-.01.068-.339-.033-.6a3 3 0 0 0-.362-.674c-.122-.152-.276-.2-.268.006 0 .047.071.24.066.29a.24.24 0 0 1-.261.192c-.052 0-.111-.07-.157-.168a3 3 0 0 1-.152-.377c-.047-.142-.108-.271-.007-.345a1.1 1.1 0 0 1 .434-.17c.177-.032.339.152.523.233.1.043.2-.1.094-.315a1.5 1.5 0 0 0-.27-.4.8.8 0 0 0-.181-.153.11.11 0 0 0-.144.013l-.012.008q-.043.033-.107.079a6.4 6.4 0 0 1-1.31.651c-.1.039-.184.078-.205.187a.3.3 0 0 0 0 .086.22.22 0 0 0 .091.154'
      data-name='Path 13415'
    />
    <path
      fill='#f60'
      d='M5.751 17.015a5.7 5.7 0 0 0 1.151.035.7.7 0 0 0 .249-.061.28.28 0 0 0-.064-.451.7.7 0 0 1-.32-.154c-.1-.1-.127-.263-.136-.654a3.2 3.2 0 0 1 .031-.768c.027-.1.147-.126.261-.11a.57.57 0 0 1 .332.13.6.6 0 0 1 .2.444c.012.139.016.27.12.315.286.123.419-.334.47-.718a3.2 3.2 0 0 0-.035-1.084c-.078-.279-.308-.46-.473-.22a4 4 0 0 1-.141.39.57.57 0 0 1-.624 0c-.091-.043-.131-.174-.125-.327a3 3 0 0 1 .069-.564c.044-.2.052-.4.292-.405a3.2 3.2 0 0 1 .907.17c.336.113.455.461.7.709.129.13.432.058.442-.285a1.7 1.7 0 0 0-.116-.691 1.1 1.1 0 0 0-.181-.329.28.28 0 0 0-.263-.106h-.284a17.6 17.6 0 0 1-2.858-.353.62.62 0 0 0-.523.043.3.3 0 0 0-.081.1.24.24 0 0 0 .032.257.8.8 0 0 0 .176.119c.3.21.24.428.275.719q-.016 1.2-.077 2.387a1.05 1.05 0 0 1-.13.533c-.127.222-.4.094-.426.352-.033.418.511.504 1.15.577'
      data-name='Path 13416'
    />
    <path
      fill='#f60'
      d='M13.035 12.912a2.833 2.833 0 0 0 4.024.723 3.127 3.127 0 0 0 1.424-4.008 2.816 2.816 0 0 0-4.012-.72 3.127 3.127 0 0 0-1.436 4.005m1.7-3.55a2.26 2.26 0 0 1 3.207.611 2.53 2.53 0 0 1-1.162 3.2 2.27 2.27 0 0 1-3.216-.594 2.53 2.53 0 0 1 1.174-3.213Z'
      data-name='Path 13417'
    />
    <path
      fill='#f60'
      d='M14.672 12a.4.4 0 0 0 .1.083c.078.035.212-.072.185-.153a.3.3 0 0 1-.023-.173c.018-.067.082-.125.255-.24a1.8 1.8 0 0 1 .355-.2.11.11 0 0 1 .125.074.26.26 0 0 1 .038.171.3.3 0 0 1-.143.2c-.059.044-.117.083-.107.137.027.15.272.075.459-.013a1.8 1.8 0 0 0 .478-.32c.1-.11.118-.254-.038-.253-.036 0-.178.059-.217.053-.1-.016-.163-.124-.182-.251-.007-.049.041-.1.111-.143a2 2 0 0 1 .274-.132c.1-.04.2-.093.267 0a1.4 1.4 0 0 1 .186.414c.047.168-.076.314-.115.485-.021.089.1.19.257.1a.9.9 0 0 0 .277-.242.6.6 0 0 0 .1-.166.13.13 0 0 0-.028-.136l-.008-.011-.074-.1a8 8 0 0 1-.669-1.253c-.043-.1-.083-.176-.171-.2a.2.2 0 0 0-.067-.005.13.13 0 0 0-.107.085.4.4 0 0 0 0 .1c-.007.181-.124.218-.244.315q-.543.331-1.1.643a.6.6 0 0 1-.278.1c-.137.012-.159-.136-.282-.072-.2.1-.08.348.072.626a2.6 2.6 0 0 0 .314.477'
      data-name='Path 13418'
    />
  </svg>
)
const Memo = memo(SvgIconBonus)
export default Memo
