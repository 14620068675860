import {createSlice} from '@reduxjs/toolkit';

const INITIAL_STATE = {
  prevLocation: ''
};

const slice = createSlice({
  name: 'prevPath',
  initialState: INITIAL_STATE,
  reducers: {
    setPrevPathAction : (state, action) => {
      state.prevLocation = action.payload
    } 
  }
});

export default slice.reducer;

export const {
  setPrevPathAction,
} = slice.actions;