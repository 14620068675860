/* eslint-disable no-param-reassign */
/* eslint-disable import/no-cycle */
import {
  createSlice,
} from '@reduxjs/toolkit';

const INITIAL_STATE = window.GENERAL_CONFIG || {};

const slice = createSlice({
  name: 'redirect',
  initialState: {
    page: '',
  },
  reducers: {
    goToPage: (state, action) => {
      state.page = action.payload;
    },
  },
  extraReducers: (builder) => {

  },
});

export default slice.reducer;

export const {
  goToPage,
} = slice.actions;
