/* eslint-disable max-len */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { paymenHeaderActiveAction, isPaymentWithCardAction } from '@Actions';

export default () => {
  const {
    cashPaymentMethod,
  } = useSelector((state) => state.order);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(paymenHeaderActiveAction(true));
    dispatch(isPaymentWithCardAction(false));
    return () => {
      dispatch(paymenHeaderActiveAction(false));
      dispatch(isPaymentWithCardAction(true));
    };
  }, []);

  return (
    <div data-widget="checkout/cash_on_delivery" className="widget widget_checkout_cash_on_delivery">
      <form id="kapida-odeme" className="cash-on-delivery flex-column flex-md-row">
        <div
          dangerouslySetInnerHTML={{
            __html: cashPaymentMethod?.description,
          }}
        />
      </form>
    </div>
  );
};
