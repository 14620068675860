/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import API from '@API';
import {
  setDijitalPaymentMethodAction,
  paymenHeaderActiveAction,
  isPaymentWithCardAction,
  showPopupAction,
  setInstallmentAction,
  setAxessAction,
  setPaymentMethodAction,
  setCreditCardAction,
  setHepsipayLinkAccountLoading
} from '@Actions';
import config from '@Config';
import {
  gettext
 } from '@Translate';
import HepsiPay from './HepsiPay';
import OTPForm from './OTPForm';

export default () => {
  const dispatch = useDispatch();

  const {
    dijitalPaymentMethod,
    paymentMethods,
    hepsipayAlreadyLinked,
    savedCardList,
    numberOfInstallments,
    hepsiPaySavedCardList
  } = useSelector((state) => state.order);

  const { project } = config;
  const [dijitalPayment, setDijitalPayment] = useState();
  const [hepsipayRender, setHepsipayRender] = useState(false);

  useEffect(async () => {
    const dijitalPaymentItem = paymentMethods?.find((item) => item.code === "dijital_banka_odemeleri");
    const dijitalPaymentHepsiPay = dijitalPaymentItem?.subPaymentMethods?.find((item) => item.code === "flo_hepsipay");
    setDijitalPayment(dijitalPaymentItem);
    dispatch(paymenHeaderActiveAction(true));
    dispatch(isPaymentWithCardAction(false));

      if ((dijitalPaymentHepsiPay?.code === 'flo_hepsipay') && hepsiPaySavedCardList && savedCardList) {
        await dispatch(setInstallmentAction({ creditCardPlatform: hepsiPaySavedCardList[0]?.name}));
      }


    return () => {
      dispatch(paymenHeaderActiveAction(false));
      dispatch(isPaymentWithCardAction(true));
    };
  }, []);

  useEffect(async ()=> {
    const options = {};
    if(hepsiPaySavedCardList.length > 0 && hepsipayRender === true) {
      options.creditCartInfo = {
        creditCartId: hepsiPaySavedCardList[0]?.id,
        numberOfInstallments,
      };
      await dispatch(setInstallmentAction({ creditCardPlatform: hepsiPaySavedCardList[0]?.name}));
      await dispatch(setCreditCardAction(options));
      await dispatch(setPaymentMethodAction('flo_hepsipay'));
      await dispatch(setDijitalPaymentMethodAction('flo_hepsipay'));
    }
    setHepsipayRender(true)
  },[hepsiPaySavedCardList]);
  const onChangeDijitalPaymentMethod = async (subPaymentMethodItem) => {

    if(subPaymentMethodItem.code === 'flo_hepsipay') {
      const dijitalPaymentItem = paymentMethods?.find((item) => item.code === "dijital_banka_odemeleri");
      if(typeof dijitalPaymentItem === 'object') {
        const options = {};
        if(hepsiPaySavedCardList) {
          options.creditCartInfo = {
            creditCartId: hepsiPaySavedCardList[0]?.id,
            numberOfInstallments,
          };
        }
        await dispatch(setCreditCardAction(options));
      }
    } else {
      await dispatch(setAxessAction(0));
    }
    if((hepsipayAlreadyLinked === false && subPaymentMethodItem.code === 'flo_hepsipay')) {
      onClickHepsiPayActiveButton();
    }else {
      if(subPaymentMethodItem.code === 'flo_hepsipay' && (savedCardList === undefined || savedCardList?.length === 0) && hepsiPaySavedCardList.length > 0) {
        await dispatch(setInstallmentAction({ creditCardPlatform: hepsiPaySavedCardList[0]?.name}));
      }
      await dispatch(setPaymentMethodAction(subPaymentMethodItem?.code));
      await dispatch(setDijitalPaymentMethodAction(subPaymentMethodItem.code));
    }
  };

  const onClickHepsiPayActiveButton = () => {
    dispatch(setHepsipayLinkAccountLoading(true))
    API.hepsiPaylinkAccount()
    .then((response) => {
      const responseData = response.data;
      if (responseData.status.code === 200) {
        if (responseData.showVerifiedCode === true) {
          dispatch(showPopupAction(() => <OTPForm formDetail={responseData} />))
        }
      } else {
        setErrMessage(responseData?.status?.message);
      }
      dispatch(setHepsipayLinkAccountLoading(false))
    })
    .catch((err) => {
      console.log(err);
      dispatch(setHepsipayLinkAccountLoading(false))
    });

  }

  return (
    <div data-widget="checkout/dijital_payment" className="widget widget_checkout_dijital_payment">
      <form id="dijital-payment" className="dijital-payment">
        <>
          <div className="dijital-payment__select">
            {dijitalPayment?.subPaymentMethods?.map((subPaymentMethodItem, index) => (
              <div
                key={`dijital-payment-${index.toString()}`}
                className={`dijital-payment-item ${
                  dijitalPaymentMethod === subPaymentMethodItem?.code ? 'active' : ''
                }`}
              >
                <button
                  type="button"
                  onClick={() => onChangeDijitalPaymentMethod(subPaymentMethodItem)}
                  className="dijital-payment-item__button"
                  data-test="checkout-dijital-payment-check-button"
                  key={subPaymentMethodItem?.code}
                  >
                  <div className="dijital-payment-item__box">
                    <span className={`dijital-payment-item__icon dijital-payment-item__icon--${project}`}><i className="icon-check-bold" /></span>
                    <picture className="dijital-payment-item__image">
                      <img src={subPaymentMethodItem?.logo} alt="" />
                    </picture>
                    <span className="dijital-payment-item__text">{subPaymentMethodItem?.title}</span>
                  </div>
                  {subPaymentMethodItem.code === 'flo_hepsipay' && hepsipayAlreadyLinked === false && (
                    <span className="dijital-payment-item__badge">{gettext('Aktif Et')}</span>
                  )}
                </button>
                <div className="dijital-payment-item__body">
                  {subPaymentMethodItem.code === 'flo_hepsipay' ? (
                    hepsipayAlreadyLinked && (
                      <div className="dijital-payment-item__hepsipay">
                        <HepsiPay item={subPaymentMethodItem} />
                      </div>
                    )
                  ) : (
                    <div dangerouslySetInnerHTML={{
                      __html: subPaymentMethodItem?.description,
                    }}>
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
        </>
      </form>
    </div>
  );
};
