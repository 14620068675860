import * as React from 'react'
import { memo } from 'react'
const TierTwo = (props) => (
<svg id="Group_17596" data-name="Group 17596" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="21.872" height="17.748" viewBox="0 0 21.872 17.748" {...props}>
  <defs>
    <clipPath id="clip-path">
      <rect id="Rectangle_15121" data-name="Rectangle 15121" width="21.872" height="17.748" fill="#ee4e17"/>
    </clipPath>
  </defs>
  <g id="Group_17585" data-name="Group 17585">
    <path id="Path_14561" data-name="Path 14561" d="M53.964,9.626a2.339,2.339,0,0,1-.651.651,2.339,2.339,0,0,1,.651.651,2.339,2.339,0,0,1,.651-.651,2.339,2.339,0,0,1-.651-.651" transform="translate(-32.743 -5.912)" fill="#ee4e17"/>
    <path id="Path_14562" data-name="Path 14562" d="M14.772,12.148l.661-10.581s0-.008,0-.013,0-.007,0-.011C15.433.043,9.892,0,9.26,0S3.087.043,3.087,1.543c0,0,0,.008,0,.012s0,.008,0,.012l.661,10.581C1.33,12.718,0,13.6,0,14.662c0,2,4.771,3.087,9.26,3.087h0c4.489,0,9.26-1.082,9.26-3.087,0-1.057-1.33-1.944-3.749-2.513m-.689-1.343a26.219,26.219,0,0,1-4.822.384,26.217,26.217,0,0,1-4.822-.384l-.1-1.544a27.175,27.175,0,0,0,4.918.385,27.179,27.179,0,0,0,4.919-.385ZM4.629,13.863,4.488,11.6a28.058,28.058,0,0,0,4.772.358,28.063,28.063,0,0,0,4.772-.358l-.141,2.26c-.195.293-1.873.8-4.631.8s-4.435-.506-4.631-.8M14.658,1.582l-.43,6.877a25.637,25.637,0,0,1-4.969.414A25.632,25.632,0,0,1,4.292,8.46l-.43-6.877C4.117,1.3,5.941.772,9.26.772s5.144.525,5.4.811M9.26,16.977C4,16.977.772,15.629.772,14.662c0-.507.94-1.222,3.025-1.733l.062.985s0,.007,0,.011c.076,1.465,4.85,1.509,5.4,1.509s5.316-.043,5.4-1.506c0,0,0-.008,0-.013l.062-.985c2.086.512,3.026,1.226,3.026,1.733,0,.967-3.229,2.315-8.488,2.315" fill="#ee4e17"/>
    <path id="Path_14563" data-name="Path 14563" d="M47.63,2.315a.385.385,0,0,0-.386-.386A1.545,1.545,0,0,1,45.7.386a.386.386,0,1,0-.772,0,1.545,1.545,0,0,1-1.543,1.543.386.386,0,1,0,0,.772,1.545,1.545,0,0,1,1.543,1.543.386.386,0,1,0,.772,0A1.545,1.545,0,0,1,47.244,2.7a.385.385,0,0,0,.386-.386m-2.315.651a2.339,2.339,0,0,0-.651-.651,2.339,2.339,0,0,0,.651-.651,2.339,2.339,0,0,0,.651.651,2.339,2.339,0,0,0-.651.651" transform="translate(-26.409 0)" fill="#ee4e17"/>
  </g>
</svg>
)

const Memo = memo(TierTwo)
export default Memo
