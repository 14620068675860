import React, { useEffect } from 'react';
import {
  goToPage,
} from '@Actions';
import {
  useLocation,
  Navigate,
} from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';

const RedirectToPage = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { page } = useSelector((state) => state.redirect);

  useEffect(() => {
    setTimeout(() => {
      dispatch(goToPage(null));
    }, 1);
  }, [page]);

  if (!page) {
    return null;
  }

  return (
    <Navigate to={page} state={{ from: location }} />
  )
};

export default RedirectToPage;
