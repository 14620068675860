import React, { useState } from 'react';
import PropTypes from 'prop-types';
import InputMask from 'react-input-mask';
import {
  phoneControl,
  textControl,
  alphanumericControl,
  checkTCKNorVergi,
  checkTCKNo,
  passwordControl,
} from '@Constants';
import {
  gettext,
} from '@Translate';
import {
  IconEyeOpen,
  IconEyeClose,
} from '@Icons';
import config from '@Config';

const { project } = config;

const beforeMaskedValueChange = (newState) => {
  const { value, selection } = newState;

  return {
    value,
    selection,
  };
};

const checkEmail = (e) => {
  const charMap = {
    Ç: 'C',
    Ö: 'O',
    Ş: 'S',
    İ: 'I',
    I: 'i',
    Ü: 'U',
    Ğ: 'G',
    ç: 'c',
    ö: 'o',
    ş: 's',
    ı: 'i',
    ü: 'u',
    ğ: 'g',
  };
  let { value } = e.target;
  const valueArray = value.split('');
  for (let i = 0, len = valueArray?.length; i < len; i++) {
    valueArray[i] = charMap[valueArray[i]] || valueArray[i];
  }
  value = valueArray.join('');
  const clearStr = value?.replace(' ', '').replace(/[^a-z0-9-.çöşüğı@_+]/gi, '');
  e.target.value = clearStr;
};
const renderSwitch = ({
  pattern, onChange, onKeyPress, onKeyUp, autoComplete, value, className, name, type, placeholder, maxLength, minLength, dataTest, readOnly, errorMessage, tabIndex, mask,
}) => {
  switch (pattern) {
    case 'phone':
      return (
        <InputMask
          mask={ project === 'lumberjack_it' || project === 'flo-kz' ? '9999999999' : mask || '(599) 999 99 99'}
          maskChar="_"
          value={value}
          type="tel"
          onChange={onChange}
          beforeMaskedValueChange={beforeMaskedValueChange}
          className={className}
          placeholder={placeholder}
          errorMessage={errorMessage}
          tabIndex={tabIndex}
        />
      );
    case 'onlyNumber':
    case 'TCKNorVergi':
      return (
        <input
          name={name}
          data-test={dataTest || ''}
          id={`input-${name}`}
          value={value}
          onChange={(event) => {
            if (event.target.validity.valid) {
              onChange(event);
            }
          }}
          maxLength={maxLength || null}
          autoComplete={autoComplete}
          pattern="[0-9]*"
          type={type}
          className={className}
          placeholder={placeholder}
          tabIndex={tabIndex}
        />
      );
      case 'TCKNo':
        return (
          <input
            name={name}
            data-test={dataTest || ''}
            id={`input-${name}`}
            value={value}
            onChange={(event) => {
              if (event.target.validity.valid) {
                onChange(event);
              }
            }}
            maxLength={maxLength || null}
            autoComplete={autoComplete}
            pattern="[0-9]*"
            type={type}
            className={className}
            placeholder={placeholder}
            tabIndex={tabIndex}
          />
        );
    case 'onlyAlphabets':
      return (
        <input
          name={name}
          data-test={dataTest || ''}
          id={`input-${name}`}
          value={value}
          onChange={(event) => {
            if (event.target.validity.valid) {
              onChange(event);
            }
          }}
          autoComplete={autoComplete}
          pattern="[A-z0-9ğüşıİüÜçÇöÖÀ-žА-яЁё \s]*"
          type={type}
          className={className}
          placeholder={placeholder}
          tabIndex={tabIndex}
        />
      );
    case 'alphanumeric':
      return (
        <input
          name={name}
          id={`input-${name}`}
          data-test={dataTest || ''}
          value={value}
          onChange={(event) => {
            if (event.target.validity.valid) {
              onChange(event);
            }
          }}
          autoComplete={autoComplete}
          pattern="[A-z0-9ğüşıİüÜçÇöÖÀ-žА-яЁё \s]*"
          type={type}
          className={className}
          placeholder={placeholder}
          tabIndex={tabIndex}
        />
      );
    case 'email':
      return (
        <input
          name={name}
          id={`input-${name}`}
          data-test={dataTest || ''}
          value={value}
          onChange={(event) => {
            if (event.target.validity.valid) {
              checkEmail(event);
              onChange(event);
            }
          }}
          autoComplete={autoComplete}
          type={type}
          className={className}
          placeholder={placeholder}
          onKeyUp={onKeyUp}
          errorMessage={errorMessage}
          tabIndex={tabIndex}
        />
      );
    case 'password':
      const [showPassword, setshowPassword] = useState(false);
      const handleEyeClick = (e) => {
        setshowPassword(!showPassword);
      };

      return (
        <>
          <input
            name={name}
            data-test={dataTest || ''}
            id={`input-${name}`}
            value={value}
            onChange={onChange}
            autoComplete={autoComplete}
            type={showPassword ? 'text' : type}
            className={className}
            placeholder={placeholder}
            onKeyUp={onKeyUp}
            tabIndex={tabIndex}
          />
          <span className="bs-eye" onClick={handleEyeClick} data-test="checkout-element-close-open-button">
            {showPassword ? <IconEyeOpen /> : <IconEyeClose />}
          </span>
        </>
      );
    default:
      return (
        <input
          name={name}
          data-test={dataTest || ''}
          id={`input-${name}`}
          value={value}
          onChange={onChange}
          autoComplete={autoComplete}
          type={type}
          className={className}
          placeholder={placeholder}
          onKeyUp={onKeyUp}
          tabIndex={tabIndex}
        />
      );
  }
};
function Input({
  errorMessage = gettext('Lütfen bu alanı doldurunuz'),
  secondMessage,
  error = false,
  type = 'text',
  placeholder,
  name,
  value,
  onChange,
  onKeyUp,
  autoComplete = 'off',
  className,
  maxLength,
  pattern,
  minLength,
  dataTest,
  readOnly = false,
  tabIndex,
  mask,
  phoneCodeDefault,
}) {
  const checkValidation = (val) => {
    if (minLength && minLength > val?.length) {
      return false;
    }
    if (pattern === 'TCKNorVergi') {
      return checkTCKNorVergi(val)?.check;
    }
    if (pattern === 'TCKNo') {
      return checkTCKNo(val)?.check;
    }
    if (pattern === 'onlyAlphabets') {
      return textControl(val);
    }
    if (pattern === 'alphanumeric') {
      return alphanumericControl(val);
    }
    if (pattern === 'phone') {
      return phoneControl(val, phoneCodeDefault);
    }
    if (pattern === 'email') {
      return /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(val);
    }
    if (pattern === 'password') {
      return passwordControl(val).check;
    }
    if (pattern) {
      return val?.length > 0;
    }
    return true;
  };

  return (
    <>
      {renderSwitch({
        pattern, onChange, onKeyUp, autoComplete, value, className, name, type, placeholder, maxLength, dataTest, readOnly, error, tabIndex, mask, phoneCodeDefault,
      })}
      {pattern == 'password' && name != 'login-password' ? (
        <span className={`form-info ${passwordControl(value)?.check ? 'valid' : (error ? 'error' : '')}`}>
          {gettext('Lütfen en az')}
          {' '}
          <em className={`${passwordControl(value)?.lenghtCheck ? 'valid' : (error ? 'error' : '')}`}>
            {gettext('8 karakter')}
            ,
          </em>
          {' '}
          <em className={`${passwordControl(value)?.upperCheck ? 'valid' : (error ? 'error' : '')}`}>
            1
            {' '}
            {gettext('büyük harf')}
          </em>
          {' '}
          <em className={`${passwordControl(value)?.numberCheck ? 'valid' : (error ? 'error' : '')}`}>
            {gettext('ve')}
            {' '}
            1
            {' '}
            {gettext('rakam giriniz')}
            .
          </em>
        </span>
      ) : (
        (!checkValidation(value?.trim ? value?.trim() : value) && error) && (
          <div className="error">{secondMessage || errorMessage}</div>
        )
      )}
    </>
  );
}

Input.propTypes = {
  error: PropTypes.bool.isRequired,
  forceError: PropTypes.bool,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onKeyUp: PropTypes.func,
  type: PropTypes.string,
  errorMessage: PropTypes.string,
  placeholder: PropTypes.string,
  autoComplete: PropTypes.string,
  className: PropTypes.string,
  tabIndex: PropTypes.string,
  mask: PropTypes.string,
  phoneCodeDefault: PropTypes.string,
};

export default Input;
