import * as React from 'react'
import { memo } from 'react'
const SvgLoading = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='1em'
    height='1em'
    viewBox='0 0 68.666 70.859'
    fill='currentColor'
    {...props}
  >
    <defs>
      <clipPath id='loading_svg__a'>
        <path
          fill='#f60'
          d='M0 0h68.666v70.859H0z'
          data-name='Rectangle 3220'
        />
      </clipPath>
    </defs>
    <g clipPath='url(#loading_svg__a)' data-name='Group 5853'>
      <path
        fill='rgba(255,102,0,0.4)'
        d='M32.314 60.086c0-2.449-.015-4.9 0-7.346a3.12 3.12 0 0 1 2.669-3.3 3.065 3.065 0 0 1 3.459 2.3 5.5 5.5 0 0 1 .129 1.173q.013 7.228 0 14.455c0 2.113-1.264 3.493-3.142 3.489a3.1 3.1 0 0 1-3.119-3.348c-.021-2.475-.005-4.95-.005-7.425'
        data-name='Path 13464'
      />
      <path
        fill='#f60'
        d='M38.571 10.776c0 2.5.016 5-.006 7.506a3.08 3.08 0 0 1-2.346 3.082 3.02 3.02 0 0 1-3.482-1.52 3.9 3.9 0 0 1-.393-1.662q-.044-7.467-.012-14.933a3.121 3.121 0 1 1 6.237.02c.016 2.5 0 5 0 7.506'
        data-name='Path 13465'
      />
      <path
        fill='rgba(255,102,0,0.8)'
        d='M10.733 38.554c-2.528 0-5.056.018-7.584-.008a3.07 3.07 0 0 1-3.011-2.234 2.92 2.92 0 0 1 1.2-3.4 4.13 4.13 0 0 1 2-.581c4.95-.047 9.9-.037 14.852-.019a3.124 3.124 0 1 1-.03 6.234c-2.475.012-4.951 0-7.426 0'
        data-name='Path 13466'
      />
      <path
        fill='rgba(255,102,0,0.1)'
        d='M56.548 38.554c-2.528 0-5.056.018-7.584-.008a3.07 3.07 0 0 1-3.011-2.234 2.92 2.92 0 0 1 1.2-3.4 4.13 4.13 0 0 1 2-.581c4.95-.047 9.9-.037 14.852-.019a3.124 3.124 0 1 1-.03 6.234c-2.475.012-4.951 0-7.426 0'
        data-name='Path 13471'
      />
      <path
        fill='#f60'
        d='M12.365 9.484a3.42 3.42 0 0 1 2.716 1.17q3.9 3.916 7.807 7.816c.965.968 1.961 1.911 2.867 2.933a2.99 2.99 0 0 1 .065 3.849 3.046 3.046 0 0 1-3.82.871 4.1 4.1 0 0 1-1-.752q-5.229-5.205-10.433-10.435a3.1 3.1 0 0 1 1.1-5.325 4 4 0 0 1 .7-.126'
        data-name='Path 13467'
      />
      <path
        fill='rgba(255,102,0,0.6)'
        d='M26.469 47.489a3 3 0 0 1-.946 2.265c-3.548 3.556-7.09 7.12-10.66 10.653a3.1 3.1 0 1 1-4.412-4.357q5.321-5.391 10.709-10.716a3.1 3.1 0 0 1 5.308 2.154'
        data-name='Path 13468'
      />
      <path
        fill='#f60'
        d='M47.497 26.448a2.96 2.96 0 0 1-2.8-1.826 2.85 2.85 0 0 1 .456-3.241c.858-.959 1.793-1.849 2.7-2.761 2.694-2.7 5.382-5.4 8.092-8.088a3.104 3.104 0 0 1 4.83 3.9 6 6 0 0 1-.523.589q-5.13 5.136-10.262 10.27a3.31 3.31 0 0 1-2.493 1.157'
        data-name='Path 13469'
      />
      <path
        fill='rgba(255,102,0,0.2)'
        d='M61.392 58.284a2.91 2.91 0 0 1-1.718 2.784 2.98 2.98 0 0 1-3.325-.384 6 6 0 0 1-.526-.476q-5.135-5.131-10.264-10.268c-1.567-1.575-1.5-3.714.123-4.929a3.09 3.09 0 0 1 3.736.047 7 7 0 0 1 .584.53q5.107 5.1 10.212 10.208a3.3 3.3 0 0 1 1.178 2.489'
        data-name='Path 13470'
      />
    </g>
  </svg>
)
const Memo = memo(SvgLoading)
export default Memo
