/* eslint-disable react/jsx-filename-extension */
import React, { useEffect, useState, useRef } from 'react';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import { toast } from 'react-toastify';
import {
  Input, ToastfyMessage, OtpInput
} from '@Components';
import {
  phoneControl,
} from '@Constants';
import {
  setWalletOtpStepAction,
  hidePopupAction,
  showPopupAction,
  isTopPopupAction,
} from '@Actions';
import {
  gettext,
} from '@Translate';
import config from '@Config';
import {
  BackButton,
} from '@Icons';
import API from '@API';
import Countdown from 'react-countdown';

const CmsPage = ({ content }) => (
  <div
    dangerouslySetInnerHTML={{
      __html: content,
    }}
  />
);

const SideBar = ({ onSuccess }) => {
  const dispatch = useDispatch();
  const {
    walletOtpStep,
  } = useSelector((state) => state.order);
  const [showError, setShowError] = useState(false);
  const [telephone, setTelephone] = useState('');
  const { webview } = useSelector((state) => state.config);
  const { BASE_URL, project } = config;
  const [verificationData, setVerificationData] = useState({});
  const [verificationOtp, setVerificationOtp] = useState({});
  const [OTP, setOTP] = useState('');
  const [err, setErr] = useState(false);
  const [errMessage, setErrMessage] = useState('');
  const [errPhoneMessage, setErrPhoneMessage] = useState('');
  const [disabled, setDisabled] = useState(true);
  const startDate = useRef(Date.now());
  const [time, setTime] = useState('');
  const [reSendCode, setReSendCode] = useState(true);
  const [isNull, setIsNull] = useState(false);
  const { guest } = useSelector((state) => state.auth);

  // tuşa her basıldığında telefon doğrulama yapıyor
  const phoneOnChange = (phone) => {
    setErrPhoneMessage('');
    setTelephone(phone?.target?.value);
    const valueLenght = phone?.target?.value?.replace(/[^0-9]/g, '').length;
    if (valueLenght > 9) {
      setShowError(!phoneControl(phone?.target?.value));
    }
  };

  // tuşa her basıldığında otp doğrulama yapıyor
  const otpOnChange = (otp) => {
    if (otp?.match(/[^0-9]/g)) {
      setErr((prevState) => (prevState = true));
      return false;
    }
    setOTP(otp);
  };

  // telefon validate işlemi yapıyor
  const validatePhoneNumber = () => {
    if (phoneControl(telephone)) {
      return true;
    }
    return false;
  };

  // hesap doğrulaması yapılmış mı ?
  useEffect(() => {
    API?.getCheckCustomerVerification()
      .then((response) => {
        setVerificationData(response?.data);
        setOTP('');
        if (response?.data?.phone) {
          setTelephone(response?.data?.phone?.replace('+9', ''));
          dispatch(setWalletOtpStepAction(1));
        } else {
          setIsNull(true);
          dispatch(setWalletOtpStepAction(2));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  // Telefon Doğrulama Servisene telefon gönderiliyor
  const sendActivationOtp = () => {
    // validatePhoneNumber()
    setErrMessage('');
    setErrPhoneMessage('');
    API?.customerVerification({
      phone: telephone,
    })
      .then((response) => {
        if (response?.data?.success) {
          setVerificationOtp(response?.data);
          setOTP('');
        } else {
          setErrPhoneMessage(response?.data?.message);
        }
      })
      .catch((err) => console.log(err));
  };

  // Otp bilgileri gelmişse Otp Ekranını Açıyor
  useEffect(() => {
    if (verificationOtp.showVerifiedCode) {
      startDate.current = Date.now();
      setTime(verificationOtp.time * 1000);
      setReSendCode(true);
      setOTP('');
      dispatch(setWalletOtpStepAction(3));
    }
  }, [verificationOtp]);

  // Step değiştiğinde telefon numarası güncelleniyor
  useEffect(() => {
    if (walletOtpStep === 1) {
      setTelephone(verificationData?.phone?.replace('+9', ''));
    }
  }, [walletOtpStep]);

  // Doğrulama Kodu Zaman kontrolü yapılıyor
  const renderer = ({ completed, formatted, total }) => {
    if (completed) {
      setReSendCode(false);
      return (
        <span>
          00:00
        </span>
      );
    }
    return (
      <span>
        {formatted?.minutes}
        :
        {formatted?.seconds}
      </span>
    );
  };

  // OTP Kod uzunluk kontrolü yapılıyor ve button aktif oluyor

  useEffect(() => {
    if (OTP?.length > 5) {
      if (err === true) {
        setErr((prevState) => (prevState = false));
      } else {
        setDisabled(false);
      }
    } else {
      setDisabled(true);
    }
  }, [OTP]);

  // Servise Otp Kodu yollanıyor
  const sendOTP = () => {
    if (OTP?.length > 5) {
      API?.customerVerification({
        phone: telephone,
        code: OTP,
      })
        .then((response) => {
          if (response?.data?.success) {
            dispatch(hidePopupAction());
            toast.dismiss();
            toast.error(ToastfyMessage({
              text: response?.data?.message,
              message: 'Keyifli alışverişler dileriz…',
              type: 'success',
            }), {
              autoClose: false,
              onClose: ({ uid }) => {
                window.location.reload();
              },
            });
          } else {
            setErrMessage(response?.data?.message);
            setErr((prevState) => (prevState = true));
            setOTP('');

            const errorTimer = setTimeout(() => {
              setErr((prevState) => (prevState = false));
            }, 1000);
            return () => clearTimeout(errorTimer);
          }
        })
        .catch((err) => console.log(err));
    }
  };

  // Telefon Doğrulama Ekranına Dönüyor
  const onBackButtonBack = () => {
    setErrPhoneMessage('');
    dispatch(setWalletOtpStepAction(1));
  };

  const handlePhoneNumberForm = (event) => {
    event.preventDefault();
    sendActivationOtp();
  };

  const handleCodeForm = (event) => {
    event.preventDefault();
    sendOTP();
  };

  // Modali Kapatıyor
  const handleClose = () => {
    dispatch(hidePopupAction());
  };

  const openToCmsPage = async (page) => {
    const res = await APIs.getCmsPage({ identifier: page });
    const { status, content } = res?.data;
    if (status?.code === 200) {
      await dispatch(isTopPopupAction(false));
      await dispatch(showPopupAction(() => CmsPage({ page, content })));
    } else {
      toast.error(ToastfyMessage({ text: gettext('Hata! Lütfen tekrar deneyiniz.') }));
    }
  };
  switch (walletOtpStep) {
    case 3:
      return (
        <form onSubmit={handleCodeForm} style={{ minHeight: '420px' }}>
          <div className="sign-up-container">
            <div className="sign-up-container__header">
              <div className="sign-up-container__header--title">{gettext('Telefon Numaranızı Doğrulayın')}</div>
            </div>
            <span className="login-back-button" onClick={onBackButtonBack} data-test="checkout-payment-process-back-three-button">
              <BackButton />
            </span>

            <div className={`sign-up-container__form-inner guest_${guest}`}>
              <div className="otp-content__title">
                {telephone}
                {' '}
                {gettext('numaralı telefonunuza gönderdiğimiz doğrulama')}
                {gettext('kodunu girin. Ref:')}
                {verificationOtp.refCode}
              </div>
              <div className="otp-content__input-container">
                <div className={`otp-content__count-section ${project}`}>
                  <div className="otp-content__timer-section--title">
                    {gettext('Doğrulama Kodu')}
                  </div>
                  <div className="otp-content__timer-section--count">
                    <span>
                      <i className="fa-solid mr-2 fa-clock-rotate-left" />
                    </span>
                    <Countdown key={startDate?.current} date={startDate?.current + time} renderer={renderer} />
                  </div>
                </div>
                <OTPInput
                  value={OTP}
                  onChange={otpOnChange}
                  numInputs={6}
                  separator={<span>&nbsp;</span>}
                  inputStyle="otp-content-in--item"
                  shouldAutoFocus
                  focusStyle="active"
                  containerStyle="otp-content-in"
                  errorStyle="form-error"
                  hasErrored={!!err}
                  isInputNum
                />
                {errMessage && (
                  <div className="error-message">
                    {errMessage}
                  </div>
                )}
                <div className="otp-content__re-send-title">
                  <a onClick={sendActivationOtp} className={reSendCode ? 'disabled' : ''} data-test="checkout-payment-process-again-two-button">{gettext('Kodu Tekrar Gönder')}</a>
                </div>
              </div>
              <div className={`otp-content__button-container ${project}-otp-content__button-container`}>
                <button
                  disabled={!!disabled}
                  type="button"
                  id="fl-lg-35"
                  className="btn otp-content__btn mb-2 btn-block"
                  data-test="submit-otp-button-verify"
                  onClick={sendOTP}
                >
                  {gettext('ONAYLA')}
                </button>
                <button
                  type="button"
                  id="fl-lg-35"
                  className="btn otp-content__btn btn--cancel mb-2 btn-block"
                  data-test="submit-otp-button-cancel"
                  onClick={handleClose}
                >
                  {gettext('VAZGEÇ')}
                </button>
              </div>
            </div>
          </div>
        </form>
      );
    case 2:
      return (
        <form onSubmit={handlePhoneNumberForm} style={{ minHeight: '420px' }}>
          {!isNull && (
          <span className="login-back-button" onClick={onBackButtonBack} data-test="checkout-payment-process-back-button">
            <BackButton />
          </span>
          )}
          <div className="empty-information__icon"><i className="icon-wallet-full" /></div>
          <h4 className="wallet-title mb-4">
            <span>{gettext('FLO Plus')}</span>
            {gettext('’ı Aktif Edin')}
            ,
            {' '}
            {gettext('hesabınızı doğrulayın siz de Kazanmaya Başlayın!')}
          </h4>
          <div className="form-group" style={{ minHeight: '144px' }}>
            <Input
              pattern="phone"
              type="text"
              id="activation_code"
              className="form-control"
              placeholder={gettext('Cep Telefonu')}
              onChange={phoneOnChange}
              dataTest="checkout-payment-sidebar-active-code-change"
            />
            {showError && (
            <div className="error">
              {gettext('Geçerli telefon numarası giriniz.')}
            </div>
            )}
            {errPhoneMessage && (
              <div className="error-message">
                {errPhoneMessage}
              </div>
            )}
          </div>
          <div className="form-link">
            {webview ? (
              <>
                <button onClick={() => openToCmsPage('aydinlatma-metni-mobile')} type="button" style={{ border: 0, backgroundColor: 'transparent', cursor: 'pointer' }} className="wallet-check-agreement" data-test="checkout-payment-process-agrrements-two-button"><span>{gettext('FLO Mağazacılık ve Pazarlama A.Ş. Müşteri Aydınlatma Metni')}</span></button>
                {gettext('’ni okudum.')}
              </>
            ) : (
              <>
                <a href={`${BASE_URL}aydinlatma-metni`} target="_blank" className="wallet-check-agreement" rel="noreferrer">{gettext('FLO Mağazacılık ve Pazarlama A.Ş. Müşteri Aydınlatma Metni')}</a>
                {gettext('’ni okudum.')}
              </>
            )}
          </div>
          <div className="row">
            <div className="col-12">
              <button type="button" id="check_activation_otp" className={`btn btn-orange w-100 mb-0 ${validatePhoneNumber() ? '' : 'btn--disabled'}`} onClick={sendActivationOtp} data-test="checkout-payment-process-again-button">
                {gettext('DOĞRULAMA KODU GÖNDER')}
              </button>
            </div>
          </div>
        </form>
      );
    default:
      return (
        <form onSubmit={handlePhoneNumberForm} style={{ minHeight: '420px' }}>
          <div className="empty-information__icon"><i className="icon-wallet-full" /></div>
          <h4 className="wallet-title mb-4">
            <span>{gettext('FLO Plus')}</span>
            {gettext('’ı Aktif Edin')}
            ,
            {' '}
            {gettext('hesabınızı doğrulayın siz de Kazanmaya Başlayın!')}
          </h4>
          <div className="wallet-otp" style={{ minHeight: '160px' }}>
            <div className="wallet-otp__phone">{verificationData?.phone}</div>
            <div className="wallet-otp__content">{gettext('numaralı telefonuma doğrulama kodu gönder.')}</div>
            <button
              className="wallet-otp__step"
              type="button"
              data-test="checkout-payment-process-phone-button"
              onClick={() => {
                dispatch(setWalletOtpStepAction(2));
              }}
            >
              {gettext('Farklı Telefon Numarası Kullan')}

            </button>
            {errPhoneMessage && (
              <div className="error-message">
                {errPhoneMessage}
              </div>
            )}
          </div>
          <div className="form-link">
            {webview ? (
              <>
                <button onClick={() => openToCmsPage('aydinlatma-metni-mobile')} type="button" style={{ border: 0, backgroundColor: 'transparent', cursor: 'pointer' }} className="wallet-check-agreement" data-test="checkout-payment-process-aggrement-button"><span>{gettext('FLO Mağazacılık ve Pazarlama A.Ş. Müşteri Aydınlatma Metni')}</span></button>
                {gettext('’ni okudum.')}
              </>
            ) : (
              <>
                <a href={`${BASE_URL}aydinlatma-metni`} target="_blank" className="wallet-check-agreement" rel="noreferrer">{gettext('FLO Mağazacılık ve Pazarlama A.Ş. Müşteri Aydınlatma Metni')}</a>
                {gettext('’ni okudum.')}
              </>
            )}
          </div>
          <div className="row">
            <div className="col-12">
              <button type="button" id="check_activation_otp_two" className={`btn btn-orange w-100 mb-0 ${validatePhoneNumber() ? '' : 'btn--disabled'}`} onClick={sendActivationOtp} data-test="checkout-payment-process-code-send-button">
                {gettext('DOĞRULAMA KODU GÖNDER')}
              </button>
            </div>
          </div>
        </form>
      );
  }
};

export default SideBar;
