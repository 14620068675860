import {
  createSlice,
} from '@reduxjs/toolkit';

const INITIAL_STATE = false;

const slice = createSlice({
  name: 'loader',
  initialState: INITIAL_STATE,
  reducers: {
    setLoader: (state, action) => {
      state = action.payload;
    },
  },
  extraReducers: (builder) => {

  },
});

export default slice.reducer;

export const {
  setLoader,
} = slice.actions;
