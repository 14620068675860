import React, { useEffect, useState } from 'react';
import { gettext } from '@Translate';
import { hidePopupAction, orderCompleteAction } from '@Actions';
import { useDispatch, useSelector } from 'react-redux';

const agtAddressErrorPopup = () => {
  const dispatch = useDispatch();
  const {
    card,
  } = useSelector((state) => state.order);
  const [data, setData] = useState(card);
  useEffect(() => {
    setData({ ...data, convert_to_standard_delivery: 1 });
  }, []);


  const agtStandartDeliveryFunction = () => {
    dispatch(orderCompleteAction({
      data,
    }));
    dispatch(hidePopupAction());
  };
  const handleClose = () => {
    dispatch(hidePopupAction());
  };
  return (
    <div className="agt-error-modal">
      <i className="agt-error-modal-icon icon icon-notification" />
      <p className="agt-error-modal-description">
        {gettext('Sepetinizde farklı lokasyonda')}
        {' '}
        <br />
        <strong>{gettext('Aynı Gün Teslimat')}</strong>
        {' '}
        {gettext('ürünü bulunmaktadır.')}
      </p>
      <p className="agt-error-modal-description"><strong>{gettext('Bu ürünleri standart teslimata çevirmek ister misiniz?')}</strong></p>
      <div className="row agt-error-modal-btn">
        <div className="col-md-12">
          <a href="javascript:;" className="btn btn-orange sameday-add-basket js-agt-sdd-to-sd-basket w-100 my-2" onClick={agtStandartDeliveryFunction} data-test="checkout-agt-error-popup-yes-button">{gettext('Evet')}</a>
        </div>
        <div className="col-md-12">
          <a href="javascript:;" data-fancybox-close className="btn btn-gray fancybox-close close-modal js-close-modal w-100 my-0" onClick={handleClose} data-test="checkout-agt-error-popup-no-button">{gettext('Hayır')}</a>
        </div>
      </div>
    </div>
  );
};

export default agtAddressErrorPopup;
