import * as React from 'react'
import { memo } from 'react'
const SvgUnionWhite = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='1em'
    height='1em'
    viewBox='0 0 19 10'
    fill='currentColor'
    {...props}
  >
    <path
      fill='#fff'
      d='M12.893 6.154H0V3.847h12.893V0L19 5l-6.107 5Z'
      data-name='Union 2'
    />
  </svg>
)
const Memo = memo(SvgUnionWhite)
export default Memo
