/* eslint-disable no-param-reassign */
/* eslint-disable import/no-cycle */
import {
  createSlice,
} from '@reduxjs/toolkit';

const INITIAL_STATE = {
  isMailVerified: false,
};

const slice = createSlice({
  name: 'otpInfo',
  initialState: INITIAL_STATE,
  reducers: {
    setIsMailVerifiedAction: (state, action) => {
      state.isMailVerified = action.payload;
    },
  },
});

export default slice.reducer;

export const {
  setIsMailVerifiedAction,
} = slice.actions;
