/* eslint-disable import/prefer-default-export */
export const flokzDev = {
  BASE_URL: 'https://staging.flo.com.kz/',
  COOKIE_BASE_URL: 'staging.flo.com.kz',
  CHECKOUT_URL: 'https://staging.flo.com.kz/',
  CHECKOUT_BASE_URL: 'https://staging.flo.com.kz/',
  currency: 'KZT',
  locale: ['en','kk','ru'],
  symbol: true,
  quoteitemtotal: 20,
  logoPath: 'pub/assets/flo-v2/images/flo-logo.svg',
  project: 'flo-kz',
  title: 'FLO',
  appDeepLink: 'flo://',
  failuresNumber: '3',
  manifest: 'pub/pwa/manifest-flo.json',
  favicon: 'pub/assets/flo/img/flo-sq.png',
  loadingAnimation: 'pub/assets/flo/img/loading/flo_loading_white.gif',
  siteKey: '6LcPQ64UAAAAAJv489J59h58XQ6t2X8cHODjm9NR',
  gtmAccountId: 'GTM-MZPL3XP',
  facebookDomainVerification:'j0qp5fko2grla15zhr9ihsq5d94bgo',
  siteName: 'FLO.COM.KZ.',
  phoneCode: [
    { name: '+90', mask: '(599) 999 99 99' },
    { name: '+7', mask: '(599) 999 99 99' }
  ],
  isGlobal: true,
  cunda: true
};

export const flokzStaging = {
  BASE_URL: 'https://staging.flo.com.kz/',
  COOKIE_BASE_URL: 'staging.flo.com.kz',
  CHECKOUT_URL: 'https://staging.flo.com.kz/',
  CHECKOUT_BASE_URL: 'https://staging.flo.com.kz/',
  currency: 'KZT',
  locale: ['en','kk','ru'],
  symbol: true,
  quoteitemtotal: 20,
  logoPath: 'pub/assets/flo-v2/images/flo-logo.svg',
  project: 'flo-kz',
  title: 'FLO',
  appDeepLink: 'flo://',
  failuresNumber: '3',
  manifest: 'pub/pwa/manifest-flo.json',
  favicon: 'pub/assets/flo/img/flo-sq.png',
  loadingAnimation: 'pub/assets/flo/img/loading/flo_loading_white.gif',
  siteKey: '6LcPQ64UAAAAAJv489J59h58XQ6t2X8cHODjm9NR',
  gtmAccountId: 'GTM-MZPL3XP',
  facebookDomainVerification:'j0qp5fko2grla15zhr9ihsq5d94bgo',
  siteName: 'FLO.COM.KZ.',
  phoneCode: [
    { name: '+90', mask: '(599) 999 99 99' },
    { name: '+7', mask: '(599) 999 99 99' }
  ],
  isGlobal: true,
  cunda: true
};

export const flokzPreprod = {
  BASE_URL: 'https://preprod.flo.com.kz/',
  COOKIE_BASE_URL: 'preprod.flo.com.kz',
  CHECKOUT_URL: 'https://preprod.flo.com.kz/',
  CHECKOUT_BASE_URL: 'https://preprod.flo.com.kz/',
  currency: 'KZT',
  locale: ['en','kk','ru'],
  symbol: true,
  quoteitemtotal: 20,
  logoPath: 'pub/assets/flo-v2/images/flo-logo.svg',
  project: 'flo-kz',
  title: 'FLO',
  appDeepLink: 'flo://',
  failuresNumber: '3',
  manifest: 'pub/pwa/manifest-flo.json',
  favicon: 'pub/assets/flo/img/flo-sq.png',
  loadingAnimation: 'pub/assets/flo/img/loading/flo_loading_white.gif',
  siteKey: '6LcPQ64UAAAAAJv489J59h58XQ6t2X8cHODjm9NR',
  gtmAccountId: 'GTM-MZPL3XP',
  facebookDomainVerification:'j0qp5fko2grla15zhr9ihsq5d94bgo',
  siteName: 'FLO.COM.KZ.',
  phoneCode: [
    { name: '+7', mask: '(599) 999 99 99' }
  ],
  isGlobal: true,
  cunda: true
};

export const flokzProd = {
  BASE_URL: 'https://www.flo.com.kz/',
  COOKIE_BASE_URL: 'flo.com.kz',
  CHECKOUT_URL: 'https://www.flo.com.kz/',
  CHECKOUT_BASE_URL: 'https://www.flo.com.kz/',
  currency: 'KZT',
  locale: ['en','kk','ru'],
  symbol: true,
  quoteitemtotal: 20,
  logoPath: 'pub/assets/flo-v2/images/flo-logo.svg',
  project: 'flo-kz',
  title: 'FLO',
  appDeepLink: 'flo://',
  failuresNumber: '3',
  manifest: 'pub/pwa/manifest-flo.json',
  favicon: 'pub/assets/flo/img/flo-sq.png',
  loadingAnimation: 'pub/assets/flo/img/loading/flo_loading_white.gif',
  siteKey: '6LcPQ64UAAAAAJv489J59h58XQ6t2X8cHODjm9NR',
  gtmAccountId: 'GTM-MZPL3XP',
  facebookDomainVerification:'j0qp5fko2grla15zhr9ihsq5d94bgo',
  siteName: 'FLO.COM.KZ.',
  phoneCode: [
    { name: '+7', mask: '(599) 999 99 99' }
  ],
  isGlobal: true,
  cunda: false
};
