import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import {
  addFavoriteActionRequest,
  addToShoppingCartAction, removeCartItemActionRequest,
  bunsarEventAction,
} from '@Actions';
import {
  toMoney,
  timeframe, logEvent,
} from '@Constants';
import {
  gettext,
} from '@Translate';
import config from '@Config';
import CustomButton from '../../CustomButton';
import ProductDetail from '../../ProductDetail';
import ToastfyConfirm from '../../ToastfyConfirm/ToastfyConfirm';
import { IsArray } from '../../../utils/IsArray';
import { buildEventActionPayload, isValidCredentials } from '../../../utils/BunsarHelpers';
import { addToCartEvent } from '../../../utils/gtmEvents';

const OldProducts = ({ successPage }) => {
  const dispatch = useDispatch();
  const { guest } = useSelector((state) => state.auth);
  const { webview } = useSelector((state) => state.config);
  const { project } = config;
  const { shoppingCart, visitorId, shopperId } = useSelector((state) => state.cart);
  const [gtmControl, setGtmControl] = useState(true);

  const handleUpdateActions = (quantity, sku, price) => {
    if (project !== 'flo' || webview) return;

    const credentials = shoppingCart?.bunsarEventCredentials;

    if (!isValidCredentials(credentials) || !sku) {
      return;
    }

    const productInfo = [sku, quantity, price].filter(Boolean).join('|');

    if (!productInfo) {
      return;
    }

    const payload = buildEventActionPayload({
      credentials,
      visitorId,
      shopperId,
      eventType: 'Cart.Rec.AddToCart',
      additionalParams: { recType: 'ctc3rd', recProduct: productInfo }
    });

    dispatch(bunsarEventAction(payload));
  };

  const addToCart = async ({
    product, sku, quantity,
  }) => {
    setGtmControl(false);
    const productId = sku;
    dispatch(addToShoppingCartAction({
      product, productId, sku, quantity,
    }));
    handleUpdateActions(quantity, product?.parentSku, product?.price)
    let modifiedProduct = { ...product };
    modifiedProduct.quantity = '1'
    addToCartEvent(modifiedProduct)
  };

  useEffect(() => {
    if (!gtmControl) {
      setGtmControl(true);
      if (shoppingCart?.shoppingCartItemList.length > 0 && webview) {
        logEvent('begin_checkout', {
          items: shoppingCart?.shoppingCartItemList,
          value: shoppingCart?.grandTotal,
          checkout_step: 1,
          currency: 'TRY',
        });
      }
    }
  }, [shoppingCart]);



  // TODO : Kontrol Edilecek, burada bir kontrol yapılması dopru değil,
  // bu durum hem performansı etkiler, hemde best practice kullanıma aykırıdır
  if (Object?.values(shoppingCart?.products || [])?.length === 0) {
    return null;
  }

  const openConfirmPopup = async (product) => {
    if (guest) {
      await dispatch(removeCartItemActionRequest(product));
    } else {
      toast.error(ToastfyConfirm({
        callback: async () => {
          toast.dismiss();
          await dispatch(removeCartItemActionRequest(product));
        },
        confirm: async () => {
          toast.dismiss();
          await dispatch(addFavoriteActionRequest(product));
        },
      }), { autoClose: 15000 });
    }
  };
  const isVisible = false;

  return (
    <div className={`prev-add-card__container my-basket ${project}-prev-add-card__container`}>
      <div className={`merchant-product-card__header ${project}-merchant-product-card__header`}>{gettext('Önceden Sepete Eklediklerim')}</div>
      <div className="prev-add-card__all-wrapper">
        {IsArray(shoppingCart)?.products?.map((product) => (
          <div key={String(product?.sku)} className=" basket-item tracking init prev-add-card__wrapper">
            <div className="prev-add-card__first-col">
              <ProductDetail
                brand={product?.name}
                element={product}
                deliveryType={product?.type}
                cargoDate={product?.merchant?.estimatedDeliveryTimeStart && timeframe(product?.merchant?.estimatedDeliveryTimeStart, product?.merchant?.estimatedDeliveryTimeEnd)}
                cargoText={gettext(shoppingCart?.deliveryDateText || orderDetail?.estimatedDateText)}
                successPage={false}
                project={project}
              />
              {/* ----- TODO ---- */}
              {/* yayina cikarken display none olacak be bitince acilacak */}
              { isVisible && (
                <div className="merchant-product-card__item-delete-wrapper">
                  <span type="button">
                    {!successPage && (
                    <div className="cart__action">
                      <CustomButton
                        className="card__delete-btn"
                        onClick={() => openConfirmPopup({ product, quantity: 1 })}
                        data-test="open-to-cart-page-delete-product-from-cart-open-modal"
                      >
                        {gettext('Sil')}
                        &nbsp;
                        <i className="icon-bin" />
                      </CustomButton>
                    </div>
                    )}
                  </span>
                </div>
              )}
            </div>
            <div className="prev-add-card__second-col">
              <div className={`prev-add-card__price-container ${project}-prev-add-card__price-container`}>
                <div className="prev-add-card__price-wrapper">
                  {(product?.secondPrice > 0 && !product.storeMode) && (
                    <div className="cart__price--old">
                      <span>
                        {toMoney(product?.firstPrice)}
                      </span>
                    </div>
                  )}
                  <div className={`prev-add-card__price--actual primary-color ${project === 'sneakerbox' ? 'sneakerbox-primary-color' : ''}`}>
                    {
                      product?.secondPrice > 0
                        ? <span className={`${project}-second-price`}>{toMoney(product?.secondPrice)}</span>
                        : toMoney(product?.firstPrice) || toMoney(product?.price)
                    }
                  </div>
                </div>
              </div>
              <CustomButton
                type="button"
                project={project}
                className={`prev-add-card__add-to-cart-btn ${project}-prev-add-card__add-to-cart-btn`}
                onClick={() => addToCart({
                  product, sku: product?.sku, quantity: 1, merchantId: (product?.merchant?.id || 10000),
                })}
                dataTest="click-to-cart-page-previously-added-to-cart-add-cart"
              >
                {gettext('Sepete Ekle')}
              </CustomButton>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default OldProducts;
