import * as React from 'react'
import { memo } from 'react'
const SvgPhoneIcon = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='1em'
    height='1em'
    viewBox='0 0 15.482 15.482'
    fill='currentColor'
    {...props}
  >
    <path
      d='M2.267 13.215a7.741 7.741 0 1 0 0-10.947 7.74 7.74 0 0 0 0 10.947M5.7 2.927l1.153 1.152a.73.73 0 0 1 .12.871l-.433.769a.484.484 0 0 0 .08.58l2.564 2.564a.484.484 0 0 0 .58.079l.769-.433a.726.726 0 0 1 .871.12l1.152 1.152a.73.73 0 0 1 0 1.028l-.546.546a1.94 1.94 0 0 1-2.345.305l-.052-.03a15.2 15.2 0 0 1-5.491-5.375l-.262-.437a1.94 1.94 0 0 1 .29-2.367l.523-.523a.726.726 0 0 1 1.028 0Z'
      data-name='phone (2)'
    />
  </svg>
)
const Memo = memo(SvgPhoneIcon)
export default Memo
