import * as React from 'react'
import { memo } from 'react'
const SvgSavedCardDots = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='1em'
    height='1em'
    data-name='Group 14926'
    viewBox='0 0 2.998 12.246'
    fill='currentColor'
    {...props}
  >
    <defs>
      <clipPath id='saved-card-dots_svg__a'>
        <path
          fill='none'
          d='M0 0h2.998v12.246H0z'
          data-name='Rectangle 13710'
        />
      </clipPath>
    </defs>
    <g
      clipPath='url(#saved-card-dots_svg__a)'
      data-name='Group 14925'
    >
      <path
        d='M1.2 9.992a1.22 1.22 0 0 0-.862.311 1.05 1.05 0 0 0-.338.808 1.07 1.07 0 0 0 .341.819 1.22 1.22 0 0 0 .859.316 1.2 1.2 0 0 0 .853-.316 1.06 1.06 0 0 0 .34-.819 1.05 1.05 0 0 0-.337-.808 1.2 1.2 0 0 0-.856-.311'
        data-name='Path 13724'
      />
      <path
        d='M1.2 4.996a1.22 1.22 0 0 0-.862.311A1.05 1.05 0 0 0 0 6.115a1.07 1.07 0 0 0 .341.819 1.22 1.22 0 0 0 .859.316 1.2 1.2 0 0 0 .853-.316 1.06 1.06 0 0 0 .34-.819 1.05 1.05 0 0 0-.337-.808 1.2 1.2 0 0 0-.856-.311'
        data-name='Path 13725'
      />
      <path
        d='M1.2 2.254a1.2 1.2 0 0 0 .853-.315 1.07 1.07 0 0 0 .34-.82 1.05 1.05 0 0 0-.337-.808A1.2 1.2 0 0 0 1.2 0a1.22 1.22 0 0 0-.862.311A1.05 1.05 0 0 0 0 1.119a1.07 1.07 0 0 0 .341.82 1.22 1.22 0 0 0 .859.315'
        data-name='Path 13726'
      />
    </g>
  </svg>
)
const Memo = memo(SvgSavedCardDots)
export default Memo
