/* eslint-disable no-param-reassign */
/* eslint-disable import/no-cycle */
import React from 'react';
import {
  createSlice,
  createAsyncThunk,
} from '@reduxjs/toolkit';
import API from '@API';

export const getSummoningShoppingCartIdAction = createAsyncThunk('getSummoningShoppingCartId', async (arg, { dispatch }) => {
  const response = await API.getSummoningShoppingCartId({ ...arg });
  return response.data;
});

const INITIAL_STATE = {
  step: 1,
};

const slice = createSlice({
  name: 'checkout',
  initialState: INITIAL_STATE,
  reducers: {
    setStepAction: (state, action) => {
      state.step = action.payload;
    },
  },
  extraReducers: (builder) => {

  },
});

export default slice.reducer;

export const {
  setStepAction,
} = slice.actions;
