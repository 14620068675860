import React from 'react';
import PropTypes from 'prop-types';
import {
  gettext,
} from '@Translate';
import config from '@Config';

import { IsArray } from '../../utils/IsArray';

const Agreements = ({ title, agreements, agreementsListRef }) => {

  const { project } = config;

  return (
    <div className={`agreements-container ${project}-agreements-container`} ref={agreementsListRef}>
      <div className="component-header agreements-container__title">
        {gettext(title)}
      </div>
      {agreements?.length > 0
          && (
          <div className="agreements-container__content">
            {IsArray(agreements)?.map((item) => (
              <div id={item?.id} key={item?.id} className="checkout-dcc__agreements js-agreement-wrapper">
                <div className="checkout-dcc__agreement">
                  <div className="agreements-wrapper__title checkout-dcc__agreement-label">{item?.name}</div>
                  <div
                    className={`agreements-wrapper__body checkout-dcc__agreement-body ${project}-checkout-dcc__agreement-body`}
                    dangerouslySetInnerHTML={{
                      __html: item?.content,
                    }}
                  />
                </div>
              </div>
            ))}
          </div>
      )}
    </div>
  );
};

Agreements.propTypes = {
  title: PropTypes.string.isRequired,
  agreementsListRef: PropTypes.object,
  agreements: PropTypes.array.isRequired,
};

Agreements.defaultProps = {
  title: gettext('Sözleşme ve Formlar'),
  agreementsListRef: {},
  agreements: [],
};

export default Agreements;
