import React, { createElement } from 'react';
import PropTypes from 'prop-types';

const Typography = ({ text, as, className, id }) => {
  const createEl = (as, className, id, text) => {
    const el = createElement(as, { className, id }, text);
    return el;
  };
  return (
    <>
      {createEl(as, className, id, text)}
    </>

  );
};

Typography.defaultProps = {
  text: '',
  className: '',
  as: '',
  id: '',
};

Typography.propTypes = {
  text: PropTypes.string,
  className: PropTypes.string,
  as: PropTypes.string,
  id: PropTypes.string,
};

export default Typography;
