/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  set3DPopupCloseAction,
} from '@Actions';
import {
  gettext,
} from '@Translate';

export default () => {
  const dispatch = useDispatch();
  const { isOpenIframe, iframe, paymentMethod = null, dijitalPaymentName = [] } = useSelector((state) => state.order);

  const handleClose = () => {
    dispatch(resetThreeDInfo())
  }

  return isOpenIframe ? (
    <div className="three-d-secure-popup" id="flo-frame">
      <div className="three-d-secure-popup__cell">
        <div className="three-d-secure-popup__content">
          {(dijitalPaymentName.includes(paymentMethod) || paymentMethod === "craftgate_flo_edenred") && (
            <button type="button" className={`three-d-secure-popup__close ${paymentMethod}`} onClick={() => dispatch(set3DPopupCloseAction())} data-test="checkout-iframe-close-button">
              <i className="icon-close" />
            </button>
          )}
          { paymentMethod === "craftgate_kz_kaspi" &&
            <button className="modal-close" type="button" onClick={handleClose} >
              <span className="icon-close" />
            </button>
          }
          <iframe src={iframe} frameBorder="0" className="three-d-secure-popup__iframe" />
          <div className="three-d-secure-popup__overlay">
            <div>
              <div className="three-d-secure-popup__overlay-image" title={gettext('Yükleniyor')} />
              <div className="text">
                {gettext('İşlem Gerçekleştiriliyor...')}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (<></>);
};
