import React from 'react';
import { useDispatch } from 'react-redux';
import { SavedCard } from '@Components';
import { gettext } from '@Translate';
import {
  setSavedCardStep,
} from '@Actions';

const SavedCardList = ({ savedCardList }) => {
  const dispatch = useDispatch();
  return (
    <div className="saved-cards__list">
      {savedCardList?.map((item, index) => (
        <SavedCard item={item} key={`saved-card-${index}`} />
      ))}
      <div className="saved-cards__item saved-cards__item--new" onClick={() => dispatch(setSavedCardStep(2))}>
        +
        {' '}
        {gettext('Yeni Kart Ekle')}
      </div>
    </div>
  );
};
export default SavedCardList;
