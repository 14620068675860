import React from 'react';
import Flowallet from '../../PaymentOptions/PaymentOptionContents/Flowallet';
import  CreditCard  from '../../PaymentOptions/PaymentOptionContents/CreditCard';
import GiftCertificate  from '../../PaymentOptions/PaymentOptionContents/GiftCertificate';
import DigitalPayments from '../../PaymentOptions/PaymentOptionContents/DijitalPayment';
import { CashOnDelivery, DefaultInfo } from '../../PaymentOptions';

const Content = ({item, className, paymentType}) => {
  const renderPaymentMethod = () => {
    switch (paymentType) {
      case 'flopay':
        return <CreditCard {...item}/>;
      // case 'flowallet':
      //   return <Flowallet item={item} />;
      case 'cashondelivery':
        return <CashOnDelivery />;
      case 'flowallet_cheque': 
        return <GiftCertificate />
      default:
        return <DefaultInfo {...item} />;
    }
  };
  return (
    <div className={className}>
        { item.code === 'dijital_banka_odemeleri' ? <DigitalPayments paymentType={paymentType} />  :  item.code === 'other_payment_methods' ? 'New Component Will Be Added' : renderPaymentMethod()}
    </div>
  )
}

export default Content;
