import * as React from 'react'
import { memo } from 'react'
const SvgIconClear = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='1em'
    height='1em'
    viewBox='0 0 10.346 10.345'
    fill='currentColor'
    {...props}
  >
    <g data-name='Group 14219'>
      <g data-name='Group 4485'>
        <path
          fill='none'
          stroke='#a7a7a7'
          d='m.708.707 8.93 8.93'
          data-name='Line 739'
        />
      </g>
      <g data-name='Group 4612'>
        <path
          fill='none'
          stroke='#a7a7a7'
          d='m9.638.707-8.93 8.93'
          data-name='Line 739'
        />
      </g>
    </g>
  </svg>
)
const Memo = memo(SvgIconClear)
export default Memo
