import * as React from 'react'
import { memo } from 'react'
const SvgCreditCard = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='1em'
    height='1em'
    data-name='Group 15209'
    viewBox='0 0 32.984 29.135'
    fill='currentColor'
    {...props}
  >
    <defs>
      <clipPath id='credit-card_svg__a'>
        <path
          fill='none'
          d='M0 0h32.984v29.135H0z'
          data-name='Rectangle 7647'
        />
      </clipPath>
    </defs>
    <g
      fill='#f60'
      clipPath='url(#credit-card_svg__a)'
      data-name='Group 15208'
    >
      <path
        d='M30.235 0H8.246a2.753 2.753 0 0 0-2.749 2.749v7.146h20.207a2.885 2.885 0 0 1 2.883 2.881v6.464h1.649a2.75 2.75 0 0 0 2.749-2.749V2.748A2.75 2.75 0 0 0 30.235 0m1.649 8.246H6.599v-3.3h25.285Z'
        data-name='Path 13545'
      />
      <path
        d='M24.738 10.994H2.749A2.75 2.75 0 0 0 0 13.743v12.643a2.75 2.75 0 0 0 2.749 2.749h21.989a2.75 2.75 0 0 0 2.749-2.749V13.743a2.75 2.75 0 0 0-2.749-2.749M11 25.287H4.941v-1.1H11Zm9.895 0h-7.15v-1.1h7.145Z'
        data-name='Path 13546'
      />
    </g>
  </svg>
)
const Memo = memo(SvgCreditCard)
export default Memo
