import { createSlice } from '@reduxjs/toolkit';

const INITIAL_STATE = {
  otpStep: false,
};

const slice = createSlice({
  name: 'loginSecondStep',
  initialState: INITIAL_STATE,
  reducers: {
    showOtpAction: (state, action) => {
      state.otpStep = action.payload;
    },
  },
});

export default slice.reducer;

export const {
  showSecondStepAction,
  hideSecondStepAction,
  showForgotPasswordAction,
  showOtpAction,
  showRegisterAction,
  dataTransferAction,
} = slice.actions;
