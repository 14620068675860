import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { gettext } from '@Translate';
import { toMoney, toMoneyBonus } from '@Constants';
import IconTime from '../../../assets/icons/bag.svg';
import config from '@Config';
import { IconAlert } from "@Icons";
import {
  walletBalanceAction,
  setUseBonusAction,
  isTopPopupAction,
  showPopupAction,
} from '@Actions';
import Cookie from 'js-cookie';
import WalletOtp from '../../WalletOTP';
// ** -------------- ACTIONS ------------------------ **

const AccordionButton = ({onClick, itemName, isActive, selected, item}) => {

  const dispatch = useDispatch();

// ** -------------- GLOBAL STATES ------------------------ **
  const {
    customer,
    useBonus,
    paymentMethod,
    savedCardList,
    paymentMethods,
    hepsiPaySavedCardList
  } = useSelector((state) => state.order);

// ** -------------- LOCAL STATES ------------------------ **
  const [walletBonusCheck, setWalletBonusCheck] = useState(false);
  const [bonusUsageIsRequired, setbonusUsageIsRequired] = useState(false);

  const { project } = config;

  useEffect(() => {
    setbonusUsageIsRequired(item?.bonusUsageIsRequired);
  }, []);


  useEffect(() => {
    if (walletBonusCheck === true) {
      setWalletBonusCheck(false);
    }
  }, [paymentMethod]);

  const useBonusOnchange = (e) => {
    if (!bonusUsageIsRequired) {
      setWalletBonusCheck((prev) => !prev);
    }
    dispatch(setUseBonusAction(e.target.checked));
  };

  const showWalletOtp = () => {
    if (project !== 'sneakerbox') {
      window.events.emit('analytics.gtmWallet', {
        eventAction: 'Checkout Wallet Activate',
        eventLabel:
          Cookie.get('is_logged_in') !== undefined
            ? customer?.masterId
            : '',
        event: 'Checkout Wallet Activate',
      });
    }

    dispatch(isTopPopupAction(false));
    dispatch(
      showPopupAction(() => WalletOtp({
        onSuccess: () => {
          if (project !== 'sneakerbox') {
            window.events.emit('analytics.gtmWallet', {
              eventAction: 'Checkout Wallet Verified',
              eventLabel:
                  Cookie.get('is_logged_in') !== undefined
                    ? customer?.masterId
                    : '',
              event: 'Checkout Wallet Verified',
            });
          }
        },
        onFail: () => {},
      })),
    );
  };
  return (
    <div>

       {/* KONTROLLÜ SİLİNECEK !!! */}
      {/* ----- Cüzdanını aktif etmemiş kullanıcılar için gösterilir ---- */}
      {item?.customerWalletActive === false ? (
            <div className={`checkout-option__label ${project}`}>
              <div
                className="checkout-option__header"
                onClick={() => showWalletOtp()}
                data-test="activate-flo-wallet-checkout-option-from-payment"
              >
                {/* Visibility hidden tasarım ihtiyacı duyulduğundan konuldu */}
                {/* <span className="checkout-option__label-checkbox" style={{ visibility: 'hidden' }} /> */}
                <div className="checkout-option__flo-plus--not-active">
                  {gettext('FLO Plus ile Hızlı Öde')}
                  <div className="checkout-option__flo-plus__wallet-balance">
                    <div className="checkout-option__flo-plus__wallet-balance--amount">
                      {gettext('Hemen Aktif Et')}
                    </div>
                  </div>
                </div>
              </div>

          </div>
        )
        : (
          <div className={`${item.code === 'flowallet' && (item?.walletShow === false && item?.requireActivation === false) ? 'passive' : ''} accordion__button`} onClick={onClick}>
      <div className="accordion-button__container checkout-option__header">
        <div className="payment-header-container">
          <div className="checkout-option__header-box">
            <span className={`${selected === isActive ? 'active' : ''} checkout-option__label-checkbox primary-checkbox`} data-test="payment-accordion-check"/>
            <span className="checkout-option__label-text">
              {itemName}
            </span>
          </div>
          { item.code !== 'dijital_banka_odemeleri'  && item.logo && (
            <div className='checkout-option__logo'>
              <img src={item.logo}/>
            </div>
          )}
          {item.code === 'dijital_banka_odemeleri' && (savedCardList === undefined || savedCardList?.length === 0) && hepsiPaySavedCardList?.length > 0  && (
            <span className="checkout-option__label--badge">{gettext('Kayıtlı Kartın Var')}</span>
          )}

          {/* ----- FLOWALLET HEADER CONTENTS ---- */}
          {/*
          {item.code === 'flowallet' && item?.customerWalletActive === true && (
            <div className={`${item?.walletShow === false && item?.requireActivation === false ? 'passive' : null}`}>
              <div className="checkout-option__flo-plus" data-test="select-flo-wallet-checkout-option-from-payment">
                {item?.walletBalance > 0 && dispatch(walletBalanceAction(item?.walletBalance)) && (
                <div className="checkout-option__flo-plus__wallet-balance">
                  <div className="checkout-option__flo-plus__wallet-balance--title">
                    {gettext('Bakiye:')}
                  </div>
                  <div className="checkout-option__flo-plus__wallet-balance--amount">
                    {toMoney(item?.walletBalance)}
                  </div>
                </div>
                      )}
                </div>
            </div>
          )}
          */}
      </div>

          {/* KONTROLLÜ SİLİNECEK !!! */}
          {/* {item?.customerWalletActive === true && (
            <div className={`checkout-option__label--flowallet ${project}`}>
              {item?.bonusBalance > 0 && (
              <div
                className="money-points"
                data-test="action-spend-flo-wallet-coin-from-payment"
                style={item.code !== 'flowallet' ? { display: 'none' } : { display: 'inline-block' }}
              >
                {item?.bonusUsageIsAllowed ? (

                  <label
                    className={`${item.code === 'flowallet' && (item?.walletShow === false && item?.requireActivation === false) ? 'passive' : null} checkbox xs checkbox--gray `}
                    htmlFor="balance"
                    data-test="checkout-payment-process-bonus-check-change"
                  >
                    <input
                      type="checkbox"
                      name="balance"
                      id="balance"
                      data-test="checkout-payment-process-bonus-check-input"
                      onChange={() => useBonusOnchange()}
                      defaultValue={useBonus}
                      checked={bonusUsageIsRequired ? true : walletBonusCheck}
                    />
                    <span className="xs" />
                    <div className="money-points__content">
                        {toMoneyBonus(item?.bonusBalance)}
                      {' '}
                      {gettext('FLO Para Kullan')}
                    </div>
                  </label>
                        ) : (
                          <div className="money-points__content money-points__content--xs">
                            {gettext('FLO Para’nızı kullanabilmeniz için alışveriş tutarınızın en az')}
                              {' '}
                              {toMoney(item?.bonusUsageOrderAmount)}
                              {' '}
                            {gettext('olması gerekmektedir.')}
                          </div>
                        )}
                        <div className={`checkout-option__flo-plus-tooltip ${item?.walletShow === false && item?.requireActivation === false ? 'flex' : null}`}>
          <IconAlert />
          <div className="checkout-option__flo-plus-tooltip__body">{item?.message}</div>
        </div>
              </div>
              )}
            </div>
            )} */}
          {/* ----- FLOWALLET HEADER CONTENTS END ---- */}
        </div>
          </div>
        )}
    </div>
  );
};

export default AccordionButton;
