import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import Items from './Items/index';
import ReCAPTCHA from 'react-google-recaptcha';
import Cookie from 'js-cookie';

import {
  applyCouponAction,
  removeCouponAction,
  getCouponsAction,
} from '@Actions';
import { gettext } from '@Translate';
import { IconBottomArrow } from '@Icons';
import config from '@Config';
import { selectPromotionEvent } from '@Utils';

const Coupon = () => {
  const dispatch = useDispatch();
  const newCouponCode = useRef();
  const { project, siteKey, failuresNumber } = config;
  const { guest } = useSelector((state) => state.auth);
  const [couponCode, setCoupon] = useState();
  const [isInitCoupons, setIsInitCoupons] = useState(false);
  const [recaptcha, setRecaptcha] = useState(null);
  const [showErrorCoupon, setShowErrorCoupon] = useState(false);
  const [activeCoupon, setActiveCoupon] = useState({
    code: '',
    name: '',
    toDate: '',
  });

  const { coupons, shoppingCart } = useSelector(
    (state) => state.cart
  );

  const handleSubmit = async (couponCode, isActive) => {
    setCoupon(couponCode);
    if (isActive) {
      await dispatch(
        removeCouponAction({
          couponCode,
        })
      );
      setCoupon('');
      newCouponCode.current.value = '';
    } else {
      if (couponCode) {
        await dispatch(
          applyCouponAction({
            couponCode,
            'g-recaptcha-response': recaptcha,
          }),
        ).then(({ payload }) => {
          const { status } = payload;
          if (status?.code === 200) {
            const selectedCoupons = coupons?.find(coupon => coupon.code === couponCode) || [{
              code: couponCode
            }]
            selectPromotionEvent(selectedCoupons)
          }
        });
        setCoupon('');
        newCouponCode.current.value = '';
        setShowErrorCoupon(false);
      } else {
        setShowErrorCoupon(true);
      }
    }
  };

  useEffect(() => {
    if (newCouponCode) {
      setShowErrorCoupon(false);
    }
  }, [newCouponCode]);

  function onChangeReCAPTCHA(value) {
    setRecaptcha(value);
  }

  const getCouponDetail = (couponCode) => {
    if (coupons.length > 0) {
      let activeCoupon = coupons.find(
        ({ code }) => code === couponCode
      );
      if (activeCoupon) {
        setActiveCoupon({
          code: activeCoupon?.code,
          name: activeCoupon?.name,
          toDate: activeCoupon?.toDate,
        });
      } else {
        setActiveCoupon({
          code: couponCode,
          name: shoppingCart?.couponData?.name || '',
          toDate: shoppingCart?.couponData?.endDate || '',
        });
      }
    } else {
      setActiveCoupon({
        code: couponCode,
        name: shoppingCart?.couponData?.name || '',
        toDate: shoppingCart?.couponData?.endDate || '',
      });
    }
  };

  const applyCoupon = () => {
    handleSubmit(newCouponCode.current.value, false);
  };

  const getCoupons = async () => {
    if (!guest && !isInitCoupons) {
      dispatch(getCouponsAction());
      setIsInitCoupons(true);
    }
  };

  useEffect(() => {
    if (shoppingCart?.couponData?.couponCode) {
      setCoupon(shoppingCart?.couponData?.couponCode);
      getCouponDetail(shoppingCart?.couponData?.couponCode);
    }
  }, [shoppingCart, coupons]);

  return (
    <div className={`coupon-container ${project}-coupon-container`}>
      <input
        type="checkbox"
        className="coupon-container__checkbox"
        id="coupon-area"
        data-test="checkout-coupon-checkbox"
        value={1}
      />
      <label
        data-test="click-to-cart-page-add-coupon-open"
        className="coupon-container__label"
        htmlFor="coupon-area"
        onClick={getCoupons}
      >
        {gettext('Kampanya Kodu')}
        <span className="arrow-icon" data-test="click-to-cart-page-add-arrow">
          <IconBottomArrow />
        </span>
      </label>
      <div className="coupon-container__body">
        {shoppingCart?.infoMessage?.length > 0 && (
          <div className='coupon-container__body--infos'>
            <i className="icon icon-info" />
            <p>{shoppingCart?.infoMessage[0]}</p>
          </div>
        )}
        <div className="coupon-container__form" id="cart-coupon">
          <input
            type="text"
            name="code"
            ref={newCouponCode}
            className="coupon-container__input"
            placeholder={gettext(
              'İndirim kodunuz varsa lütfen giriniz'
            )}
            data-test="checkout-card-summary-coupon-change"
          />
          <button
            type="submit"
            data-test={
              shoppingCart?.couponApplied
                ? 'click-to-cart-page-remove-coupon'
                : 'click-to-cart-page-apply-coupon'
            }
            id="coupon-container-input"
            className={`coupon-container__button ${project}`}
            onClick={applyCoupon}
          >
            {gettext('Uygula')}
          </button>
        </div>
        {showErrorCoupon && (
            <div className="error">
              {gettext('Lütfen kupon kodunu giriniz.')}
            </div>
        )}
        {Number(Cookie.get('apply_coupon_failures_num')) >= Number(failuresNumber) && (
          <div className="coupon-container__captcha">
            {siteKey !== '' && siteKey !== undefined 
            ? (
              <ReCAPTCHA
                sitekey={siteKey}
                hl={Cookie.get('language') || ''}
                onChange={onChangeReCAPTCHA}
              />
            )
            : <></> }
          </div>
        )}
        {shoppingCart?.couponData?.couponCode &&
          activeCoupon?.code && (
            <Items
              name={activeCoupon?.name}
              code={activeCoupon?.code}
              toDate={activeCoupon?.toDate}
              isActive={shoppingCart?.couponData?.couponApplied}
              conditionMinimumAmount={
                shoppingCart?.couponData?.conditionMinimumAmount
              }
              onSubmit={handleSubmit}
            />
          )}

        {coupons?.length > 0 &&
          coupons?.map((item) =>
            item.code !== shoppingCart?.couponData?.couponCode ? (
              <Items
                name={item?.name}
                code={item?.code}
                toDate={item?.toDate}
                onSubmit={handleSubmit}
                conditionMinimumAmount={item?.conditionMinimumAmount}
              />
            ) : null
          )}
      </div>
    </div>
  );
};

Coupon.defaultProps = {
  shoppingCart: {},
};

Coupon.propTypes = {
  shoppingCart: PropTypes.object.isRequired,
};

export default Coupon;
