import * as React from 'react'
import { memo } from 'react'
const SvgArrowPrev = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='1em'
    height='1em'
    viewBox='0 0 11.478 19.132'
    fill='currentColor'
    {...props}
  >
    <g data-name='noun_Next_1135493 (1)'>
      <path
        d='m39.916 36.62 7.577 7.58a1.362 1.362 0 0 0 1.926-1.926l-6.741-6.744 6.741-6.741a1.362 1.362 0 0 0-1.926-1.926l-7.578 7.578a1.54 1.54 0 0 0 .001 2.179z'
        data-name='Path 139'
        style={{
          stroke: '#fff',
        }}
        transform='translate(-38.84 -25.964)'
      />
    </g>
  </svg>
)
const Memo = memo(SvgArrowPrev)
export default Memo
