import React, { useState, useRef, useEffect } from 'react';
import { Loader, CustomButton } from '@Components';
import { toMoney } from '@Constants';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import 'tippy.js/dist/tippy.css';
import {
  gettext,
} from '@Translate';
import config from '@Config';
import {isNumberOrString} from "../../../../utils/NumberStringControl";
import { UnionBlack, UnionWhite } from '@Icons';
import { useHover } from '@Hooks';
import CustomToastMessage from '../../../CustomToastMessage';
import { cardValidationPopupAction } from '@Actions';

const MobileCardSummary = ({
  buttonText,
  isAgreementActive,
  executeAgreementsListScroll,
  orderSummary,
  onClick,
}) => {
  const { project } = config;
  const dispatch = useDispatch();
  const [dropdown, setDropdown] = useState(false);
  const agreementCheckbox = useRef(null);
  const [emptyPageHoverRef, isEmptyPageHoverRef] = useHover();
  const {
    installments,
    numberOfInstallments,
    isAgreementCheck,
    paymentMethod,
    deliveryOption
  } = useSelector((state) => state.order);

  const { shoppingCart, basketInfo } = useSelector((state) => state.cart);

  const {
    checkboxValidation, cardValidationError,
  } = useSelector((state) => state.cardValidation);

  const mobilSummary = () => {
    if (dropdown) {
      setDropdown(false);
    } else {
      setDropdown(true);
    }
  };
  const [maturityDifferenceRate, setMaturityDifferenceRate] = useState(null);

  useEffect(() => {
    if (installments) {
      const installment = installments?.find(
        (item) => Number(item.code) === Number(numberOfInstallments),
      );
      if (installment) {
        setMaturityDifferenceRate(installment);
      } else {
        setMaturityDifferenceRate(null);
      }
    } else {
      setMaturityDifferenceRate(null);
    }
    return () => {
      setMaturityDifferenceRate(null);
    };
  }, [numberOfInstallments, installments, paymentMethod]);

  const handleClose = () => {
    if (cardValidationError) {
      dispatch(cardValidationPopupAction(false));
    }
  };

  return (
    <div className={`mobile-summary-container${dropdown ? '--active' : ''} `}>
      <div className="mobile-summary-container__background" onClick={() => mobilSummary()} data-test="checkout-summary-dropdown-button" />
      <div className={`mobile-summary-container__body ${project}-mobile-summary-container__body ${isAgreementActive ? 'acreement-active' : ''}`}>
        {!shoppingCart && <Loader />}
        <div className="mobile-summary-container-header">
          <div className="mobile-summary-container-heading">{gettext('Sipariş Özeti')}</div>
          <div className="mobile-summary-container-info text-right">
            {` (${gettext('Sepette')} ${shoppingCart?.shoppingCartItemCount} ${gettext('Ürün Var')})`}
          </div>
        </div>
        {shoppingCart?.orderSummary?.priceItems?.map((item, index) => (
          <div key={index.toString()} className="mobile-summary-container__review">
            <div className="mobile-summary-container__label">
              {item?.title}
              {item?.info && (
              <div className="mobile-summary-container__discount">
                {item?.info?.map((item, index) => (
                  <div key={index.toString()}>{item?.campaign_name}</div>
                ))}
              </div>
                    )}
            </div>
            <div className="mobile-summary-container__price">{toMoney(item?.value)}</div>
          </div>
        ))}
        <div className="mobile-summary-container__review">
          <div className="mobile-summary-container__label">{gettext(shoppingCart?.orderSummary?.shipping?.title)}</div>
          <div className="mobile-summary-container__price--cargo">
            {shoppingCart?.orderSummary?.shipping?.value === 0 ? (
              <>
                <span className="mobile-summary-container__free-text">{gettext('Ücretsiz')}</span>
                <span className="mobile-summary-container__free-price">{toMoney(shoppingCart?.orderSummary?.shipping?.shippingValue)}</span>
              </>
                ) : toMoney(shoppingCart?.orderSummary?.shipping?.value)}
          </div>
        </div>



        {paymentMethod === 'flopay' || paymentMethod === 'flo_hepsipay'
        && installments && (
          (maturityDifferenceRate?.fee > 0 && (
            <div className="mobile-summary-container__review">
              <div className="cart-summary-container__label">
                Vade Farkı ({(maturityDifferenceRate?.title)})
              </div>
              <div className="mobile-summary-container__price--cargo">
                <span className="cargomoney">
                  { isNumberOrString(toMoney(maturityDifferenceRate?.fee))}
                </span>
              </div>
            </div>
          ))
        )}
        {paymentMethod === 'cashondelivery'
            && orderSummary?.cashOnDeliveryFee.value > 0 && (
              <div className="mobile-summary-container__review">
                <div className="mobile-summary-container__label">
                  {orderSummary?.cashOnDeliveryFee?.title}
                </div>
                <div className="mobile-summary-container__price--cc">
                  {orderSummary?.cashOnDeliveryFee?.value > 0
                    ? toMoney(orderSummary?.cashOnDeliveryFee?.value)
                    : gettext('ÜCRETSİZ')}
                </div>
              </div>
        )}
      </div>
      <div className={`mobile-summary-container__footer ${isAgreementActive ? 'acreement-active' : ''}  ${project}`}>
        { isAgreementActive && (
        <div className="mobile-summary-container__agreement">
          <label className={`checkbox ${checkboxValidation ? 'checkbox--error' : 'checkbox--gray'} ${project}-checkbox js-agreement-required`} ref={agreementCheckbox} htmlFor="cc_check">
            <input data-test="mobile-summary-agreement-check" type="checkbox" name="cc_check" id="cc_check" checked={isAgreementCheck} onChange={() => dispatch(setAgreementCheck(!isAgreementCheck))} />
            <span id="js-cc-check--icon" data-test="mobile-summary-agreement" />
            {project !== 'sneakerbox'
              ? (
                <div className={`mobile-summary-container__agreement--wrapper ${(!isAgreementCheck && checkboxValidation) ? 'mobile-summary-container__agreement--error' : ' '} ${project}-mobile-summary-container__agreement--error`}>
                  { basketInfo?.basketType !== 'self_checkout' && (
                  <>
                    <u className="agreement-read-link" onClick={() => executeAgreementsListScroll()}>{gettext('Mesafeli Satış Sözleşmesini')}</u>
                    {' '}
                    {gettext('ve')}
                  </>
            )}
                  <u className="agreement-read-link" onClick={() => executeAgreementsListScroll()}>
                    {' '}
                    {gettext(' Ön Bilgilendirme Formunu')}
                  </u>
                  {' '}
                  {gettext('okudum onaylıyorum.')}
                </div>
              )
              : (
                <div className={`mobile-summary-container__agreement--wrapper ${(!isAgreementCheck && checkboxValidation) ? 'mobile-summary-container__agreement--error' : ' '} ${project}-mobile-summary-container__agreement--error`}>
                  Я прочитал и принимаю приложенные соглашения.
                </div>
              )}

          </label>
        </div>
        )}

        <div className={`mobile-summary-container__payment ${project}-mobile-summary-container__payment`}>
          <div className="mobile-summary-container__total" onClick={() => mobilSummary()} data-test="checkout-summary-total-button">
            <div className="mobile-summary-container__arrow" />
            <div className={`mobile-summary-container-total ${project}-mobile-summary-container-total`}>
              <p className="mobile-summary-container-total__head">{gettext('Toplam')}</p>
              <span className="mobile-summary-container-total__text">
                {paymentMethod === 'cashondelivery'
                  ? toMoney(
                        shoppingCart?.total + orderSummary?.cashOnDeliveryFee?.value,
                  )
                  : toMoney(maturityDifferenceRate?.installment_amount_sum || shoppingCart?.total)}
              </span>
            </div>
          </div>
          { project === 'reebok' ? <CustomButton
              className="reebok-payment-step-button reebok-start-shopping-button"
              dataTest="open-to-cart-page-confirm-cart"
              type="button"
              onClick={onClick}
            >
            <div
              ref={emptyPageHoverRef}
              className="reebok-payment-step-button-text-wrapper"
            >
              <span className="reebok-payment-step-button__text">
                {gettext(buttonText)}
              </span>
              <span className="">
                      {isEmptyPageHoverRef ? <UnionBlack /> : <UnionWhite />}
                    </span>
                  </div>
                </CustomButton>


            : <button
              data-test="open-to-cart-page-confirm-cart"
              className={`btn btn-green mobile-summary-container__button ${project}`}
              onClick={onClick}
              >
              {gettext(buttonText)}
            </button>
            }
        </div>
      </div>
      { cardValidationError && <CustomToastMessage text={gettext('Siparişinizi onaylamak için eksik bilgilerinizi doldurmanız gerekmektedir.')} handleClose={handleClose} isMobile />}
    </div>
  );
};

MobileCardSummary.propTypes = {
  shoppingCart: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
  buttonText: PropTypes.string,
};

MobileCardSummary.defaultProps = {
  shoppingCart: {},
  onClick: () => {},
  buttonText: 'SEPETİ ONAYLA',
};
export default MobileCardSummary;
