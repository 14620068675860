/* eslint-disable import/prefer-default-export */
const lumberjackitDev = {
  BASE_URL: 'https://staging.lumberjack.com/',
  COOKIE_BASE_URL: 'staging.lumberjack.com',
  CHECKOUT_URL: 'https://staging.lumberjack.com/',
  CHECKOUT_BASE_URL: 'https://staging.lumberjack.com/',
  currency: 'EUR',
  locale: ['it','en'],
  symbol: true,
  quoteitemtotal: 20,
  logoPath: 'pub/assets/lumberjackit/img/Lumberjack-logo-black.svg',
  project: 'lumberjack_it',
  title: 'Lumberjack',
  appDeepLink: 'lumberjack://',
  failuresNumber: '3',
  manifest: 'pub/pwa/manifest-lumberjack.json',
  favicon: 'pub/assets/lumberjack/img/logo.png',
  loadingAnimation: 'pub/assets/flo/img/loading/flo_loading_white.gif',
  gtmMerchant: '',
  googleClientId: '',
  twitterCreator: '',
  appStoreId: '',
  alternateIos: '',
  alternateAndroid: '',
  facebookDomainVerification: '',
  robotoFont: '',
  androidPackage: '',
  appleClientId: '',
  siteName: 'lumberjack.com.',
  gtmAccountId: 'GTM-NVXZBF3',
  segmentifyScript: '',
  phoneCode: [
    { name: '+39', mask: '(599) 9999 999' },
    { name: '+49', mask: '(599) 999 99 99' },
    { name: '+90', mask: '(599) 999 99 99' }
  ],
  isGlobal: true,
  cunda: true
};

const lumberjackitStaging = {
  BASE_URL: 'https://staging.lumberjack.com/',
  COOKIE_BASE_URL: 'staging.lumberjack.com',
  CHECKOUT_URL: 'https://staging.lumberjack.com/',
  CHECKOUT_BASE_URL: 'https://staging.lumberjack.com/',
  currency: 'EUR',
  locale: ['it','en'],
  symbol: true,
  quoteitemtotal: 20,
  logoPath: 'pub/assets/lumberjackit/img/Lumberjack-logo-black.svg',
  project: 'lumberjack_it',
  title: 'Lumberjack',
  appDeepLink: 'lumberjack://',
  failuresNumber: '3',
  manifest: 'pub/pwa/manifest-lumberjack.json',
  favicon: 'pub/assets/lumberjack/img/logo.png',
  loadingAnimation: 'pub/assets/flo/img/loading/flo_loading_white.gif',
  gtmMerchant: '',
  googleClientId: '',
  twitterCreator: '',
  appStoreId: '',
  alternateIos: '',
  alternateAndroid: '',
  facebookDomainVerification: '',
  robotoFont: '',
  androidPackage: '',
  appleClientId: '',
  siteName: 'lumberjack.com.',
  gtmAccountId: 'GTM-NVXZBF3',
  segmentifyScript: '',
  phoneCode: [
    { name: '+39', mask: '(599) 9999 999' },
    { name: '+90', mask: '(599) 999 99 99' }
  ],
  isGlobal: true,
  cunda: true
};

const lumberjackitPreprod = {
  BASE_URL: 'https://preprod.lumberjack.com/',
  COOKIE_BASE_URL: 'lumberjack.com',
  CHECKOUT_URL: 'https://preprod.lumberjack.com/',
  CHECKOUT_BASE_URL: 'https://preprod.lumberjack.com/',
  currency: 'EUR',
  locale: ['it','en'],
  symbol: true,
  quoteitemtotal: 20,
  logoPath: 'pub/assets/lumberjackit/img/Lumberjack-logo-black.svg',
  project: 'lumberjack_it',
  title: 'Lumberjack',
  appDeepLink: 'lumberjack://',
  failuresNumber: '3',
  manifest: 'pub/pwa/manifest-lumberjack.json',
  favicon: 'pub/assets/lumberjack/img/logo.png',
  loadingAnimation: 'pub/assets/flo/img/loading/flo_loading_white.gif',
  gtmMerchant: '',
  googleClientId: '',
  twitterCreator: '',
  appStoreId: '',
  alternateIos: '',
  alternateAndroid: '',
  facebookDomainVerification: '',
  robotoFont: '',
  androidPackage: '',
  appleClientId: '',
  siteName: 'lumberjack.com.',
  gtmAccountId: 'GTM-NVXZBF3',
  segmentifyScript: '',
  phoneCode: [
    { name: '+39', mask: '(599) 9999 999' }
  ],
  isGlobal: true,
  cunda: true
};

const lumberjackitProd = {
  BASE_URL: 'https://www.lumberjack.com/',
  COOKIE_BASE_URL: 'lumberjack.com',
  CHECKOUT_URL: 'https://www.lumberjack.com/',
  CHECKOUT_BASE_URL: 'https://www.lumberjack.com/',
  currency: 'EUR',
  locale: ['it','en'],
  symbol: true,
  quoteitemtotal: 20,
  logoPath: 'pub/assets/lumberjackit/img/Lumberjack-logo-black.svg',
  project: 'lumberjack_it',
  title: 'Lumberjack',
  appDeepLink: 'lumberjack://',
  failuresNumber: '3',
  manifest: 'pub/pwa/manifest-lumberjack.json',
  favicon: 'pub/assets/lumberjack/img/logo.png',
  loadingAnimation: 'pub/assets/flo/img/loading/flo_loading_white.gif',
  gtmMerchant: '',
  googleClientId: '',
  twitterCreator: '',
  appStoreId: '',
  alternateIos: '',
  alternateAndroid: '',
  facebookDomainVerification: '',
  robotoFont: '',
  androidPackage: '',
  appleClientId: '',
  siteName: 'lumberjack.com.',
  gtmAccountId: 'GTM-NVXZBF3',
  segmentifyScript: '',
  phoneCode: [
    { name: '+39', mask: '(599) 9999 999' }
  ],
  isGlobal: true,
  cunda: false
};

exports.lumberjackitDev = lumberjackitDev;
exports.lumberjackitStaging = lumberjackitStaging;
exports.lumberjackitPreprod = lumberjackitPreprod;
exports.lumberjackitProd = lumberjackitProd;
