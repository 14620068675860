import React from 'react';
import PropTypes from 'prop-types';
import { gettext } from '@Translate';

const SizeWrapper = ({ size }) => (
  <>
    <span className="size-title">
      {gettext('Beden:')}
    </span>
    <span className="size-number">
      {size}
    </span>
  </>
);

SizeWrapper.defaultProps = {
  size: Number,
};

SizeWrapper.propTypes = {
  size: PropTypes.string,
};

export default SizeWrapper;
