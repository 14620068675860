import * as React from 'react'
import { memo } from 'react'
const TierOne = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="17.578" height="20.766" viewBox="0 0 17.578 20.766" {...props}>
    <g id="hat" transform="translate(-15.06 -7.995)">
      <path id="Path_14564" data-name="Path 14564" d="M16.2,28.761H31.5a1.141,1.141,0,0,0,1.141-1.141V23.965A1.141,1.141,0,0,0,31.5,22.825h-.132V20.581a7.528,7.528,0,0,0-5.8-7.318,2.912,2.912,0,1,0-3.424,0,7.528,7.528,0,0,0-5.8,7.318v2.253H16.2a1.141,1.141,0,0,0-1.141,1.141V27.62A1.141,1.141,0,0,0,16.2,28.761Zm5.5-17.844a2.152,2.152,0,1,1,2.155,2.149A2.152,2.152,0,0,1,21.7,10.917Zm-4.6,9.665a6.756,6.756,0,0,1,13.513,0v2.253H17.093ZM15.82,23.965a.38.38,0,0,1,.38-.38H31.5a.38.38,0,0,1,.38.38V27.62a.38.38,0,0,1-.38.38H16.2a.38.38,0,0,1-.38-.38Z" transform="translate(0 0)" fill="#ee4e18"/>
      <path id="Path_14565" data-name="Path 14565" d="M23.27,47.631a.38.38,0,0,0,.38-.38V45.383a.38.38,0,1,0-.76,0v1.868A.38.38,0,0,0,23.27,47.631Zm2.16,0a.38.38,0,0,0,.38-.38V45.383a.38.38,0,1,0-.76,0v1.868A.38.38,0,0,0,25.43,47.631Zm2.16,0a.38.38,0,0,0,.38-.38V45.383a.38.38,0,1,0-.76,0v1.868A.38.38,0,0,0,27.589,47.631Zm2.172,0a.38.38,0,0,0,.38-.38V45.383a.38.38,0,1,0-.76,0v1.868A.38.38,0,0,0,29.762,47.631Zm2.147,0a.38.38,0,0,0,.38-.38V45.383a.38.38,0,1,0-.76,0v1.868A.38.38,0,0,0,31.909,47.631Zm2.162,0a.38.38,0,0,0,.38-.38V45.383a.38.38,0,1,0-.76,0v1.868A.38.38,0,0,0,34.071,47.631Zm2.157,0a.38.38,0,0,0,.38-.38V45.383a.38.38,0,1,0-.76,0v1.868A.38.38,0,0,0,36.228,47.631ZM29.354,35.48A3.86,3.86,0,0,0,25.5,39.335a.38.38,0,0,0,.76,0,3.1,3.1,0,0,1,3.095-3.095.38.38,0,1,0,0-.76Z" transform="translate(-5.845 -20.518)" fill="#ee4e18"/>
    </g>
  </svg>
)

const Memo = memo(TierOne)
export default Memo
