import * as React from 'react'
import { memo } from 'react'
const SvgInformationSolid = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='1em'
    height='1em'
    viewBox='0 0 2.8 13.994'
    fill='currentColor'
    {...props}
  >
    <path
      d='M1.4 13.995a1.4 1.4 0 0 1-1.4-1.4v-5.6a1.4 1.4 0 1 1 2.8 0v5.6a1.4 1.4 0 0 1-1.4 1.4m0-11.2a1.4 1.4 0 0 1-.532-.112 1.6 1.6 0 0 1-.462-.294A1.39 1.39 0 0 1 0 1.395 1.2 1.2 0 0 1 .112.863 1.6 1.6 0 0 1 .406.401 1.4 1.4 0 0 1 .868.107a1.4 1.4 0 0 1 1.064 0 1.5 1.5 0 0 1 .462.294 1.47 1.47 0 0 1 .406.994 1.386 1.386 0 0 1-1.4 1.4'
      data-name='Path 13761'
    />
  </svg>
)
const Memo = memo(SvgInformationSolid)
export default Memo
