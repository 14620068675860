import * as React from 'react'
import { memo } from 'react'
const SvgLocationIcon = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='1em'
    height='1em'
    viewBox='0 0 41.575 52.712'
    fill='currentColor'
    {...props}
  >
    <g data-name='Group 14299'>
      <g data-name='Group 14297'>
        <path
          fill='#f60'
          d='M41.198 20.755a19.6 19.6 0 0 1-.307 3.134 31 31 0 0 1-4.264 10.3 63 63 0 0 1-5.085 7.107c-.65.8-1.336 1.567-2.007 2.349-1.431 1.667-2.948 3.25-4.5 4.8q-1.937 1.94-3.991 3.757c-.189.168-.3.189-.5.01q-3.465-3.087-6.625-6.485a86 86 0 0 1-2.91-3.272 76 76 0 0 1-3.81-4.885 48 48 0 0 1-4.211-6.978 27.7 27.7 0 0 1-2.1-5.75 17.9 17.9 0 0 1-.509-4.405 20.4 20.4 0 0 1 2.079-8.65 20.5 20.5 0 0 1 3.438-4.971 20.5 20.5 0 0 1 5.827-4.34A19.9 19.9 0 0 1 18.27.51a15 15 0 0 1 1.869-.128 26 26 0 0 1 2.592.061 20.7 20.7 0 0 1 5.654 1.373 20.3 20.3 0 0 1 6.7 4.375 20.2 20.2 0 0 1 3.528 4.61 20.2 20.2 0 0 1 1.946 4.864 20 20 0 0 1 .586 3.641c.025.42.027.84.053 1.449'
          data-name='Path 13492'
        />
        <path
          fill='#fff'
          d='M20.793 52.713a.75.75 0 0 1-.5-.22 100 100 0 0 1-6.65-6.509 86 86 0 0 1-2.923-3.287 77 77 0 0 1-3.829-4.909 47.5 47.5 0 0 1-4.243-7.033A27.7 27.7 0 0 1 .52 24.927a18.2 18.2 0 0 1-.518-4.5 20.8 20.8 0 0 1 2.118-8.808 21 21 0 0 1 3.5-5.062 21 21 0 0 1 5.933-4.42 20.3 20.3 0 0 1 6.67-2 16 16 0 0 1 1.915-.131h.549a19 19 0 0 1 2.081.065 21.2 21.2 0 0 1 5.756 1.4 20.8 20.8 0 0 1 6.821 4.456 20.7 20.7 0 0 1 3.593 4.7 20.6 20.6 0 0 1 1.983 4.945 20 20 0 0 1 .6 3.71c.015.245.023.485.031.763.006.206.013.432.024.694v.025a20 20 0 0 1-.314 3.195A30.8 30.8 0 0 1 36.95 34.38a63 63 0 0 1-5.115 7.15c-.46.564-.943 1.121-1.41 1.658q-.3.348-.6.7c-1.246 1.451-2.681 2.984-4.518 4.824a94 94 0 0 1-4.007 3.773.77.77 0 0 1-.507.228M20.11.758a15 15 0 0 0-1.794.125 19.6 19.6 0 0 0-6.424 1.928 20.2 20.2 0 0 0-5.721 4.261 20.2 20.2 0 0 0-3.375 4.88 20.1 20.1 0 0 0-2.041 8.492 17.5 17.5 0 0 0 .5 4.312 27 27 0 0 0 2.073 5.672 47 47 0 0 0 4.178 6.923 76 76 0 0 0 3.792 4.861 83 83 0 0 0 2.9 3.258 99 99 0 0 0 6.594 6.455l.006-.005a92 92 0 0 0 3.975-3.742c1.823-1.826 3.246-3.346 4.479-4.782q.3-.352.607-.7c.463-.533.942-1.085 1.394-1.64a62 62 0 0 0 5.055-7.064 30.1 30.1 0 0 0 4.216-10.171 19 19 0 0 0 .3-3.06c-.011-.26-.018-.485-.024-.691a21 21 0 0 0-.03-.739 19.4 19.4 0 0 0-.576-3.572 19.9 19.9 0 0 0-1.91-4.774 20 20 0 0 0-3.463-4.524 20 20 0 0 0-6.575-4.295A20.5 20.5 0 0 0 22.695.818a19 19 0 0 0-2-.061h-.583Z'
          data-name='Path 13493'
        />
      </g>
      <g data-name='Group 14298'>
        <path
          fill='#ff812c'
          d='M38.323 20.501a17.536 17.536 0 0 1-17.537 17.536A17.536 17.536 0 0 1 3.25 20.501 17.537 17.537 0 0 1 20.786 2.964a17.537 17.537 0 0 1 17.537 17.537'
          data-name='Path 13494'
        />
        <path
          fill='#fff'
          d='M36.387 20.5a15.6 15.6 0 0 1-15.6 15.6 15.6 15.6 0 0 1-15.6-15.6 15.6 15.6 0 0 1 15.6-15.6 15.6 15.6 0 0 1 15.6 15.6'
          data-name='Path 13495'
        />
      </g>
      <g fill='#f60' data-name='Group 4689'>
        <path
          d='M27.72 28.651h-7.594c0-.069-.008-.137-.008-.207v-5.919a.67.67 0 0 0-.675-.663h-3.381a.65.65 0 0 0-.651.461 1.2 1.2 0 0 0-.039.329v5.8a2 2 0 0 1-.008.207h-1.493a.8.8 0 0 1-.537-.867c.01-2.394 0-4.789 0-7.175v-.2a5 5 0 0 0 .63.086q1.064.014 2.128 0a1.9 1.9 0 0 0 .939-.252c.126-.076.252-.166.386-.252a2.23 2.23 0 0 0 1.574.514c1.26-.013 2.509 0 3.764-.006a3 3 0 0 0 .519-.053 2 2 0 0 0 .914-.462 2.22 2.22 0 0 0 1.656.52c.349-.014.7 0 1.048 0a4.6 4.6 0 0 0 1.323-.1q.009.002.038.023v7.358a.8.8 0 0 1-.533.858m-3.879-3.4h1.619a.686.686 0 0 0 .756-.607.7.7 0 0 0 0-.149v-1.878a.683.683 0 0 0-.6-.755.7.7 0 0 0-.142 0h-3.255a.68.68 0 0 0-.749.607.7.7 0 0 0 0 .149v1.878a.686.686 0 0 0 .607.756.7.7 0 0 0 .149 0Z'
          data-name='Path 2354'
        />
        <path
          d='M28.927 18.655a3 3 0 0 1-.126.359 1.32 1.32 0 0 1-1.245.808q-1.008.019-2.016 0a1.34 1.34 0 0 1-1.328-1.066l-.036-.126a1.36 1.36 0 0 1-1.048 1.152 1.8 1.8 0 0 1-.425.04h-3.8a1.386 1.386 0 0 1-1.512-1.188c-.015.052-.026.086-.035.126a1.336 1.336 0 0 1-1.313 1.064q-1.024.022-2.049 0a1.347 1.347 0 0 1-1.349-1.345V17.8c.068 0 .126-.008.18-.008h15.93a1 1 0 0 1 .157.008Z'
          data-name='Path 2355'
        />
        <path
          d='M22.413 12.35q.196 1.013.4 2.025.252 1.293.517 2.586a1 1 0 0 1 .01.126h-5.1l.188-.958.745-3.723a1 1 0 0 0 0-.063Z'
          data-name='Path 2356'
        />
        <path
          d='M17.806 12.35q-.175.85-.348 1.7-.292 1.457-.581 2.915-.014.062-.034.126h-4.014c.088-.311.173-.61.252-.91.3-1.06.61-2.117.9-3.178a.83.83 0 0 1 .575-.654Z'
          data-name='Path 2357'
        />
        <path
          d='M27.021 12.35a.83.83 0 0 1 .575.654c.363 1.312.741 2.619 1.114 3.928q.02.072.042.161h-4.01q-.114-.563-.227-1.134l-.708-3.554a.4.4 0 0 0-.023-.059Z'
          data-name='Path 2358'
        />
      </g>
    </g>
  </svg>
)
const Memo = memo(SvgLocationIcon)
export default Memo
