/* eslint-disable max-len */
import React from 'react';
import { useDispatch } from 'react-redux';
import {
  getSummoningShoppingCartIdAction,
  resetThreeDInfo,
} from '@Actions';

export default ({ customText }) => {
  // TODO: Bankadan çözüm istenecek - JS:HACKING | halyk bank
  const onpopstate = (event) => {
    event.preventDefault();
      history.go(1);
  };
  // halyk bank, flo kazakistan sitesinde kullanılmakta, legacy projeden kalma bir kod, sayfa history'sini etkiliyor
  // testlerden sonra bir sorun çıkmaz ise ilgili fonksiyon ile birlikte silinecek 23/02/24
  //window.onpopstate = onpopstate;

  const dispatch = useDispatch();
  const RG = {
    Overlay: {
      blockTimer: 0,
      message: '',
      tick: '',
      block() {},

      blockTicker() {},

      unblock() {},
    },
    payment: {
      checkoutAction() {},
      reActivateBasket() {},
    },
  };

  RG.payment.reActivateBasket = (data) => {
    dispatch(getSummoningShoppingCartIdAction(data));
  };

  RG.payment.checkoutAction = (data) => {
    if (!data?.success) {
      RG.payment.reActivateBasket(data);
      dispatch(resetThreeDInfo());
    } else {
      setTimeout(() => {
        dispatch(resetThreeDInfo());
        // TODO refactor
        // Burası refactor edildiğinde app teki success kontrolü düzenlenecek
        window.location.href = `/success?order_id=${data?.order_id}&hsh=${data?.hash}`;
      }, 250);
    }
  };
  // RG objesi kullanılmadığı gözlemlendi, legacy projeden gelme kod, testlerden sonra bir sorun çıkmaz ise ilgili fonksiyon ile birlikte silinecek 23/02/24
  // TODO : Refactor - Iframe popup içerisinden erişmek için kullanılıyor
  //window.RG = RG;

  return (
    <>
      <div
        dangerouslySetInnerHTML={{
          __html: customText,
        }}
      />
    </>
  );
};
