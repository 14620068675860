/* eslint-disable no-param-reassign */
/* eslint-disable import/no-cycle */
import {
  createSlice,
} from '@reduxjs/toolkit';
import Cookie from 'js-cookie';

const INITIAL_STATE = {
  language: (Cookie.get('language') || "en"),
};

const slice = createSlice({
  name: 'language',
  initialState: INITIAL_STATE,
  reducers: {
    setCurrentLang: (state, action) => {
      state.language = action.payload;
    },
    getCurrentLang: (state) => {
      return state.language;
    },
  },
  extraReducers: (builder) => {

  },
});

export default slice.reducer;

export const {
  setCurrentLanguage,
  getCurrentLanguage,
} = slice.actions;
