import * as React from 'react'
import { memo } from 'react'
const SvgMenu = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='1em'
    height='1em'
    viewBox='0 0 22.972 15.254'
    fill='currentColor'
    {...props}
  >
    <defs>
      <clipPath id='menu_svg__a'>
        <path d='M0 0h22.972v15.254H0z' data-name='Rectangle 2624' />
      </clipPath>
    </defs>
    <g data-name='Group 4287'>
      <g
        data-name='Group 4029'
        style={{
          clipPath: 'url(#menu_svg__a)',
        }}
      >
        <path
          d='M22.973 7.852a.4.4 0 0 0-.041.076 1.13 1.13 0 0 1-1.134.845c-.471.009-.942 0-1.412 0H6.595a1.16 1.16 0 0 1-1.176-.692 1.133 1.133 0 0 1 .908-1.588 3 3 0 0 1 .38-.015c4.941 0 9.882.006 14.823-.008a1.31 1.31 0 0 1 1.444.93z'
          data-name='Path 13241'
        />
        <path
          d='m22.972 1.693-.089.2a1.12 1.12 0 0 1-1.009.7H6.603a1.28 1.28 0 0 1-.86-.256A1.147 1.147 0 0 1 6.404.309c.1-.006.194 0 .291 0h14.868a1.223 1.223 0 0 1 1.409.941z'
          data-name='Path 13242'
        />
        <path
          d='M22.972 14.009a1.2 1.2 0 0 1-.962.921 1.5 1.5 0 0 1-.267.019h-15.2a1.148 1.148 0 0 1-1.159-1.509 1.13 1.13 0 0 1 1.044-.779h.292c4.933 0 9.867.008 14.8-.009a1.33 1.33 0 0 1 1.457.912z'
          data-name='Path 13243'
        />
        <path
          d='M0 1.543A1.546 1.546 0 0 1 1.538 0 1.55 1.55 0 0 1 3.08 1.539a1.55 1.55 0 0 1-1.539 1.543A1.547 1.547 0 0 1 0 1.543'
          data-name='Path 13244'
        />
        <path
          d='M0 13.702a1.54 1.54 0 1 1 3.079.023 1.55 1.55 0 0 1-1.552 1.53A1.547 1.547 0 0 1 0 13.702'
          data-name='Path 13245'
        />
        <path
          d='M1.529 9.156a1.534 1.534 0 1 1 1.55-1.525 1.533 1.533 0 0 1-1.55 1.525'
          data-name='Path 13246'
        />
      </g>
    </g>
  </svg>
)
const Memo = memo(SvgMenu)
export default Memo
