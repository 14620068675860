/* eslint-disable max-len */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CustomSelect } from '@Components';
import { gettext } from '@Translate';

import {
  setCreditCartIdAction,
  setPaymentMethodAction,
  setDijitalPaymentMethodAction,
  setCreditCardAction
} from '@Actions';

import {
  IsArray
 } from '../../../../../utils/IsArray';

import HepsiPayInstallment from './installment';


export default (props) => {
  const {code } = props.item;
  const {
    hepsiPaySavedCardList,
    numberOfInstallments
  } = useSelector((state) => state.order);
  const dispatch = useDispatch();

  return (
    <>
      <div className="select-wrapper form-group">
        <span className="pay-with-another-card-title">
          {hepsiPaySavedCardList?.length ? gettext('Kayıtlı Kartlarım') : gettext('Kart Numarası')}
        </span>
        <div className="select-group">
          {IsArray(hepsiPaySavedCardList) && (
            <CustomSelect
              list={hepsiPaySavedCardList}
              title="Kayıtlı Kartlarım"
              name="savecard"
              type="card"
              defaultValue={hepsiPaySavedCardList[0]?.name}
              onChange={(event) => {dispatch(
                setCreditCartIdAction(
                  hepsiPaySavedCardList.find(
                    (item) => item?.id === event?.id,
                  ),
                ),
              );
            }}
              dataTest="checkout-payment-credit-card-save-card-list-change"
              id="hepsipay-saved-card-list"

            />
          )}
        </div>
      </div>
      {code === 'flo_hepsipay' && (
        <HepsiPayInstallment  savedCardList={hepsiPaySavedCardList}/>
      )}
    </>
  );
};
