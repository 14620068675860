import * as React from 'react'
import { memo } from 'react'
const SvgForgotPassword = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    id='_forgot_password_svg__Layer_1'
    viewBox='0 0 190.37 200'
    width='1em'
    height='1em'
    fill='currentColor'
    {...props}
  >
    <defs>
      <clipPath id='_forgot_password_svg__clippath'>
        <path
          d='M144.04 21.02 13.92 46.31C4.6 48.13-1.49 57.16.32 66.48c.1.52.23 1.03.37 1.54 7.12 24.36 19.65 67.16 26.12 89.29 2.38 8.13 10.33 13.32 18.73 12.22l129.86-16.96c9.42-1.23 16.05-9.87 14.82-19.29-.1-.73-.24-1.46-.43-2.17l-25.86-97.63c-2.32-8.75-11.02-14.21-19.91-12.48'
          style={{
            fill: 'none',
          }}
        />
      </clipPath>
      <style>
        {'._forgot_password_svg__cls-7{fill-rule:evenodd;fill:#f60}'}
      </style>
    </defs>
    <g id='_forgot_password_svg__Group_5503'>
      <g id='_forgot_password_svg__Group_5502'>
        <path
          id='_forgot_password_svg__Path_13402'
          d='M163.95 33.5c-2.32-8.75-11.02-14.21-19.91-12.48L13.92 46.32C4.6 48.13-1.49 57.16.32 66.48c.1.52.23 1.04.37 1.54 7.12 24.36 19.65 67.16 26.12 89.29 2.38 8.13 10.33 13.32 18.73 12.23l129.86-16.96c9.42-1.23 16.05-9.87 14.82-19.29-.1-.73-.24-1.46-.43-2.17l-25.86-97.63'
          style={{
            fillRule: 'evenodd',
            fill: '#d3d3d3',
          }}
        />
      </g>
    </g>
    <g
      id='_forgot_password_svg__Group_5505'
      style={{
        clipPath: 'url(#_forgot_password_svg__clippath)',
      }}
      transform='translate(6.811 6.401)'
    >
      <g id='_forgot_password_svg__Group_5504'>
        <path
          id='_forgot_password_svg__Path_13403'
          d='m-23.9 55.26 113.43 54.05c4.22 2.01 9.25 1.03 12.4-2.41l91.29-99.52C110.67-12.51 43.12-1.45-23.9 55.26'
          style={{
            fillRule: 'evenodd',
            fill: '#adadad',
          }}
        />
      </g>
    </g>
    <g id='_forgot_password_svg__Group_5507'>
      <g id='_forgot_password_svg__Group_5506'>
        <path
          id='_forgot_password_svg__Path_13405'
          d='M189.49 146.21 83.8 200l-8.58-44.62z'
          style={{
            fill: '#fa9653',
            fillRule: 'evenodd',
          }}
        />
        <path
          id='_forgot_password_svg__Path_13406'
          d='M189.49 146.21 83.8 200l-20.79-31.15z'
          style={{
            fillRule: 'evenodd',
            fill: '#de5900',
          }}
        />
        <path
          id='_forgot_password_svg__Path_13407'
          d='m189.49 146.21-98.44-9.07c-1.05-.1-2.09.35-2.73 1.19l-13.09 17.05z'
          className='_forgot_password_svg__cls-7'
        />
        <path
          id='_forgot_password_svg__Path_13408'
          d='M189.46 146.15 41.4 199.72a1.535 1.535 0 0 1-1.71-2.42L63 168.83l126.45-22.69Z'
          className='_forgot_password_svg__cls-7'
        />
        <path
          id='_forgot_password_svg__Path_13409'
          d='M174.19 4.68c12.18 8.35 15.29 24.98 6.94 37.16s-24.98 15.29-37.16 6.94-15.29-24.98-6.94-37.16c8.35-12.17 24.98-15.28 37.16-6.94'
          className='_forgot_password_svg__cls-7'
        />
        <path
          id='_forgot_password_svg__Path_13410'
          d='M168.61 19.93c.23-3.88-2.6-7.27-6.46-7.72a8.07 8.07 0 0 0-8.71 5.85l-1.69 6.09a4.78 4.78 0 0 1-2.52 3.02 4.614 4.614 0 0 0-2.15 6.16 4.6 4.6 0 0 0 3.14 2.49c5.54 1.25 11.18 2.06 16.85 2.43a3.58 3.58 0 0 0 3.75-2.11c.42-1.83-.1-3.75-1.37-5.13a6.36 6.36 0 0 1-1.17-5.36c.1-1.65.22-3.73.35-5.74'
          style={{
            fillRule: 'evenodd',
            fill: '#fff',
          }}
        />
      </g>
    </g>
  </svg>
)
const Memo = memo(SvgForgotPassword)
export default Memo
