import React from 'react';
import PropTypes from 'prop-types';
import { CloseIcon, InformationSolid } from '@Icons';
import config from '@Config';

const CustomToastMessage = ({ text, handleClose, isMobile }) => {
  const { project } = config;
  return (
    <div className={`error-popup ${project} ${isMobile ? 'mobile-error' : ''}`}>
      <div className="popup-text">
        <div className="alert-icon">
          <InformationSolid />
        </div>
        <p>{text}</p>
      </div>
      <div className="popup-icon" onClick={handleClose}>
        <CloseIcon />
      </div>
    </div>
  );
};

CustomToastMessage.propTypes = {
  text: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
};

export default CustomToastMessage;
