import React from 'react';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { toast, Zoom } from 'react-toastify';
import { ToastfyMessage } from '@Components';
import { logEvent } from '@Constants';
import API from '@API';

export const getBagsAction = createAsyncThunk(
  'getBags',
  async ({ storeCode }, { dispatch }) => {
    const response = await API.getBags({ storeCode });
    return response.data;
  },
);

export const activeQrCodeAction = createAsyncThunk(
  'activeQrCode',
  async ({ storeCode }) => {
    const response = await API.activeQrCode({ storeCode });
    return response.data;
  },
);

export const createQrCodeAction = createAsyncThunk(
  'createQrCode',
  async (arg, { dispatch }) => {
    const response = await API.createQrCode({ ...arg });
    return response.data;
  },
);

export const cancelOrderAction = createAsyncThunk(
  'cancelOrder',
  async (arg, { dispatch, getState }) => {
    const response = await API.cancelOrder({ ...arg });
    const {
      shoppingCart,
    } = getState().cart;
    return {
      ...response.data,
      shoppingCart: shoppingCart
    };
  },
);

export const qrCodeCheckAction = createAsyncThunk(
  'qrCodeCheck',
  async (arg, { dispatch }) => {
    const response = await API.qrCodeCheck({ ...arg });
    return response.data;
  },
);



const INITIAL_STATE = {
  selfCheckout: false,
  isOrderTimeout: false,
  qrCode: '',
  activeQR: false,
  activeOrder: null,
  stateOrderDetail: {
    orderId: '',
    hsh: ''
  }
};

const slice = createSlice({
  name: 'selfCheckout',
  initialState: INITIAL_STATE,
  reducers: {
    setSelfCheckoutAction: (state, action) => {
      state.selfCheckout = action.payload;
    },
    setOrderTimeOutAction: (state, action) => {
      state.isOrderTimeout = action.payload;
    },
    setStoreOrderDetailAction: (state, action) => {
      state.stateOrderDetail = action.payload
    }
  },
  extraReducers: (builder) => {
    // getBagsAction
    builder.addCase(getBagsAction.pending, (state, action) => {
      state.bagsLoading = true;
      state.bagsRequestId = action.meta.requestId;
    });
    builder.addCase(
      getBagsAction.fulfilled,
      (state, { payload }) => {
        const { status, products } = payload;
        if (status?.code === 200) {
          state.bags = products; 
        } else if(status?.message) {
          toast.dismiss();
          toast.error(ToastfyMessage({ text: status?.message  }));
        }
        state.bagsLoading = false;
        state.bagsRequestId = null;
      },
    );
    builder.addCase(getBagsAction.rejected, (state) => {
      state.bagsLoading = false;
      state.bagsRequestId = null;
    });
    // create qr action
    builder.addCase(createQrCodeAction.pending, (state, action) => {
      state.createQrLoading = true;
      state.createQrRequestId = action.meta.requestId;
    });
    builder.addCase(
      createQrCodeAction.fulfilled,
      (state, { payload }) => {
        const { status, qr_code } = payload;
        if (status?.code === 200 ) {
          state.qrCode = qr_code;
        } else if (status?.code !== 200 && status?.message) {
          toast.dismiss();
          toast.error(ToastfyMessage({ text: status?.message }));
        }
        state.createQrLoading = false;
        state.createQrRequestId = null;
      },
    );
    builder.addCase(createQrCodeAction.rejected, (state) => {
      state.createQrLoading = false;
      state.createQrRequestId = null;
    });
    // cancel qr action
    builder.addCase(cancelOrderAction.pending, (state, action) => {
      state.cancelOrderLoading = true;
      state.cancelOrderRequestId = action.meta.requestId;
    });
    builder.addCase(
      cancelOrderAction.fulfilled,
      (state, { payload }) => {
        const { status, shoppingCart } = payload;
        if (status?.code !== 200 && status?.message) {
          toast.dismiss();
          toast.error(ToastfyMessage({ text: status?.message }));
        } else {
          logEvent('self_checkout_refund', {
            ...payload,
            items: shoppingCart?.shoppingCartItemList,
            value: shoppingCart?.grandTotal,
            currency: "TRY"
          })
        }
        state.cancelOrderLoading = false;
        state.cancelOrderRequestId = null;
      },
    );
    builder.addCase(cancelOrderAction.rejected, (state) => {
      state.cancelOrderLoading = false;
      state.cancelOrderRequestId = null;
    });
    // check qr action
    builder.addCase(qrCodeCheckAction.pending, (state, action) => {
      state.qrCodeCheckLoading = true;
      state.qrCodeCheckRequestId = action.meta.requestId;
    });
    builder.addCase(
      qrCodeCheckAction.fulfilled,
      (state, { payload }) => {
        const { status } = payload;
        if (status?.code !== 200 && status?.message) {
          toast.dismiss();
          toast.error(ToastfyMessage({ text: status?.message }));
        }
        state.qrCodeCheckLoading = false;
        state.qrCodeCheckRequestId = null;
      },
    );
    builder.addCase(qrCodeCheckAction.rejected, (state) => {
      state.qrCodeCheckLoading = false;
      state.qrCodeCheckRequestId = null;
    });
    // active qr action
    builder.addCase(activeQrCodeAction.pending, (state, action) => {
      state.activeQrCodeLoading = true;
      state.activeQrCodeRequestId = action.meta.requestId;
    });
    builder.addCase(
      activeQrCodeAction.fulfilled,
      (state, { payload }) => {
        const { status, time, qrCode, incrementId, orderHash } = payload;
        if (status?.code === 200 && time > 15 ) {
          state.activeQR = true;
          state.activeOrder = {
            time,
            qrCode,
            incrementId,
            orderHash
          }
        }
        state.activeQrCodeLoading = false;
        state.activeQrCodeRequestId = null;
      },
    );
    builder.addCase(activeQrCodeAction.rejected, (state) => {
      state.activeQrCodeLoading = false;
      state.activeQrCodeRequestId = null;
    });
  }
});

export default slice.reducer;

export const {
  setSelfCheckoutAction,
  setOrderTimeOutAction,
  setStoreOrderDetailAction
} = slice.actions;