import * as React from 'react'
import { memo } from 'react'
const SvgXCloseSml = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='1em'
    height='1em'
    viewBox='0 0 38 38'
    fill='currentColor'
    {...props}
  >
    <path
      fill='#fff'
      d='M.76 37.24h36.48V.76H.76Zm9.126-25.669 1.713-1.691 7.4 7.307 7.4-7.306 1.719 1.7-7.4 7.306 7.336 7.238-1.577 2L19 20.74l-7.4 7.306-1.719-1.7 7.408-7.312Z'
      data-name='Path 13729'
    />
    <path
      fill='#d8d8d8'
      d='M.76.76h36.48v36.48H.76ZM0 38h38V0H0Z'
      data-name='Path 13730'
    />
    <path
      d='m11.599 28.046 7.4-7.306 7.481 7.382 1.577-2-7.338-7.242 7.4-7.306-1.718-1.694-7.4 7.306-7.402-7.306-1.713 1.691 7.4 7.466-7.406 7.312Z'
      data-name='Path 13731'
    />
  </svg>
)
const Memo = memo(SvgXCloseSml)
export default Memo
