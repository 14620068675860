import * as React from 'react'
import { memo } from 'react'
const SvgCartButigo = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='1em'
    height='1em'
    viewBox='0 0 22 18.98'
    fill='currentColor'
    {...props}
  >
    <path
      fill='#fff'
      d='M16.21 6.98 11.83.42A1 1 0 0 0 11 0a.98.98 0 0 0-.83.43L5.79 6.98H1a1 1 0 0 0-1 1 .8.8 0 0 0 .04.27l2.54 9.27a1.99 1.99 0 0 0 1.92 1.46h13a2.01 2.01 0 0 0 1.93-1.46l2.54-9.27.03-.27a1 1 0 0 0-1-1ZM8 6.98l3-4.4 3 4.4Zm3 8a2 2 0 1 1 2-2 2.006 2.006 0 0 1-2 2'
    />
  </svg>
)
const Memo = memo(SvgCartButigo)
export default Memo
