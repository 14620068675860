import React from 'react';
import PropTypes from 'prop-types';
import GalleryImage from '../GalleryImage';

// Component for gallery
const Gallery = ({ src, openModal }) => (
  <>
    <GalleryImage className="gallery-thumbnail" src={src} alt="" onClick={(e) => openModal(src, e)} />
  </>
);

Gallery.defaultProps = {
  src: '',
  openModal: () => {},
};

Gallery.propTypes = {
  brand: PropTypes.string,
  title: PropTypes.func,

};

export default Gallery;
