import * as React from 'react'
import { memo } from 'react'
const SvgReturnDeliveryIcon = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='1em'
    height='1em'
    viewBox='0 0 30 30'
    fill='currentColor'
    {...props}
  >
    <path
      fill='#f4fbf5'
      d='M15 1 1 7v16l14 6 14-6V7Z'
      data-name='Path 13518'
    />
    <g
      fill='none'
      stroke='#28a745'
      strokeLinejoin='round'
      strokeWidth={2}
      data-name='Group 14630'
    >
      <path
        d='m15 1 14 6-14 6L1 7Zm7 3L8 10m0 16-7-3V7m23 18 5-2V7m-14 6v5m-7 3h9a4 4 0 0 1 0 8h-5'
        data-name='Path 13519'
      />
      <path d='m12 25-5-4 5-4' data-name='Path 13520' />
    </g>
  </svg>
)
const Memo = memo(SvgReturnDeliveryIcon)
export default Memo
