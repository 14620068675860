import * as React from 'react'
import { memo } from 'react'
const InstreetClub = (props) => (
  <svg id="Group_18564" data-name="Group 18564" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="98.014" height="17.346" viewBox="0 0 98.014 17.346">
    <defs>
      <clipPath id="clip-path">
        <rect id="Rectangle_15291" data-name="Rectangle 15291" width="98.013" height="17.346" transform="translate(0 0)"/>
      </clipPath>
      <clipPath id="clip-path-2">
        <rect id="Rectangle_15292" data-name="Rectangle 15292" width="59.328" height="12.842" transform="translate(38.686 2.252)"/>
      </clipPath>
    </defs>
    <rect id="Rectangle_15288" data-name="Rectangle 15288" width="0.724" height="17.346" transform="translate(32.566 0)"/>
    <g id="Group_18565" data-name="Group 18565">
      <g id="Group_18564-2" data-name="Group 18564" clip-path="url(#clip-path)">
        <path id="Path_14618" data-name="Path 14618" d="M4.021,3.806C1.513,3.806,0,5.067,0,6.986v4.006c0,1.918,1.527,3.179,4.035,3.179s3.992-1.261,3.992-3.179v-.9H5.771v.8c0,.8-.6,1.316-1.75,1.316-1.219,0-1.849-.518-1.849-1.316V7.083c0-.8.644-1.316,1.863-1.316,1.133,0,1.736.518,1.736,1.316v.8H8.027v-.9c0-1.919-1.5-3.18-4.006-3.18"/>
        <rect id="Rectangle_15289" data-name="Rectangle 15289" width="2.101" height="10.786" transform="translate(9.366 3.175)"/>
        <path id="Path_14619" data-name="Path 14619" d="M17.054,11.132a1.048,1.048,0,0,1-1.022,1.134c-.631,0-1.023-.42-1.023-1.261V6.818h-2.1v4.145c0,2.27,1.064,3.208,2.662,3.208a2.693,2.693,0,0,0,1.89-.686l.365.476h1.331V6.818h-2.1Z"/>
        <path id="Path_14620" data-name="Path 14620" d="M24.39,6.608a3.215,3.215,0,0,0-1.653.406V3.175h-2.1V13.961h1.387l.337-.462a2.989,2.989,0,0,0,2.031.672c1.722,0,2.8-.9,2.8-3.025V9.633c0-2.129-1.079-3.025-2.8-3.025m.7,4.482a1.059,1.059,0,0,1-1.163,1.176,1.074,1.074,0,0,1-1.19-1.134V9.647a1.062,1.062,0,0,1,1.19-1.135A1.059,1.059,0,0,1,25.09,9.689Z"/>
        <rect id="Rectangle_15290" data-name="Rectangle 15290" width="3.694" height="12.842" transform="translate(38.685 2.252)"/>
        <path id="Path_14621" data-name="Path 14621" d="M54.085,2.252v7.959L47.25,2.252H43.543V15.094H47.25V7.416l6.835,7.678h3.708V2.252Z"/>
        <path id="Path_14622" data-name="Path 14622" d="M91.2,11.149H88.46V9.595H91.2V7.751H88.46V6.2H91.2V4.352H86.194v8.642H91.2Z"/>
        <path id="Path_14623" data-name="Path 14623" d="M85.279,11.149H82.537V9.595h2.742V7.751H82.537V6.2h2.742V4.352H80.271v8.642h5.008Z"/>
      </g>
    </g>
    <g id="Group_18567" data-name="Group 18567">
      <g id="Group_18566" data-name="Group 18566" clip-path="url(#clip-path-2)">
        <path id="Path_14624" data-name="Path 14624" d="M64.228,7.971c-.539-.248-1.15-.352-1.713-.558-.288-.118-.613-.279-.652-.572a.579.579,0,0,1,.359-.673,1.54,1.54,0,0,1,1.061-.1,2.494,2.494,0,0,1,1.05.526c.286-.576.608-1.141.891-1.708a4.839,4.839,0,0,0-1.389-.526A4.566,4.566,0,0,0,61.64,4.3,3.029,3.029,0,0,0,60.1,5.19a2.97,2.97,0,0,0-.642,1.738,2.253,2.253,0,0,0,.381,1.453,2.038,2.038,0,0,0,.814.626A11.891,11.891,0,0,0,62.4,9.53a1.687,1.687,0,0,1,.776.528.741.741,0,0,1-.321.958,1.732,1.732,0,0,1-1.222.146,3.635,3.635,0,0,1-1.514-.807l-.842,1.491-.118.241c-.051.1.78.582.877.63a3.788,3.788,0,0,0,1,.322,4.429,4.429,0,0,0,3.7-.7,3.074,3.074,0,0,0,.7-3.28,2.2,2.2,0,0,0-1.214-1.09"/>
        <path id="Path_14625" data-name="Path 14625" d="M78.872,11.307c-.071-.09-1.525-2-1.525-2a2.4,2.4,0,0,0,1.277-.727,2.675,2.675,0,0,0,.564-1.944,2.383,2.383,0,0,0-.9-1.667,3.813,3.813,0,0,0-2.454-.62c-.1,0-.192,0-.288,0h-.313l-.228,0h-.015s-1.529,0-2.283,0l.006,1.337H72.7v7.305h2.306V9.555l.073.106,2.257,3.332h2.8ZM76.714,4.538H76.7V4.524l.015.014m-.135,1.9a1.141,1.141,0,0,1,.206.878.926.926,0,0,1-.523.643,3.122,3.122,0,0,1-1.253.179V6.052h.372a1.639,1.639,0,0,1,1.2.382"/>
        <path id="Path_14626" data-name="Path 14626" d="M98.014,6.2V4.353H91.955V6.2h1.872v6.8h2.314V6.2Zm-5.891-.332,0-.012v.01l0,0"/>
        <path id="Path_14627" data-name="Path 14627" d="M71.962,6.2V4.353H65.9V6.2h1.872v6.8H70.09V6.2Zm-5.891-.332,0-.012v.01l0,0"/>
      </g>
    </g>
  </svg>
)

const Memo = memo(InstreetClub)
export default Memo
