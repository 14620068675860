import * as React from 'react'
import { memo } from 'react'
const SvgBasketIcon = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='1em'
    height='1em'
    viewBox='0 0 24 24'
    fill='currentColor'
    {...props}
  >
    <path
      fill='#fff'
      d='M18.179 22.151A1.817 1.817 0 1 1 20 24a1.84 1.84 0 0 1-1.821-1.849m-13.489 0A1.817 1.817 0 1 1 6.511 24a1.84 1.84 0 0 1-1.822-1.849Zm2.014-3.8a3 3 0 0 1-2.071-.826 3.14 3.14 0 0 1-.974-2.038L2.555 2.141.744 1.822A.919.919 0 0 1 .918 0a1 1 0 0 1 .138.011l2.86.44a.92.92 0 0 1 .743.833l.228 2.743a.74.74 0 0 0 .731.687h16.194a2.03 2.03 0 0 1 1.691.821 2.7 2.7 0 0 1 .456 2.1l-1.139 8.033a3.1 3.1 0 0 1-3.034 2.682Zm6.942-8.192a.91.91 0 0 0 .9.918h3.321a.91.91 0 0 0 .9-.918.9.9 0 0 0-.9-.919h-3.322a.9.9 0 0 0-.899.922Z'
    />
  </svg>
)
const Memo = memo(SvgBasketIcon)
export default Memo
