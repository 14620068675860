import * as React from 'react'
import { memo } from 'react'
const SvgBasketIcon2 = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='1em'
    height='1em'
    viewBox='0 0 23.387 25.067'
    fill='currentColor'
    {...props}
  >
    <path
      fill='#fff'
      d='M15.586 21.626a3.442 3.442 0 1 1 3.442 3.441 3.44 3.44 0 0 1-3.442-3.441m2.337 0a1.1 1.1 0 1 0 1.1-1.1 1.106 1.106 0 0 0-1.1 1.1m-12.078 0a3.441 3.441 0 1 1 3.442 3.441 3.44 3.44 0 0 1-3.442-3.441m2.337 0a1.1 1.1 0 1 0 1.1-1.1 1.106 1.106 0 0 0-1.1 1.1m-.14-4.352a2.465 2.465 0 0 1-2.4-1.9C5 12.653 3.278 5.334 3.278 5.334v-.021c-.046-.2-.421-1.794-.677-2.875a.13.13 0 0 0-.126-.1h-1.3A1.169 1.169 0 0 1 1.169 0h1.3a2.47 2.47 0 0 1 2.4 1.9l.473 2h7.517a1.169 1.169 0 0 1 0 2.338H5.891l2.025 8.6a.13.13 0 0 0 .125.1h12.23a.13.13 0 0 0 .125-.1l.682-2.895a1.169 1.169 0 0 1 2.275.536s-.41 1.74-.682 2.895a2.465 2.465 0 0 1-2.4 1.9Z'
      data-name='Union 5'
    />
  </svg>
)
const Memo = memo(SvgBasketIcon2)
export default Memo
