import React from 'react';
import PropTypes from 'prop-types';
import { CustomButton } from '@Components';
import { toMoney } from '@Constants';
import { gettext } from '@Translate';

const ProductCard = ({
  buttonTitle, name, price, imgUrl, project, clickEvent,
}) => (
  <>
    <div className="product-card-container">
      <div className="product-card__image-container">
        <img src={imgUrl} />
      </div>
      <div className="product-card__content-container">
        <span className="product-card__content-container--product-name">
          {name}
        </span>
        <span className="product-card__content-container--product-price">
          {toMoney(price)}
        </span>
        <CustomButton
          type="button"
          project={project}
          className="prev-add-card__add-to-cart-btn"
          onClick={clickEvent}
          dataTest="click-to-cart-page-previously-added-to-cart-add-cart"
        >
          {gettext(buttonTitle)}
        </CustomButton>
      </div>
    </div>
  </>
);

ProductCard.defaultProps = {
  buttonTitle: 'Sepete Ekle',
  clickEvent: () => {},
  price: '',
  brand: '',
  name: '',
  imgUrl: '',
  project: 'flo',
};

// eslint-disable-next-line react/no-typos
ProductCard.proptypes = {
  buttonTitle: PropTypes.string,
  clickEvent: PropTypes.func,
  price: PropTypes.string,
  name: PropTypes.string,
  brand: PropTypes.string,
  imgUrl: PropTypes.string,
  project: PropTypes.string,
};

export default ProductCard;
