import * as React from 'react'
import { memo } from 'react'
const SvgInformation = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='1em'
    height='1em'
    data-name='Group 5501'
    viewBox='0 0 15.228 15.228'
    fill='currentColor'
    {...props}
  >
    <path
      d='M7.614 0A7.623 7.623 0 0 0 0 7.614a7.62 7.62 0 0 0 7.614 7.614 7.62 7.62 0 0 0 7.614-7.614A7.623 7.623 0 0 0 7.614 0m0 13.843a6.237 6.237 0 0 1-6.23-6.229 6.237 6.237 0 0 1 6.23-6.23 6.237 6.237 0 0 1 6.229 6.23 6.237 6.237 0 0 1-6.229 6.229'
      data-name='Path 879'
    />
    <path
      d='M7.614 3.23a.923.923 0 1 0 .923.923.924.924 0 0 0-.923-.923'
      data-name='Path 880'
    />
    <path
      d='M7.614 6.46a.69.69 0 0 0-.692.692v4.153a.692.692 0 1 0 1.384 0V7.152a.69.69 0 0 0-.692-.692'
      data-name='Path 881'
    />
  </svg>
)
const Memo = memo(SvgInformation)
export default Memo
