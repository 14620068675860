import React from 'react';
const NotFound = React.lazy(() => import('./NotFound'));

// Checkout
const Basket = React.lazy(() => import('./Basket'));
const Payment = React.lazy(() => import('./Payment'));
const Success = React.lazy(() => import('./Success'));
const CheckoutThreedSecure = React.lazy(() => import('./CheckoutThreedSecure/CheckoutThreedSecure'));

// Wallet
const Wallet = React.lazy(() => import('./Wallet/Wallet'));
const WalletDetail = React.lazy(() => import('./Wallet/components/WalletDetail/WalletDetail'));
const WalletBarcodeScan = React.lazy(() => import('./Wallet/components/WalletBarcodScan/WalletBarcodScan'));
const WalletStore = React.lazy(() => import('./Wallet/components/WalletStoreInfo/WalletStoreInfo'));
const WalletApprove = React.lazy(() => import('./Wallet/components/CashierConfirmation/CashierConfirmation'));
const WalletBarcodeComplete = React.lazy(() => import('./Wallet/components/CashierConfirmation/CashierComplete'));
const WalletBarcode = React.lazy(() => import('./Wallet/WalletBarcode'));


// Otp
const Otp = React.lazy(() => import('./Otp/index'));

// Store Basket
const StoreBasket = React.lazy(() => import('./StoreBasket'));
const StoreOrderCancellation = React.lazy(() => import('./StoreOrderCancellation'));
const StoreQrComplate = React.lazy(() => import('./SelfQrComplete'));

// Saved card without shopping
const SavedCardWithoutShopping = React.lazy(()=> import('./SavedCardWithoutShopping'));

export {
  Success,
  Payment,
  CheckoutThreedSecure,
  Otp,
  Basket,
  NotFound,
  WalletBarcodeScan,
  WalletBarcode,
  WalletDetail,
  WalletStore,
  WalletApprove,
  WalletBarcodeComplete,
  Wallet,
  StoreOrderCancellation,
  StoreBasket,
  StoreQrComplate,
  SavedCardWithoutShopping,
};
