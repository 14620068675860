import * as React from 'react'
import { memo } from 'react'
const SvgIconInstallments = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='1em'
    height='1em'
    viewBox='0 0 42.713 38.458'
    fill='currentColor'
    {...props}
  >
    <g data-name='Group 14492'>
      <g data-name='Iconly/Bold/Wallet'>
        <path
          fill='#a7a7a7'
          d='M33.389 30H18.037a9.7 9.7 0 0 1-6.861-2.352A8.77 8.77 0 0 1 8.713 21.1V8.9a8.77 8.77 0 0 1 2.463-6.548A9.7 9.7 0 0 1 18.037 0h15.352a9.67 9.67 0 0 1 6.861 2.361 8.89 8.89 0 0 1 2.463 6.609H35.52v.057a6.16 6.16 0 0 0-4.273 1.7 5.61 5.61 0 0 0-1.772 4.082 5.923 5.923 0 0 0 6.045 5.778h7.193v.513a8.77 8.77 0 0 1-2.463 6.546A9.7 9.7 0 0 1 33.389 30M16.767 6.486a1.276 1.276 0 0 0-1.3 1.227 1.29 1.29 0 0 0 1.3 1.257h9.6a1.243 1.243 0 1 0 0-2.484ZM41.443 18.1h-5.837a3.55 3.55 0 0 1-3.5-2.656 3.25 3.25 0 0 1 .736-2.754 3.58 3.58 0 0 1 2.679-1.233h5.922a1.244 1.244 0 0 1 1.27 1.21v4.218a1.263 1.263 0 0 1-1.27 1.215m-5.683-4.7a1.3 1.3 0 0 0-.9.354 1.2 1.2 0 0 0-.383.873 1.29 1.29 0 0 0 1.3 1.257h.558a1.243 1.243 0 1 0 0-2.484z'
          data-name='Wallet'
        />
      </g>
      <g data-name='Iconly/Bold/Discount'>
        <path
          fill='#a7a7a7'
          stroke='#fff'
          d='M10.719 37.963a3.02 3.02 0 0 1-2.131-.868l-.73-.732a1.5 1.5 0 0 0-1.06-.438H5.767a3.015 3.015 0 0 1-3.011-3.011V31.88a1.54 1.54 0 0 0-.44-1.071l-.72-.71a3.02 3.02 0 0 1-.01-4.259l.73-.732a1.5 1.5 0 0 0 .44-1.07v-1.02a3.013 3.013 0 0 1 3.011-3.008h1.03a1.5 1.5 0 0 0 1.06-.441l.72-.72a3.017 3.017 0 0 1 4.263-.009l.731.729a1.5 1.5 0 0 0 1.06.441h1.03a3.013 3.013 0 0 1 3.011 3.009v1.031a1.49 1.49 0 0 0 .441 1.058l.72.72a3.03 3.03 0 0 1 .02 4.251l-.02.02-.01.01-.71.71a1.5 1.5 0 0 0-.441 1.061v1.029a3.014 3.014 0 0 1-3.011 3.011h-1.03a1.5 1.5 0 0 0-1.06.438l-.721.72a2.99 2.99 0 0 1-2.131.885Zm2.571-8.3a.875.875 0 1 0 .87.87.877.877 0 0 0-.869-.87Zm-.005-5.144a.87.87 0 0 0-.616.254l-5.142 5.14a.88.88 0 0 0 0 1.239.83.83 0 0 0 .61.26.84.84 0 0 0 .62-.26l5.143-5.139a.875.875 0 0 0-.616-1.495Zm-5.137 0a.875.875 0 1 0 .87.869.876.876 0 0 0-.87-.869Z'
          data-name='Discount'
        />
      </g>
    </g>
  </svg>
)
const Memo = memo(SvgIconInstallments)
export default Memo
