import {createSlice} from '@reduxjs/toolkit';

const INITIAL_STATE = {
  isOpen: false,
  data: {},
};

const slice = createSlice({
  name: "fModal",
  initialState: INITIAL_STATE,
  reducers: {
    showFModalAction: (state, action) => {
      state.isOpen = action.payload;
    },
    hideFModalAction: (state, action) => {
      state.isOpen = action.payload;
    }
  }
});

export default slice.reducer;

export const {
  showFModalAction,
  hideFModalAction,
} = slice.actions;
