import React, { useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { gettext } from '@Translate';
import config from '@Config';
import { toMoney } from '@Constants';
import { getChequeAction } from '@Actions';
import GiftCreditCard from '../../../PaymentOptions/PaymentOptionContents/GiftCreditCard/index';
import ToastfyMessage from '../../../ToastfyMessage/ToastfyMessage';
import { toast } from 'react-toastify';
import ReCAPTCHA from 'react-google-recaptcha';
import {
  setPaymentMethodsAction,
} from '@Actions';

import { CameraFill, InfoSquareLine } from '@Icons';
import BarcodeScanner from '../../../BarcodeScanner';

const GiftCertificate = () => {
  const dispatch = useDispatch();
  const { siteKey } = config;
  const recaptchaRef = useRef(null);

  const {
    paymentMethods,
  } = useSelector((state) => state.order);
  const { webview } = useSelector((state) => state.config);
  const [state, setState] = useState({
    chequeCode: '',
    isCreditCardVisible: false,
    remainingAmount: 0,
    increasingAmount: 0,
    checkAmount: 0,
    chequeType: null,
    oldUsableBalance: 0,
    successCheck: false,
    recaptcha: null,
    chequeFailuresNum: 0,
  });

  const handleInputChange = (e) => setState((prev) => ({ ...prev, chequeCode: e.target.value }));

  const onChangeReCAPTCHA = (value) => setState((prev) => ({ ...prev, recaptcha: value }));

  const handleChequeBarcodeAction = () => {
    toast.dismiss();
    toast(
      BarcodeScanner({
        callback: (value) => {
          setState((prev) => ({ ...prev, chequeCode: value }));
          toast.dismiss();
        },
      }),
      { autoClose: false }
    );
  };

  const updateFlowalletBalance = (newBalance) => {
    // paymentMethods listesini güncelleyip flowallet'ı bulup balance güncelleyeceğiz.
    const updatedPaymentMethods = paymentMethods.map(method => {
      if (method.code === 'flowallet') {
        return { ...method, walletBalance: newBalance }; // Yeni balance ile güncellendi
      }
      return method;
    });

    // Güncellenmiş paymentMethods listesini Redux store'a dispatch edeceğiz
    dispatch(setPaymentMethodsAction(updatedPaymentMethods));
  };

  const handleClick = async (e) => {
    e.preventDefault();

    // Eğer value boşsa hata verelim
    if (!state.chequeCode) {
      toast.dismiss();
      toast.error(ToastfyMessage({ text: gettext('Geçersiz hediye çeki kodu girdiniz, lütfen tanımlı bir hediye çeki giriniz.') }, { autoClose: 3000 }));
      return;
    }
    try {
      const chequeNumber = state.chequeFailuresNum > 2 
        ? { cheque_number: state.chequeCode, 'g-recaptcha-response': state.recaptcha } 
        : { cheque_number: state.chequeCode };

      const { payload } = await dispatch(getChequeAction(chequeNumber));
      const { success, data, failures_num } = payload;

      if (success) {
        const basketTotal = parseFloat(data.basket_grand_total);
        const loadedChequeBalance = parseFloat(data.loaded_cheque_balance);

        updateFlowalletBalance(data.usable_balance_without_bonus)

        setState({
          chequeFailuresNum: failures_num,
          recaptcha: '',
          chequeType: data.CEK_NO,
          checkAmount: loadedChequeBalance,
          oldUsableBalance: data.old_usable_balance_with_bonus,
          increasingAmount: loadedChequeBalance - basketTotal,
          remainingAmount: data.needed_balance,
          successCheck: true,
        });
      } else {
        setState((prev) => ({ ...prev, chequeFailuresNum: failures_num }));
      }

      recaptchaRef.current?.reset(); // recaptchaRef'i güncelledim
    } catch (error) {
      console.error('API Error:', error);
    }
  };

  return (
    <div className="gift-certificate">
      {!state.successCheck ? (
        <>
          <label className="gift-certificate__label" htmlFor="gift-certificate__input">
            <InfoSquareLine className="gift-certificate__info-icon" />
            {gettext('Hediye çekiniz varsa aşağıdaki alana hediye çekini ekleyip, ödemenizi yapabilirsiniz.')}
          </label>
          <div className="gift-certificate__input-wrapper">
            <input 
              type="text" 
              id="gift-certificate__input" 
              placeholder={gettext("Hediye çekini girin veya barkodu okutun.")}
              value={state.chequeCode}
              onChange={handleInputChange}
              className="gift-certificate__input"
            />
             {/* Geçici olarak webview da kamera kapatıldı 11/09/2024 BÖ */}
            {!webview && (<button className="gift-certificate__upload-btn" onClick={handleChequeBarcodeAction}>
              <CameraFill className="gift-certificate__camera-icon" />
            </button> )}
            <button className="gift-certificate__submit-btn" onClick={handleClick}>
              {gettext('YÜKLE')}
            </button>
          </div>
          {state.chequeFailuresNum > 2 && (
            <div className="cheque__captcha">
              <ReCAPTCHA
                sitekey={siteKey}
                onChange={onChangeReCAPTCHA}
                ref={recaptchaRef}
              />
            </div>
          )}
        </>
      ) : (
        <div className="gift-certificate__summary">
          <div className="gift-certificate__cheque-type">
            {state.chequeType || "Cheques not available"}
          </div>
          <div className="gift-certificate__summary-detail">
            {state.checkAmount > 0 && (
              <p>
                <InfoSquareLine />
                <span>
                  {gettext(`Hesabınıza `)}
                  <strong>{toMoney(state.checkAmount)}</strong>
                  {gettext(` bakiye aktarılmıştır.`)}
                </span>
              </p>
            )}

            {state.remainingAmount > 0 && state.oldUsableBalance > 0 && (
              <p>
                <InfoSquareLine />
                <span>
                  {gettext(`Bakiyenizdeki `)}
                  <strong>{toMoney(state.oldUsableBalance)}</strong>
                  {gettext(` dahil edilmiştir.`)}
                </span>
              </p>
            )}

            {state.remainingAmount > 0 && (
              <>
                <p>
                  <InfoSquareLine />
                  <span>
                    {gettext(`Geriye kalan `)}
                    <strong>{toMoney(state.remainingAmount)}</strong>
                    {gettext(` kartınızdan çekilecektir.`)}
                  </span>
                </p>
                <p className="gift-certificate__additional-info">
                  {gettext('Hediye çeki tutarı, toplam alışveriş tutarı için yeterli değildir, kart bilgilerinizi girerek ödemeyi tamamlayabilirsiniz.')}
                </p>
              </>
            )}

            {state.increasingAmount > 0 && (
              <p className="gift-certificate__additional-info">
                {gettext(`Kalan `)}
                <strong>{toMoney(state.increasingAmount)}</strong>
                {gettext(` tutarı daha sonra FLO Plus kullanabilirsiniz.`)}
              </p>
            )}
          </div>
        </div>
      )}
      {state.remainingAmount > 0 && <GiftCreditCard />}
    </div>
  );
};

export default GiftCertificate;
