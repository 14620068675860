/* eslint-disable no-param-reassign */
/* eslint-disable import/no-cycle */
import {
  createSlice,
} from '@reduxjs/toolkit';

const INITIAL_STATE = {
  lang: (localStorage.getItem("lang") || "en"),
};

const slice = createSlice({
  name: 'lang',
  initialState: INITIAL_STATE,
  reducers: {
    setCurrentLang: (state, action) => {
      state.lang = action.payload;
    },
    getCurrentLang: (state) => {
      return state.lang;
    },
  },
  extraReducers: (builder) => {

  },
});

export default slice.reducer;

export const {
  setCurrentLang,
  getCurrentLang,
} = slice.actions;
