const data = {
  "Sepetim": "Carrello",
  "Ürün": "Prodotti",
  "Toplam": "Totale",
  "SEPETİ ONAYLA": "CONFERMA CARRELLO",
  "Sipariş Özeti": "Riepilogo Ordine",
  "Beden": "Taglia",
  "Beden:": "Taglia:",
  "Lütfen en az 3 karakter uzunluğunda bir değer giriniz.": "Inserisci un valore con un minimo di 3 caratteri."

};

export default data;
