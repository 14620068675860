import * as React from 'react'
import { memo } from 'react'
const SvgIconAlert = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='1em'
    height='1em'
    data-name='Group 14593'
    viewBox='0 0 17.765 15.963'
    fill='currentColor'
    {...props}
  >
    <defs>
      <clipPath id='icon-alert_svg__a'>
        <path
          fill='#f60'
          d='M0 0h17.765v15.963H0z'
          data-name='Rectangle 3090'
        />
      </clipPath>
    </defs>
    <g
      fill='#f60'
      clipPath='url(#icon-alert_svg__a)'
      data-name='Group 5475'
    >
      <path
        d='M8.882 1.396a.8.8 0 0 1 .386.1.77.77 0 0 1 .295.3l6.7 11.6a.77.77 0 0 1 .108.4.81.81 0 0 1-.386.669.77.77 0 0 1-.4.107H2.184a.77.77 0 0 1-.4-.107.81.81 0 0 1-.386-.669.77.77 0 0 1 .108-.4l6.7-11.6a.77.77 0 0 1 .294-.3.8.8 0 0 1 .386-.1m-.004-1.4a2.16 2.16 0 0 0-1.888 1.09L.3 12.696a2.181 2.181 0 0 0 1.888 3.271h13.4a2.18 2.18 0 0 0 1.881-3.271l-6.7-11.6a2.16 2.16 0 0 0-1.887-1.1'
        data-name='Path 13399'
      />
      <path
        d='M9.914 4.964a1.087 1.087 0 0 0-2.114.453l.505 5.009a.566.566 0 0 0 1.126 0l.532-5.006a1.1 1.1 0 0 0-.05-.459'
        data-name='Path 13400'
      />
      <path
        d='M8.852 11.555a1.01 1.01 0 0 0-1.013 1.013v.006a1.013 1.013 0 0 0 2.025 0v-.006a1.01 1.01 0 0 0-1.012-1.013'
        data-name='Path 13401'
      />
    </g>
  </svg>
)
const Memo = memo(SvgIconAlert)
export default Memo
