import React from 'react';
import PropTypes from 'prop-types';

const CustomButton = ({ children, project, onClick, type, className, dataTest }) => {
  return (
    <button
      type={type}
      onClick={onClick}
      data-test={dataTest}
      className={`custom-button ${project} ${className}`}
    >
      {children}
    </button>
  );
};

CustomButton.defaultProps = {
  project: '',
  onClick: () => {},
  type: '',
  className: '',
  dataTest: '',
};

CustomButton.propTypes = {
  project: PropTypes.string,
  onClick: PropTypes.func,
  type: PropTypes.string,
  className: PropTypes.string,
  dataTest: PropTypes.string,
};

export default CustomButton;
