import Cookie from 'js-cookie';
import {
  logEvent,
} from '@Constants';

export default class {
  constructor() {
    this._bindEvents();
  }

  _bindEvents() {
    window.addEventListener('error', (msg, url, lineNo, columnNo, error) => {
      const message = {
        event: 'consoleErrorHandler',
        eventName: 'consoleErrorHandler',
        message: msg,
        'url: ': url,
        'line: ': lineNo,
        'column: ': columnNo,
        'errorObject: ': error,
      };

      if (Cookie.get('webview')) {
        logEvent('consoleErrorHandler', message);
      } else {
        dataLayer.push(message);
      }

      return false;
    });
  }
}
