import React from 'react';
import PropTypes from 'prop-types';

const CustomIframe = ({
  url, width, height, styleName,
}) => (
  <div className={styleName}>
    <iframe src={url} frameBorder="0" width={width} height={height} />
  </div>
);

CustomIframe.defaultProps = {
  url: '',
  width: '100%',
  height: '100%',
  styleName: '',
};

CustomIframe.propTypes = {
  url: PropTypes.string.isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
  styleName: PropTypes.string,
};

export default CustomIframe;
