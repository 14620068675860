import * as React from 'react'
import { memo } from 'react'
const SvgReebokWhatInf = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    xmlSpace='preserve'
    id='reebok-what-inf_svg__katman_1'
    x={0}
    y={0}
    viewBox='0 0 18 18'
    width='1em'
    height='1em'
    fill='currentColor'
    {...props}
  >
    <style>
      {
        '.reebok-what-inf_svg__st0{fill:#707070}.reebok-what-inf_svg__st1{fill:#fff}.reebok-what-inf_svg__st2{fill:#1d1d1b}'
      }
    </style>
    <path
      d='M8 17.9c.3.1.7.1 1 .1s.7 0 1-.1c-.3.1-.7.1-1 .1s-.7 0-1-.1'
      className='reebok-what-inf_svg__st0'
    />
    <path
      d='M0 9c0 4.6 3.5 8.5 8 8.9-4.5-.4-8-4.3-8-8.9'
      className='reebok-what-inf_svg__st1'
    />
    <path
      d='M8.4 0C3.7.3 0 4.2 0 9 0 4.2 3.7.3 8.4 0'
      className='reebok-what-inf_svg__st1'
    />
    <path
      d='M9 .5C4.3.5.5 4.3.5 9s3.8 8.5 8.5 8.5 8.5-3.8 8.5-8.5S13.7.5 9 .5m-.2 12.9c-.6 0-1-.4-1-1s.4-1 1-1 1 .4 1 1-.4 1-1 1m1-2.8H7.9c1.7-2.7 2-3.2 2-3.8s-.3-.9-1.1-.9c-.5-.1-.8.1-1 .5 0 .2-.1.3-.1.6H6.1c0-.5 0-.9.2-1.2.4-.8 1.3-1.3 2.6-1.3 1.8 0 2.8.8 2.8 2.2 0 .8-.3 1.3-1.9 3.9'
      className='reebok-what-inf_svg__st1'
    />
    <path
      d='M18 9C18 4.2 14.3.4 9.6.1 14.3.3 18 4.2 18 9'
      className='reebok-what-inf_svg__st1'
    />
    <path
      d='M10 17.9c4.5-.5 8-4.3 8-8.9 0 4.6-3.5 8.4-8 8.9'
      className='reebok-what-inf_svg__st1'
    />
    <path
      d='M9.6 0H8.4h1.2M9.6 0H8.4C3.7.3 0 4.2 0 9c0 4.6 3.5 8.5 8 8.9.3.1.7.1 1 .1s.7 0 1-.1c4.5-.5 8-4.3 8-8.9 0-4.8-3.7-8.7-8.4-9M9 17.5C4.3 17.5.5 13.7.5 9S4.3.5 9 .5s8.5 3.8 8.5 8.5-3.8 8.5-8.5 8.5'
      className='reebok-what-inf_svg__st0'
    />
    <path
      d='M8.9 4.5c-1.3 0-2.2.5-2.6 1.3-.2.4-.2.7-.2 1.2h1.6c0-.3 0-.5.1-.7.2-.3.5-.5 1.1-.5.8 0 1.1.4 1.1.9 0 .6-.3 1.2-2 3.8h1.9c1.6-2.6 1.9-3.1 1.9-3.9-.1-1.3-1.1-2.1-2.9-2.1M8.8 11.5c-.6 0-1 .4-1 1s.4 1 1 1 1-.4 1-1c0-.7-.4-1-1-1'
      className='reebok-what-inf_svg__st2'
    />
  </svg>
)
const Memo = memo(SvgReebokWhatInf)
export default Memo
