import * as React from 'react'
import { memo } from 'react'
const SvgMissionIcon = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='1em'
    height='1em'
    viewBox='0 0 28.625 33.951'
    fill='currentColor'
    {...props}
  >
    <defs>
      <clipPath id='mission-icon_svg__a'>
        <path
          fill='#f60'
          d='M8.749 17.82c-3.97-.5-7.127-4.462-7-8.793S5.2 1.539 9.133 2.04s7.082 4.453 6.971 8.738c-.1 4.042-3.01 7.091-6.568 7.091a6 6 0 0 1-.787-.049M0 8.875a11.2 11.2 0 0 0 .93 4.684.4.4 0 0 0-.055.023 4 4 0 0 1 .48.939 4 4 0 0 1 .249.521c.019 0 .037-.012.056-.016h.04a9.77 9.77 0 0 0 7.04 4.731 8 8 0 0 0 .812.047h.094a8.04 8.04 0 0 0 7.154-4.472 3 3 0 0 1 .219-.552 3 3 0 0 1 .4-.784 4.5 4.5 0 0 1 .189-.883 10.2 10.2 0 0 0 .278-2.2C17.967 5.469 14.053.627 9.112.056a8 8 0 0 0-.938-.054c-4.5 0-8.069 3.774-8.178 8.875'
          data-name='Path 14421'
        />
      </clipPath>
      <clipPath id='mission-icon_svg__b'>
        <path
          fill='#f60'
          d='M1.815 8.267C.478 5.492.863 2.476 2.67 1.591c1.808-.888 4.395.646 5.721 3.392 1.343 2.774.97 5.815-.824 6.7a2.54 2.54 0 0 1-1.131.257c-1.648 0-3.543-1.434-4.621-3.672M2.026.341C-.23 1.494-.664 5.223 1.034 8.7c1.689 3.462 4.911 5.356 7.149 4.2s2.7-4.944 1-8.388C7.813 1.748 5.498 0 3.47 0a3.15 3.15 0 0 0-1.447.341'
          data-name='Path 14422'
        />
      </clipPath>
      <clipPath id='mission-icon_svg__c'>
        <path
          fill='#f60'
          d='m2.704.057-.02.014c-.048.036-.1.081-.176.13a10.5 10.5 0 0 1-2.16 1.073c-.162.064-.3.128-.338.309a.4.4 0 0 0-.006.141.36.36 0 0 0 .154.25.6.6 0 0 0 .185.035c.322.066.392.324.569.611q.62 1.255 1.212 2.526a1.8 1.8 0 0 1 .192.62c.03.3-.229.3-.108.582.2.454.618.269 1.1.023a3.5 3.5 0 0 0 .813-.544.6.6 0 0 0 .14-.19c.056-.157-.143-.473-.283-.438a.46.46 0 0 1-.3 0c-.119-.058-.226-.209-.439-.613a5 5 0 0 1-.384-.815.205.205 0 0 1 .122-.246.36.36 0 0 1 .3-.032.73.73 0 0 1 .37.363c.081.139.153.273.25.267.262-.016.112-.56-.055-.985a5 5 0 0 0-.6-1.112c-.2-.251-.455-.323-.443.01 0 .081.116.4.108.477a.39.39 0 0 1-.43.317c-.086 0-.182-.115-.258-.277a5 5 0 0 1-.254-.625c-.081-.236-.178-.447-.013-.566a1.9 1.9 0 0 1 .717-.28c.292-.052.558.25.861.384.162.07.33-.162.156-.52a2.5 2.5 0 0 0-.445-.66 1.2 1.2 0 0 0-.3-.253.18.18 0 0 0-.107-.036.18.18 0 0 0-.131.057'
          data-name='Path 14423'
        />
      </clipPath>
      <clipPath id='mission-icon_svg__d'>
        <path
          fill='#f60'
          d='M.382.135a.5.5 0 0 0-.133.162.4.4 0 0 0 .052.424 1.3 1.3 0 0 0 .291.2c.5.346.395.707.455 1.186Q1.019 4.079.92 6.042a1.75 1.75 0 0 1-.214.879c-.21.367-.666.155-.7.581-.059.688.839.829 1.892.951a9.3 9.3 0 0 0 1.9.057 1.2 1.2 0 0 0 .412-.1.463.463 0 0 0 .014-.654.5.5 0 0 0-.119-.09 1.16 1.16 0 0 1-.528-.254c-.157-.167-.211-.433-.225-1.079a5.2 5.2 0 0 1 .05-1.266c.044-.161.241-.208.43-.181a.94.94 0 0 1 .549.214 1 1 0 0 1 .323.732c.02.23.026.445.2.52.472.2.691-.552.776-1.184a5.2 5.2 0 0 0-.058-1.787c-.129-.459-.508-.757-.779-.362a7 7 0 0 1-.233.646.95.95 0 0 1-1.03 0c-.15-.071-.216-.288-.205-.539a5 5 0 0 1 .112-.93c.072-.336.085-.666.485-.668a5.2 5.2 0 0 1 1.5.281c.553.185.75.758 1.161 1.17.213.214.711.094.727-.471a2.8 2.8 0 0 0-.193-1.139 1.7 1.7 0 0 0-.3-.542.46.46 0 0 0-.434-.176h-.468A29 29 0 0 1 1.254.069 1 1 0 0 0 .899.005 1.03 1.03 0 0 0 .391.14'
          data-name='Path 14424'
        />
      </clipPath>
      <clipPath id='mission-icon_svg__e'>
        <path
          fill='#f60'
          d='M1.353 6.907c-.927-1.742-.057-4.135 1.931-5.31S7.657.877 8.57 2.605s.048 4.118-1.916 5.281a4.6 4.6 0 0 1-2.333.661 3.26 3.26 0 0 1-2.968-1.64M2.846.849C.37 2.334-.697 5.308.477 7.449s4.174 2.69 6.633 1.193c2.476-1.506 3.523-4.453 2.348-6.608A4.05 4.05 0 0 0 5.79-.001a5.75 5.75 0 0 0-2.945.849'
          data-name='Path 14425'
        />
      </clipPath>
      <clipPath id='mission-icon_svg__f'>
        <path
          fill='#f60'
          d='M3.484.002a.22.22 0 0 0-.176.14.7.7 0 0 0 0 .173c-.011.3-.2.359-.4.519q-.9.546-1.809 1.06a1 1 0 0 1-.458.162.3.3 0 0 1-.215-.072c-.073-.053-.135-.107-.25-.047-.328.171-.132.573.118 1.033a4.3 4.3 0 0 0 .522.782.6.6 0 0 0 .162.137c.129.057.348-.12.3-.252a.5.5 0 0 1-.038-.289c.03-.111.134-.207.421-.395a3 3 0 0 1 .585-.337.185.185 0 0 1 .206.122.43.43 0 0 1 .064.281.52.52 0 0 1-.236.337c-.1.074-.193.136-.178.227.046.248.449.124.758-.022a3 3 0 0 0 .788-.528c.169-.182.195-.418-.063-.417-.061 0-.292.1-.357.088-.162-.027-.269-.2-.3-.414-.011-.081.066-.168.183-.235a3 3 0 0 1 .451-.216c.173-.067.324-.154.441 0a2.3 2.3 0 0 1 .306.682c.081.276-.124.518-.19.8-.036.147.161.314.422.162a1.5 1.5 0 0 0 .457-.4.9.9 0 0 0 .162-.273.21.21 0 0 0-.046-.225l-.013-.019c-.033-.047-.076-.1-.124-.17a13 13 0 0 1-1.1-2.065C3.808.169 3.741.042 3.596.003a.3.3 0 0 0-.075-.01h-.036'
          data-name='Path 14426'
        />
      </clipPath>
    </defs>
    <g data-name='Group 17439'>
      <g data-name='Group 17440'>
        <g
          clipPath='url(#mission-icon_svg__a)'
          data-name='Group 17439'
          transform='translate(0 14.147)'
        >
          <path
            fill='#f60'
            d='M-.029 0h18.001v19.804H-.029z'
            data-name='Rectangle 15061'
          />
        </g>
      </g>
      <g data-name='Group 17442'>
        <g
          clipPath='url(#mission-icon_svg__b)'
          data-name='Group 17441'
          transform='translate(8.909)'
        >
          <path
            fill='#f60'
            d='M-.664 0h11.546v14.057H-.664z'
            data-name='Rectangle 15062'
          />
        </g>
      </g>
      <g data-name='Group 17444'>
        <g
          clipPath='url(#mission-icon_svg__c)'
          data-name='Group 17443'
          transform='translate(11.312 3.289)'
        >
          <path
            fill='#f60'
            d='M-.003 0h4.319v6.8H-.003z'
            data-name='Rectangle 15063'
          />
        </g>
      </g>
      <g data-name='Group 17446'>
        <g
          clipPath='url(#mission-icon_svg__d)'
          data-name='Group 17445'
          transform='translate(5.257 19.605)'
        >
          <path
            fill='#f60'
            d='M-.056 0h7.421v8.55H-.056z'
            data-name='Rectangle 15064'
          />
        </g>
      </g>
      <g data-name='Group 17448'>
        <g
          clipPath='url(#mission-icon_svg__e)'
          data-name='Group 17447'
          transform='translate(18.684 13.847)'
        >
          <path
            fill='#f60'
            d='M-.697 0h11.331v10.139H-.697z'
            data-name='Rectangle 15065'
          />
        </g>
      </g>
      <g data-name='Group 17450'>
        <g
          clipPath='url(#mission-icon_svg__f)'
          data-name='Group 17449'
          transform='translate(21.048 16.034)'
        >
          <path
            fill='#f60'
            d='M-.155 0h5.348v3.946H-.155z'
            data-name='Rectangle 15066'
          />
        </g>
      </g>
    </g>
  </svg>
)
const Memo = memo(SvgMissionIcon)
export default Memo
