import * as React from 'react'
import { memo } from 'react'
const SvgCameraIcon = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='1em'
    height='1em'
    viewBox='0 0 22.167 19'
    fill='currentColor'
    {...props}
  >
    <path
      fill='#707070'
      d='M15.833 11.083a3.167 3.167 0 1 1-3.167-3.167 3.17 3.17 0 0 1 3.167 3.167m6.333-3.958v7.917A3.963 3.963 0 0 1 18.208 19H3.958A3.963 3.963 0 0 1 0 15.042V7.125a3.963 3.963 0 0 1 3.958-3.958h.6a.79.79 0 0 0 .708-.438l.271-.541A3.94 3.94 0 0 1 9.082 0h4a3.94 3.94 0 0 1 3.54 2.188l.271.541a.79.79 0 0 0 .708.438h.6a3.963 3.963 0 0 1 3.966 3.958Zm-15.042 0a.79.79 0 0 0-.792-.792H3.958a.792.792 0 0 0 0 1.583h2.375a.79.79 0 0 0 .792-.791Zm10.292 3.958a4.75 4.75 0 1 0-4.75 4.75 4.755 4.755 0 0 0 4.751-4.75Z'
    />
  </svg>
)
const Memo = memo(SvgCameraIcon)
export default Memo
