import React from 'react';
const LoyaltyBoxBody = ({ text }) => {
  return (
    <div className="loyalty-box-container__body">
      <p dangerouslySetInnerHTML={{ __html: text }} />
    </div>
  )
}

export default LoyaltyBoxBody;
