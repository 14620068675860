import * as React from 'react'
import { memo } from 'react'
const SvgIconEyeClose = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='1em'
    height='1em'
    viewBox='0 0 25.387 18.621'
    fill='currentColor'
    {...props}
  >
    <defs>
      <clipPath id='icon-eye-close_svg__a'>
        <path
          fill='#9a9a9a'
          d='M0 0h25.387v18.621H0z'
          data-name='Rectangle 1862'
        />
      </clipPath>
    </defs>
    <g data-name='Group 4076'>
      <g
        fill='#9a9a9a'
        clipPath='url(#icon-eye-close_svg__a)'
        data-name='Group 4075'
      >
        <path
          d='M5.931 14.863c-.591-.412-1.177-.8-1.74-1.218A22.6 22.6 0 0 1 .253 9.907a.83.83 0 0 1-.021-1.173 21.4 21.4 0 0 1 7.512-5.911 11.7 11.7 0 0 1 5.27-1.122A11.9 11.9 0 0 1 17.6 2.823a.347.347 0 0 0 .472-.079Q19.3 1.487 20.551.256a.83.83 0 0 1 1.187-.012.843.843 0 0 1 .019 1.19l-.074.076-16.8 16.8a.85.85 0 1 1-1.185-1.2q1.017-1.02 2.036-2.037.099-.102.193-.206m10.6-10.643a.2.2 0 0 0-.04-.034c-.048-.021-.1-.04-.147-.06a9.59 9.59 0 0 0-7.74.171 19 19 0 0 0-5.665 3.99c-.331.325-.646.666-.994 1.026.483.485.931.957 1.4 1.4A19.3 19.3 0 0 0 7 13.488c.077.044.237.066.284.021.634-.61 1.254-1.233 1.872-1.848a4.28 4.28 0 0 1 .528-5.33A4.24 4.24 0 0 1 15 5.756l1.534-1.536m-2.825 2.811a2.54 2.54 0 0 0-2.909.587 2.43 2.43 0 0 0-.386 2.714l3.3-3.3'
          data-name='Path 2267'
        />
        <path
          d='M23.405 9.29a22.3 22.3 0 0 0-2.872-2.7.89.89 0 0 1-.415-.9.826.826 0 0 1 1.35-.5q1.281 1.08 2.5 2.235a17 17 0 0 1 1.184 1.315.814.814 0 0 1 .013 1.132 20.9 20.9 0 0 1-8.117 6.173 11.1 11.1 0 0 1-6.185.721.88.88 0 0 1-.813-.769.83.83 0 0 1 .6-.885 1 1 0 0 1 .443-.024 9.8 9.8 0 0 0 6.128-.966A20.2 20.2 0 0 0 23.37 9.37a.4.4 0 0 0 .037-.075'
          data-name='Path 2268'
        />
      </g>
    </g>
  </svg>
)
const Memo = memo(SvgIconEyeClose)
export default Memo
