import * as React from 'react'
import { memo } from 'react'
const InfoSquareLine = (props) => (
  <svg
    id="InfoSquare_Line"
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    style={{ minWidth: '20px', minHeight: '20px' }}
  >
    <defs>
      <clipPath id="clip-path">
        <rect
          id="Rectangle_14450"
          data-name="Rectangle 14450"
          width="16"
          height="16"
          transform="translate(-0.455 -0.456)"
        />
      </clipPath>
    </defs>
    <g
      id="Group_17231"
      data-name="Group 17231"
      transform="translate(2.455 2.455)"
      clipPath="url(#clip-path)"
    >
      <path
        id="Path_14198"
        data-name="Path 14198"
        d="M9.812,12.382a.572.572,0,0,1-.572-.572V8.443a.572.572,0,1,1,1.143,0v3.367a.572.572,0,0,1-.572.572M9.819,6.6a.575.575,0,0,1-.575-.572.568.568,0,0,1,.568-.572h.008a.572.572,0,1,1,0,1.143"
        transform="translate(-2.275 -1.374)"
      />
      <path
        id="Path_14199"
        data-name="Path 14199"
        d="M10.922,15.241H4.317A4.18,4.18,0,0,1,0,10.732V4.508A4.18,4.18,0,0,1,4.317,0h6.606a4.18,4.18,0,0,1,4.318,4.508v6.224a4.181,4.181,0,0,1-4.318,4.508M4.317,1.143A3.067,3.067,0,0,0,1.143,4.508v6.224A3.067,3.067,0,0,0,4.317,14.1h6.605A3.068,3.068,0,0,0,14.1,10.732V4.508a3.067,3.067,0,0,0-3.175-3.365Z"
        transform="translate(-0.076 -0.076)"
      />
    </g>
  </svg>
)

const Memo = memo(InfoSquareLine)
export default Memo
