const pattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

// eslint-disable-next-line import/prefer-default-export
const ValidateEmail = (email) => {
  const isValidEmail = String(email).toLowerCase();
  const emailReg = pattern;
  const emailValid = emailReg.test(isValidEmail);

  return emailValid;
};

export default ValidateEmail;
