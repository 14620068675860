import { createSlice } from "@reduxjs/toolkit";

const INITIAL_STATE = {
  cardNumberIsValid: true,
  cvvNumberIsValid: true,
  expirationMonthIsValid: true,
  expirationYearIsValid: true,
  cardValidationError: false,
  walletBalance: 0,
  headerValidationWithCard: false,
  headerValidationWithCVV: false,
  headerValidationWithMonth: false,
  headerValidationWithYear: false,
  bonusBalance: 0, // TODO: kullanılmıyor kontrol edilerek silinecek
  checkboxValidation: false,
  bonusUsageActive: false,
};

const slice = createSlice({
  name: 'cardValidation',
  initialState: INITIAL_STATE,
  reducers: {
    cardNumberIsValidAction: (state, action) => {
      state.cardNumberIsValid = action.payload;
    },
    cardCVVNumberIsValidAction: (state, action) => {
      state.cvvNumberIsValid = action.payload;
    },
    cardMonthIsValidAction: (state, action) => {
      state.expirationMonthIsValid = action.payload;
    },
    cardYearIsValidAction: (state, action) => {
      state.expirationYearIsValid = action.payload;
    },
    cardValidationPopupAction: (state, action) => {
      state.cardValidationError = action.payload;
    },
    walletBalanceAction: (state, action) => {
      state.walletBalance = action.payload;
    },
    headerValidationCardAction: (state, action) => {
      state.headerValidationWithCard = action.payload;
    },
    headerValidationWithCVVAction: (state, action) => {
      state.headerValidationWithCVV = action.payload;
    },
    headerValidationWithMonthAction: (state, action) => {
      state.headerValidationWithMonth = action.payload;
    },
    headerValidationWithYearAction: (state, action) => {
      state.headerValidationWithYear = action.payload;
    },
    isPaymentWithCardAction: (state, action) => {
      state.isPaymentWithCard = action.payload;
    },
    // TODO: Kullanılmıyor silinecek
    bonusBalanceAction: (state, action) => {
      state.bonusBalance = action.payload;
    },
    checkboxValidationAction: (state, action)=> {
      state.checkboxValidation = action.payload;
    },
    bonusUsageAction: (state, action) => {
      state.bonusUsageActive = action.payload;
    },
  },
});

export default slice.reducer;

export const {
  cardNumberIsValidAction,
  cardCVVNumberIsValidAction,
  cardMonthIsValidAction,
  cardYearIsValidAction,
  cardValidationPopupAction,
  walletBalanceAction,
  headerValidationCardAction,
  headerValidationWithMonthAction,
  headerValidationWithCVVAction,
  headerValidationWithYearAction,
  bonusBalanceAction,
  bonusUsageAction,
  checkboxValidationAction,
  isPaymentWithCardAction,
} = slice.actions;
