
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  formatCreditCardNumber,
  formatCVC,
} from '@Constants';
import { useNavigate } from 'react-router';
import {
  setCreditCardAction,
  setCreditCartIdAction,
  getSummoningShoppingCartIdAction,
  resetThreeDInfo,
  setCreditCartNumber,
  setSavedCards,
  setExpirationMonth,
  setExpirationYear,
  setCvcNumber,
  cardNumberIsValidAction,
  cardCVVNumberIsValidAction,
  cardMonthIsValidAction,
  cardYearIsValidAction,
  cardValidationPopupAction,
  paymenHeaderActiveAction,
  headerValidationCardAction,
  headerValidationWithMonthAction,
  headerValidationWithCVVAction,
  headerValidationWithYearAction,
  isPaymentWithCardAction,
  showPopupAction,
  isTopPopupAction,
  setInstallmentAction,
} from '@Actions';

import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';
import { gettext } from '@Translate';
import config from '@Config';
import { ReebokWhatInf } from '@Icons';
import { IsArray } from '../../../../utils/IsArray';
import { creditYear } from '../../../../utils/creditYear';
// Components
import Loader from '../../../Loader/Loader';
import CustomIframe from '../../../CustomIframe';
import CustomSelect from '../../../CustomSelect';


let lastNumber = 0;
const checkBinNumber = (number) => {
  let check = false;
  if (!number || number.length < 8) {
    return false;
  }

  const newBinNumber = String(number.replaceAll(' ', '')).split('');
  newBinNumber.length = 8;
  if (
    number.replaceAll(' ', '').length > 7
    && newBinNumber.join('') !== lastNumber
  ) {
    check = true;
    lastNumber = newBinNumber.join('');
  }

  return check;
};

/// REACT ============================================================================== ////
const GiftCreditCard = (props) => {
  const { savedCardPage, addNewCreditCard } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { project, BASE_URL } = config;
  const { guest } = useSelector((state) => state.auth);
  const {
    savedCards,
    creditCartNumber,
    expirationMonth,
    expirationYear,
    cvcNumber,
    savedCardList,
    defaultSavedCard,
    installments,
    platform,
    creditCartId,
    creditCardPlatform,
    paymentMethod,
    numberOfInstallments,
    cardImageURL,
    installmentsUrl,
    orderSummary,
    paymentMethods,
  } = useSelector((state) => state.order);

  // --------------- WALLET constants ---------------------- //
  const paymentMethodsArray = IsArray(paymentMethods);
  const paymentIsWallet = paymentMethodsArray.length > 0 ? paymentMethodsArray.find(item => item.code === 'flowallet'): '';

  const {
    basketInfo,
  } = useSelector((state) => state.cart);

  const {
    cardNumberIsValid,
    cvvNumberIsValid,
    expirationMonthIsValid,
    expirationYearIsValid,
  } = useSelector((state) => state.cardValidation);

  const [focus, setFocus] = useState('number');
  // const [savedCards, setSavedCards] = useState(false);
  const [saveCartInfo, setSaveCartInfo] = useState(false);
  const [dropdown, setDropdown] = useState(false);
  const [installmentsActive, setInstallmentsActive] = useState(false);

  // Error
  const [cardError, setCardError] = useState(true);
  const [cardCVVError, setCardCVVError] = useState(true);
  const [cardMonthError, setCardMonthError] = useState(true);
  const [cardYearError, setCardYearError] = useState(true);
  const [ccardImageURL, setCCardImageURL] = useState('');
  const [ccardImageIsVisible, setCCardImageIsVisible] = useState(false);
  const [cardNumberControl, setCardNumberControl] = useState(false);

  // wallet
  const [bonusUsageIsRequired, setbonusUsageIsRequired] = useState(false);
  const [showCreditCard, setShowCreditCard] = useState(true)

  const [yearMonthLimit, setYearMonthLimit] = useState(0);
  const questionImage = 'https://floimages.mncdn.com/media/catalog/product/original/23-12/19/question.svg';
  const questionImageLumberjack = 'https://floimages.mncdn.com/media/catalog/product/original/23-12/19/question-lumberjack-1702986568.svg';
  const questionButigo = 'https://floimages.mncdn.com/media/catalog/product/original/24-01/03/butigo-question.svg';

  const RG = {
    Overlay: {
      blockTimer: 0,
      message: '',
      tick: '',
      block() { },

      blockTicker() { },

      unblock() { },
    },
    payment: {
      checkoutAction() { },
      reActivateBasket() { },
    },
  };

  RG.payment.reActivateBasket = (data) => {
    dispatch(getSummoningShoppingCartIdAction(data));
  };

  RG.payment.checkoutAction = (data) => {
    if (!data?.success) {
      RG.payment.reActivateBasket(data);
      dispatch(resetThreeDInfo());
    } else {
      setTimeout(() => {
        dispatch(resetThreeDInfo());
        // TODO refactor
        if (basketInfo?.basketType === 'self_checkout') {
          //window.location.href = `/success?order_id=${data?.order_id}&hsh=${data?.hash}`;
          navigate({
            pathname: '/store-self-qr',
            search: `?order_id=${data?.order_id}&hsh=${data?.hash}`,
          });
        } else {
          window.location.href = `/success?order_id=${data?.order_id}&hsh=${data?.hash}`;
        }
        // Burası refactor edildiğinde app teki success kontrolü düzenlenecek
      }, 250);
    }
  };

  // TODO : Refactor - Iframe popup içerisinden erişmek için kullanılıyor
  window.RG = RG;

  const handleInputFocus = (e) => {
    setFocus(e.target.name);
  };

  useEffect(() => {
    setCCardImageURL((prevState) => (prevState = cardImageURL));
    setCCardImageIsVisible(true);
  }, [cardImageURL]);

  useEffect(() => {
    const options = {
      creditCartInfo: {
        creditCartNumber,
        cvcNumber,
        expirationMonth,
        expirationYear,
        // name,
        numberOfInstallments,
      },
      paymentMethod,
      saveCartInfo,
      use3ds: false,
      useCustomerBalance: false,
    };
    if (paymentMethod === 'flowallet_cheque') {
      options.paymentMethod = 'flopay'
      options.useBonus = true
    }

    if (savedCards) {
      options.creditCartInfo = {
        creditCartId,
        numberOfInstallments,
      };
    }
    dispatch(setCreditCardAction(options));
  }, [
    creditCartNumber,
    saveCartInfo,
    expirationMonth,
    expirationYear,
    cvcNumber,
    numberOfInstallments,
    savedCardList,
    savedCards,
    creditCartId,
    paymentMethod
  ]);
 
  useEffect(() => {
    if (savedCardList && savedCardList?.length > 0) {
      dispatch(paymenHeaderActiveAction(true));
      dispatch(isPaymentWithCardAction(false));
      dispatch(setSavedCards(true));
    } else {
      dispatch(setSavedCards(false));
    }
  }, [savedCardList]);


  useEffect(() => {
    if (paymentMethod === 'flowallet') {
      setInstallmentsActive(false);
    } else if (installments.length > 0) {
      if (creditCartNumber.length > 7) {
        setInstallmentsActive(true);
        setCCardImageIsVisible(true);
      } else if (savedCards) {
        setInstallmentsActive(true);
      } else {
        setInstallmentsActive(false);
        setCCardImageIsVisible(false);
      }
    } else {
      setInstallmentsActive(false);
      setCCardImageIsVisible(false);
    }
  }, [savedCards, creditCartNumber, installments]);

  const handleSubmit = (event) => {
    event.preventDefault();
  };

  const onClick = (event) => {
    if (!event.target.closest('.installment-wrapper')) {
      setDropdown(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', onClick, false);
    if (!(creditCartNumber && expirationMonth && expirationYear && cvcNumber) && !(savedCardList && savedCardList?.length > 0)) {
      dispatch(isPaymentWithCardAction(true));
      dispatch(paymenHeaderActiveAction(true));
    }

    // ------------------- for wallet use
    setbonusUsageIsRequired(paymentIsWallet?.bonusUsageIsRequired);

    return () => {
      document.removeEventListener('click', onClick);
      lastNumber = 0
    };
  }, ['init']);

  /* ------------------------------------------------- */
  /* ----------------- VALIDATION METHODS ------------ */
  /* ------------------------------------------------- */

  /* ------------------------------------------------- */
  // CARD NUMBER VALIDATION
  const setCardInfo = (e) => {
    setCardNumberControl((prev)=> !prev );

    dispatch(
      setCreditCartNumber(formatCreditCardNumber(e.target.value)),
    );
    if (e.target.value.length > 13) {
      dispatch(cardNumberIsValidAction(true));
      dispatch(cardValidationPopupAction(false));
      dispatch(headerValidationCardAction(true));
    } else {
      dispatch(headerValidationCardAction(false));
    }
  };

  useEffect(()=>{
    if (installments.length === 0) {
      if (savedCards) {
        dispatch(setInstallmentAction({ creditCardPlatform }));
      } else if (creditCartNumber.length > 7 ) {
        dispatch(setInstallmentAction({ creditCartNumber }));
      }
    }
   }, [])

   useEffect(() => {
    if (checkBinNumber(creditCartNumber)) {
      dispatch(setInstallmentAction({ creditCartNumber }));
    }
  }, [creditCartNumber]);
  
  /* ------------------------------------------------- */
  // CVV NUMBER VALIDATION
  const setCardCVV = (e) => {
    dispatch(
      setCvcNumber(formatCVC(e.target.value, creditCartNumber)),
    );

    if (e.target.value.length >= 3) {
      dispatch(cardCVVNumberIsValidAction(true));
      dispatch(headerValidationWithCVVAction(true));
      dispatch(cardValidationPopupAction(false));
    } else {
      // dispatch(cardCVVNumberIsValidAction(false));
      dispatch(headerValidationWithCVVAction(false));
    }
  };

  const payForAnotherCard = () => {
    dispatch(setSavedCards(false));
    setCCardImageIsVisible(false);
    if (!(creditCartNumber && expirationMonth && expirationYear && cvcNumber)) {
      dispatch(paymenHeaderActiveAction(false));
      dispatch(isPaymentWithCardAction(true));
      dispatch(cardValidationPopupAction(false));
    }
  };
  /* ------------------------------------------------- */
  // CARD EXPIRATION MONTH VALIDATION
  const monthOptionsError = (e) => {
    dispatch(setExpirationMonth(e.target.value));
    if (e.target.value.length >= 1) {
      dispatch(cardMonthIsValidAction(true));
      dispatch(headerValidationWithMonthAction(true));
      dispatch(cardValidationPopupAction(false));
    } else {
      dispatch(cardMonthIsValidAction(false));
      dispatch(headerValidationWithMonthAction(false));
    }
  };
  /* ------------------------------------------------- */
  // CARD EXPIRATION YEAR VALIDATION
  const yearOptionsError = (e) => {
    const cardMonth = Number(new Date().getMonth());
    const cardYear = Number(new Date().getFullYear());
    if (Number(e.target.value) === cardYear) {
      setYearMonthLimit(cardMonth - 1);
    } else {
      setYearMonthLimit(0);
    }
    dispatch(setExpirationYear(e.target.value));
    if (e.target.value.length > 3) {
      dispatch(cardYearIsValidAction(true));
      dispatch(headerValidationWithYearAction(true));
      dispatch(cardValidationPopupAction(false));
    } else {
      dispatch(cardYearIsValidAction(false));
      dispatch(headerValidationWithYearAction(false));
    }
  };

  /* ------------------------------------------------- */
  /* -------------- VALIDATION METHODS END ----------- */
  /* ------------------------------------------------- */

  const continueForSavedCard = () => {
    dispatch(setSavedCards(true));
    dispatch(paymenHeaderActiveAction(true));
    dispatch(isPaymentWithCardAction(false));
    dispatch(cardValidationPopupAction(false));
  };

  useEffect(() => {
    /* ------------------------------------------------- */
    // CARD NUMBER ERROR
    if (!cardNumberIsValid) {
      setCardError(false);
    } else {
      setCardError(true);
    }
    /* ------------------------------------------------- */
    // CVV NUMBER ERROR
    if (!cvvNumberIsValid) {
      setCardCVVError(false);
    } else {
      setCardCVVError(true);
    }
    /* ------------------------------------------------- */
    // CARD EXPIRATION MONTH ERROR
    if (!expirationMonthIsValid) {
      setCardMonthError(false);
    } else {
      setCardMonthError(true);
    }
    /* ------------------------------------------------- */
    // CARD EXPIRATION YEAR ERROR
    if (!expirationYearIsValid) {
      setCardYearError(false);
    } else {
      setCardYearError(true);
    }
    /* ------------------------------------------------- */

  }, [
    cardNumberIsValid,
    cvvNumberIsValid,
    expirationMonthIsValid,
    expirationYearIsValid,
  ]);

  const allInstallmentsClick = async () => {
    const url = installmentsUrl + orderSummary?.totalPrice?.value;
    await dispatch(isTopPopupAction(true));
    await dispatch(showPopupAction(() => CustomIframe({
      url, width: '100%', height: '700px', styleName: 'allInstallment',
    })));
  };


  return (
    <form
      className={`${savedCardPage ? 'saved-card-page-card-form' : 'checkout-dcc online'} flowallet_cheque`}
      id="cc_cart_info"
      onSubmit={handleSubmit}
    >
      {/* Left Column */}
      <div className='checkout-dcc__left'>
        {showCreditCard && <div>
        { savedCards && savedCardList && !addNewCreditCard && savedCardList?.length > 0 ? (
          <div
            className="checkout-dcc__tab checkout-dcc__tab--saved active use-saved-cart"
            data-target="saved"
            style={{
              position: 'relative',
              zIndex: 1,
            }}
          >
            <div>
              <div className="pay-with-another-card-title-wrapper">
                <span className="pay-with-another-card-title">
                  {gettext('Kayıtlı Kartlarım')}
                </span>
                <div className="text-right text-lg-left">
                  <button
                    type="button"
                    data-test="click-to-checkout-payment-pay-with-another-card"
                    className={`checkout-dcc__link ${project}`}
                    data-target="new"
                    onClick={() => payForAnotherCard()}
                  >
                    {gettext('Başka Kartla Öde')}
                  </button>
                </div>
              </div>
              <div className="select-wrapper form-group">


                <div className="select-group">
                  <CustomSelect
                    list={IsArray(savedCardList)}
                    title="Kayıtlı Kartlarım"
                    name="savecard"
                    type="card"
                    defaultValue={creditCardPlatform || defaultSavedCard}
                    onChange={(event) => dispatch(
                      setCreditCartIdAction(
                        IsArray(savedCardList)?.find(
                          (item) => item?.id === event?.id,
                        ),
                      ),
                    )}
                    dataTest="checkout-payment-credit-card-save-card-list-change"
                    id="saved-card-list"
                  />
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div
            className="checkout-dcc__tab active"
            data-target="new"
          >
            <input type="hidden" name="expiry" id="expiry" />
            <div className="">
              <div className="checkout-dcc__tab-cart-number-wrapper">
                <div className="checkout-dcc__tab-cart-number-wrapper--title">
                  <label htmlFor="" className="checkout-dcc__label">
                    {gettext('Kart Numarası')}
                    <div
                      className={`review-saved-card__logo js-program-name ${platform}`}
                    />
                  </label>
                  {savedCardList && !addNewCreditCard && savedCardList?.length > 0 && (
                    <div className="payment-continue-with-registered-card__container">
                      <button
                        type="button"
                        data-test="click-to-checkout-payment-continue-with-registered-card"
                        className={`checkout-dcc__link ${project}`}
                        data-target="saved"
                        onClick={() => continueForSavedCard()}
                      >
                        {gettext('Kayıtlı Kart İle Devam Et')}
                      </button>
                    </div>

                  )}
                </div>
                <div className="checkout-dcc__credit-card-num-wrapper">
                  {/* input className = {`${validationError ? 'cart-validation__error' : ''} form-control card-numbers`} */}
                  <input
                    type="tel"
                    id="cc_number"
                    data-name="cc_number"
                    name="number"
                    pattern="[0-9]* "
                    value={creditCartNumber}
                    onFocus={handleInputFocus}
                    data-test={`checkout-payment-credit-card-cc-number-change-${paymentMethod}`}
                    onChange={(e) => {
                      setCardInfo(e);
                    }}
                    // eslint-disable-next-line no-undef
                    className={` card-numbers ${project}-card-numbers ${cardError ? '' : 'form-error'
                      }`}
                    placeholder="_ _ _ _   _ _ _ _   _ _ _ _   _ _ _ _"
                  />
                  {ccardImageURL && ccardImageIsVisible && (
                    <div className={`credit-card-platform-image ${project}-credit-card-platform-image`}>
                      <img src={`${BASE_URL}${ccardImageURL}`} />
                    </div>
                  )}
                  <span
                    className={`cart-validation__error-message ${cardError ? 'none' : 'block'
                      }`}
                  >
                    {gettext('Kart numaranızı giriniz.')}
                  </span>
                </div>
              </div>
              <div className="checkout-dcc__card-date-wrapper">
                <div>
                  <label htmlFor="" className="checkout-dcc__label">
                    {gettext('Son Kullanma Tarihi')}
                  </label>
                  <div className="checkout-payment-select">
                    <div className={`checkout-dcc__month ${project}-checkout-dcc__month`}>
                      <select
                        name="payment[cc_exp_month]"
                        id="card-expiration-month"
                        value={expirationMonth}
                        onChange={(e) => monthOptionsError(e)}
                        data-test={`checkout-payment-credit-card-cc-exp-month-change-${paymentMethod}`}
                        className={` ${cardMonthError ? '' : 'form-error'
                          }`}
                      >
                        <option value="" key="ay">
                          {gettext('Ay')}
                        </option>
                        {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].filter((item) => item > yearMonthLimit).map(
                          (item) => (
                            <option
                              value={item}
                              key={item.toString()}
                            >
                              {item}
                            </option>
                          ),
                        )}
                      </select>
                    </div>
                    <div className={`checkout-dcc__year pl-0 ${project}-checkout-dcc__year`}>
                      <select
                        name="payment[cc_exp_year]"
                        id="card-expiration-year"
                        className={` ${cardYearError ? '' : 'form-error'
                          }`}
                        value={expirationYear}
                        onChange={(e) => yearOptionsError(e)}
                        data-test={`checkout-payment-credit-card-cc-exp-year-change-${paymentMethod}`}
                      >
                        <option value="" key="yil">
                          {gettext('Yıl')}
                        </option>
                        {creditYear().map((item) => (
                          <option value={item} key={item.toString()}>
                            {item}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <span
                    className={`cart-validation__error-message cart-validation__error-message--dcc  ${cardMonthError && cardYearError
                      ? 'none'
                      : 'block'
                      }`}
                  >
                    {gettext('Son kullanma tarihini giriniz.')}
                  </span>
                </div>
                <div className="">
                  <label htmlFor="" className={`checkout-dcc__label ${project}-checkout-dcc__label`}>
                    {gettext('Güvenlik Kodu')}
                    {
                      project === 'reebok' ? (
                        <span
                          onClick={() => {
                            const cvcTip = tippy('#card-ccv', {
                              content: gettext(
                                'Kartınızın arka yüzündeki son 3 rakamı yazınız.',
                              ),
                              trigger: 'manual',
                              placement: 'bottom',
                            });

                            cvcTip[0].show();
                          }}
                        ><ReebokWhatInf /></span>
                      ) : (

                        <img
                          src={project === 'lumberjack' || project === 'lumberjack_it' || project === 'ninewest' || project === 'reebok' ? questionImageLumberjack : project === 'butigo' ? questionButigo : questionImage}
                          className="icon-info js-show-cvc-information ml-auto"
                          data-test="checkout-payment-installment-img-cvc-select-turn"
                          onClick={() => {
                            const cvcTip = tippy('#card-ccv', {
                              content: gettext(
                                'Kartınızın arka yüzündeki son 3 rakamı yazınız.',
                              ),
                              trigger: 'manual',
                              placement: 'bottom',
                            });

                            cvcTip[0].show();
                          }}
                          title={gettext(
                            'Kartınızın arka yüzündeki son 3 rakamı yazınız.',
                          )}
                          id="cvc-info"
                        />
                      )
                    }

                  </label>
                  <div className="">
                    {/* input className = {`${cvvValidationError ? 'cart-validation__error' : ''} form-control card-numbers`} */}
                    <input
                      type="tel"
                      name="cvc"
                      data-name="cc_cvv"
                      id="card-ccv"
                      pattern="[0-9]*"
                      className={` checkout-dcc__cvv-input ${project}-checkout-dcc__cvv-input
                          ${cardCVVError ? '' : 'form-error'}`}
                      value={cvcNumber}
                      onFocus={handleInputFocus}
                      onChange={(e) => setCardCVV(e)}
                      data-test={`checkout-payment-credit-card-cvv-change-${paymentMethod}`}
                      placeholder="CVC/CVV"
                    />
                  </div>
                  <span
                    className={`cart-validation__error-message cart-validation__error-message--dcc ${cardCVVError ? 'none' : 'block'
                      }`}
                  >
                    {gettext('Güvenlik kodunu giriniz.')}
                  </span>
                </div>
              </div>
              {!guest && !savedCardPage && (
                <div className="save-card-command__wrapper mb-4">
                  <label
                    className={`checkbox ${project}-checkbox`}
                    id="save-card-command"
                  >
                    <input
                      type="checkbox"
                      checked={saveCartInfo}
                      value={saveCartInfo}
                      data-test={`checkout-payment-credit-card-save-card-info-change-${paymentMethod}`}
                      onChange={(event) => {
                        setSaveCartInfo(event.target.checked);
                      }}
                    />
                    <span data-test={`checkout-payment-credit-card-save-card-info-change-${paymentMethod}`} />
                    {gettext('Kredi Kartımı Sakla')}
                  </label>
                </div>
              )}

            </div>
          </div>
        )}
       
        {/* TODO Tasarımda olmadığıiçin none class ı ile kapatıldı UI ekibi ile görüşülerek kaldırılacak */}
        <div className="checkout-dcc__payment-options none">
          <img
            src={`${BASE_URL}pub/assets/flo-v2/images/checkout/ssl-secured.png`}
            className="checkout-dcc__ssl-text"
            alt=""
            style={{ height: '24px' }}
          />
          <div
            className="checkout-dcc__separator"
            style={{ width: '10px' }}
          />
          <img
            src={`${BASE_URL}pub/assets/flo-v2/images/checkout/cards2.png`}
            className="checkout-dcc__cards"
            alt=""
            style={{ maxWidth: '168px' }}
          />
        </div>

        </div>}
      </div>
    </form>
  );
};

export default GiftCreditCard;
