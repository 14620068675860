/* eslint-disable max-len */
import React, { useState, useEffect, Suspense } from 'react';
import { Routes, Route, useSearchParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router';
import Cookie from 'js-cookie';

import {
  Loader,
  ErrorBoundary,
  RequireAuth,
  RequireWebView,
  RedirectToPage,
  MetaTags,
} from '@Components';
import {
  Basket,
  Payment,
  Success,
  NotFound,
  CheckoutThreedSecure,
  Otp,
  WalletDetail,
  Wallet,
  WalletBarcodeScan,
  WalletBarcode,
  WalletStore,
  WalletApprove,
  WalletBarcodeComplete,
  StoreBasket,
  StoreQrComplate,
  StoreOrderCancellation,
  SavedCardWithoutShopping,
} from '@Pages';

import API from '@API';

import {
  cartActionRequest,
  setBasketInfo,
  setSelfCheckoutAction,
  setDynamicBaseUrlAction,
  setInstallmentAction,
} from "@Actions";

import './App.scss';
import config from '@Config';
import { goToLogin } from '@Utils';

const DefaultLayout = React.lazy(() => import('./containers/DefaultLayout'));

function ThemeApp() {
  const [shoppingCartData, setShoppingCartData] = useState('');
  const navigate = useNavigate();
  const { webview } = useSelector((state) => state.config);
  const { token } = useSelector((state) => state.auth);
  const { title, googleClientId, project, BASE_URL, CHECKOUT_BASE_URL } = config;
  const dispatch = useDispatch();
  const urlLocation = useLocation();
  const [searchParams] = useSearchParams();
  const pathName = urlLocation?.pathname;
  const query = urlLocation?.search;

  // TODO : navigate = ajax sonrası yönlendirme için ekleniyor. Bunun için uygu çözüm üretilmesi gerekiyor. Burada ki çözüm geçici.
  window.navigate = navigate;

  useEffect(() => {
    if (webview) {
     // logEvent('ready', true);
      document.getElementById('title').innerText = 'Checkout';
    } else {
      document.getElementById(
        'title',
      ).innerText = `Checkout - ${title}`;
    }
  }, [webview]);

  // uygulama ayağa kalkerken beyaz ekran oluşmaması için.
  // success sayfasında istek atılmayacak ve redirect refactor edildikten sonra success kontrolü düzenlenecek.
  useEffect(() => {
    if (pathName === '/' ||
        pathName === '/basket' ||
        pathName === '/payment' ||
        pathName === '/store-basket' ||
        pathName === '/store-payment' ||
        pathName === '/store-self-qr'
    ) {
      if (pathName === '/store-basket' || pathName === '/store-payment' || pathName === '/store-self-qr') {
        const basketInfo = {
          storeCode: searchParams.get('storeCode'),
          basketType: 'self_checkout',
        };
        dispatch(setBasketInfo(basketInfo));
        dispatch(setSelfCheckoutAction(true));
      } else {
        localStorage.removeItem('storeCode');
        localStorage.removeItem('basketType');
      }

      handleRequests()

    }
    Cookie.set('language', config.locale, { domain: config.COOKIE_BASE_URL });
    
    // override getScript
    const originalGetScript = window.getScript;
    window.getScript = function() {
      return null
    };
    return () => {
      window.getScript = originalGetScript;
    };
  }, []);

  async function handleRequests() {
    if (pathName === '/basket'
    ) {
      await dispatch(
        setInstallmentAction({ location: 'basket' })
      );
    }

    dispatch(cartActionRequest({ lastBasketItems: pathName === '/basket' })).then(({ payload }) => {
      try {
        const { status, shoppingCart } = payload;
  
        const baseUrl = shoppingCart?.checkoutBEBaseUrl;
        dispatch(setDynamicBaseUrlAction(baseUrl));
        if (status?.code === 200) {
          if ((pathName === '/payment' || pathName === '/store-payment') && !token && !query) {
          // ** ---------------- RETURN LOGIN QUERY ----------------------- **
            goToLogin(BASE_URL, CHECKOUT_BASE_URL);
          } else if (token && ((shoppingCart?.shoppingCartItemList?.length <= 0 && (pathName !== '/basket' && pathName !== '/store-basket' && pathName !== '/store-self-qr' && pathName !== '/store-order-cancellation')))) {
            navigate('/basket');
          }
        } else {
          setShoppingCartData(shoppingCart);
        }
      } catch (error) {
        
      }
    });
  }

  return (
    <ErrorBoundary>
      <MetaTags />
      <Suspense fallback={<Loader type="cart" />}>
        <RedirectToPage />
        <Routes>
          <Route element={<Wallet />}>
            <Route exact path="/checkout/wallet" name="wallet" element={<WalletDetail />} />
            <Route element={<WalletBarcode />}>
              <Route exact path="/checkout/wallet/barcode" name="wallet-barcode" element={<WalletBarcodeScan />} />
              <Route path="/checkout/wallet/store" name="wallet-store" element={<WalletStore />} />
              <Route path="/checkout/wallet/approve" name="wallet-approve" element={<WalletApprove />} />
            </Route>
            <Route path="/checkout/wallet/complete" name="wallet-complete" element={<WalletBarcodeComplete />} />
          </Route>
          <Route
            path="saved-card-without-shopping"
            name="saved-card-without-shopping"
            element={
              <RequireAuth>
                <SavedCardWithoutShopping />
              </RequireAuth>
            }
          />
          <Route path="/" name="Home" element={<DefaultLayout />}>
            <Route exact path="basket" name="basket" element={<Basket />} />
            <Route path="/" name="Checkout">
              <Route
                path="otp"
                name="otp"
                element={(
                  <Otp />
                )}
              />
              <Route
                path="payment"
                name="payment"
                element={(
                  <RequireAuth>
                    <Payment getshoppingCart={shoppingCartData} />
                  </RequireAuth>
                )}
              />
              <Route
                path="success"
                name="success"
                element={<Success />}
              />
              <Route
                path="threedsecureresponse"
                name="threedsecureresponse"
                element={<CheckoutThreedSecure />}
              />
              <Route
                path="store-basket"
                name="store-basket"
                element={
                  <RequireWebView>
                    <StoreBasket />
                  </RequireWebView>
                }
              />
              <Route
                path="store-order-cancellation"
                name="store-order-cancellation"
                element={
                  <RequireAuth>
                    <RequireWebView>
                      <StoreOrderCancellation />
                    </RequireWebView>
                  </RequireAuth>
                }
              />
              <Route
                path="store-payment"
                name="store-payment"
                element={(
                  <RequireAuth >
                    <RequireWebView>
                      <Payment getshoppingCart={shoppingCartData} />
                    </RequireWebView>
                  </RequireAuth>
                )}
              />
              <Route
                path="store-self-qr"
                name="store-self-qr"
                element={
                  <RequireAuth>
                    <RequireWebView>
                      <StoreQrComplate />
                    </RequireWebView>
                  </RequireAuth>
                }
              />
            </Route>
            <Route
              path="*"
              element={<NotFound />}
            />
          </Route>
        </Routes>
      </Suspense>
    </ErrorBoundary>
  );
}

export default ThemeApp;
