/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import ReactDOM from 'react-dom';
import {
  BrowserRouter,
} from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './store';
import ThemeApp from './App';
import analytics from './analytics';


analytics();

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <ThemeApp />
    </BrowserRouter>
  </Provider>,
  document.querySelector('#root'),
);
