/* eslint-disable max-len */
// eslint-disable-next-line no-extend-native
Number.prototype.toFixedNumber = function (digits, base) {
  // eslint-disable-next-line no-restricted-properties
  const pow = Math.pow(base || 10, digits);
  return Math.round(this * pow) / pow;
};

const storage = {
  set: (key, value, session) => {
    if (session) {
      window.sessionStorage.setItem(RG.storage.getKey(key), JSON.stringify(value));
    } else {
      window.localStorage.setItem(RG.storage.getKey(key), JSON.stringify(value));
    }
  },

    get: (key, session) => {
    if (session) {
      return JSON.parse(window.sessionStorage.getItem(RG.storage.getKey(key)));
    }
    return JSON.parse(window.localStorage.getItem(RG.storage.getKey(key)));
  },

    getKey(key) {
    return `layerData.${key}`;
  },
}

function skuLengthControl(sku) {
  const len = String(sku).length;
  if (len < 18) {
    let prefix = '';
    for (let i = 0; i < (18 - len); i++) {
      prefix += '0';
    }
    return prefix + sku;
  }
  return sku;
}

const getRefScreenCode = (product) => {
  if (product && product.refType) {
    return  product.refType;
  }

  const search = window.location.search;
  const params = new URLSearchParams(search);
  let value = null;

  if (params.get('ref_screen_code')) {
    value = params.get('ref_screen_code');
  }

  if (!value) {
    value = 'other';
  }

  return value;
};

const getQuantity = (product) => {
  if (product?.quantity) {
    return Number(product.quantity);
  }

  if (product?.basket_id && product?.qty) {
    return Number(product.qty);
  }

  if (product?.qty_ordered) {
    return Number(product.qty_ordered);
  }

  return 1;
};

const hasDiscount = (product) => {
  let discount = false;

  if (product?.special_price || product?.third_price) {
    discount = true;
  }

  if (product?.data) {
    if (product.data?.special_price || product.data?.third_price) {
      discount = true;
    }
  }

  if (product?.raw_data) {
    if (product.raw_data?.special_price || product.raw_data?.third_price) {
      discount = true;
    }
  }
  return discount;
};

const getRuleInfo = (product) => {
  if (product?.rule_info) {
    return product.rule_info[0]?.name || product.rule_info[0]?.label;
  }

  if (product?.data?.rule_info) {
    return product?.data?.rule_info[0]?.name || product?.data?.rule_info[0]?.label;
  }

  if (product?.raw_data?.rule_info) {
    return product.raw_data.rule_info[0]?.name || product.raw_data.rule_info[0]?.label;
  }

  return '';
};

const getDiscoutedPrice  = (product) => {
  try {
    if (product?.special_price) {
      return Number(product.special_price).toFixed(2)
    }

    return Number(product?.price).toFixed(2);
  } catch (e) {
    console.error(e);
  }
};

const getPrice = (product) => {
  let monay = product?.price;

  if (product?.special_price) {
    monay = product.special_price;
  }

  if (product?.third_price) {
    monay = product.third_price;
  }

  if (product?.secondPrice) {
    monay = product.secondPrice;
  }

  if (product?.thirdPrice) {
    monay = product.thirdPrice;
  }

  if (product?.data) {
    monay = product.data?.price;

    if (product.data?.special_price) {
      monay = product.data.special_price;
    }

    if (product.data?.third_price) {
      monay = product.data.third_price;
    }
  }

  if (product?.raw_data) {
    monay = product.raw_data?.price;

    if (product?.raw_data?.special_price) {
      monay = product.raw_data.special_price;
    }

    if (product?.raw_data?.third_price) {
      monay = product.raw_data.third_price;
    }
  }

  return Number(monay).toFixed(2);
};


const getCategory = (product) => {
  let category = [];
  if (product?.categories?.length) {
    category = [product.categories[0]?.name, product.categories[product.categories.length - 1]?.name];
  }

  if (product?.data?.categories?.length) {
    category = [product.data.categories[0]?.name, product.data.categories[product.data.categories?.length - 1]?.name];
  }

  if (product?.raw_data?.categories?.length) {
    category = [product.raw_data.categories[0]?.name, product.raw_data.categories[product.raw_data.categories?.length - 1]?.name];
  }

  if (product?.ecommerce_categories) {
    category = [product.ecommerce_categories];
  }

  if (product?.data?.ecommerce_categories) {
    category = [product.data.ecommerce_categories];
  }

  if (product?.raw_data?.ecommerce_categories) {
    category = [product.raw_data.ecommerce_categories];
  }

  return category.join('/');
};

const getListName = () => {
  let list = window.location.pathname.substr(1, 1)?.toUpperCase() + window.location.pathname.substr(2);

  if (window.breadcrumb) {
    list = window.breadcrumb.map(item => item.name).join('/');
  }

  return list;
};

const getMainCategory = (product) => {
  if (product?.categories) {
    return product.categories[0]?.name;
  }

  if (product?.data) {
    if (product.data?.categories) {
      return product.data.categories[0]?.name;
    }
  }

  if (product?.raw_data) {
    if (product.raw_data.categories) {
      return product.raw_data.categories[0]?.name;
    }
  }

  return '';
};

const getSubcategory = (product) => {
  if (product?.categories) {
    return product.categories[product.categories?.length - 1]?.name;
  }

  if (product?.data) {
    if (product.data?.categories) {
      return product.data.categories[product.data.categories?.length - 1]?.name;
    }
  }

  if (product?.raw_data) {
    if (product.raw_data?.categories) {
      return product.raw_data.categories[product.raw_data.categories?.length - 1]?.name;
    }
  }

  return '';
};

const getSelectedOption = (product) => {
  if (product?.size) {
    return product?.size;
  }

  if (product?.options) {
    return product?.options?.filter(item => skuLengthControl(item?.sku) === skuLengthControl(product?.sku))?.option_value;
  }

  if (product?.data?.options) {
    return product?.data?.options?.find(item => skuLengthControl(item?.sku) === skuLengthControl(product?.sku))?.option_value;
  }

  if (product?.raw_data?.options) {
    return product.raw_data.options?.find(item => skuLengthControl(item?.sku) === skuLengthControl(product?.sku))?.option_value;
  }

  return null;
};

const getGender = (product) => {
  if (product?.data?.gender) {
    return product?.data?.gender;
  }
  if (product?.raw_data?.gender) {
    return product?.raw_data?.gender;
  }
  return product?.gender;
};

const getProductId = (product) => {
  if (product?.parentSku) {
    return product.parentSku;
  }

  if (product?.data?.parent_sku) {
    return product.data.parent_sku;
  }

  if (product?.raw_data?.parent_sku) {
    return product.raw_data.parent_sku;
  }

  if (product?.parent_sku) {
    return product.parent_sku;
  }

  if (product?.data?.sku) {
    return product.data.sku;
  }

  return product.sku;
};

const getMerchantName = (product) => {
  if (product?.data) {
    if (product.data?.merchant) {
      return product.data.merchant?.name;
    }
  }

  if (product?.raw_data) {
    if (product.raw_data?.merchant) {
      return product.raw_data.merchant?.name;
    }
  }

  if (product?.merchant) {
    return product.merchant?.name;
  }

  return '';
};

const getMerchantInfo = (product) => {
  if (product?.data) {
    if (product.data?.merchant) {
      return product.data.merchant;
    }
  }

  if (product?.raw_data) {
    if (product.raw_data?.merchant) {
      return product.raw_data.merchant;
    }
  }

  if (product?.merchant) {
    return product.merchant;
  }

  return {};
};

const getColor = (product) => {
  if (product?.data?.renk) {
    return product?.data?.renk;
  }
  if (product?.data?.color) {
    return product?.data?.color;
  }
  if (product?.raw_data?.color) {
    return product?.raw_data?.color;
  }
  if (product?.color) {
    return product?.color;
  }
  return product?.renk;
};

const getModel = (product) => {
  if (product?.data) {
    return product.data?.model;
  }
  if (product?.raw_data) {
    return product.raw_data?.model;
  }
  return product.model;
};

const getBrand = (product) => {
  if (product?.brand) {
    return product.brand;
  }

  if (product?.brandName) {
    return product.brandName;
  }

  if (product?.data) {
    return product.data.manufacturer;
  }

  if (product?.raw_data) {
    return product.raw_data?.manufacturer;
  }

  return product?.manufacturer;
};

const getMerchantId = (product) => {
  if (product) {
    if (product.merchant?.id) {
      return product.merchant.id.toString();
    }
    if (product.data) {
      return product.data.merchant_id.toString();
    }
    if (product.raw_data) {
      return product.raw_data.merchant_id.toString();
    }
    if (product.merchant_id) {
      return product.merchant_id.toString();
    }
  }
  return null
};

const getSayaMalzeme = (product) => {
  if (product?.data) {
    return product?.data?.materyal;
  }
  if (product?.raw_data) {
    return product?.raw_data?.materyal;
  }
  return product?.materyal;
};

const getProductPercentageOfAvailabilty = (product) => {
  if (product?.data) {
    return product.data?.available_options ? (product.data?.available_options.length / product.data?.options?.length * 100) : '0';
  }
  if (product?.raw_data) {
    return product.raw_data?.available_options ? (product.raw_data?.available_options?.length / product.raw_data?.options?.length * 100) : '0';
  }
  if (product?.availableOptions) {
    return product.availableOptions ? (product.availableOptions?.length / product.availableOptions?.length * 100) : '0';
  }
  return product.available_options ? (product.available_options?.length / product.options?.length * 100) : '0';
};

const getProductAvailableSizeGeneral = (product) => {
  if (product?.data) {
    return product.data?.available_options ? product.data?.available_options.join(' | ') : '';
  }
  if (product.raw_data) {
    return product.raw_data?.available_options ? product.raw_data?.available_options.join(' | ') : '';
  }
  return product.available_options ? product.available_options.join(' | ') : '';
};


const getCategoryFirst = (product) => {
  let category = null;

  if (product?.categories?.length) {
    category = product?.categories[0]?.name;
  }

  if (product?.data?.categories?.length) {
    category = product.data?.categories[0]?.name;
  }

  if (product?.raw_data?.categories?.length) {
    category = product?.raw_data?.categories[0]?.name;
  }

  return category;
};

const getCategoryLast = (product) => {
  let category = null;

  if (product?.categories?.length) {
    category = product.categories[product.categories?.length - 1]?.name;
  }

  if (product?.data?.categories?.length) {
    category = product?.data?.categories[product.data.categories?.length - 1]?.name;
  }

  if (product?.raw_data?.categories?.length) {
    category = product?.raw_data?.categories[product.raw_data.categories?.length - 1]?.name;
  }
  return category;
};

const getProductName = (product) => {
  if (product?.raw_data?.name) {
    return product?.raw_data?.name;
  }
  return product?.name;
};

let getBreadcrumpLastItem = "";
if (window.breadcrumb != undefined && window.breadcrumb.length > 0) {
  getBreadcrumpLastItem = window.breadcrumb[(window.breadcrumb.length) - 1].name;
}

const productMapping = product => ({
  name: getProductName(product),
  sku: skuLengthControl(product.sku),
  quantity: getQuantity(product),
  id: skuLengthControl(getProductId(product)),
  brand: getBrand(product),
  price: getPrice(product),
  category: getCategory(product),
  variant: getColor(product),
  size: getSelectedOption(product),
  list: getListName(product),
  gender: getGender(product),
  main_category: getCategoryFirst(product),
  subcategory: getCategoryLast(product),
  color: getColor(product), // product_color color of product
  dimension1: getColor(product), // product_color color of product
  dimension2: getSelectedOption(product), // product available size in selected merchant
  dimension3: product.image ? 'true' : 'false', // product_image is there image of product?
  dimension9: getModel(product), // product_model
  dimension13: getBrand(product), // product brand
  dimension17: getGender(product), // product gender
  dimension18: `${product.sezon || ''}${product.sezon_yil ? ' - ' : ''}${product.season || ''}`, // sezon bilgisi
  dimension30: getCategoryFirst(product), // product main category
  dimension31: getCategoryLast(product), // product sub category
  dimension33: getQuantity(product), // product_stock number of product in stock
  dimension35: !!(product?.stock > 0), // product_stock is product in stock?
  dimension37: skuLengthControl(product.sku), // product stock keeping unit number
  dimension41: getRuleInfo(product), // product_percentage_of_availabilty
  dimension44: getProductPercentageOfAvailabilty(product), // product_percentage_of_availabilty
  dimension151: getRefScreenCode(product),
  dimension157: getMerchantId(product),
  metric2: getProductPercentageOfAvailabilty(product), // product_percentage_of_availabilty
});
export default productMapping;
