import { CreditCard } from './PaymentOptionContents';
import CashOnDelivery from './PaymentOptionContents/CashOnDelivery';
import DijitalPayment from './PaymentOptionContents/DijitalPayment';
import SmsActivation from './SMSActivation';
import Agreements from './Agreements';
import DefaultInfo from './DefaultInfo';
import WalletOtp from './WalletOtp';
import PaymentProcess from './PaymentProcess';
import SideBar from './SideBar';
import Iframe from './Iframe';

export {
  DefaultInfo,
  CreditCard,
  CashOnDelivery,
  DijitalPayment,
  SmsActivation,
  Iframe,
  Agreements,
  WalletOtp,
  PaymentProcess,
  SideBar
};
