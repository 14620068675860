import * as React from 'react'
import { memo } from 'react'
const SvgReebokEmptyCart = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='1em'
    height='1em'
    viewBox='0 0 76 76'
    fill='currentColor'
    {...props}
  >
    <defs>
      <clipPath id='reebok-empty-cart_svg__a'>
        <path
          fill='none'
          d='M0 0h76v76H0z'
          data-name='Rectangle 7940'
        />
      </clipPath>
    </defs>
    <g
      clipPath='url(#reebok-empty-cart_svg__a)'
      data-name='Group 15608'
    >
      <path
        fill='#fff'
        d='M43.257 28.954c0-2.786-.46-4.194-1.108-4.867s-1.856-1.044-4.217-1.044-3.566.374-4.211 1.042-1.114 2.084-1.114 4.868v.176h10.65Z'
        data-name='Path 13732'
      />
      <path
        fill='#fff'
        d='M29.564 28.95c.005-3.1.456-5.4 1.968-6.974S35.259 20 37.932 20H25v9.128h4.564Z'
        data-name='Path 13733'
      />
      <path
        fill='#fff'
        d='M28.578 56.475 25 51.5v5.018h22.277l-17.922-.043Z'
        data-name='Path 13734'
      />
      <path
        fill='#fff'
        d='M50.863 56.518v-4.975l-3.586 4.975Z'
        data-name='Path 13735'
      />
      <path
        fill='#fff'
        d='M52.012 4.828a36.01 36.01 0 1 0 19.134 47.121A35.763 35.763 0 0 0 52.012 4.828m-1.149 24.3v27.39H25V20h25.863Z'
        data-name='Path 13736'
      />
      <path
        fill='#fff'
        d='M46.3 38.257h-3.043v-6.086h-10.65v6.086h-3.043v-6.086h-1.521v18.346l2.1 2.918 15.58.032 2.1-2.909V32.171H46.3Z'
        data-name='Path 13737'
      />
      <path
        fill='#fff'
        d='M44.337 21.976c1.519 1.577 1.963 3.877 1.963 6.977v.175h4.563V20H37.932c2.674 0 4.886.4 6.405 1.976'
        data-name='Path 13738'
      />
      <path
        d='M38 0a38 38 0 1 0 38 38A38 38 0 0 0 38 0M23.988 71.172a36.01 36.01 0 1 1 47.158-19.223 36.01 36.01 0 0 1-47.158 19.223'
        data-name='Path 13739'
      />
      <path
        d='M46.3 28.953c0-3.1-.444-5.4-1.963-6.977S40.606 20 37.932 20s-4.888.4-6.4 1.976-1.963 3.878-1.968 6.974v.178H25V51.5l3.578 4.975h.777l17.922.043 3.586-4.975V29.128H46.3Zm-13.693 0c0-2.784.469-4.2 1.114-4.868s1.85-1.042 4.211-1.042 3.569.371 4.217 1.044 1.108 2.081 1.108 4.867v.175h-10.65Zm15.214 3.218v18.387l-2.1 2.909-15.58-.032-2.1-2.918V32.169h1.521v6.086h3.043v-6.084h10.65v6.086H46.3v-6.086Z'
        data-name='Path 13740'
      />
    </g>
  </svg>
)
const Memo = memo(SvgReebokEmptyCart)
export default Memo
