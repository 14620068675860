import React from 'react';
import { useSelector } from 'react-redux';
import { Loader } from '@Components';
import { IsArray } from '../../../utils/IsArray';

const Agreements = () => {
  const {
    agreements,
    agreementsLoading,
  } = useSelector((state) => state.order);

  return (
    <div className="" style={{ padding: 20, backgroundColor: '#ffffff' }}>
      {agreementsLoading && (
        <Loader />
      )}

      {IsArray(agreements)?.map((item) => (
        <div id={item?.id} key={item?.id} className="checkout-dcc__agreements js-agreement-wrapper">
          <div className="checkout-dcc__agreement mb-lg-4">
            <div className="checkout-dcc__agreement-label mb-2">{item?.name}</div>
            <div
              className="checkout-dcc__agreement-body mb-4"
              dangerouslySetInnerHTML={{
                __html: item?.content,
              }}
            />
          </div>
        </div>
      ))}
    </div>
  );
};

export default Agreements;
