/* eslint-disable react/prop-types */
/* eslint-disable no-unused-expressions */
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router';
import { Logo } from '@Components';
import { IconSecure } from '@Icons';
import Cookie from 'js-cookie';


import {
  setStepAction,
  visibleBackButtonAction,
  visibleCloseButtonAction,
  resetShippingAddressAction,
} from '@Actions';

import {
  gettext,
} from '@Translate';
import config from '@Config';
import { goToLogin } from '@Utils';
import RenderHeading from './Heading';

const Navbar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { step } = useSelector((state) => state.checkout);
  const { language } = useSelector((state) => state.language);
  const { webview, native } = useSelector((state) => state.config);
  const { appDeepLink, project, BASE_URL, CHECKOUT_BASE_URL } = config;
  const { shoppingCart, basketInfo } = useSelector((state) => state.cart);

  const [ didComeFromSite, setDidComeFromSite ] = useState(false);

  const urlLocation = useLocation();
  const goToBack = () => {

    const currentPathname = urlLocation.pathname;
    if (webview) {
      if (step === 1 || step === 0 || step === 'store-step-1' || (native && step === 2)) {
        window.location.href = `${appDeepLink}close`;
      } else {
        if (basketInfo?.basketType === 'self_checkout') {
          navigate('/store-basket');
        } else {
          navigate('/basket');
        }
      }
    } else {
      // site üzerinden gelmeyen (ve webview olmayan) kullanıcıları anasayfaya yönlendirir

      if (!didComeFromSite && currentPathname === '/basket') {
        window.location.href = BASE_URL;
      } else if(currentPathname === '/payment'){
        // route süreçlerinde doğru bir yol izlemek için history tutmamamız gerekmekte
        // bu sebeple replace parametresi eklenmiştir
        navigate( '/basket', {replace: true} );
      } else {
        navigate(-1);
      }

    }
  };


  const goToMainPage = () => {
    if (webview) {
      window.location.href = `${appDeepLink}mainpage`;
    } else {
      window.location.href = BASE_URL;
    }
  };


  const historyLength = window.history.length;

  /* ------------------------------------------------- */
  /* ------------------- PROJECT INIT ---------------- */
  /* ------------------------------------------------- */

  useEffect(() => {
    if (urlLocation?.pathname === '/basket') {
      dispatch(visibleBackButtonAction(false));
      // dispatch(cartActionRequest());
      dispatch(visibleCloseButtonAction(true));
      dispatch(setStepAction(1));
    }


    // site üzerinden gelmeyen kullanıcıları anasayfaya yönlendirmek için
    // bir önceki sayfa URL bilgisi kontrol ediliyor
    const Referer = document.referrer;
    const backPage = Referer.includes(BASE_URL);
    setDidComeFromSite(backPage);
  }, []);

  useEffect(() => {
    if (webview && urlLocation?.pathname === '/basket' && historyLength > 1) {
      dispatch(setStepAction(1));
    } else if (webview && urlLocation?.pathname === '/payment') {
      dispatch(setStepAction(2));
    }
  }, [urlLocation?.pathname]);

  /* ----------------------- // ---------------------- */

  return (
    <>
      <div className="checkout-header">
        <div className="checkout-header__inner">
          <div className="checkout-header__wrapper custom-container">
            <div className={`checkout-header__menuLogo ${project}`}>
              <a href={project === 'flo-kz' ? BASE_URL + language : BASE_URL} className="checkout-header__logo" data-test="open-to-checkout-header-home-page-redirect">
                <Logo />
              </a>
            </div>
            {step >= 1 && step < 4 && shoppingCart?.shoppingCartItemList?.length > 0 && (
              <div className="checkout-header__navigation">
                <button
                  type="button"
                  key={`step${step}-1`}
                  onClick={()=>
                    navigate('/basket', { from: 'Sepetim' })
                  }
                  className={`checkout-header__step ${step === 1 ? 'active' : ''} ${step > 1 ? 'approved' : ''}`}
                  data-test="action-show-basket-from-checkout"
                >
                  {gettext('Sepetim')}
                </button>
                <div className="checkout-header__step  checkout-header__separator">- - - - - - -</div>
                <button
                  type="button"
                  key={`step${step}-2`}
                  onClick={() => {
                    if (!Cookie.get('is_logged_in')) {
                      webview
                        ? (window.location.href = `${appDeepLink}login`)
                        : goToLogin(BASE_URL, CHECKOUT_BASE_URL)
                    } else {
                      navigate('/payment', { from: 'Ödeme' });
                    }
                  }}
                  className={`checkout-header__step ${step === 2 ? 'active' : ''}`}
                  data-test="action-show-payment-from-checkout"
                >
                  {gettext('Adres/Ödeme')}
                </button>
              </div>
            )}
            <div>
              {step !== 0 && (
              <div className="secure-payment-container">
                <IconSecure />
                <span className="secure-payment-container-text">
                  {gettext('Güvenli Alışveriş ve Ödeme')}
                </span>
              </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className={`m-checkout-header ${step === 3 ? 'success' : ''}`}>
        {(step < 3 || step === 'store-step-1') && (
          <button
            type="button"
            key="step-back-2"
            onClick={goToBack}
            data-test="checkout-navbar-step-back-button"
            className="m-checkout-header__back d-lg-none"
          >
            <i className="icon-chevron-left" />
          </button>
        )}
        {step === 3 && (
          <button
            type="button"
            key="step-close"
            onClick={goToMainPage}
            data-test="checkout-navbar-step-close-button"
            className="m-checkout-header__close d-lg-none"
          >
              <i className="icon-close" />
          </button>
        )}
        {step === 'store-step-4' && (
          <button
            type="button"
            key="step-close"
            onClick={goToMainPage}
            data-test="checkout-navbar-step-close-button"
            className="m-checkout-header__close"
          >
              <i className="icon-close-black" />
          </button>
        )}
        {(<div className="m-checkout-header__title">
          {(step > 0 || step === 'store-step-1' || step === 'store-step-3' || step === 'store-step-4') && <RenderHeading step={step} cartCount={shoppingCart?.shoppingCartItemCount} /> }
        </div>)}
      </div>
    </>
  );
};

export default Navbar;
