import React from 'react';
import { useNavigate } from 'react-router';
import { isDisasterPopupAction, hidePopupAction } from '@Actions';
import { useDispatch } from 'react-redux';

// eslint-disable-next-line max-len,react/prop-types
export default function ToastifyDisaster({ errorMessage, redirect }) {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleRedirect = async () => {
    dispatch(isDisasterPopupAction(false));
    dispatch(hidePopupAction());
    if (redirect) {
      navigate('/basket');
    }
  };

  return (
    // eslint-disable-next-line react/jsx-filename-extension
    <div style={{ color: '#ffffff' }}>
      <div id="remove-add-wishlist-product-100482192002" className="fancybox-hidden-content remove-product disaster fancybox-content">
        <div className="remove-product__heading">
          <div className="basket-icon">
            <span className="heart-icon">
              <i className="icon-heart-o" />
            </span>
            <i className="icon-basket" />
          </div>
        </div>
        <div className="remove-product__content">
          <p>
            {errorMessage}
          </p>
        </div>
        <div className="remove-product__buttons disaster-btn">
          <button type="button" onClick={handleRedirect} className="btn btn-orange" data-test="checkout-coupon-continue-button">
            Devam Et
          </button>
        </div>
      </div>
    </div>
  );
}
