import config from '@Config';
import * as React from 'react';
import { useSelector } from 'react-redux';
import {
  useLocation,
  Navigate,
} from 'react-router-dom';

function RequireAuth({ children, redirect, shoppingCartCount }) {
  const { webview } = useSelector((state) => state.config);
  const { prevLocation } = useSelector((state) => state.prevPath);
  const { appDeepLink } = config;
  const { token } = useSelector((state) => state.auth);
  const { basketInfo } = useSelector((state) => state.cart);
  const location = useLocation();

  if (webview && !token) {
    if (appDeepLink) {
      window.location.href = `${appDeepLink}login`;
    }
    if (basketInfo?.basketType === 'self_checkout') {
      return <Navigate to="/store-basket" replace state={{ from: location }} />;
    }
    return <Navigate to="/basket" replace state={{ from: location }} />;
  }

  if (!redirect && !token && prevLocation !== '/login' && shoppingCartCount > 0) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/login" replace state={{ from: location }} />;
  }

  if (redirect && token) {
    return <Navigate to={redirect} replace state={{ from: location }} />;
  }

  return children;
}

export default RequireAuth;
