import * as React from 'react'
import { memo } from 'react'
const SvgWallet = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='1em'
    height='1em'
    viewBox='0 0 20.622 20.623'
    fill='currentColor'
    {...props}
  >
    <g stroke='#000' strokeWidth={0.4} data-name='wallet (1)'>
      <path
        d='M19.176 20.423H1.446A1.247 1.247 0 0 1 .2 19.176V6.571a1.247 1.247 0 0 1 1.246-1.246h17.73a1.247 1.247 0 0 1 1.246 1.246v12.605a1.247 1.247 0 0 1-1.246 1.247ZM1.446 5.738a.834.834 0 0 0-.834.833v12.605a.834.834 0 0 0 .834.834h17.73a.834.834 0 0 0 .834-.834V6.571a.834.834 0 0 0-.834-.833Z'
        data-name='Path 1786'
      />
      <path
        d='M20.216 14.989h-3.853a2.115 2.115 0 1 1 0-4.23h3.853a.206.206 0 0 1 .206.206v3.817a.206.206 0 0 1-.206.207Zm-3.853-3.813a1.7 1.7 0 0 0 0 3.4h3.646v-3.4Z'
        data-name='Path 1787'
      />
      <path
        d='M16.332 13.789a.915.915 0 1 1 .915-.915.915.915 0 0 1-.915.915Zm0-1.417a.5.5 0 1 0 .5.5.5.5 0 0 0-.5-.504Z'
        data-name='Path 1788'
      />
      <path
        d='M8.556 5.738a.206.206 0 0 1-.08-.4l7.067-2.957a.206.206 0 0 1 .266.1l1.417 2.957a.206.206 0 1 1-.371.178l-1.333-2.778-6.887 2.879a.2.2 0 0 1-.079.021Z'
        data-name='Path 1789'
      />
      <path
        d='M2.418 5.738a.206.206 0 0 1-.1-.389l9.75-5.124a.206.206 0 0 1 .281.091l1.49 2.991a.206.206 0 1 1-.369.183L12.08.69 2.515 5.716a.2.2 0 0 1-.1.024Z'
        data-name='Path 1790'
      />
    </g>
  </svg>
)
const Memo = memo(SvgWallet)
export default Memo
