import React from 'react';
import PropTypes from 'prop-types';
import { toMoney,toMoneyBonus } from '@Constants';
import { IconBonus } from '@Icons';
import { gettext } from '@Translate';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';

const LoyaltyBonus = ({ totalLoyaltyBonus }) => (
  <Tippy
    theme="orange"
    content={gettext('FLO Parayı sadece “FLO” satıcılı ürünlerde kazanabilirsiniz')}
    placement="bottom-end"
  >
    <div className="loyalty-bonus-container">
      <div className="loyalty-bonus-container__icon">
        <IconBonus />
      </div>
      <div className="loyalty-bonus-container__content">
        {gettext('Alışverişi Tamamla ')}
        <span>
          {toMoneyBonus(totalLoyaltyBonus)}
          {'  '}
          {gettext(' FLO Para Kazan')}!
        </span>
      </div>
      <div className="loyalty-bonus-container__info">
        <i className="icon-info" />
      </div>
    </div>
  </Tippy>
);

LoyaltyBonus.propTypes = {
  totalLoyaltyBonus: PropTypes.number.isRequired,
};

LoyaltyBonus.defaultProps = {
  totalLoyaltyBonus: 0,
};

export default LoyaltyBonus;
