import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Cookie from 'js-cookie';

// ** -------------- ACTıONS ------------------------ **
import {
  setPaymentMethodAction,
  setAxessAction,
  setNumberOfInstallmentsAction,
  setDijitalPaymentMethodAction,
  getOrderSummaryAction,
  setInstallmentAction,
  setCreditCartIdAction,
  setCreditCartNumber,
  setExpirationMonth,
  setExpirationYear,
  setCvcNumber
} from '@Actions';
import AccordionItem from '../AccordionItem';
import {addPaymentInfoEvent} from '@Utils'
import config from '@Config';
const AccordionContainer = ({items, multiple}) => {

  const dispatch = useDispatch();

  const {
    paymentMethod,
    creditCardPlatform,
    hepsiPaySavedCardList,
    savedCardList
  } = useSelector((state) => state.order);
  const { isGlobal } = config;

  const {
    shoppingCart
  } = useSelector((state) => state.cart);
  const [selected, setSelected] = useState(0);
  const [selectedIndex, setSelectedIndex] = useState(isGlobal ? 0 : 'flopay');
  const paymentsWithoutWallet = items.filter((item) => item.code !=='flowallet');

  const onChangePaymentMethod = (item) => {
    if (item?.code !== paymentMethod) {
      dispatch(setPaymentMethodAction(item?.code));
      dispatch(setAxessAction(0));
      dispatch(setNumberOfInstallmentsAction(1));
    }

  };

  const handleClick = async (item, i) => {
    if(item.code === "flopay") {
      if (savedCardList?.length > 0 && savedCardList !== undefined ) {
        await dispatch(setCreditCartIdAction({ name: savedCardList[0].name, id: savedCardList[0].id }));
      }
    } else {
      await dispatch(setCreditCartNumber(''))
      await dispatch(setExpirationMonth(''));
      await dispatch(setExpirationYear(''));
      await dispatch(setCvcNumber(''));
    }
    if(item.code === "flo_hepsipay") {
      await dispatch(setInstallmentAction({ creditCardPlatform: hepsiPaySavedCardList[0]?.name}));
    }
    if(item.code !== "dijital_banka_odemeleri") {
      await dispatch(setDijitalPaymentMethodAction(''));
    }
    if (selected === i) {
      return setSelected(selected);
    }
    setSelected(i);
    setSelectedIndex(item.code);
    onChangePaymentMethod(item);
  };

  useEffect(() => {
    // Teslimat tipi değiştiğinde Accordion Contenti yeniden render edilir
    if (selectedIndex !== paymentMethod) {
      setSelectedIndex(selectedIndex);
    }

   // --- !! local state ler silindi !!---

    // if (paymentMethod === 'flowallet') {
    //   window.events.emit('analytics.gtmWallet', {
    //     eventAction: 'Checkout Add Money',
    //     eventLabel:
    //       useBonus === true
    //         ? purchaseAmountWithBonus
    //         : purchaseAmountWithoutBonus,
    //     event: 'Checkout Add Money',
    //   });
    // }
    if (paymentMethod) {
      addPaymentInfoEvent(paymentMethod, shoppingCart?.shoppingCartItemList)
    }
  }, [paymentMethod]);
  return (
    <div className="Accordion">
      {
        paymentsWithoutWallet.map((item, index) => {
          return (
            <AccordionItem
              key={`accordion-${index.toString()}`}
              itemName={item.title}
              itemContent={item.description}
              onClick={() => handleClick(item, index)}
              isActive={index}
              selected={selected}
              item={item}
              paymentType={selectedIndex}
            />
          );
        })
      }
    </div>
  );
};

export default AccordionContainer
