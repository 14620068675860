import * as React from 'react'
import { memo } from 'react'
const SvgAddressPlus = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='1em'
    height='1em'
    className='address-plus_svg__address_plus-icon'
    viewBox='0 0 28.417 26.944'
    fill='currentColor'
    {...props}
  >
    <g
      fill='none'
      stroke='#f60'
      strokeLinecap='round'
      strokeWidth={2}
      data-name='Group 5624'
    >
      <path d='M14.208 1v24.944' data-name='Line 498' />
      <path d='M27.417 13.472H1' data-name='Line 499' />
    </g>
  </svg>
)
const Memo = memo(SvgAddressPlus)
export default Memo
