const pattern = /(?:\d[ -]*?){13,16}/g;

const luhnCheck = (card) => {
  const num = card.replace(/[^0-9]/g, '');
  const arr = (num)
    .split('')
    .reverse()
    .map((x) => parseInt(x));
  const lastDigit = arr.splice(0, 1)[0];
  let sum = arr.reduce((acc, val, i) => (i % 2 !== 0 ? acc + val : acc + ((val * 2) % 9) || 9), 0);
  sum += lastDigit;
  return sum % 10 === 0;
};

const ValidateLuhn = (arr) => {
  let isCreditCard = false;
  arr.forEach((elem) => {
    const isValid = String(elem).toLowerCase().match(pattern);
    if (isValid && luhnCheck(isValid[0])) {
      isCreditCard = true;
    }
  });
  return isCreditCard;
};

export default ValidateLuhn;
