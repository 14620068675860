const mindboxSetCart = (productList) => {
  const productListItem = [];
  Object.keys(productList).forEach((v) => {
    const element = productList[v];
    productListItem.push({
      product: {
        ids: {
          sneakerbox: element.sku,
        },
      },
      count: element.quantity,
      pricePerItem: element.price,
    });
  });
  mindbox('async', {
    operation: 'Website.SetCart',
    data: {
      productList: productListItem,
    },
  });
};

const mindboxFavoriteList = (productId) => {
  mindbox('async', {
    operation: 'Website.SetFavouritesList',
    data: {
      productList: [{
        product: {
          ids: {
            sneakerbox: productId,
          },
        },
        count: 1,
        pricePerItem: '',
      }],
    },
  });
};

export { mindboxSetCart, mindboxFavoriteList };
