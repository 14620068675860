/* eslint-disable no-param-reassign */
/* eslint-disable import/no-cycle */
import React from 'react';
import {
  createSlice,
  createAsyncThunk,
} from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { ToastfyMessage } from '@Components';
import API from '@API';
import {
  hidePopupAction,
} from '@Actions';
import Cookie from 'js-cookie';

const INITIAL_STATE = {
  hasMoreTransactions: true,
  walletStep: 1,
  walletTabsStep: 1,
  savedCardList: [],
  transactionsData: [],
  bonusExpirationTimeData: null,
  otpStep: 1,
  default_topup_options: [75, 100, 150, 200],
  helpText: null,
  code: '',
  telephone: '',
  barcode: '',
  useBonus: null,
  isBackActive: true,
  isBarcodeStep: false,
  barcodeTime: null,
  fastpriceFreetext: null,
  isPaymentSuccess: null,
  checkQrActive: false,
  bonus_balance: 0,
};

export const getQrAction = createAsyncThunk('wallet/qr', async (arg) => {
  const response = await API.getQr({ ...arg });
  return response.data;
});

export const checkQrAction = createAsyncThunk('wallet/check/qr', async (arg) => {
  const response = await API.checkQr({ ...arg });
  return response.data;
});

export const getInitializeWalletAction = createAsyncThunk('getInitialize', async (arg, { dispatch }) => {
  const response = await API.getInitialize({ ...arg });
  return response.data;
});

export const checkQrComplateAction = createAsyncThunk('wallet/check/qr-complate', async (arg, { dispatch }) => {
  const response = await API.checkQrComplate({ ...arg });
  return response.data;
});

export const rejectQrPaymentAction = createAsyncThunk('wallet/qr/reject', async (arg) => {
  const response = await API.rejectQrPayment({ ...arg });
  return response.data;
});

export const approveQrPaymentAction = createAsyncThunk('wallet/qr/approve', async (arg, { dispatch }) => {
  const response = await API.approveQrPayment({ ...arg });
  dispatch(getBalanceAction());
  return response.data;
});

export const getQrPaymentDetailAction = createAsyncThunk('wallet/qr/detail', async (arg, { dispatch }) => {
  const response = await API.getQrPaymentDetail({ ...arg });
  return response.data;
});

export const getTransactionsAction = createAsyncThunk('wallet/transactions', async (arg, { dispatch }) => {
  const response = await API.getTransactions({ ...arg });
  return response.data;
});

export const getBonusExpirationTimeAction = createAsyncThunk('wallet/bonusExpirationTime', async (arg, { dispatch }) => {
  const response = await API.getBonusExpirationTime({ ...arg });
  return response.data;
});

export const getRefundToCardAction = createAsyncThunk('wallet/refundToCardAction', async (arg, { dispatch }) => {
  const response = await API.getRefundToCard({ ...arg });
  if (response?.status === 200) {
    const { refunds } = response.data;
    toast.error(ToastfyMessage({
      text: response.data?.title,
      message: response.data?.message,
      description: response.data?.description,
      type: response.data?.type,
      refunds,
    }), {
      autoClose: false,
      onClose: ({ uid }) => {
        dispatch(getBalanceAction());
        dispatch(getTransactionsAction({ page: 1 }));
      },
    });
  }
  return response.data;
});
export const getRefundToCardPreviewAction = createAsyncThunk('wallet/refundToCardPreviewAction', async (arg, { dispatch }) => {
  const response = await API.getRefundToCardPreview({ ...arg });
  if (response?.data.success === false) {
    toast.error(ToastfyMessage({
      text: response.data?.title,
      message: response.data?.message,
      description: response.data?.description,
      type: response.data?.type,
    }), { autoClose: 3000 });
  }
  if (response?.data?.status?.code === 200) {
    const { refunds } = response?.data;
    let refundItemTotal = 0;
    response.data.refunds?.map((item, index) => (
      refundItemTotal += item?.total
    ));
    toast.error(ToastfyMessage({
      text: response.data?.title,
      message: response.data?.message,
      description: response.data?.description,
      type: response.data?.type,
      refunds,
      callback: async () => {
        toast.dismiss();
        dispatch(getRefundToCardAction({ refundAmount: parseFloat(refundItemTotal) }));
      },
    }), { autoClose: false });
  }

  return response.data;
});

export const getRefundToIbanAction = createAsyncThunk('wallet/refundToIbanAction', async (arg, { dispatch }) => {
  const response = await API.getRefundToIban({ ...arg });
  if (response?.status === 200) {
    const { refunds } = response.data;
    toast.error(ToastfyMessage({
      text: response.data?.title,
      message: response.data?.message,
      description: response.data?.description,
      type: response.data?.type,
      refunds,
    }), {
      autoClose: false,
      onClose: ({ uid }) => {
        dispatch(getBalanceAction());
        dispatch(getTransactionsAction({ page: 1 }));
      },
    });
  }
  return response.data;
});

export const getSavedCardsWalletAction = createAsyncThunk('wallet/getSavedCards', async (arg, { dispatch }) => {
  const response = await API.getSavedCards({ ...arg });
  return response.data;
});

// {"success":true,"message":"success","usable_balance_with_bonus":0,"usable_balance_without_bonus":0,"credit_balance":0,"cash_balance":0,"bonus_balance":0,"max_bonus_percentage":2,"default_bonus_percentage":1,"max_bonus_amount":1250,"bonus_expire_day":365,"max_active_amount":1250,"bonus_refund_expire_day":30,"min_load_money_amount":5,"max_load_money_amount":1250,"min_refund_amount_cc":50,"max_refund_amount_cc":3000,"min_refund_amount_iban":50,"max_refund_amount_iban":3000,"monthly_max_load_money_amount":10000,"status":{"code":200,"message":"OK"}}
export const getBalanceAction = createAsyncThunk('wallet/balance', async (arg, { dispatch }) => {
  const response = await API.getBalance({ ...arg });
  return { ...response.data, response: arg };
});

export const walletTopupAction = createAsyncThunk('wallet/topup', async (arg, { dispatch }) => {
  const response = await API.walletTopup({ ...arg });

  dispatch(getBalanceAction());
  dispatch(getTransactionsAction({ page: 1 }));
  return response.data;
});

export const sendActivationOtpAction = createAsyncThunk('wallet/activate/send', async (arg, { dispatch }) => {
  const response = await API.sendActivationOtp({ ...arg });
  return { ...response.data, response: arg };
});

export const validateActivationOtpAction = createAsyncThunk('wallet/activate/validate', async (arg, { dispatch }) => {
  const response = await API.validateActivationOtp({ ...arg });
  // {"status":{"code":200,"message":"OK"}}
  if (response?.data?.status?.code === 200) {
    dispatch(getBalanceAction());
    dispatch(getTransactionsAction({ page: 1 }));
    await dispatch(hidePopupAction());
  }
  return { ...response.data, response: arg };
});

export const getChequeAction = createAsyncThunk('/v1/wallet/topupcheque', async (arg, { dispatch }) => {
  const response = await API.walletCheque({ ...arg });
  return { ...response.data, response: arg };
});

/*
{
  '2': {
    code: 'flowallet',
    title: 'Flo Cüzdan',
    savedCardActive: false,
    storeCreditAmount: 0,
    threeDSActive: false,
    customText: '',
    walletShow: true,
    bonusBalance: '0.00',
    isPayableWithBonus: true,
    purchaseAmountWithBonus: '154.98',
    bonusAmount: '1.55',
    isPayableWithoutBonus: false,
    purchaseAmountWithoutBonus: '154.98'
  }
}
*/
const slice = createSlice({
  name: 'wallet',
  initialState: INITIAL_STATE,
  reducers: {
    setFastpriceFreetext: (state, action) => {
      state.fastpriceFreetext = action.payload;
    },
    setCreditCartId: (state, action) => {
      state.creditCartId = action.payload;
    },
    set3DPopupWalletAction: (state, action) => {
      state.isOpenIframe = true;
      state.iframe = action.payload;
    },
    close3DPopupWalletAction: (state, action) => {
      state.isOpenIframe = false;
      state.iframe = null;
    },
    setCreditCardPlatform: (state, action) => {
      state.creditCardPlatform = action.payload;
    },
    setWalletActive: (state, action) => {
      state.active = action.payload;
    },
    openOtpSendCode: (state, action) => {
      state.isOpenActivationOtp = action.payload;
    },
    setWalletAction: (state, action) => {
      state.walletStep = action.payload;
    },
    setWalletTabsAction: (state, action) => {
      state.walletTabsStep = action.payload;
    },
    setSavedCardsAction: (state, action) => {
      state.savedCardList = action.payload;
    },
    setTelephoneWalletAction: (state, action) => {
      state.telephone = action.payload;
    },
    setCodeWalletAction: (state, action) => {
      state.code = action.payload;
    },
    setBarcodeCode: (state, action) => {
      state.barcode = action.payload;
    },
    setUseBonusQrAction: (state, action) => {
      state.useBonus = action.payload;
    },
    isBackActiveAction: (state, action) => {
      state.isBackActive = action.payload;
    },
    isBarcodeStepAction: (state, action) => {
      state.isBarcodeStep = action.payload;
    },
    setBarcodeTime: (state, action) => {
      state.barcodeTime = action.payload;
    },
    setIsPayment: (state, action) => {
      state.isPaymentSuccess = action.payload;
    },
  },
  extraReducers: (builder) => {
    // walletTopupAction
    builder.addCase(walletTopupAction.pending, (state, action) => {
      state.topupLoading = true;
      state.topupRequestId = action.meta.requestId;
    });
    builder.addCase(walletTopupAction.fulfilled, (state, { payload }) => {
      const {
        success, message, title, description, type, error_description, redirect, result,
      } = payload;
      if (success) {
        if (redirect) {
          state.isOpenIframe = true;
          state.isOpenIframe = true;
          state.iframe = redirect;
        } else if (state.isBarcodeStep !== true) {
          state.fastpriceFreetext = state.default_topup_options !== undefined ? state.default_topup_options[0] : '';
        }
        if (document.getElementById('wallet-amount') !== null) {
          document.getElementById('wallet-amount').value = '';
        }
        if (message) {
          toast.dismiss();
          toast.error(ToastfyMessage({ text: message }));
        }
      } else {
        toast.dismiss();
        toast.error(ToastfyMessage({
          text: title, message, description, type,
        }));
      }

      state.topupLoading = false;
      state.topupRequestId = null;
    });
    builder.addCase(walletTopupAction.rejected, (state, action) => {
      state.topupLoading = false;
      state.topupRequestId = null;
    });

    /* get qr action */
    builder.addCase(getQrAction.pending, (state, action) => {
      state.qrLoading = true;
      state.qrRequestId = action.meta.requestId;
    });
    builder.addCase(getQrAction.fulfilled, (state, { payload }) => {
      const {
        success, message, title, data,
      } = payload;
      if (success) {
        state.barcode = data?.barcode;
      } else {
        toast.dismiss();
        toast.error(ToastfyMessage({ message }));
      }
      state.qrLoading = false;
      state.qrRequestId = null;
    });
    builder.addCase(getQrAction.rejected, (state, action) => {
      state.qrLoading = false;
      state.qrRequestId = null;
    });

    /* get store action */
    builder.addCase(checkQrAction.pending, (state, action) => {
      state.checkQrLoading = true;
      state.checkQrRequestId = action.meta.requestId;
    });
    builder.addCase(checkQrAction.fulfilled, (state, { payload }) => {
      const { success, status, continue_check } = payload;
      if (continue_check === false && success === false) {
        toast.dismiss();
        toast.error(ToastfyMessage({ message: status?.message }));
      }
      state.checkQrLoading = false;
      state.checkQrRequestId = null;
    });
    builder.addCase(checkQrAction.rejected, (state, action) => {
      state.checkQrLoading = false;
      state.checkQrRequestId = null;
    });

    /* get reject action */
    builder.addCase(rejectQrPaymentAction.pending, (state, action) => {
      state.rejectQrLoading = true;
      state.rejectQrRequestId = action.meta.requestId;
    });
    builder.addCase(rejectQrPaymentAction.fulfilled, (state, { payload }) => {
      const { success, status } = payload;
      if (success && status?.message) {
        toast.dismiss();
        toast.error(ToastfyMessage({
          text: 'Ödeme İptali',
          description: status?.message,
          type: 'reject_success',
          callback: async () => {
            toast.dismiss();
          },
        }), {
          autoClose: false,
        });
      } else if (status?.message) {
        toast.dismiss();
        toast.error(ToastfyMessage({ message: status?.message }));
      }
      state.rejectQrLoading = false;
      state.rejectQrRequestId = null;
    });
    builder.addCase(rejectQrPaymentAction.rejected, (state, action) => {
      state.rejectQrLoading = false;
      state.rejectQrRequestId = null;
    });

    // approve qr payment
    builder.addCase(approveQrPaymentAction.pending, (state, action) => {
      state.approveQrLoading = true;
      state.approveQrRequestId = action.meta.requestId;
    });
    builder.addCase(approveQrPaymentAction.fulfilled, (state, { payload }) => {
      const { status, success } = payload;
      if (success !== true) {
        toast.dismiss();
        toast.error(ToastfyMessage({ message: status?.message }));
      }
      state.approveQrLoading = false;
      state.approveQrRequestId = null;
    });
    builder.addCase(approveQrPaymentAction.rejected, (state) => {
      state.approveQrLoading = false;
      state.approveQrRequestId = null;
    });

    // qr payment detail
    builder.addCase(getQrPaymentDetailAction.pending, (state, action) => {
      state.qrPaymentDetailLoading = true;
      state.qrPaymentDetailRequestId = action.meta.requestId;
    });
    builder.addCase(getQrPaymentDetailAction.fulfilled, (state, { payload }) => {
      const { status, success } = payload;
      if (success !== true) {
        toast.dismiss();
        toast.error(ToastfyMessage({ message: status?.message }));
      }
      state.qrPaymentDetailLoading = false;
      state.qrPaymentDetailRequestId = null;
    });
    builder.addCase(getQrPaymentDetailAction.rejected, (state) => {
      state.qrPaymentDetailLoading = false;
      state.qrPaymentDetailRequestId = null;
    });

    /* check qr payment action */
    builder.addCase(checkQrComplateAction.pending, (state, action) => {
      state.qrComplateLoading = true;
      state.qrComplateRequestId = action.meta.requestId;
    });
    builder.addCase(checkQrComplateAction.fulfilled, (state, { payload, dispatch }) => {
      const { success, status, continue_check } = payload;
      if (continue_check === true) {
        state.checkQrActive = true;
      } else {
        state.checkQrActive = false;
      }
      state.qrComplateLoading = false;
      state.qrComplateRequestId = null;
    });
    builder.addCase(checkQrComplateAction.rejected, (state, action) => {
      state.qrComplateLoading = false;
      state.qrComplateRequestId = null;
    });

    // ------------------------------------------------------------------------
    builder.addCase(getInitializeWalletAction.pending, (state, action) => {
      state.initializeWalletLoading = true;
      state.initializeWalletRequestId = action.meta.requestId;
    });
    builder.addCase(getInitializeWalletAction.fulfilled, (state, { payload, dispatch }) => {
      const {
        status,
        geniusQrPaymentIsActive,
        QrPaymentIsActiveMobileWeb,
        QrPaymentIsActiveApp,
      } = payload;
      if (status?.code === 200) {
        state.QrPaymentIsActive = geniusQrPaymentIsActive;
        state.QrPaymentIsActiveMobileWeb = QrPaymentIsActiveMobileWeb;
        state.QrPaymentIsActiveApp = QrPaymentIsActiveApp;
      }
      state.initializeWalletLoading = false;
      state.initializeWalletRequestId = null;
    });
    builder.addCase(getInitializeWalletAction.rejected, (state, action) => {
      state.initializeWalletLoading = false;
      state.initializeWalletRequestId = null;
    });

    // sendActivationOtpAction
    builder.addCase(sendActivationOtpAction.pending, (state, action) => {
      state.sendActivationOtpLoading = true;
      state.sendActivationOtpRequestId = action.meta.requestId;
    });

    builder.addCase(sendActivationOtpAction.fulfilled, (state, { payload }) => {
      const { status, otp_key } = payload;
      // {"status":{"code":200,"message":"OK"},"otp_key":"cd1aee51005935e","validation_method":"phone","validation_value":"+90*****21","sms_activation":true,"time":120}
      if (status?.code === 200) {
        state.otpStep = 2;
        state.walletStep = 4;
        state.otp_key = otp_key;
      }

      if (status?.code !== 200 && status?.message) {
        toast.dismiss();
        toast.error(ToastfyMessage({ text: status?.message }));
      }

      state.isOpenActivationOtp = false;
      state.sendActivationOtpLoading = false;
      state.sendActivationOtpRequestId = null;
    });

    builder.addCase(sendActivationOtpAction.rejected, (state) => {
      state.isOpenActivationOtp = false;
      state.sendActivationOtpLoading = false;
      state.sendActivationOtpRequestId = null;
    });

    // validateActivationOtpAction
    builder.addCase(validateActivationOtpAction.pending, (state, action) => {
      state.validateActivationOtpLoading = true;
      state.validateActivationOtpRequestId = action.meta.requestId;
    });

    builder.addCase(validateActivationOtpAction.fulfilled, (state, { payload }) => {
      const { status, otp_key } = payload;
      // {"status":{"code":200,"message":"OK"},"otp_key":"cd1aee51005935e","validation_method":"phone","validation_value":"+90*****21","sms_activation":true,"time":120}
      if (status?.code === 200) {
        state.otpStep = 1;
        state.walletStep = 4;
        window.events.emit('analytics.gtmWallet', {
          eventAction: 'Profile Wallet Success',
          eventLabel: Cookie.get('is_logged_in') !== undefined ? window.dataLayer[0]?.user?.masterId : '',
          event: 'Profile Wallet Success',
        });
      }

      if (status?.code !== 200 && status?.message) {
        state.code = '';
        toast.dismiss();
        toast.error(ToastfyMessage({ text: status?.message }));
      }

      state.isOpenActivationOtp = false;
      state.validateActivationOtpLoading = false;
      state.validateActivationOtpRequestId = null;
    });

    builder.addCase(validateActivationOtpAction.rejected, (state) => {
      state.isOpenActivationOtp = false;
      state.validateActivationOtpLoading = false;
      state.validateActivationOtpRequestId = null;
    });

    // getBalanceAction
    builder.addCase(getBalanceAction.pending, (state, action) => {
      state.balanceLoading = true;
      state.balanceRequestId = action.meta.requestId;
    });

    builder.addCase(getBalanceAction.fulfilled, (state, { payload }) => {
      const {
        status,
        bonus_balance,
        usable_balance_with_bonus,
        min_load_money_amount,
        max_load_money_amount,
        default_bonus_percentage,
        usable_balance_without_bonus,
        min_refund_amount_cc,
        max_refund_amount_cc,
        min_refund_amount_iban,
        max_refund_amount_iban,
        credit_balance,
        cash_balance,
        default_topup_options,
        changeStep = true,
        success,
        cheque_is_active,
      } = payload;

      if (status?.code === 200) {
        if (state.credit_balance < credit_balance) {
          state.isPaymentSuccess = true;
        }
        state.bonus_balance = bonus_balance;
        state.usable_balance_with_bonus = usable_balance_with_bonus;
        state.usable_balance_without_bonus = usable_balance_without_bonus;
        state.min_load_money_amount = min_load_money_amount;
        state.max_load_money_amount = max_load_money_amount;
        state.default_bonus_percentage = default_bonus_percentage;
        state.min_refund_amount_cc = min_refund_amount_cc;
        state.max_refund_amount_cc = max_refund_amount_cc;
        state.min_refund_amount_iban = min_refund_amount_iban;
        state.max_refund_amount_iban = max_refund_amount_iban;
        state.credit_balance = credit_balance;
        state.cheque_is_active = cheque_is_active;
        state.cash_balance = cash_balance;
        state.default_topup_options = default_topup_options;
        if (changeStep) {
          state.walletStep = 4;
        }
      } else if (success === false) {
        toast.error(ToastfyMessage({ text: status?.message }),
          {
            autoClose: false,
          });
      }

      state.balanceLoading = false;
      state.balanceRequestId = null;
    });

    builder.addCase(getBalanceAction.rejected, (state) => {
      state.balanceLoading = false;
      state.balanceRequestId = null;
    });

    // getSavedCardsAction
    builder.addCase(getSavedCardsWalletAction.pending, (state, action) => {
      state.savedCardsLoading = true;
      state.savedCardsRequestId = action.meta.requestId;
    });
    builder.addCase(getSavedCardsWalletAction.fulfilled, (state, { payload }) => {
      const { status, savedCardList } = payload;
      if (status?.code === 200) {
        state.savedCardList = savedCardList;
        if (savedCardList && savedCardList?.length) {
          const savedCardListSort = savedCardList.sort((a, b) => Number(b.isDefault) - Number(a.isDefault));
          state.creditCartId = savedCardListSort[0]?.id;
          state.creditCardPlatform = savedCardListSort[0]?.name;
        }
      } else {
        toast.dismiss();
        toast.error(ToastfyMessage({ text: status?.message }));
      }

      if (status.code === 2072) {
        toast.dismiss();
        toast.error(ToastfyMessage({ text: status?.message }));
      }

      state.savedCardsLoading = false;
      state.savedCardsRequestId = null;
    });
    builder.addCase(getSavedCardsWalletAction.rejected, (state) => {
      state.savedCardsLoading = false;
      state.savedCardsRequestId = null;
    });

    // getRefundToCardAction
    builder.addCase(getRefundToCardAction.pending, (state, action) => {
      state.savedCardsLoading = true;
      state.savedCardsRequestId = action.meta.requestId;
    });
    builder.addCase(getRefundToCardAction.fulfilled, (state, { payload }) => {
      const { status } = payload;
      if (status?.code === 200) {
      }
    });
    builder.addCase(getRefundToCardAction.rejected, (state) => {
      state.savedCardsLoading = false;
      state.savedCardsRequestId = null;
    });

    // getRefundToIbanAction
    builder.addCase(getRefundToIbanAction.pending, (state, action) => {
      state.savedCardsLoading = true;
      state.savedCardsRequestId = action.meta.requestId;
    });
    builder.addCase(getRefundToIbanAction.fulfilled, (state, { payload }) => {
      const { status } = payload;
      if (status?.code === 200) {
      }
    });
    builder.addCase(getRefundToIbanAction.rejected, (state) => {
      state.savedCardsLoading = false;
      state.savedCardsRequestId = null;
    });

    // getRefundToCardPreviewAction
    builder.addCase(getRefundToCardPreviewAction.pending, (state, action) => {
      state.savedCardsLoading = true;
      state.savedCardsRequestId = action.meta.requestId;
    });
    builder.addCase(getRefundToCardPreviewAction.fulfilled, (state, { payload }) => {
      const { status, refunds, message } = payload;
      if (status?.code === 200) {

      }
    });
    builder.addCase(getRefundToCardPreviewAction.rejected, (state) => {
      state.savedCardsLoading = false;
      state.savedCardsRequestId = null;
    });

    // getBonusExpirationTimeAction
    builder.addCase(getBonusExpirationTimeAction.pending, (state, action) => {
      state.savedCardsLoading = true;
      state.savedCardsRequestId = action.meta.requestId;
    });
    builder.addCase(getBonusExpirationTimeAction.fulfilled, (state, { payload }) => {
      const { status, bonuses } = payload;
      if (status?.code === 200) {
        state.bonusExpirationTimeData = bonuses;
      }
    });

    builder.addCase(getBonusExpirationTimeAction.rejected, (state) => {
      state.savedCardsLoading = false;
      state.savedCardsRequestId = null;
    });

    // getTransactionsAction
    builder.addCase(getTransactionsAction.pending, (state, action) => {
      state.savedCardsLoading = true;
      state.savedCardsRequestId = action.meta.requestId;
    });
    builder.addCase(getTransactionsAction.fulfilled, (state, { payload }) => {
      const {
        status, rows, total_count, total_page, current_page,
      } = payload;
      if (status?.code === 200) {
        const trans = [...state.transactionsData];
        current_page > 1 ? state.transactionsData = trans.concat(rows) : state.transactionsData = rows;
        state.transactionsTotalCount = total_count;

        if (current_page === total_page) {
          state.hasMoreTransactions = false;
        }
      }
    });
    builder.addCase(getTransactionsAction.rejected, (state) => {
      state.savedCardsLoading = false;
      state.savedCardsRequestId = null;
    });
  },
});

export default slice.reducer;

export const {
  setFastpriceFreetext,
  setWalletActive,
  setWalletAction,
  setWalletTabsAction,
  setCreditCartId,
  setCreditCardPlatform,
  set3DPopupWalletAction,
  close3DPopupWalletAction,
  setTelephoneWalletAction,
  setCodeWalletAction,
  setUseBonusQrAction,
  isBackActiveAction,
  setIsPayment,
  isBarcodeStepAction,
  setBarcodeTime,
  setBarcodeCode,
} = slice.actions;
