import React from 'react'

const CustomBanner = ({icon, content}) => {
  return (
    <div className="custom-banner-container">
      <div className="custom-banner-container__icon">
        <span className={icon} />
      </div>
      <div className="custom-banner-container__content">
        {content}
      </div>
  </div>
  )
}

CustomBanner.propTypes = {}

export default CustomBanner;