import { useSelector } from 'react-redux';
import Payment from 'payment';
import config from '@Config';
import {
  gettext,
} from '@Translate';

const { currency: configCurrency, locale: configLocale, symbol: configSymbol, phoneCode } = config;

function vergikimlik(kno) {
  if (kno?.length === 10) {
    const v = [];
    const lastDigit = Number(kno.charAt(9));
    for (let i = 0; i < 9; i++) {
      const tmp = (Number(kno.charAt(i)) + (9 - i)) % 10;
      v[i] = (tmp * 2 ** (9 - i)) % 9;
      if (tmp !== 0 && v[i] === 0) v[i] = 9;
    }
    const sum = v.reduce((a, b) => a + b, 0) % 10;
    return (10 - (sum % 10)) % 10 === lastDigit;
  }
  return false;
}
export const formatTcNumber = (TCNO) => {
  let tek = 0;
  let cift = 0;
  let sonuc = 0;
  let TCToplam = 0;
  var i = 0;
  const hatali = [11111111110, 22222222220, 33333333330, 44444444440, 55555555550, 66666666660, 7777777770, 88888888880, 99999999990];

  if (TCNO?.length != 11) return false;
  if (isNaN(TCNO)) return false;
  if (TCNO[0] == 0) return false;

  tek = parseInt(TCNO[0]) + parseInt(TCNO[2]) + parseInt(TCNO[4]) + parseInt(TCNO[6]) + parseInt(TCNO[8]);
  cift = parseInt(TCNO[1]) + parseInt(TCNO[3]) + parseInt(TCNO[5]) + parseInt(TCNO[7]);

  tek *= 7;
  sonuc = Math.abs(tek - cift);
  if (sonuc % 10 != TCNO[9]) return false;

  for (var i = 0; i < 10; i++) {
    TCToplam += parseInt(TCNO[i]);
  }

  if (TCToplam % 10 != TCNO[10]) return false;

  if (hatali.toString().indexOf(TCNO) != -1) return false;

  return true;
}

export const checkTCKNorVergi = (value) => {
  if (value?.length <= 10 && value?.length >= 1) {
    return {
      check: vergikimlik(value),
      message: gettext('Lütfen vergi kimlik numaranızı kontrol ediniz!'),
    };
  } if (value?.length === 11) {
    return {
      check: formatTcNumber(value),
      message: gettext('Lütfen T.C. kimlik numaranızı kontrol ediniz!'),
    };
  }
  return {
    check: false,
    message: gettext('Lütfen bu alanı doldurunuz'),
  };
};

export const checkTCKNo = (value) => {
  if (value?.length === 11) {
    return {
      check: formatTcNumber(value),
      message: gettext('Lütfen T.C. kimlik numaranızı kontrol ediniz!'),
    };
  }
  return {
    check: false,
    message: gettext('Lütfen bu alanı doldurunuz'),
  };
};

export const checkCompany = (value) => {
  if (!value || value?.length < 5) {
    return {
      check: false,
    };
  }

  return {
    check: true,
  };
};

export const phoneControl = (value, phoneCodeDefault = '+90') => {
  if (value) {
    switch (phoneCodeDefault) {
      case '+7':
        return /^([0-9]){10}$/i.test(value.replace(/[^0-9]/g, ''));
        // eslint-disable-next-line no-unreachable
        break;
      default:
        if (Number(value[0]) === 0) {
          return /^(05)(10|11|12|13|14|15|16|17|18|19|30|31|32|33|34|35|36|37|38|39|40|41|42|43|44|45|46|47|48|49|00|01|02|03|04|05|06|07|08|09|50|51|52|53|54|55|56|57|58|59|60|61|62)+([0-9]){7}$/i.test(value.replace(/[^0-9]/g, ''));
        }
        if (Number(value[1]) === 5 || Number(value[0]) === 5) {
          return /^(5)(10|11|12|13|14|15|16|17|18|19|30|31|32|33|34|35|36|37|38|39|40|41|42|43|44|45|46|47|48|49|00|01|02|03|04|05|06|07|08|09|50|51|52|53|54|55|56|57|58|59|60|61|62)+([0-9]){7}$/i.test(value.replace(/[^0-9]/g, ''));
        }
    }
  }
  return false;
};

export const textControl = (value) => (value ? /^(?=^(?!.*(DENEME|TEST|DEMO|deneme|test|demo|[0-9!#$%^&*(),.?\\:{}|<>*]|(\\s{2,}))).*$).*$/.test(value) : false);
export const alphanumericControl = (value) => (value ? /^(?=^(?!.*(DENEME|TEST|DEMO|deneme|test|demo|[!#$%^&*(),.?\\:{}|<>*]|(\\s{2,}))).*$).*$/.test(value) : false);
export const alphanumericSpaceControl = (value) => (value ? /^(?=^(?!.*(DENEME|TEST|DEMO|deneme|test|demo|[/'+=\-_£!#$%^&*(),.?\\:{}\s|<>*]|(\\s{2,}))).*$).*$/.test(value) : false);
export const xmlSpecialControl = (value) => (value ? /^(?!.*([!#$%"'&?\\{}<>]|(\s{2,})))[\s\S]*$/.test(value) : false);

export const passwordControl = (value) => {
  const upperControl = !!/[A-ZÜĞİŞÇÖ]/.exec(value);
  const numberControl = !!/[0-9]/.exec(value);
  let lenghtControl = false;
  let passwordCheck = false;
  value?.length > 7 ? lenghtControl = true : lenghtControl = false;
  upperControl && numberControl && lenghtControl ? passwordCheck = true : passwordCheck = false;
  return {
    check: passwordCheck,
    upperCheck: upperControl,
    numberCheck: numberControl,
    lenghtCheck: lenghtControl,
  };
};

export const titleControl = (title, guest) => {
  if (guest) {
    return !title ? true : false;
  } else {
    return !title ? false : true
  }
}

export const validAddress = (address, guest, isNeighborhoodActive, phoneCodeDefault) => {
  if (
    !address?.phone
    || !titleControl(address?.titleAddress,guest)
    || !phoneControl(address?.phone, phoneCodeDefault)
    || !address?.lastName
    || !textControl(address?.lastName)
    || !address?.firstName
    || !textControl(address?.firstName)
    || !address?.cityId
    || !address?.cityName
    || !address?.countyName
    || (isNeighborhoodActive && (!address?.neighborhoodId || !address?.neighborhoodName))
    || !xmlSpecialControl(address?.address)
  ) {
    return false;
  }
  if (address?.lastName?.length < 2 || address?.firstName?.length < 2) {
    return false;
  }
  if (!address?.address || address?.address?.length <= 10) {
    return false;
  }
  // eposta validasyonu eklenecek
  return true;
};

export const validCorporateInvoice = (corporateInvoice, control) => {
  if (control && (!checkTCKNorVergi(corporateInvoice?.taxNumber).check
    || !corporateInvoice?.taxOffice
    || !checkCompany(corporateInvoice?.companyName).check)) {
    return false;
  }
  return true;
};

export const validPickupSummary = (pickupSummary) => {
  if (pickupSummary?.storeId
    && pickupSummary?.phone
    && pickupSummary?.lastName
    && pickupSummary?.firstName) {
    return true;
  }
  return false;
};

export const isMobile = () => window.innerWidth <= 992;

export const isTablet = () => {
  const userAgent = navigator.userAgent.toLowerCase();
  return /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(userAgent);
};

export const useDebounce = (func, wait, immediate) => {
  let timeout;
  return function () {
    const context = this; const
      // eslint-disable-next-line prefer-rest-params
      args = arguments;
    const later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    const callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
};

export const logEvent = (name, params) => {
  if (!name) {
    return;
  }

  try {
    if (window.AnalyticsWebInterface) {
      // Call Android interface
      window.AnalyticsWebInterface.logEvent(name, JSON.stringify(params));
    } else if (window.webkit
        && window.webkit.messageHandlers
        && window.webkit.messageHandlers.firebase) {
      // Call iOS interface
      const message = {
        command: 'logEvent',
        name,
        parameters: params,
      };
      window.webkit.messageHandlers.firebase.postMessage(message);
    } else {
      // No Android or iOS interface found
      console.log('No native APIs found.');
    }
  } catch (error) {

  }
};

export const setUserProperty = (name, value) => {
  if (!name || !value) {
    return;
  }

  if (window.AnalyticsWebInterface) {
    // Call Android interface
    window.AnalyticsWebInterface.setUserProperty(name, value);
  } else if (window.webkit
      && window.webkit.messageHandlers
      && window.webkit.messageHandlers.firebase) {
    // Call iOS interface
    const message = {
      command: 'setUserProperty',
      name,
      value,
    };
    window.webkit.messageHandlers.firebase.postMessage(message);
  } else {
    // No Android or iOS interface found
    console.log('No native APIs found.');
  }
};

export const helper = {
  getKey(key) {
    return `layerData.${key}`;
  },

  set(key, value, session) {
    if (session) {
      window.sessionStorage.setItem(this.getKey(key), JSON.stringify(value));
    } else {
      window.localStorage.setItem(this.getKey(key), JSON.stringify(value));
    }
  },

  get(key, session) {
    if (session) {
      return JSON.parse(window.sessionStorage.getItem(this.getKey(key)));
    }
    return JSON.parse(window.localStorage.getItem(this.getKey(key)));
  },

  remove(key) {
    return window.localStorage.removeItem(this.getKey(key));
  },
};

function clearNumber(value = '') {
  return value.replace(/\D+/g, '');
}

export function formatCreditCardNumber(value) {
  if (!value) {
    return value;
  }

  const issuer = Payment?.fns?.cardType(value);
  const clearValue = clearNumber(value);
  let nextValue;

  switch (issuer) {
    case 'amex':
      nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
        4,
        10,
      )} ${clearValue.slice(10, 15)}`;
      break;
    case 'dinersclub':
      nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
        4,
        10,
      )} ${clearValue.slice(10, 14)}`;
      break;
    default:
      nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
        4,
        8,
      )} ${clearValue.slice(8, 12)} ${clearValue.slice(12, 19)}`;
      break;
  }

  return nextValue.trim();
}

export function formatCVC(value, number) {
  const clearValue = clearNumber(value);
  let maxLength = 4;

  if (number) {
    const issuer = Payment?.fns?.cardType(number);
    maxLength = issuer === 'amex' ? 4 : 3;
  }

  return clearValue.slice(0, maxLength);
}

export function formatExpirationDate(value) {
  const clearValue = clearNumber(value);

  if (clearValue?.length >= 3) {
    return `${clearValue.slice(0, 2)}/${clearValue.slice(2, 4)}`;
  }

  return clearValue;
}

export const toMoney = (function () {
  const locale = configLocale || 'tr';
  let currency = configCurrency || 'TRY';
  const symbol = configSymbol || false;

  if (currency === 'TL') {
    currency = 'TRY';
  }

  const options = {
    style: 'currency',
    currencyDisplay: symbol ? 'symbol' : 'code',
    currency,
  };

  const l10nTRY = new Intl.NumberFormat(locale, options);

  return function (price) {
    let formated = l10nTRY.format(price);

    if (currency === 'TRY') {
      formated = formated.replace('TRY', '').trim();
      formated += ' TL';
    }

    return formated;
  };
}());

export const toMoneyBonus = (function () {
  const locale = configLocale || 'tr';
  let currency = configCurrency || 'TRY';
  const symbol = configSymbol || false;

  if (currency === 'TL') {
    currency = 'TRY';
  }

  const options = {
    style: 'currency',
    currencyDisplay: symbol ? 'symbol' : 'code',
    currency,
  };

  const l10nTRY = new Intl.NumberFormat(locale, options);

  return function (price) {
    let formated = l10nTRY.format(price);

    if (currency === 'TRY') {
      formated = formated.replace('TRY', '').trim();
    }

    return formated;
  };
}());

export const toMoneyInt = (function () {
  const locale = configLocale || 'tr';
  const l10nTRY = new Intl.NumberFormat(locale);

  return function (price) {
    const formated = l10nTRY.format(price);
    return formated;
  };
}());

export const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

const getTimeSplit = (value) => (value.split(' ')[0]).split('-');

export const timeframe = (start, end) => {
  if (!start || !end) {
    return '';
  }
  let Month = [];
  if (configLocale == 'ru') {
    Month = ['Января', 'Февраля', 'Марта', 'Апреля', 'Мая', 'Июня', 'Июль', 'Августа', 'Сентября', 'Октября', 'Ноября', 'Декабря'];
  } else if (configLocale == 'kk') {
    Month = ['Қаңтар', 'Ақпан', 'Сапта жүру', 'Сәуір', 'Мамыр', 'Маусым', 'Шілде', 'Тамыз', 'Қыркүйек', 'Қазан', 'Қараша', 'Желтоқсан'];
  } else if (configLocale == 'en') {
    Month = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
  } else {
    Month = ['Ocak', 'Şubat', 'Mart', 'Nisan', 'Mayıs', 'Haziran', 'Temmuz', 'Ağustos', 'Eylül', 'Ekim', 'Kasım', 'Aralık'];
  }
  const startTime = getTimeSplit(start);
  const endTime = getTimeSplit(end);
  const $startYear = Number(startTime[2]);
  const $startMonth = Number(startTime[1]);
  const $startDay = Number(startTime[0]);
  const $endYear = Number(endTime[2]);
  const $endMonth = Number(endTime[1]);
  const $endDay = Number(endTime[0]);

  if (start === end) {
    return `${$startDay} ${Month[$startMonth - 1]}`;
  }

  if ($startMonth === $endMonth) {
    return `${$startDay} - ${$endDay} ${Month[$startMonth - 1]}`;
  }

  if ($startMonth !== $endMonth && $startYear === $endYear) {
    return `${$startDay} ${Month[$startMonth - 1]} - ${$endDay} ${Month[$endMonth - 1]}`;
  }

  if ($startYear < $endYear) {
    return `${$startDay} ${Month[$startMonth - 1]} ${$startYear} - ${$endDay} ${Month[$endMonth - 1]} ${$endYear}`;
  }

  return '';
};

export const getItems = (items, level1, level2) => {
  const result = {};

  items?.forEach((item) => {
    if (Array?.isArray(level1)) {
      if (item[level1[0]][level1[1]]) {
        if (result[`${item[level1[0]][level1[1]]}_${item[level2]}`]) {
          result[`${item[level1[0]][level1[1]]}_${item[level2]}`].push(item);
        } else {
          result[`${item[level1[0]][level1[1]]}_${item[level2]}`] = [item];
        }
      }
      if (!item[level1[0]][level1[1]] && result?.others) {
        result.others?.push(item);
      }
      if (!item[level1[0]][level1[1]] && !result?.others) {
        result.others = [item];
      }
    } else if (item[level1] && level2) {
      if (item[level1][level2]) {
        if (result[item[level1][level2]]) {
          result[item[level1][level2]].push(item);
        } else {
          result[item[level1][level2]] = [item];
        }
      } else if (result?.others) {
          result.others?.push(item);
      } else {
        result.others = [item];
      }
    } else if (item[level1]) {
      if (result[item[level1]]) {
        result[item[level1]].push(item);
      } else {
        result[item[level1]] = [item];
      }
    } else if (result?.others) {
        result.others?.push(item);
    } else {
      result.others = [item];
    }
  });

  return result;
};

export const getOptionNumbers = (val) => {
  const array = [];
  const value = val >= 7 ? 7 : val;
  for (let i = 1; i <= value; i++) {
    array.push(i);
  }
  return array;
};

export const hasMerchant = (products) => {
  const item = products?.filter((product) => product?.merchant && product?.merchant?.id !== 10000);
  if (item?.length) {
    return true;
  }
  return false;
};

const reducer = (accumulator, item) => ((item?.quantity * (item?.secondPrice || item?.firstPrice)) + accumulator);
export const getTotalPriceForMerchant = (elements) => elements.reduce(reducer, 0);
export const getFreeShippingLimit = (elements) => {
  const freeShippingLimit = elements?.map((elem) => elem.merchant?.freeShippingLimit);
  return freeShippingLimit[0];
};
export const getPriceForShippingLimit = (elements) => {
  const priceForShippingLimit = elements?.map((elem) => elem.merchant?.price_for_free_shipping);
  return priceForShippingLimit[0];
};

export const getMerchantIsShow = (elements) => {
  const merchantIsShow = elements?.map((elem) => elem.merchant?.is_show);
  return merchantIsShow[0];
};

export const skuLengthControl = (sku) => {
  const len = String(sku)?.length;
  if (len < 18) {
    let prefix = '';
    for (let i = 0; i < (18 - len); i++) {
      prefix += '0';
    }
    return prefix + sku;
  }
  return sku;
};

export const storage = {

  getKey(key) {
    return `layerData.${key}`;
  },

  set(key, value, session) {
    if (session) {
      window.sessionStorage.setItem(this.getKey(key), JSON.stringify(value));
    } else {
      window.localStorage.setItem(this.getKey(key), JSON.stringify(value));
    }
  },

  delete(key, session) {
    if (session) {
      window.sessionStorage.removeItem(this.getKey(key));
    } else {
      window.localStorage.removeItem(this.getKey(key));
    }
  },

  get(key, session) {
    try {
      if (session) {
        if (window.sessionStorage.getItem(this.getKey(key))) {
          return JSON.parse(window.sessionStorage.getItem(this.getKey(key)));
        }
        return null;
      }
      return JSON.parse(window.localStorage.getItem(this.getKey(key)));
    } catch (error) {
      return null;
    }
  },
};
