import React from 'react';
import PropTypes from 'prop-types';
import 'tippy.js/dist/tippy.css';
import {
  gettext,
} from '@Translate';
import config from '@Config';

// icons
import { IconGreenCheck } from '@Icons';

const RenderSwitch = ({ merchantShipping, successPage, priceForFreeShipping }) => {
  const { project } = config;
  if (!successPage) {
    const isFastDelivery = merchantShipping?.info?.filter(((item) => item.shippingType === 'SDD')).length > 0;
    const isStandartDelivery = merchantShipping?.info?.filter(((item) => item.shippingType === 'SD')).length > 0;

    if (!isFastDelivery && priceForFreeShipping === 0) {
      return (
        <div className="merchant-product-card__header--shipping-wrapper">
          <div className={`merchant-product-card__header--shipping-wrapper-info free-info ${project === 'sneakerbox' ? 'sneakerbox-free-info' : ''}`}>
            <IconGreenCheck />
            <strong>{gettext('Kargo Bedava')}</strong>
          </div>
        </div>
      );
    }
    if (isFastDelivery && !isStandartDelivery && priceForFreeShipping === 0) {
      return (
        <div className="merchant-product-card__header--shipping-wrapper">
          <div className={`merchant-product-card__header--shipping-wrapper-info free-info ${project === 'sneakerbox' ? 'sneakerbox-free-info' : ''}`}>
            <IconGreenCheck />
            <strong>{gettext('Standart Teslimat Kargo Bedava')}</strong>
          </div>
        </div>
      );
    }
  }
  return ('');
};

RenderSwitch.defaultProps = {
  successPage: false,
  priceForFreeShipping: 600,
};

RenderSwitch.propTypes = {
  successPage: PropTypes.bool,
  priceForFreeShipping: PropTypes.number,
};

export default RenderSwitch;
