import React from 'react';
import { ReturnDeliveryIcon } from '@Icons';
import { gettext } from '@Translate';

const ReturnDelivery = () => (
  <div className="return-delivery" style={{ display: 'none' }}>
    <ReturnDeliveryIcon />
    <div className="return-delivery-content">
      <span className="return-delivery-content__head">
        {gettext('30 Gün İçinde İade Avantajı')}
      </span>
      <span>
        {gettext('Binlerce üründe 30 gün boyunca Kolay İade hakkı.')}
      </span>
    </div>
  </div>
);

export default ReturnDelivery;
