import * as React from 'react'
import { memo } from 'react'
const SvgCart = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='1em'
    height='1em'
    viewBox='0 0 28 25'
    fill='currentColor'
    {...props}
  >
    <g fill='#000' fillRule='nonzero'>
      <path d='M27.92 3.695a.48.48 0 0 0-.396-.21H7.418a.4.4 0 0 0-.075.015l-.538-1.345A2.5 2.5 0 0 0 4.427 0H.48a.479.479 0 0 0 0 .957h3.97c.114 0 1.058 0 1.428 1.433L9.46 15.97c.022.094.521 2.352 2.383 2.352h11.367a.479.479 0 0 0 0-.957H11.846c-1.086 0-1.444-1.581-1.45-1.612l-.433-1.738H23.69a.48.48 0 0 0 .444-.302l3.83-9.571a.48.48 0 0 0-.046-.447m-4.55 9.362H9.812a.4.4 0 0 0-.083.016l-2.15-8.63h19.233zM12.442 24.064a2.632 2.632 0 1 1 0-5.263 2.632 2.632 0 0 1 0 5.263m0-4.308a1.675 1.675 0 1 0 0 3.346 1.675 1.675 0 0 0 0-3.344zM21.535 24.064a2.632 2.632 0 1 1 0-5.263 2.632 2.632 0 0 1 0 5.263m0-4.308a1.675 1.675 0 1 0 0 3.346 1.675 1.675 0 0 0 0-3.344z' />
    </g>
  </svg>
)
const Memo = memo(SvgCart)
export default Memo
