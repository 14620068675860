import Analytics from 'analytics';
import googleTagManager from 'analytics-plugin-google-tag-manager';
import config from '@Config';

window.dataLayer = window.dataLayer || [];

function getCookie(name) {
  const v = document.cookie.match(`(^|;) ?${name}=([^;]*)(;|$)`);
  return v ? v[2] : null;
}

function setCookie(name, value, days) {
  const d = new Date();
  d.setTime(d.getTime() + 24 * 60 * 60 * 1000 * days);
  document.cookie = `${name}=${value};path=/;expires=${d.toGMTString()}`;
}

if (!getCookie('get_user_id')) {
  setCookie('get_user_id', [...Array(10)]?.map(() => (~~(Math.random() * 36)).toString(36)).join('') + new Date().getUTCMilliseconds(), 1);
}

const getDeviceType = () => {
  const ua = navigator.userAgent;
  if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
    return 't';
  }
  if (
    /Mobile|iP(hone|od|ad)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
      ua,
    )
  ) {
    return 'm';
  }
  return 'd';
};

const getPageInfo = ({ pageType }) => {
  let userInformation = {
    session_id: getCookie('get_user_id'),
    server_name: window.SERVER_NAME,
    user_logged_in: false,
    user_member_status: 'not',
    type: 'guest',
  };

  if (GtmDataLayer.get('customer')) {
    userInformation = GtmDataLayer.get('customer');
  }

  const page = {
    page_language: window.PAGE_LANGUAGE, // language of current page
    // 'page_type': 'product_detail', //
    // 'previous_page_type': 'search', // where the user come from to the current page?
    // 'page_tab_type': 'new', // new or same
    // 'page_navigation': 'back', // back, forward, reload
  };

  if (pageType) {
    page.page_type = pageType;
  }

  if (typeof window.page_product_MP !== 'undefined') {
    page.page_product_MP = window.page_product_MP;
    page.page_product_merchant = window.page_product_merchant;
  }

  if (performance?.navigation) {
    if (performance.navigation?.type === 0) {
      page.page_navigation = 'NAVIGATE'; // back, forward, reload
    } else if (performance.navigation.type === 1) {
      page.page_navigation = 'RELOAD'; // back, forward, reload
    } else if (performance.navigation.type === 2) {
      page.page_navigation = 'BACK_FORWARD'; // back, forward, reload
    } else if (performance.navigation.type === 255) {
      page.page_navigation = 'RESERVED'; // back, forward, reload
    }
  }

  if (window.PAGE_ERROR) {
    page.page_error = window.PAGE_ERROR; // the error notification get on page
  }

  page.version = 'redesign-v1'; // tasarım versiyon bilgisi - redesign

  return {
    page,
    user: userInformation,
    device: {
      device_platform: getCookie('platform') ? getCookie('platform') : getDeviceType() === 'd' ? 'WEB' : 'MOBILEWEB',
      device_type: getDeviceType(), // m,d,t (mobile,desktop,tablet)
    },
  };
}

window.getPageInfo = getPageInfo;

if (window?.orderSuccessDetail) {
  const OM = {
    tid: window.orderSuccessDetail?.info?.increment_id,
    medium: getDeviceType() === 'd' ? 'Web' : 'Mobile',
    coupon: window.orderSuccessDetail.info.coupon_code ? 1 : 0,
  };

  if (window?.orderSuccessDetail?.info?.shipping_method === 'ClickCollect_ClickCollect') {
    OM.storeCity = window.orderSuccessDetail?.shipping?.region;
    OM.storeName = window.orderSuccessDetail?.shipping?.street;
  }

  window.dataLayer.push({
    OM,
  });
}

window.dataLayer.push(getPageInfo({ pageType: window.pageType }));
const { gtmAccountId } = config;
// export analytics instance for use in the app
export default Analytics({
  app: 'flo',
  // debug: process.env.NODE_ENV === 'development',
  debug: false,
  plugins: [
    googleTagManager({
      containerId: gtmAccountId,
    }),
  ],
});
