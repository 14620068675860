import * as React from 'react'
import { memo } from 'react'
const SvgBrokenTimeSquare = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='1em'
    height='1em'
    data-name='Iconly/Broken/Time Square'
    viewBox='0 0 25.499 25.499'
    fill='currentColor'
    {...props}
  >
    <path
      fill='#f60'
      d='M7.961 25.5C3.05 25.5 0 22.449 0 17.534a.975.975 0 0 1 1.951 0c0 3.868 2.131 6.01 6.022 6.01h9.552c3.881 0 6.022-2.131 6.022-6.01V7.972c0-3.88-2.129-6.022-6.022-6.022H7.972c-3.88 0-6.022 2.142-6.022 6.022v2.972a.964.964 0 0 1-.964.964H.973A.973.973 0 0 1 0 10.933V7.972C0 3.051 3.073 0 7.974 0h9.552C22.449 0 25.5 3.051 25.5 7.976v9.562c0 4.911-3.05 7.961-7.972 7.961Zm8.622-9.355-4.323-2.581a.96.96 0 0 1-.467-.823V7.183a.956.956 0 1 1 1.913 0V12.2l3.858 2.3a.957.957 0 0 1-.981 1.644Z'
      data-name='Time Square'
    />
  </svg>
)
const Memo = memo(SvgBrokenTimeSquare)
export default Memo
