import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useOutsideClick } from '@Hooks';
import { SavedCardDots } from '@Icons';

const CustomPopover = ({children, rootClassName, triggerClassName, contentClassName, close}) => {
  const [show, setShow] = useState(false);

  const handleClickOutside = () => {
    setShow(false);
  };
  useEffect(() => {
    if (close) {
      setShow(!close);
    }
  }, [close]);

  const ref = useOutsideClick(handleClickOutside);
  return (
    <div className={`custom-popover ${rootClassName} ${show ? 'show' : ''}`} ref={ref}>
      <div
        className={`custom-popover__trigger ${triggerClassName}`}
        onClick={() => {
          setShow(!show);
        }}
      >
        <SavedCardDots />
      </div>
      <div className={`custom-popover__content ${contentClassName}`}>{children}</div>
    </div>
  );
};

CustomPopover.defaultProps = {
  rootClassName: '',
  triggerClassName: '',
  contentClassName: '',
};

CustomPopover.propTypes = {
  rootClassName: PropTypes.string,
  triggerClassName: PropTypes.string,
  contentClassName: PropTypes.string,
};
export default CustomPopover;
