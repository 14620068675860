import * as React from 'react'
import { memo } from 'react'
const SvgIconClose = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='1em'
    height='1em'
    viewBox='0 0 13.344 13.519'
    fill='currentColor'
    {...props}
  >
    <path d='m12.142 13.514-5.47-5.47-5.414 5.419L0 12.205l5.414-5.419-5.41-5.533L1.258 0l5.414 5.414L12.086 0l1.258 1.258L7.93 6.672l5.365 5.365Z' />
  </svg>
)
const Memo = memo(SvgIconClose)
export default Memo
