/* eslint-disable react/prop-types */
import React from 'react';
import {
  gettext,
} from '@Translate';
import PropTypes from 'prop-types';
import { IconSecure } from '@Icons';

const RenderHeading = ({ step, cartCount }) => (
  <>
    {step === 1 && (
    <>
      <span key="step-1" className="step-1">
        {gettext('Sepetim')}
      </span>
      {cartCount > 0 && (
      <span className="cart-count">
        {' '}
        (
        {cartCount}
        {' '}
        {gettext('ürün')}
        )
        {' '}
      </span>
      )}
    </>
    )}
    {step === 'store-step-1' && (
    <>
      <span key="store-step-1" className="store-step-1">
        {gettext('Mağaza Sepetim')}
      </span>
      {cartCount > 0 && (
      <span className="cart-count">
        {' '}
        (
        {cartCount}
        {' '}
        {gettext('ürün')}
        )
        {' '}
      </span>
      )}
    </>
    )}
    {step === 'store-step-3' && (
    <>
      <span key="store-step-3" className="store-step-3">
        {gettext('QR ile Siparişi Tamamla')}
      </span>
    </>
    )}
    {step === 'store-step-4' && (
    <>
      <span key="store-step-4" className="step-1">
        {gettext('Sipariş İptal Edilmiştir  ')}
      </span>
    </>
    )}
    {step === 2 && (
    <span key="step-2" className="secure-payment-container step-2">
      <IconSecure />
      {gettext('Güvenli Alışveriş ve Ödeme')}
    </span>
    )}
    {step === 3 && (
    <span key="step-4" className="step-4">
      {gettext('Teşekkürler')}
    </span>
    )}
  </>
);

RenderHeading.defaultProps = {
  step: 1,
  cartCount: null,
};

RenderHeading.propTypes = {
  step: PropTypes.number.isRequired,
  cartCount: PropTypes.string,
};

export default RenderHeading;
