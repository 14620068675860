/* eslint-disable react/prop-types */
import React from 'react';
import { Logo } from '@Components';
import APIs from '@API';
import Cookie from 'js-cookie';
import { isMobile } from '@Constants';
import {
  gettext,
} from '@Translate';
import { TrackJS } from 'trackjs';
TrackJS.install({
  token: "ac367eedd05748779a5617be2a2d2b9c"
});

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  componentDidCatch(error, errorInfo) {

    TrackJS.track(error);

    const errorMsg = String(error);

    const splitErr = errorMsg?.split(':');


    if (errorInfo && splitErr[0] === 'ChunkLoadError') {
      const forceRefreshed = window.localStorage.getItem('page-has-been-force-refreshed');

      if (forceRefreshed === '0' || forceRefreshed === null) {
        window.localStorage.setItem('page-has-been-force-refreshed', 0);
      }

      const pageHasAlreadyBeenForceRefreshed = JSON.parse(window.localStorage.getItem('page-has-been-force-refreshed'));
      const intPageRefreshed = parseInt(pageHasAlreadyBeenForceRefreshed);

      if (intPageRefreshed < 3) {
          window.localStorage.setItem('page-has-been-force-refreshed', (intPageRefreshed + 1));
          setTimeout(() => {
            window.location.reload();
          }, 600);
      } else {
          // hata mesajlarını state e setler
          this.setState({
            error,
            errorInfo,
          });
         // local storage daki ilgili değeri sııfırlar
          window.localStorage.setItem('page-has-been-force-refreshed', 0);
      }
    }
  }

  render() {
    const { error, errorInfo } = this.state;
    const { children } = this.props;



    if (errorInfo) {
      try {
        APIs.logRequest({
          url: window.location.href,
          platform: Cookie.get('platform') ? Cookie.get('platform') : isMobile() ? 'MOBILEWEB' : 'WEB',
          error_message: (error && error?.toString()),
          error_type: 'javascript',
        });
      } catch (ex) {}

      // Error path
      return (
        <div style={{
          color: '#ffffff', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', minHeight: '100vh', backgroundColor: '#000',
        }}
        >
          <Logo />
          <h1 style={{ marginTop: 50 }}>{gettext('Bu ekranı gördüğünüz için üzgünüz!')}</h1>
          <h2>{gettext('Lütfen sayfayı yeniden yükleyin.')}</h2>
          <details style={{ whiteSpace: 'pre-wrap' }}>
            {error && error?.toString()}
            <br />
            {errorInfo?.componentStack}
          </details>
        </div>
      );
    }
    // Normally, just render children
    return children;
  }
}

export default ErrorBoundary;
