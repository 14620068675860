import * as React from 'react'
import { memo } from 'react'
const SvgQrButton = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='1em'
    height='1em'
    viewBox='0 0 24.289 24.625'
    fill='currentColor'
    {...props}
  >
    <g fill='#f60' data-name='Group 5373'>
      <path
        d='M10.044 0H2.006A2.01 2.01 0 0 0 0 2.006v10.047h8.035v-2.009H2.006V2.006h8.035v6.027h2.009V0Z'
        data-name='Path 13387'
      />
      <path
        d='M3.686 3.747h4.018v4.018H3.686z'
        data-name='Rectangle 3038'
      />
      <path
        d='M16.586 3.747h4.018v4.018h-4.018z'
        data-name='Rectangle 3039'
      />
      <path
        d='M3.686 16.86h4.018v4.018H3.686z'
        data-name='Rectangle 3040'
      />
      <path
        d='M18.81 13.02h-6.027v6.027h2.009v-4.018h4.018Z'
        data-name='Path 13388'
      />
      <path
        d='M22.281 18.598h-2.009v2.009h2.009v2.009h-4.018v2.009h4.018a2.01 2.01 0 0 0 2.009-2.009v-4.018Z'
        data-name='Path 13389'
      />
      <path
        d='M16.801 16.589v4.018h-4.018v4.018h2.009v-2.009h4.018v-6.027Z'
        data-name='Path 13390'
      />
      <path
        d='M22.28 13.193h2.009v2.009H22.28z'
        data-name='Rectangle 3041'
      />
      <path
        d='M20.272 15.078h2.009v2.009h-2.009z'
        data-name='Rectangle 3042'
      />
      <path
        d='M9.284 9.423h2.009v2.009H9.284z'
        data-name='Rectangle 3043'
      />
      <path
        d='M14.245 0h8.035a2.01 2.01 0 0 1 2.009 2.009v10.044h-4.018v-2.009h2.009V2.006h-8.035v10.047h-2.009V0Z'
        data-name='Path 13391'
      />
      <path
        d='M16.71 9.423h2.009v2.009H16.71z'
        data-name='Rectangle 3044'
      />
      <path
        d='M10.044 12.572H0v10.044a2.01 2.01 0 0 0 2.009 2.009h10.044v-2.009H2.006v-8.035h8.035v6.027h2.009v-8.035Z'
        data-name='Path 13392'
      />
    </g>
  </svg>
)
const Memo = memo(SvgQrButton)
export default Memo
