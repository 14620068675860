import {
  butigoDev, butigoProd, butigoPreprod, butigoStaging,
} from './butigo';
import {
  floDev, floProd, floPreprod, floStaging,
} from './flo';
import {
  flokzDev, flokzProd, flokzPreprod, flokzStaging,
} from './flo_kz';
import {
  incideriDev, incideriProd, incideriPreprod, incideriStaging,
} from './incideri';
import {
  instreetDev, instreetProd, instreetPreprod, instreetStaging,
} from './instreet';
import {
  lumberjackDev, lumberjackProd, lumberjackPreprod, lumberjackStaging,
} from './lumberjack';
import {
  lumberjackitDev, lumberjackitProd, lumberjackitPreprod, lumberjackitStaging,
} from './lumberjack_it';
import {
  ninewestDev, ninewestProd, ninewestPreprod, ninewestStaging,
} from './ninewest';

import {
  reebokDev, reebokStaging, reebokPreprod, reebokProd,
} from './reebok';

import {
  sneakerboxDev, sneakerboxStaging, sneakerboxPreprod, sneakerboxProd,
} from './sneakerbox';

const conf = {
  floDev,
  floProd,
  floPreprod,
  floStaging,
  butigoDev,
  butigoProd,
  butigoPreprod,
  butigoStaging,
  incideriDev,
  incideriProd,
  incideriPreprod,
  incideriStaging,
  instreetDev,
  instreetProd,
  instreetPreprod,
  instreetStaging,
  lumberjackDev,
  lumberjackProd,
  lumberjackPreprod,
  lumberjackStaging,
  ninewestDev,
  ninewestProd,
  ninewestPreprod,
  ninewestStaging,
  flokzDev,
  flokzProd,
  flokzPreprod,
  flokzStaging,
  reebokDev,
  reebokStaging,
  reebokPreprod,
  reebokProd,
  sneakerboxDev,
  sneakerboxStaging,
  sneakerboxPreprod,
  sneakerboxProd,
  lumberjackitDev,
  lumberjackitStaging,
  lumberjackitPreprod,
  lumberjackitProd
};

export default {
  ...conf[process.env.REACT_APP_STAGE],
};
