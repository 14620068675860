import * as React from 'react'
import { memo } from 'react'
const TierThree = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="19.812" height="17.748" viewBox="0 0 19.812 17.748" {...props}>
    <g id="crown" transform="translate(-1.001 -3.501)">
      <path id="Path_14559" data-name="Path 14559" d="M2.782,12.163,3.89,18.806v2.443H17.923V18.807l1.107-6.643a1.651,1.651,0,1,0-1.52-1.646,1.644,1.644,0,0,0,.294.937l-3.4,2.162-2.792-6.98a1.651,1.651,0,1,0-1.417,0l-2.792,6.98-3.4-2.162a1.65,1.65,0,1,0-1.227.707Zm1.933,8.261V19.185H17.1l0,1.238H4.715ZM19.162,9.692a.826.826,0,0,1,0,1.651.815.815,0,0,1-.31-.062l-.083-.034-.078-.054a.824.824,0,0,1,.471-1.5Zm-9.081-4.54a.826.826,0,1,1,.931.813l-.106.014L10.8,5.964A.825.825,0,0,1,10.081,5.151ZM7.8,14.847l3.1-7.759,3.1,7.759,4.179-2.659L17.161,18.36H4.647L3.623,12.188ZM2.651,9.692a.824.824,0,0,1,.471,1.5l-.078.054-.083.034a.816.816,0,0,1-.31.062.826.826,0,0,1,0-1.651Z" transform="translate(0 0)" fill="#ee4e17"/>
      <path id="Path_14560" data-name="Path 14560" d="M24,34.5h.826v.826H24Z" transform="translate(-13.505 -18.203)" fill="#ee4e17"/>
    </g>
  </svg>
)

const Memo = memo(TierThree)
export default Memo
