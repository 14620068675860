import React from 'react';
import {
  gettext,
} from '@Translate';

// eslint-disable-next-line max-len,react/prop-types
export default function ToastfyCouponConfirm({ beforeApplyCouponGrandTotal, afterApplyCouponGrandTotal }) {
  const removeCouponActionButton = () => {
    document.querySelector('#remove-coupon-btn').click();
  };
  return (
    // eslint-disable-next-line react/jsx-filename-extension
    <div style={{ color: '#ffffff' }}>
      <div id="remove-add-wishlist-product-100482192002" className="fancybox-hidden-content remove-product fancybox-content" style={{ display: 'inline-block' }}>
        <div className="remove-product__heading">
          <div className="basket-icon">
            <span className="heart-icon">
              <i className="icon-heart-o" />
            </span>
            <i className="icon-basket" />
          </div>
        </div>
        <div className="remove-product__content">
          <p>
            {gettext('Uyguladığınız kupon, sepetinizdeki indirim ile geçerli olmadığından sepet tutarınız')}
            {' '}
            {beforeApplyCouponGrandTotal}
            {' '}
            {gettext('TL ’den')}
            {' '}
            {afterApplyCouponGrandTotal}
            {' '}
            {gettext('TL’ye yükselmiştir.')}
          </p>
          <p>{gettext('Devam etmek ister misiniz?')}</p>
        </div>

        <div className="remove-product__buttons">
          <div className="row">
            <div className="col-4 col-sm-6">
              <button type="button" onClick={removeCouponActionButton} className="btn btn-default js-remove-coupon" data-test="checkout-coupon-cancel-button">
                {gettext('İptal Et')}
              </button>
            </div>
            <div className="col-8 col-sm-6 pl-0">
              <button type="button" className="btn btn-orange" data-test="checkout-coupon-continue-button">
                {gettext('Devam Et')}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
