import * as React from 'react'
import { memo } from 'react'
const SvgIconNextArrow = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='1em'
    height='1em'
    viewBox='0 0 8.493 14.873'
    fill='currentColor'
    {...props}
  >
    <g data-name='noun_Next_1135493 (1)'>
      <path
        d='m8.123 8.33-6.216 6.216a1.117 1.117 0 0 1-1.58-1.58l5.53-5.529L.328 1.908a1.117 1.117 0 0 1 1.58-1.58l6.215 6.215a1.264 1.264 0 0 1 0 1.787'
        data-name='Path 139'
      />
    </g>
  </svg>
)
const Memo = memo(SvgIconNextArrow)
export default Memo
