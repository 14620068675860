import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { gettext } from '@Translate';
import config from '@Config';

const ShareBasketPopup = (staticLink, checkDeliveryOptions, checkDeliveryOptionsMessage) => {
  const { webview } = useSelector((state) => state.config);
  const {project}  = config;
  const [copyValue, setCopyValue] = useState({
    value: staticLink,
    copied: false,
  });
  return (
    <div className="share-basket-popup">
      <div className="share-basket-popup-container">
        <div className="share-basket-popup__icon-head primary-color">
          <span className="icon-share-basket" />
        </div>

        <>
          <div className="share-basket-popup__title primary-color">
            {gettext('Sepetini Arkadaşınla Paylaş')}
          </div>
          <div className="share-basket-popup__sub-title">
            {gettext(
              'Beğendiğiniz ürünleri kolayca sepetinize ekleyebilir ve arkadaşınızla paylaşabilirsiniz.',
            )}
          </div>
          {(checkDeliveryOptions === true && checkDeliveryOptionsMessage) && (
            <div className="share-basket-popup__sub-title delivery-option">
              {' '}
              {gettext(checkDeliveryOptionsMessage)}
            </div>
          )}
        </>

        <div className="share-basket-popup__input">
          <input
            readOnly
            value={staticLink}
            onChange={() => setCopyValue({ value, copied: false })}
            data-test="checkout-share-basket-popup-change"
            type="text"
          />
        </div>
        <CopyToClipboard
          text={staticLink}
          onCopy={() => setCopyValue({ copied: true })}
        >
          <div className={`share-basket-popup__button  primary-button ${copyValue?.copied ? 'share-basket-popup__button--active' : ''}`}>{copyValue?.copied ? gettext('Kopyalandı') : gettext('Kopyala')}</div>
        </CopyToClipboard>
        {!webview && project !== "sneakerbox" && (
          <div className="share-basket-popup__social">
            <div className="share-basket-popup__icon-group">
              <a
                target="_blank"
                href={`mailto:?subject=Senin için hazırladığım sepeti kaçırma!&body=${staticLink}`}
                rel="noreferrer"
                aria-label={gettext('eposta ile paylaş')}
              >
                <span className="icon-mail-circle share-basket-popup__icon" />
              </a>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={`https://www.facebook.com/messages/t?text=Senin için hazırladığım sepeti kaçırma! ${staticLink}`}
                aria-label={gettext('facebook ile paylaş')}
              >
                <span className="icon-facebook-circle share-basket-popup__icon" />
              </a>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={`https://www.instagram.com/direct/new/?text=Senin için hazırladığım sepeti kaçırma! ${staticLink}`}
                aria-label={gettext('instagram ile paylaş')}

              >
                <span className="icon-instagram-circle share-basket-popup__icon" />
              </a>
              <a
                target="_blank"
                href={`https://api.whatsapp.com/send?text=Senin için hazırladığım sepeti kaçırma! ${staticLink}`}
                rel="noreferrer"
                aria-label={gettext('whatsapp ile paylaş')}
              >
                <span className="icon-whatsap-circle share-basket-popup__icon" />
              </a>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ShareBasketPopup;
