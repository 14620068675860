/* eslint-disable import/prefer-default-export */
export const sneakerboxDev = {
  BASE_URL: 'https://staging.sneakerbox.ru/',
  COOKIE_BASE_URL: 'staging.sneakerbox.ru',
  CHECKOUT_URL: 'https://staging.sneakerbox.ru/',
  CHECKOUT_BASE_URL: 'https://checkout.staging.sneakerbox.ru/',
  currency: 'RUB',
  googleClientId: '',
  facebookAppId: '',
  appleClientId: '',
  locale: 'ru',
  symbol: true,
  quoteitemtotal: 1000, // bu değer yer tutucu olarak eklenmiştir, doğrusu ile değiştirilmelidir
  logoPath: 'pub/assets/sneakerbox/images/sneakerbox-logo.svg',
  project: 'sneakerbox',
  title: 'Sneaker Box',
  appDeepLink: '',
  failuresNumber: '3',
  manifest: '',
  favicon: 'pub/assets/sneakerbox/img/favicon-s-box.ico',
  loadingAnimation: 'pub/assets/sneakerbox/img/loading/loading.gif',
  gtmAccountId: 'GTM-MVJBTNX6',
  gtmMerchant: '2YX4WKmqYwAXO1iS28h4JnBmF8aOQfPiFo3vgJ9k3oo',
  siteKey: '6LfObvIpAAAAAPpHMs1h1IUBrljKL4VX_zpEX5Y3',
  twitterCreator: '',
  appStoreId: '',
  alternateIos: '',
  alternateAndroid: '',
  facebookDomainVerification: '',
  robotoFont: '',
  segmentifyScript: '',
  siteName: 'SNEAKERBOX.RU.',
  phoneCode: [{ name: '+7', mask: '(999) 999 99 99' }],
  isGlobal: true,
  cunda: true,
  loyaltyBox: true
};

export const sneakerboxStaging = {
  BASE_URL: 'https://staging.sneakerbox.ru/',
  COOKIE_BASE_URL: 'staging.sneakerbox.ru',
  CHECKOUT_URL: 'https://checkout-be.staging.sneakerbox.ru/',
  CHECKOUT_BASE_URL: 'https://checkout.staging.sneakerbox.ru/',
  currency: 'RUB',
  googleClientId: '885834410672-nggp60l13utunuv4ikvbhdp82asom29s.apps.googleusercontent.com',
  facebookAppId: '5258346120899465',
  appleClientId: 'com.sneakerbox.appleClient',
  locale: 'ru',
  symbol: true,
  quoteitemtotal: 20,
  logoPath: 'pub/assets/sneakerbox/images/sneakerbox-logo.svg',
  project: 'sneakerbox',
  title: 'Sneaker Box',
  appDeepLink: 'sneakerbox://',
  failuresNumber: '3',
  manifest: '',
  favicon: 'pub/assets/sneakerbox/img/favicon-s-box.ico',
  loadingAnimation: 'pub/assets/sneakerbox/img/loading/sneakerbox_loading_white.gif',
  gtmAccountId: 'GTM-MVJBTNX6',
  gtmMerchant: '2YX4WKmqYwAXO1iS28h4JnBmF8aOQfPiFo3vgJ9k3oo',
  siteKey: '6LfObvIpAAAAAPpHMs1h1IUBrljKL4VX_zpEX5Y3',
  twitterCreator: '',
  appStoreId: '',
  alternateIos: '',
  alternateAndroid: '',
  facebookDomainVerification: '',
  robotoFont: '',
  segmentifyScript: '',
  siteName: 'SNEAKERBOX.RU.',
  phoneCode: [{ name: '+7', mask: '(999) 999 99 99' }],
  isGlobal: true,
  cunda: true,
  loyaltyBox: true
};

export const sneakerboxPreprod = {
  BASE_URL: 'https://preprod.sneakerbox.ru/',
  COOKIE_BASE_URL: '.sneakerbox.ru',
  CHECKOUT_URL: 'https://preprod.sneakerbox.ru/',
  CHECKOUT_BASE_URL: 'https://checkout.preprod.sneakerbox.ru/',
  currency: 'RUB',
  googleClientId: '',
  facebookAppId: '',
  appleClientId: '',
  locale: 'ru',
  symbol: true,
  quoteitemtotal: 20,
  logoPath: 'pub/assets/sneakerbox/images/sneakerbox-logo.svg',
  project: 'sneakerbox',
  title: 'Sneaker Box',
  appDeepLink: 'sneakerbox://',
  failuresNumber: '3',
  manifest: '',
  favicon: 'pub/assets/sneakerbox/img/favicon-s-box.ico',
  loadingAnimation: 'pub/assets/sneakerbox/img/loading/sneakerbox_loading_white.gif',
  gtmAccountId: 'GTM-MVJBTNX6',
  gtmMerchant: '2YX4WKmqYwAXO1iS28h4JnBmF8aOQfPiFo3vgJ9k3oo',
  siteKey: '6LfObvIpAAAAAPpHMs1h1IUBrljKL4VX_zpEX5Y3',
  twitterCreator: '',
  appStoreId: '',
  alternateIos: '',
  alternateAndroid: '',
  facebookDomainVerification: '',
  robotoFont: '',
  segmentifyScript: '',
  siteName: 'SNEAKERBOX.RU.',
  phoneCode: [{ name: '+7', mask: '(999) 999 99 99' }],
  isGlobal: true,
  cunda: true,
  loyaltyBox: true
};

export const sneakerboxProd = {
  BASE_URL: 'https://www.sneakerbox.ru/',
  COOKIE_BASE_URL: '.sneakerbox.ru',
  CHECKOUT_URL: 'https://www.sneakerbox.ru/',
  CHECKOUT_BASE_URL: 'https://checkout.sneakerbox.ru/',
  currency: 'RUB',
  googleClientId: '',
  facebookAppId: '',
  appleClientId: '',
  locale: 'ru',
  symbol: true,
  quoteitemtotal: 20,
  logoPath: 'pub/assets/sneakerbox/images/sneakerbox-logo.svg',
  project: 'sneakerbox',
  title: 'Sneaker Box',
  appDeepLink: 'sneakerbox://',
  failuresNumber: '3',
  manifest: '',
  favicon: 'pub/assets/sneakerbox/img/favicon-s-box.ico',
  loadingAnimation: 'pub/assets/sneakerbox/img/loading/sneakerbox_loading_white.gif',
  gtmAccountId: 'GTM-MVJBTNX6',
  gtmMerchant: '2YX4WKmqYwAXO1iS28h4JnBmF8aOQfPiFo3vgJ9k3oo',
  siteKey: '6LfObvIpAAAAAPpHMs1h1IUBrljKL4VX_zpEX5Y3',
  twitterCreator: '',
  appStoreId: '',
  alternateIos: '',
  alternateAndroid: '',
  facebookDomainVerification: '',
  robotoFont: '',
  segmentifyScript: '',
  siteName: 'SNEAKERBOX.RU.',
  phoneCode: [{ name: '+7', mask: '(999) 999 99 99' }],
  isGlobal: true,
  cunda: false,
  loyaltyBox: true
};
