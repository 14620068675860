import { errorCodes } from './ErrorCodesList';

// eslint-disable-next-line import/prefer-default-export
export const isNumberOrString = (prop) => {
  // eslint-disable-next-line no-restricted-globals

  if ((prop === undefined || prop === null)) {

    console.log(errorCodes.unknownValue);
    return '';
  }

  if (prop === 'NaN TL') {
    return '';
  }

  if ((typeof prop === 'string' || prop instanceof String) && prop.length < 1) {
    console.log(errorCodes.emptyValue);
    return '';
  }

  return prop;
};
