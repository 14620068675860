import * as React from 'react'
import { memo } from 'react'
const SvgDelete = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='1em'
    height='1em'
    data-name='Iconly/Bold/Delete'
    viewBox='0 0 11.021 12.246'
    fill='currentColor'
    {...props}
  >
    <path
      d='M3.142 12.222a1.79 1.79 0 0 1-1.795-1.731C1.193 9.086.955 6.135.865 5.024c-.022-.268-.034-.43-.036-.438a.48.48 0 0 1 .117-.341.43.43 0 0 1 .32-.144H9.76a.44.44 0 0 1 .32.144.46.46 0 0 1 .111.341c0 .012-.321 4.119-.513 5.906a1.785 1.785 0 0 1-1.835 1.731 109.183 109.183 0 0 1-4.701-.001m2.981-5.793V10.1a.612.612 0 0 0 1.225 0V6.429a.612.612 0 0 0-1.225 0m-2.449 0V10.1a.612.612 0 1 0 1.224 0V6.429a.612.612 0 0 0-1.224 0M.437 3.118A.447.447 0 0 1 0 2.668v-.233a.443.443 0 0 1 .437-.45h1.785a.786.786 0 0 0 .76-.621l.094-.417A1.216 1.216 0 0 1 4.246 0h2.529A1.216 1.216 0 0 1 7.94.916l.1.447a.784.784 0 0 0 .76.622h1.785a.443.443 0 0 1 .437.45v.232a.447.447 0 0 1-.437.45Z'
      data-name='Delete'
    />
  </svg>
)
const Memo = memo(SvgDelete)
export default Memo
