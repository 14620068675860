// Kredi kartındaki yıl alanını doldurur
export const creditYear = () => {
  const currentYear = Number(new Date().getFullYear());
  const yearCount = 11;
  const yearArray = [];
  for (let i = 0; i < yearCount; i++) {
    yearArray.push(currentYear + i);
  }
  return yearArray;
};
