/* eslint-disable no-param-reassign */
/* eslint-disable import/no-cycle */
import {
  createSlice,
} from '@reduxjs/toolkit';

const INITIAL_STATE = {
  isOpen: false,
  message: null,
  count: 0,
};

const slice = createSlice({
  name: 'loading',
  initialState: INITIAL_STATE,
  reducers: {
    showLoadingAction: (state, action) => {
      state.isOpen = action.payload;
    },
    hideLoadingAction: (state, action) => {
      state.isOpen = false;
    },
  },
  extraReducers: (builder) => {

  },
});

export default slice.reducer;

export const {
  showLoadingAction,
  hideLoadingAction,
} = slice.actions;
