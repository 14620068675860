import * as React from 'react'
import { memo } from 'react'
const SvgIconWarning = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='1em'
    height='1em'
    data-name='Group 15560'
    viewBox='0 0 15.113 15.064'
    fill='currentColor'
    {...props}
  >
    <defs>
      <clipPath id='icon-warning_svg__a'>
        <path
          fill='none'
          d='M0 0h15.113v15.064H0z'
          data-name='Rectangle 13774'
        />
      </clipPath>
      <clipPath id='icon-warning_svg__b'>
        <path
          fill='none'
          d='M0 0h12.331v12.283H0z'
          data-name='Rectangle 13772'
        />
      </clipPath>
    </defs>
    <g clipPath='url(#icon-warning_svg__a)' data-name='Group 15010'>
      <g data-name='Group 15009'>
        <g
          clipPath='url(#icon-warning_svg__a)'
          data-name='Group 15008'
        >
          <g data-name='Group 15007' opacity={0.1}>
            <g data-name='Group 15006'>
              <g
                clipPath='url(#icon-warning_svg__b)'
                data-name='Group 15005'
                transform='translate(1.391 1.391)'
              >
                <path
                  fill='none'
                  d='M7.456.793a1.439 1.439 0 0 0-2.571 0L.156 10.198a1.44 1.44 0 0 0 1.286 2.085h9.453a1.44 1.44 0 0 0 1.286-2.085zm-.59 9.576a.7.7 0 0 1-1.391 0v-.065a.7.7 0 1 1 1.391 0Zm0-2.041a.7.7 0 0 1-1.391 0V2.684a.7.7 0 1 1 1.391 0Z'
                  data-name='Path 13752'
                />
              </g>
            </g>
          </g>
          <path
            d='m14.811 10.964-4.726-9.405a2.83 2.83 0 0 0-5.057 0L.3 10.964a2.83 2.83 0 0 0 2.528 4.1h9.453a2.83 2.83 0 0 0 2.528-4.1m-.709 2.391a2.13 2.13 0 0 1-1.817 1.014H2.83a2.134 2.134 0 0 1-1.907-3.093L5.65 1.871a2.134 2.134 0 0 1 3.814 0l4.727 9.405a2.13 2.13 0 0 1-.09 2.079'
            data-name='Path 13753'
          />
          <path
            d='M7.556 11.002a.7.7 0 0 0-.7.7v.065a.7.7 0 1 0 1.391 0v-.065a.7.7 0 0 0-.7-.7'
            data-name='Path 13754'
          />
          <path
            d='M9.464 1.871a2.134 2.134 0 0 0-3.814 0L.923 11.276a2.134 2.134 0 0 0 1.907 3.093h9.453a2.134 2.134 0 0 0 1.907-3.093zm4.044 11.119a1.44 1.44 0 0 1-1.225.684H2.83a1.44 1.44 0 0 1-1.286-2.085l4.727-9.406a1.439 1.439 0 0 1 2.571 0l4.727 9.405a1.44 1.44 0 0 1-.061 1.4'
            data-name='Path 13755'
          />
          <path
            d='M7.556 3.38a.7.7 0 0 0-.7.7v5.644a.7.7 0 1 0 1.391 0V4.075a.7.7 0 0 0-.7-.7'
            data-name='Path 13756'
          />
        </g>
      </g>
    </g>
  </svg>
)
const Memo = memo(SvgIconWarning)
export default Memo
