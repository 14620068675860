/* eslint-disable import/prefer-default-export */
export const ninewestDev = {
  BASE_URL: 'https://staging.ninewest.com.tr/',
  COOKIE_BASE_URL: 'staging.ninewest.com.tr',
  CHECKOUT_URL: 'https://checkout-be.staging.ninewest.com.tr/',
  CHECKOUT_BASE_URL: 'https://checkout.staging.ninewest.com.tr/',
  currency: 'TL',
  googleClientId: '885834410672-35f2rg0r88ns4gktf2k20edo9n6fd0me.apps.googleusercontent.com',
  facebookAppId: '469951816921531',
  appleClientId: 'com.ninewest.appleClient',
  locale: 'tr',
  symbol: false,
  quoteitemtotal: 20,
  logoPath: 'pub/assets/ninewest/img/ninewest-logo.svg',
  project: 'ninewest',
  title: 'Nine West',
  appDeepLink: 'ninewest://',
  failuresNumber: '3',
  manifest: 'pub/pwa/manifest-nw.json',
  favicon: 'pub/assets/ninewest/img/favicon.png',
  loadingAnimation: 'pub/assets/flo/img/loading/flo_loading_white.gif',
  gtmAccountId: 'GTM-NVKT5SX',
  gtmMerchant: '6zA6j_9nycRF5airEGDylyNkMSY95yTye_mYKX8_7uM',
  siteKey: '6LcPQ64UAAAAAJv489J59h58XQ6t2X8cHODjm9NR',
  twitterCreator: '@ninewestturkiye',
  appStoreId: '1497667341',
  androidPackage: 'app-id=tr.com.ninewest',
  alternateIos: 'ios-app://1497667341/https/www.ninewest.com',
  alternateAndroid: 'android-app://tr.com.ninewest/https/www.ninewest.com.tr',
  facebookDomainVerification:'r4b9ssn9tbu32jhhtcq0i64w0qi460',
  robotoFont: '',
  efilliScript: 'https://bundles.efilli.com/staging.ninewest.com.tr.prod.js',
  segmentifyScript: '',
  siteName: 'NINEWEST.COM.TR.',
  phoneCode: [{ name: '+90', mask: '(599) 999 99 99' }],
  isGlobal: false,
  cunda: true
};

export const ninewestStaging = {
  BASE_URL: 'https://staging.ninewest.com.tr/',
  COOKIE_BASE_URL: 'staging.ninewest.com.tr',
  CHECKOUT_URL: 'https://checkout-be.staging.ninewest.com.tr/',
  CHECKOUT_BASE_URL: 'https://checkout.staging.ninewest.com.tr/',
  currency: 'TL',
  googleClientId: '885834410672-35f2rg0r88ns4gktf2k20edo9n6fd0me.apps.googleusercontent.com',
  facebookAppId: '469951816921531',
  appleClientId: 'com.ninewest.appleClient',
  locale: 'tr',
  symbol: false,
  quoteitemtotal: 20,
  logoPath: 'pub/assets/ninewest/img/ninewest-logo.svg',
  project: 'ninewest',
  title: 'Nine West',
  appDeepLink: 'ninewest://',
  failuresNumber: '3',
  manifest: 'pub/pwa/manifest-nw.json',
  favicon: 'pub/assets/ninewest/img/favicon.png',
  loadingAnimation: 'pub/assets/flo/img/loading/flo_loading_white.gif',
  gtmAccountId: 'GTM-NVKT5SX',
  gtmMerchant: '6zA6j_9nycRF5airEGDylyNkMSY95yTye_mYKX8_7uM',
  siteKey: '6LcPQ64UAAAAAJv489J59h58XQ6t2X8cHODjm9NR',
  twitterCreator: '@ninewestturkiye',
  appStoreId: '1497667341',
  androidPackage: 'app-id=tr.com.ninewest',
  alternateIos: 'ios-app://1497667341/https/www.ninewest.com',
  alternateAndroid: 'android-app://tr.com.ninewest/https/www.ninewest.com.tr',
  facebookDomainVerification:'r4b9ssn9tbu32jhhtcq0i64w0qi460',
  robotoFont: '',
  efilliScript: 'https://bundles.efilli.com/staging.ninewest.com.tr.prod.js',
  segmentifyScript: '',
  siteName: 'NINEWEST.COM.TR.',
  phoneCode: [{ name: '+90', mask: '(599) 999 99 99' }],
  isGlobal: false,
  cunda: true
};

export const ninewestPreprod = {
  BASE_URL: 'https://preprod.ninewest.com.tr/',
  COOKIE_BASE_URL: 'preprod.ninewest.com.tr',
  CHECKOUT_URL: 'https://checkout-be.preprod.ninewest.com.tr/',
  CHECKOUT_BASE_URL: 'https://checkout.preprod.ninewest.com.tr/',
  currency: 'TL',
  googleClientId: '885834410672-35f2rg0r88ns4gktf2k20edo9n6fd0me.apps.googleusercontent.com',
  facebookAppId: '469951816921531',
  appleClientId: 'com.ninewest.appleClient',
  locale: 'tr',
  symbol: false,
  quoteitemtotal: 20,
  logoPath: 'pub/assets/ninewest/img/ninewest-logo.svg',
  project: 'ninewest',
  title: 'Nine West',
  appDeepLink: 'ninewest://',
  failuresNumber: '3',
  manifest: 'pub/pwa/manifest-nw.json',
  favicon: 'pub/assets/ninewest/img/favicon.png',
  loadingAnimation: 'pub/assets/flo/img/loading/flo_loading_white.gif',
  gtmAccountId: 'GTM-NVKT5SX',
  gtmMerchant: '6zA6j_9nycRF5airEGDylyNkMSY95yTye_mYKX8_7uM',
  siteKey: '6LcPQ64UAAAAAJv489J59h58XQ6t2X8cHODjm9NR',
  twitterCreator: '@ninewestturkiye',
  appStoreId: '1497667341',
  androidPackage: 'app-id=tr.com.ninewest',
  alternateIos: 'ios-app://1497667341/https/www.ninewest.com',
  alternateAndroid: 'android-app://tr.com.ninewest/https/www.ninewest.com.tr',
  facebookDomainVerification:'r4b9ssn9tbu32jhhtcq0i64w0qi460',
  robotoFont: '',
  efilliScript: 'https://bundles.efilli.com/staging.ninewest.com.tr.prod.js',
  segmentifyScript: '',
  siteName: 'NINEWEST.COM.TR.',
  phoneCode: [{ name: '+90', mask: '(599) 999 99 99' }],
  isGlobal: false,
  cunda: true
};

export const ninewestProd = {
  BASE_URL: 'https://www.ninewest.com.tr/',
  COOKIE_BASE_URL: 'ninewest.com.tr',
  CHECKOUT_URL: 'https://checkout-be.ninewest.com.tr/',
  CHECKOUT_BASE_URL: 'https://checkout.ninewest.com.tr/',
  currency: 'TL',
  googleClientId: '885834410672-35f2rg0r88ns4gktf2k20edo9n6fd0me.apps.googleusercontent.com',
  facebookAppId: '469951816921531',
  appleClientId: 'com.ninewest.appleClient',
  locale: 'tr',
  symbol: false,
  quoteitemtotal: 20,
  logoPath: 'pub/assets/ninewest/img/ninewest-logo.svg',
  project: 'ninewest',
  title: 'Nine West',
  appDeepLink: 'ninewest://',
  failuresNumber: '3',
  manifest: 'pub/pwa/manifest-nw.json',
  favicon: 'pub/assets/ninewest/img/favicon.png',
  loadingAnimation: 'pub/assets/flo/img/loading/flo_loading_white.gif',
  gtmAccountId: 'GTM-NVKT5SX',
  gtmMerchant: '6zA6j_9nycRF5airEGDylyNkMSY95yTye_mYKX8_7uM',
  siteKey: '6LcPQ64UAAAAAJv489J59h58XQ6t2X8cHODjm9NR',
  twitterCreator: '@ninewestturkiye',
  appStoreId: '1497667341',
  androidPackage: 'app-id=tr.com.ninewest',
  alternateIos: 'ios-app://1497667341/https/www.ninewest.com',
  alternateAndroid: 'android-app://tr.com.ninewest/https/www.ninewest.com.tr',
  facebookDomainVerification:'r4b9ssn9tbu32jhhtcq0i64w0qi460',
  robotoFont: '',
  efilliScript: 'https://bundles.efilli.com/staging.ninewest.com.tr.prod.js',
  segmentifyScript: '',
  siteName: 'NINEWEST.COM.TR.',
  phoneCode: [{ name: '+90', mask: '(599) 999 99 99' }],
  isGlobal: false,
  cunda: false
};
