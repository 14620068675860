import * as React from 'react'
import { memo } from 'react'
const SvgMissionDone = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='1em'
    height='1em'
    viewBox='0 0 24 24'
    fill='currentColor'
    {...props}
  >
    <g data-name='Group 17436' transform='rotate(-90 42 308)'>
      <circle
        cx={12}
        cy={12}
        r={12}
        fill='#fff'
        data-name='Ellipse 140'
        transform='rotate(-90 308 -18)'
      />
      <path
        fill='#28a745'
        d='M342.278 284.183a1.058 1.058 0 0 0 .107-1.62l-.061-.063-.019-.018-6.48-6.5 2.647-2.648.011-.012a1.055 1.055 0 0 0-1.48-1.484l-3.4 3.4a1.05 1.05 0 0 0-.31.742 1.05 1.05 0 0 0 .309.74l7.214 7.231a1.054 1.054 0 0 0 1.466.225'
        data-name='Path 14419'
      />
    </g>
  </svg>
)
const Memo = memo(SvgMissionDone)
export default Memo
