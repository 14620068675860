import * as React from 'react'
import { memo } from 'react'
const SvgReebokAdressClose = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='1em'
    height='1em'
    viewBox='0 0 50 50'
    fill='currentColor'
    {...props}
  >
    <g data-name='Group 15601'>
      <g fill='#fff' stroke='#d8d8d8' data-name='Rectangle 7791'>
        <path stroke='none' d='M0 0h50v50H0z' />
        <path fill='none' d='M.5.5h49v49H.5z' />
      </g>
      <path d='M34.839 37.002 25 27.29l-9.738 9.613L13 34.67l9.747-9.621-9.739-9.824L15.262 13 25 22.614l9.738-9.613L37 15.234l-9.738 9.613 9.652 9.524Z' />
    </g>
  </svg>
)
const Memo = memo(SvgReebokAdressClose)
export default Memo
