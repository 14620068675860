import React from 'react';
import PropTypes from 'prop-types';
import { OldProducts, ShareBasket } from '@Components';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import Coupon from './Coupon';
import Loyalty from './Loyalty'
import InstreetClub from './InstreetClub'
import ReturnDelivery from './ReturnDelivery';
import DesktopCardSummary from './CardSummary/Desktop/index';
import ReebokDesktopCartSummary from './ReebokCartSummary/Desktop';
import MobileCardSummary from './CardSummary/Mobile/index';
import config from '@Config';

const Sidebar = ({
  buttonText,
  onClick,
  isCouponActive,
  isOldProductActive,
  isShareBasketActive,
  isAgreementActive,
  executeAgreementsListScroll,
  orderSummary,
}) => {
  const { guest } = useSelector((state) => state.auth);
  const { project, loyaltyBox } = config;
  const urlLocation = useLocation();
  const pathName = urlLocation?.pathname;
  return (
    <>
      {project === 'reebok' ? (
        <ReebokDesktopCartSummary
          onClick={onClick}
          isAgreementActive={isAgreementActive}
          executeAgreementsListScroll={executeAgreementsListScroll}
          orderSummary={orderSummary}
        />
      ) : (
        <DesktopCardSummary
          buttonText={buttonText}
          onClick={onClick}
          isAgreementActive={isAgreementActive}
          executeAgreementsListScroll={executeAgreementsListScroll}
          orderSummary={orderSummary}
        />
      )}

      <MobileCardSummary
        buttonText={buttonText}
        onClick={onClick}
        isAgreementActive={isAgreementActive}
        executeAgreementsListScroll={executeAgreementsListScroll}
        orderSummary={orderSummary}
      />
      {loyaltyBox && <Loyalty />}
      {pathName === '/basket' && <InstreetClub />}
      <div className="coupon-wrapper">
        {isCouponActive && <Coupon />}
        {project === 'flo' && <ReturnDelivery />}
      </div>
      {isOldProductActive && <OldProducts show="desktop" />}
      {(isShareBasketActive && project !== 'reebok') && (<div className="is-mobile">{!guest && <ShareBasket />}</div>)}
    </>
  );
};

Sidebar.defaultProps = {
  buttonText: '',
  onClick: () => {},
  isCouponActive: false,
  isAgreementActive: false,
  executeAgreementsListScroll: () => {},
};

Sidebar.propTypes = {
  buttonText: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  isCouponActive: PropTypes.bool.isRequired,
  isAgreementActive: PropTypes.bool,
  executeAgreementsListScroll: PropTypes.func,
};

export default Sidebar;
