import config from '@Config';
import React from 'react';
import { useSelector } from 'react-redux';
import {
  Navigate,
} from 'react-router-dom';

export default ({ to, onWebView }) => {
  const { webview } = useSelector((state) => state.config);
  const { appDeepLink } = config;

  if (webview) {
    window.location.href = `${appDeepLink}${onWebView}`;
    return null;
  }

  return (
    <Navigate to={to} />
  );
};
