import * as React from 'react'
import { memo } from 'react'
const SvgSimpleCreditCard = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='1em'
    height='1em'
    viewBox='0 0 12.179 7.951'
    fill='currentColor'
    {...props}
  >
    <defs>
      <clipPath id='simple-credit-card_svg__a'>
        <path
          fill='none'
          d='M0 0h12.179v7.95H0z'
          data-name='Rectangle 13762'
        />
      </clipPath>
    </defs>
    <g
      clipPath='url(#simple-credit-card_svg__a)'
      data-name='Group 14991'
    >
      <path
        d='M12.179 1.028A1.03 1.03 0 0 0 11.151 0H1.028A1.03 1.03 0 0 0 0 1.028v.932h12.179Z'
        data-name='Path 13744'
      />
      <path
        d='M0 3.855v3.068a1.03 1.03 0 0 0 1.028 1.032h10.123a1.03 1.03 0 0 0 1.028-1.028V3.855Zm3.574 2.522h-1.53a.4.4 0 1 1 0-.8h1.53a.4.4 0 1 1 0 .8m3.244 0H5.292a.4.4 0 1 1 0-.8h1.526a.4.4 0 1 1 0 .8'
        data-name='Path 13745'
      />
    </g>
  </svg>
)
const Memo = memo(SvgSimpleCreditCard)
export default Memo
