/* eslint-disable no-param-reassign */
/* eslint-disable import/no-cycle */
import {
  createSlice,
} from '@reduxjs/toolkit';
import Cookie from 'js-cookie';

const slice = createSlice({
  name: 'config',
  initialState: {
    webview: !!Cookie.get('webview'),
    native: Cookie.get('appBasketPageType') === 'native',
    disaster: false,
  },
  reducers: {
    visibleBackButtonAction: (state, action) => {
      state.backButton = action.payload;
    },
    visibleCloseButtonAction: (state, action) => {
      state.closeButton = action.payload;
    },
    setDisasterAction: (state, action) => {
      state.disaster = action.payload;
    }
  },
  extraReducers: (builder) => {

  },
});

export default slice.reducer;

export const {
  visibleBackButtonAction,
  visibleCloseButtonAction,
  setDisasterAction,
} = slice.actions;
