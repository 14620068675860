import * as React from 'react'
import { memo } from 'react'
const SvgComplate = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='1em'
    height='1em'
    viewBox='0 0 47.5 40.364'
    fill='currentColor'
    {...props}
  >
    <g strokeWidth={2} data-name='Group 5260'>
      <g
        fill='#fff'
        stroke='#28a745'
        data-name='Ellipse 79'
        transform='translate(0 .364)'
      >
        <ellipse cx={19.5} cy={20} stroke='none' rx={19.5} ry={20} />
        <ellipse cx={19.5} cy={20} fill='none' rx={18.5} ry={19} />
      </g>
      <path
        fill='#28a745'
        stroke='#fff'
        d='M45.285 8.076 23.846 29.515a4.14 4.14 0 0 1-5.862 0L7.933 19.466a4.146 4.146 0 0 1 5.864-5.862l7.116 7.119 18.51-18.51a4.145 4.145 0 1 1 5.861 5.861Z'
        data-name='Path 762'
      />
    </g>
  </svg>
)
const Memo = memo(SvgComplate)
export default Memo
