/* eslint-disable no-param-reassign */
/* eslint-disable import/no-cycle */
import React from 'react';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import API from '@API';
import { toast } from 'react-toastify';
import { ToastfyMessage } from '@Components';
import Cookie from 'js-cookie';
import config from '@Config';
import { setAuthToken as setAuthTokenNew } from '@Actions';

const { failuresNumber } = config;

const { BASE_URL } = config;

const handleCookie = (Cookie.get('is_encrypted') === '1' ? Cookie.get('d8aa4b94ba5a823b53cde96be4a3aef7') : Cookie.get('_token')) || Cookie.get('__anon_id');

export const loginAction = createAsyncThunk(
  'user/login',
  async (arg, { dispatch }) => {
    const response = await API.postLoginRequest({ ...arg });
    if (response?.data?.status?.code === 200) {
      if (response?.data?.status?.token) {
        // await checkAxiosInstance(response?.data?.status?.token);
        dispatch(setAuthTokenNew(response?.data?.status?.token));
      }
      if (!response?.data?.status?.token) {
        const timer = setInterval(async () => {
          if (handleCookie) {
            // await checkAxiosInstance();
            await dispatch(setAuthTokenNew(handleCookie));
            clearInterval(timer);
          }
        }, 100);
      }
    }
    return response.data;
  },
);

// Cookie'nin temizlenmesini bekle
const waitForCookie = async () => {
  let attempts = 10;  // Maksimum 10 deneme yap
  return new Promise((resolve) => {
    const interval = setInterval(() => {

      if (!handleCookie || attempts <= 0) {
        clearInterval(interval);
        resolve();
      }

      attempts -= 1;
    }, 100);
  });
};

export const registerAction = createAsyncThunk(
  'user/register',
  async (arg, { dispatch }) => {
    const response = await API.postRegisterRequest({ ...arg });
    if (response?.data?.status?.code === 200) {
      if (response?.data?.status?.token) {
        // await checkAxiosInstance(response?.data?.status?.token);
        dispatch(setAuthTokenNew(response?.data?.status?.token));
      }
      if (!response?.data?.status?.token) {
        const timer = setInterval(async () => {
          if (handleCookie) {
            // await checkAxiosInstance();
            await dispatch(setAuthTokenNew(handleCookie));
            clearInterval(timer);
          }
        }, 100);
      }
    }
    return response.data;
  },
);

export const logoutAction = createAsyncThunk(
  'user/logout',
  async (arg) => {
    const response = await API.logoutRequest({ ...arg });
    if (response?.data?.status?.code === 200) {
      await waitForCookie()
    }
    return response.data;
  },
);

export const loginAggrementAction = createAsyncThunk(
  'user/aggrement',
  async (arg) => {
    const response = await API.postLoginAggrementsRequest({ ...arg });
    return response.data;
  },
);

export const questAction = createAsyncThunk(
  'user/quest',
  async (arg) => {
    const response = await API.guestRequest({ ...arg });
    return response.data;
  },
);

export const signInWithGoogleAction = createAsyncThunk(
  'user/signin/google',
  async (arg, { dispatch }) => {
    const response = await API.signInWithGoogle({ ...arg });
    if (response?.data?.status?.code === 200) {
      if (response?.data?.status?.token) {
        // await checkAxiosInstance(response?.data?.status?.token);
        dispatch(setAuthTokenNew(response?.data?.status?.token));
      }
    }
    return response.data;
  },
);

export const signInWithAppleAction = createAsyncThunk(
  'user/signin/apple',
  async (arg, { dispatch }) => {
    const response = await API.signInWithApple({ ...arg });
    if (response?.data?.status?.code === 200) {
      if (response?.data?.status?.token) {
        // await checkAxiosInstance(response?.data?.status?.token);
        dispatch(setAuthTokenNew(response?.data?.status?.token));
      }
    }
    return response.data;
  },
);

export const getCustomerAction = createAsyncThunk(
  'user/info',
  async (arg) => {
    const response = await API.getCustomer({ ...arg });
    return response.data;
  },
);

export const facebookConnectAction = createAsyncThunk(
  'user/signin/facebook',
  async (arg, { dispatch }) => {
    const response = await API.facebookConnection({ ...arg });
    if (response?.data?.status?.code === 200) {
      if (response?.data?.status?.token) {
        // await checkAxiosInstance(response?.data?.status?.token);
        dispatch(setAuthTokenNew(response?.data?.status?.token));
      }
    }
    return response.data;
  },
);

export const forgotPasswordAction = createAsyncThunk(
  'user/password-forgot',
  async (arg) => {
    const response = await API.forgotPasswordRequest({ ...arg });
    return response.data;
  },
);

export const phoneLoginAction = createAsyncThunk(
  'user/phoneLogin',
  async (arg, { dispatch }) => {
    const response = await API.phoneLogin({ ...arg });
    if (response?.data?.status?.code === 200) {
      if (response?.data?.status?.token) {
        // await checkAxiosInstance(response?.data?.status?.token);
        dispatch(setAuthTokenNew(response?.data?.status?.token));
      }
    }
    return response.data;
  },
);

const INITIAL_STATE = {
  token: (Cookie.get('is_encrypted') === '1' ? Cookie.get('d8aa4b94ba5a823b53cde96be4a3aef7') : Cookie.get('_token') ) || Cookie.get('quest') || null,
  guest: (Cookie.get('is_encrypted') === '1' ? !Cookie.get('d8aa4b94ba5a823b53cde96be4a3aef7') : !Cookie.get('_token')) ? true : !!Cookie.get('quest'),
  isGuest: false,
  information: null,
  failuresNum: 0,
  isPhoneLogin: false,
};

const slice = createSlice({
  name: 'auth',
  initialState: INITIAL_STATE,
  reducers: {
    setAuthToken: (state, action) => {
      state.token = action.payload;
    },
    getAuthToken: (state) => state.token,
    setUserInformation: (state, action) => {
      state.information = action.payload;
    },
    deleteAuthToken: (state, action) => {
      state.token = null;
    },
    setGuest: (state,action) => {
      state.token = action.payload;
      state.guest = action.payload;
    },
    isGuestAction: (state, action) => {
      state.isGuest = action.payload;
    },
    isPhoneLoginAction: (state, action) => {
      state.isPhoneLogin = action.payload;
    }
  },
  extraReducers: (builder) => {
    // getCustomerAction
    builder.addCase(getCustomerAction.pending, (state, action) => {
      state.authCustomerLoading = true;
      state.authCustomerRequestId = action.meta.requestId;
    });
    builder.addCase(
      getCustomerAction.fulfilled,
      (state, { payload }) => {
        const { status, customer } = payload;
        if (status.code === 200) {
          state.information = customer;
        }

        state.authCustomerLoading = false;
        state.authCustomerRequestId = null;
      }
    );
    builder.addCase(getCustomerAction.rejected, (state) => {
      state.authCustomerLoading = false;
      state.authCustomerRequestId = null;
    });

    // loginAction
    builder.addCase(loginAction.pending, (state, action) => {
      state.loginLoading = true;
      state.loginRequestId = action.meta.requestId;
    });
    builder.addCase(loginAction.fulfilled, (state, { payload }) => {
      const { status, message, failures_num, success, customer, redirect, error_code } =
        payload;
      // eslint-disable-next-line no-unused-expressions
      if (status?.code === 200) {
        //localde çalışması için cookie setlenmesi gerekiyor şimdilik
        if (process.env.NODE_ENV === 'development') {
          status.token !== undefined ? Cookie.set('_token', status.token) : '';
          status.token !== undefined ? Cookie.set('is_logged_in', 1) : '';
        }
        //state.token = status.token;
        state.information = { ...payload };
        state.guest = false;
      }

      state.failuresNum = failures_num;

      if (Number(failures_num) >= Number(failuresNumber)) {
        window.location.reload();
      }
      if (success === false) {
        if (error_code === 2073 && redirect) {
          window.location.href = (BASE_URL + redirect).replace(/([^:]\/)\/+/g, "$1");
        } else {
          toast.dismiss();
          toast.error(ToastfyMessage({ text: message }));
        }
      }
      state.loginLoading = false;
      state.loginRequestId = null;
    });
    builder.addCase(loginAction.rejected, (state) => {
      state.loginLoading = false;
      state.loginRequestId = null;
    });

    // registerAction
    builder.addCase(registerAction.pending, (state, action) => {
      state.registerLoading = true;
      state.registerRequestId = action.meta.requestId;
    });
    builder.addCase(
      registerAction.fulfilled,
      (state, { payload }) => {
        const { success, status, message, page_reload, customer } = payload;
        if (success === true) {
          //localde çalışması için cookie setlenmesi gerekiyor şimdilik
          if (process.env.NODE_ENV === 'development') {
            status.token !== undefined ? Cookie.set('_token', status.token) : '';
            status.token !== undefined ? Cookie.set('is_logged_in', 1) : '';
          }
          // state.token = status.token;
          state.guest = false;
          state.information = { ...payload };
        }

        if (message) {
          toast.dismiss();
          toast.error(ToastfyMessage({ text: message }));
        }

        if (page_reload) {
          window.location.reload();
        }

        state.registerLoading = false;
        state.registerRequestId = null;
      }
    );
    builder.addCase(registerAction.rejected, (state) => {
      state.registerLoading = false;
      state.registerRequestId = null;
    });

    // loginaggrementAction
    builder.addCase(loginAggrementAction.pending, (state, action) => {
      state.aggrementLoading = false;
    });
    builder.addCase(
      loginAggrementAction.fulfilled,
      (state, { payload }) => {
        const { agreement_pages } = payload;
        state.loginAggrements = agreement_pages;
      }
    );
    builder.addCase(loginAggrementAction.rejected, (state) => {
      state.aggrementLoading = false;
    });

    // logoutAction
    builder.addCase(logoutAction.pending, (state, action) => {
      state.logoutLoading = false;
      state.logoutRequestId = action.meta.requestId;
    });
    builder.addCase(logoutAction.fulfilled, (state, { payload }) => {
        const { status, message } = payload;
        const { appDeepLink, BASE_URL, CHECKOUT_BASE_URL } = config;
        if (status?.code === 200) {
          if (Cookie.get('webview')) {
            window.location.href = `${appDeepLink}logout`;
          } else {
            window.location.href = `${BASE_URL}customer/login?redirect=${CHECKOUT_BASE_URL}`;
          }
        } else {
          toast.error(ToastfyMessage({ text: status?.message || message }));
        }
        state.logoutLoading = false;
        state.logoutRequestId = null;
    });
    builder.addCase(logoutAction.rejected, (state) => {
      state.logoutLoading = false;
      state.logoutRequestId = null;
    });

    // guestAction
    builder.addCase(questAction.pending, (state, action) => {
      state.questLoading = true;
      state.questRequestId = action.meta.requestId;
    });
    builder.addCase(questAction.fulfilled, (state, { payload }) => {
      const { success, message } = payload;
      if (success === true) {
        state.token = true;
        state.guest = true;
        // state.information = Object.assign({}, payload);
      }

      if (success === false) {
        toast.dismiss();
        toast.error(ToastfyMessage({ text: message }));
      }

      state.questLoading = false;
      state.questRequestId = null;
    });
    builder.addCase(questAction.rejected, (state) => {
      state.questLoading = false;
      state.questRequestId = null;
    });

    // facebookConnectionRequest
    builder.addCase(
      facebookConnectAction.pending,
      (state, action) => {
        state.facebookConnectionLoading = true;
        state.facebookConnectionRequestId = action.meta.requestId;
      }
    );
    builder.addCase(
      facebookConnectAction.fulfilled,
      (state, { payload }) => {
        const { success, data, message } = payload;
        if (success === true) {
          if (data?.redirect_to_register) {
            window.location = `${BASE_URL}customer/register`;
          } else {
            state.guest = false;
            // state.token = true;
          }
        }

        if (success !== true) {
          toast.dismiss();
          toast.error(ToastfyMessage({ text: message || '' }));
        }

        state.facebookConnectionLoading = false;
        state.facebookConnectionRequestId = null;
      }
    );
    builder.addCase(facebookConnectAction.rejected, (state) => {
      state.facebookConnectionLoading = false;
      state.facebookConnectionRequestId = null;
    });

    // signInWithGoogleAction
    builder.addCase(
      signInWithGoogleAction.pending,
      (state, action) => {
        state.googleConnectionLoading = true;
        state.googleConnectionRequestId = action.meta.requestId;
      }
    );
    builder.addCase(
      signInWithGoogleAction.fulfilled,
      (state, { payload }) => {
        const { success, data, message } = payload;
        if (success === true) {
          if (data?.redirect_to_register) {
            window.location = `${BASE_URL}customer/register`;
          } else {
            state.guest = false;
            // state.token = true;
          }
        }

        if (success !== true) {
          toast.dismiss();
          toast.error(ToastfyMessage({ text: message || '' }));
        }

        state.googleConnectionLoading = false;
        state.googleConnectionRequestId = null;
      }
    );
    builder.addCase(signInWithGoogleAction.rejected, (state) => {
      state.googleConnectionLoading = false;
      state.googleConnectionRequestId = null;
    });

    // apple
    builder.addCase(
      signInWithAppleAction.pending,
      (state, action) => {
        state.appleConnectionLoading = true;
        state.appleConnectionRequestId = action.meta.requestId;
      }
    );
    builder.addCase(
      signInWithAppleAction.fulfilled,
      (state, { payload }) => {
        const { success, data, message } = payload;
        if (success === true) {
          if (data?.redirect_to_register) {
            window.location = `${BASE_URL}customer/register`;
          } else {
            state.guest = false;
            // state.token = true;
          }
        }

        if (success !== true) {
          toast.dismiss();
          toast.error(ToastfyMessage({ text: message || '' }));
        }

        state.appleConnectionLoading = false;
        state.appleConnectionRequestId = null;
      }
    );
    builder.addCase(signInWithAppleAction.rejected, (state) => {
      state.appleConnectionLoading = false;
      state.appleConnectionRequestId = null;
    });

    //forgotPassoword
    builder.addCase(forgotPasswordAction.pending, (state) => {
      state.forgotPasswordActionLoading = true;
    });
    builder.addCase(
      forgotPasswordAction.fulfilled,
      (state, { payload }) => {
        const { status } = payload;

        if (status.code != 200) {
          toast.dismiss();
          toast.error(ToastfyMessage({ text: status.message }));
        }

        state.forgotPasswordActionLoading = false;
      }
    );
    builder.addCase(forgotPasswordAction.rejected, (state) => {
      state.forgotPasswordActionLoading = false;
    });

    //phoneLogin
    builder.addCase(phoneLoginAction.pending, (state) => {
      state.phoneLoginActionLoading = true;
    });
    builder.addCase(
      phoneLoginAction.fulfilled,
      (state, { payload }) => {
        const { status, error_code, redirect, message, showPasswordScreen, showVerifiedCode } = payload;

        if (status?.code === 200 && status.token) {
          //localde çalışması için cookie setlenmesi gerekiyor şimdilik
          if (process.env.NODE_ENV === 'development') {
            Cookie.set('_token', status.token);
            Cookie.set('is_logged_in', 1);
          }
          //state.token = status.token;
          state.information = { ...payload };
          state.guest = false;
        } else if (!showPasswordScreen && !showVerifiedCode) {
          if (error_code === 2073 && redirect) {
            window.location.href = (BASE_URL + redirect).replace(/([^:]\/)\/+/g, '$1');
          } else if (message) {
            toast.dismiss();
            toast.error(ToastfyMessage({ text: message }));
          } else {
            toast.dismiss();
            toast.error(ToastfyMessage({ text: status.message }));
          }
        }

        state.phoneLoginActionLoading = false;
      }
    );
    builder.addCase(phoneLoginAction.rejected, (state) => {
      state.phoneLoginActionLoading = false;
    });
  },
});

export default slice.reducer;

export const {
  getAuthToken,
  setAuthToken,
  deleteAuthToken,
  setUserInformation,
  setGuest,
  isGuestAction,
  isPhoneLoginAction,
} = slice.actions;
