// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("30121210.eot", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("30121210.otf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("30121210.svg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("30121210.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_4___ = new URL("30121210.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_5___ = new URL("30121210.woff2", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___, { hash: "?#iefix" });
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_6___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face{font-display:block;font-family:ProximaNovaA;font-style:normal;font-weight:400;src:url(${___CSS_LOADER_URL_REPLACEMENT_0___});src:url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format("embedded-opentype"),url(${___CSS_LOADER_URL_REPLACEMENT_2___}) format("opentype"),url(${___CSS_LOADER_URL_REPLACEMENT_3___}) format("svg"),url(${___CSS_LOADER_URL_REPLACEMENT_4___}) format("truetype"),url(${___CSS_LOADER_URL_REPLACEMENT_5___}) format("woff"),url(${___CSS_LOADER_URL_REPLACEMENT_6___}) format("woff2")}`, "",{"version":3,"sources":["webpack://./src/fonts/proximanova-a/style.css"],"names":[],"mappings":"AACA,WAWE,kBAAA,CAVA,wBAAA,CASA,iBAAA,CADA,eAAA,CAPA,2CAAA,CACA,kWAQA","sourcesContent":["/* font converted using font-converter.net. thank you! */\n@font-face {\n  font-family: \"ProximaNovaA\";\n  src: url(\"./30121210.eot\"); /* IE9 Compat Modes */\n  src: url(\"./30121210.eot?#iefix\") format(\"embedded-opentype\"), /* IE6-IE8 */\n    url(\"./30121210.otf\") format(\"opentype\"), /* Open Type Font */\n    url(\"./30121210.svg\") format(\"svg\"), /* Legacy iOS */\n    url(\"./30121210.ttf\") format(\"truetype\"), /* Safari, Android, iOS */\n    url(\"./30121210.woff\") format(\"woff\"), /* Modern Browsers */\n    url(\"./30121210.woff2\") format(\"woff2\"); /* Modern Browsers */\n  font-weight: normal;\n  font-style: normal;\n  font-display: block;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
