/* eslint-disable import/prefer-default-export */
export const incideriDev = {
  BASE_URL: 'https://staging.incideri.com.tr/',
  CHECKOUT_URL: 'https://checkout-be.staging.incideri.com.tr/',
  currency: 'TL',
  locale: 'tr',
  symbol: 'false',
  quoteitemtotal: 20,
  logoPath: 'pub/assets/inciayakkabi/img/inci-logo.svg',
  project: 'incideri',
  title: 'İnci Deri',
  appDeepLink: 'incideri://',
  failuresNumber: '3',
  manifest: 'pub/pwa/manifest-incideri.json',
  favicon: 'pub/assets/inciayakkabi/img/inci-logo.svg',
  loadingAnimation: 'pub/assets/flo/img/loading/flo_loading_white.gif',
  siteKey: '6LcPQ64UAAAAAJv489J59h58XQ6t2X8cHODjm9NR',
  gtmAccountId: 'GTM-T4KVJH2',
  robotoFont: '',
  segmentifyScript: '',
  siteName: 'INCIDERI.COM.TR.',
  cunda: true
};

export const incideriStaging = {
  BASE_URL: 'https://staging.incideri.com.tr/',
  CHECKOUT_URL: 'https://checkout-be.staging.incideri.com.tr/',
  currency: 'TL',
  locale: 'tr',
  symbol: 'false',
  quoteitemtotal: 20,
  logoPath: 'pub/assets/inciayakkabi/img/inci-logo.svg',
  project: 'incideri',
  title: 'İnci Deri',
  appDeepLink: 'incideri://',
  failuresNumber: '3',
  manifest: 'pub/pwa/manifest-incideri.json',
  favicon: 'pub/assets/inciayakkabi/img/inci-logo.svg',
  loadingAnimation: 'pub/assets/flo/img/loading/flo_loading_white.gif',
  siteKey: '6LcPQ64UAAAAAJv489J59h58XQ6t2X8cHODjm9NR',
  gtmAccountId: 'GTM-T4KVJH2',
  robotoFont: '',
  segmentifyScript: '',
  siteName: 'INCIDERI.COM.TR.',
  cunda: true
};

export const incideriPreprod = {
  BASE_URL: 'https://preprod.incideri.com.tr/',
  CHECKOUT_URL: 'https://checkout-be.preprod.incideri.com.tr/',
  currency: 'TL',
  locale: 'tr',
  symbol: 'false',
  quoteitemtotal: 20,
  logoPath: 'pub/assets/inciayakkabi/img/inci-logo.svg',
  project: 'incideri',
  title: 'İnci Deri',
  appDeepLink: 'incideri://',
  failuresNumber: '3',
  manifest: 'pub/pwa/manifest-incideri.json',
  favicon: 'pub/assets/inciayakkabi/img/inci-logo.svg',
  loadingAnimation: 'pub/assets/flo/img/loading/flo_loading_white.gif',
  siteKey: '6LcPQ64UAAAAAJv489J59h58XQ6t2X8cHODjm9NR',
  gtmAccountId: 'GTM-T4KVJH2',
  robotoFont: '',
  segmentifyScript: '',
  siteName: 'INCIDERI.COM.TR.',
  cunda: true
};

export const incideriProd = {
  BASE_URL: 'https://www.incideri.com.tr/',
  CHECKOUT_URL: 'https://checkout-be.incideri.com.tr/',
  currency: 'TL',
  locale: 'tr',
  symbol: 'false',
  quoteitemtotal: 20,
  logoPath: 'pub/assets/inciayakkabi/img/inci-logo.svg',
  project: 'incideri',
  title: 'İnci Deri',
  appDeepLink: 'incideri://',
  failuresNumber: '3',
  manifest: 'pub/pwa/manifest-incideri.json',
  favicon: 'pub/assets/inciayakkabi/img/inci-logo.svg',
  loadingAnimation: 'pub/assets/flo/img/loading/flo_loading_white.gif',
  siteKey: '6LcPQ64UAAAAAJv489J59h58XQ6t2X8cHODjm9NR',
  gtmAccountId: 'GTM-T4KVJH2',
  robotoFont: '',
  segmentifyScript: '',
  siteName: 'INCIDERI.COM.TR.',
  cunda: false
};
