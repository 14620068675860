/* eslint-disable react/jsx-filename-extension */
import React, { useEffect, useState, useRef } from 'react';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import {
  Input,
} from '@Components';
import {
  phoneControl,
} from '@Constants';
import {
  sendActivationSmsAction,
  checkActivationSmsAction,
  hidePopupAction,
  setOtpStep,
} from '@Actions';
import Countdown from 'react-countdown';
import {
  gettext,
} from '@Translate';

const SMSActivation = ({ onSuccess }) => {
  const dispatch = useDispatch();
  const {
    otpStep,
  } = useSelector((state) => state.order);
  const [code, setCode] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [showError, setShowError] = useState(false);
  const [showSmsError, setShowSmsError] = useState(false);
  const [showSmsErrorFirst, setShowSmsErrorFirst] = useState(false);
  const [minutes, setMinutes] = useState('');
  const [phoneCheck, setPhoneCheck] = useState(false);
  const [reSendCode, setReSendCode] = useState(false);

  const startDate = useRef(Date.now());

  useEffect(() => () => {
    dispatch(setOtpStep(1));
  }, ['init']);

  const sendActivationSms = async () => {
    await setMinutes(0);
    if (phoneCheck) {
      await dispatch(sendActivationSmsAction({ phoneNumber })).then(({ payload }) => {
        const { time, status } = payload;
        if (status?.code === 200) {
          startDate.current = Date.now();
          setMinutes(time * 1000);
          setReSendCode(false);
        }
      });
    } else {
      setShowError(true);
    }
  };

  const handlePhoneNumberForm = (event) => {
    event.preventDefault();

    sendActivationSms();
  };

  const checkActivationSms = () => {
    if (code?.replace(/[^0-9]/g, '').length === 6) {
      dispatch(checkActivationSmsAction({ code }));
    } else {
      setShowSmsError(true);
      setShowSmsErrorFirst(true);
    }
  };

  const handleCodeForm = (event) => {
    event.preventDefault();
    checkActivationSms();
  };

  const renderer = ({ completed, formatted, total }) => {
    if (completed) {
      setReSendCode(true);
      return (
        <span>
          00:00
        </span>
      );
    }
    return (
      <span>
        {formatted?.minutes}
        :
        {formatted?.seconds}
      </span>
    );
  };

  const handleClose = () => {
    dispatch(hidePopupAction());
    dispatch(setOtpStep(1));
  };

  const phoneOnChange = (phone) => {
    setPhoneNumber(phone?.target?.value);
    setPhoneCheck(phoneControl(phone?.target?.value));
    const valueLenght = phone?.target?.value?.replace(/[^0-9]/g, '').length;
    if (valueLenght > 9) {
      setShowError(!phoneControl(phone?.target?.value));
    }
  };

  const codeOnChange = (event) => {
    setCode(event.target.value);
    if (showSmsErrorFirst) {
      if (event.target.value.replace(/[^0-9]/g, '').length === 6) {
        setShowSmsError(false);
      } else {
        setShowSmsError(true);
      }
    }
  };

  return (
    <>
      <div className="sms-activation__title text-center mb-3">
        {gettext('KAPIDA ÖDEME')}
      </div>
      {otpStep === 2 ? (
        <form onSubmit={handleCodeForm}>
          <div className="detail-bas">
            <div className="sms-activation__information mb-3">
              <span id="ajx_cevap">{phoneNumber}</span>
              {' '}
              {gettext('numaralı telefonuza gönderilen 6 haneli doğrulama kodunu giriniz.')}
            </div>
            <div className="form-group">
              <input
                type="number"
                inputMode="numeric"
                id="activation_code"
                className="form-control"
                placeholder={gettext('Doğrulama Kodu')}
                value={code}
                onChange={codeOnChange}
                data-test="checkout-payment-sms-activation-numeric-active-code-change"
              />
              {showSmsError && (
                <div className="error">
                  {gettext('Geçerli bir doğrulama kodu giriniz.')}
                </div>
              )}
            </div>
            <div className="form-group">
              <div className="countdown sms-activation__countdown">
                {gettext('Kalan Süreniz:')}
                {' '}
                <div className="countdownNew sms-activation__timer finished">
                  <Countdown key={startDate?.current} date={startDate?.current + minutes} renderer={renderer} />
                </div>
              </div>
            </div>
            <div className="row">
              {reSendCode && (
                <div className="col-12">
                  <button
                    type="button"
                    className="btn re-send-btn btn-orange w-100"
                    id="sms_rety"
                    data-test="checkout-payment-sms-again-two-button"
                    onClick={() => {
                      sendActivationSms();
                    }}
                  >
                    {gettext('Tekrar SMS Gönder')}
                  </button>
                </div>
              )}
              <div className="col-6">
                <button type="button" className="sms-activation__cancel-btn" onClick={handleClose} data-test="checkout-payment-sms-cancel-two-button">
                  {gettext('İptal')}
                </button>
              </div>
              <div className="col-6">
                <button type="button" id="check_activation_sms" className="sms-activation__send-btn primary-color-bg" onClick={checkActivationSms} data-test="checkout-payment-sms-confirm-two-button">
                  {gettext('GÖNDER')}
                </button>
              </div>
            </div>
          </div>
        </form>
      ) : (
        <form onSubmit={handlePhoneNumberForm}>
          <div className="detail-bas">
            <div className="sms-activation__information mb-3">
              {gettext('6 haneli doğrulama kodunu almak için telefon numaranızı giriniz.')}
            </div>
            <div className="form-group">
              <Input
                pattern="phone"
                type="text"
                id="activation_code"
                className="sms-activation__input"
                placeholder={gettext('Cep Telefonu')}
                value={phoneNumber}
                onChange={phoneOnChange}
                data-test="checkout-payment-sms-activation-active-code-change"
              />
              {showError && (
                <div className="error">
                  {gettext('Geçerli telefon numarası giriniz.')}
                </div>
              )}
            </div>
            <div className="row">
              <div className="col-6">
                <button type="button" className="sms-activation__cancel-btn" onClick={handleClose} data-test="checkout-payment-sms-cancel-button">
                  {gettext('İptal')}
                </button>
              </div>
              <div className="col-6">
                <button type="button" id="check_activation_sms" className="sms-activation__send-btn primary-color-bg" onClick={sendActivationSms} data-test="checkout-payment-sms-confirm-button">
                  {gettext('GÖNDER')}
                </button>
              </div>
            </div>
          </div>
        </form>
      )}
    </>
  );
};

export default SMSActivation;
