import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { showPopupAction, isTopPopupAction } from '@Actions';
import { gettext } from '@Translate';
import { toast } from 'react-toastify';
import { IconNextArrow } from '@Icons';
import APIs from '@API';
import ShareBasketPopup from './ShareBasketPopup';
import ToastfyMessage from '../../ToastfyMessage/ToastfyMessage';
import config from '@Config';

const ShareBasket = () => {
  const { project } = config;
  const dispatch = useDispatch();
  const handleShareBasket = () => {
    APIs.postShareBasket()
      .then((response) => {
        if (response?.data?.status?.code !== 200) {
          toast.dismiss();
          toast.error(ToastfyMessage({ text: response?.data?.status?.message || gettext('Bir hata oluştu.') }));
        } else {
          dispatch(isTopPopupAction(false));
          dispatch(
            showPopupAction(() => ShareBasketPopup(response.data.link, response?.data?.checkDeliveryOptions === true, response?.data?.checkDeliveryOptionsMessage)),
          );
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <div className="share-basket" onClick={handleShareBasket} data-test="checkout-share-basket-button">
      <div className="share-basket__first-section">
        <div className="d-flex align-items-baseline share-txt">
          <span className="icon-share-basket share-basket__first-section--icon" />
          <div className="share-basket__first-section--text">
            {gettext('Sepetini Arkadaşınla Paylaş')}
          </div>
        </div>
        {
          !project ==='sneakerbox' &&<div className="share-basket__text">
            {gettext('Beğendiğiniz ürünleri kolayca sepetinize ekleyebilir ve arkadaşınızla paylaşabilirsiniz.')}
          </div>
        }
      </div>
      <div className="share-basket__mobile-icon">
        <IconNextArrow />
      </div>
    </div>
  );
};

export default ShareBasket;
