/* eslint-disable no-param-reassign */
/* eslint-disable import/no-cycle */
import {
  createSlice,
} from '@reduxjs/toolkit';
import store from '@Store';
import SnackBar from 'node-snackbar';

function ShowNotification(text, type) {
  const options = {
    text,
    pos: 'bottom-right',
    actionText: '&times;',
  };
  if (type === 'error') {
    options.backgroundColor = '#e60000';
  }
  SnackBar.show(options);
}

function debounce(func, wait, immediate) {
  var timeout;
  return function() {
    var context = this, args = arguments;
    var later = function() {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    var callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
};

const control = debounce(() => {
  setTimeout(() => {
    store.dispatch({ type: 'HIDE_SNACKBAR', isOpen: false });
  }, 2000);
}, 300);

const INITIAL_STATE = { isOpen: false };

const slice = createSlice({
  name: 'snackbar',
  initialState: INITIAL_STATE,
  reducers: {
    showSnackbarAction: (state, action) => {
      control();
      ShowNotification(action.payload.message);
      state.isOpen = false;
      state.message = action.payload.message;
      state.type = action.payload.snacknarType;
    },
    hideSnackbarAction: (state, action) => {
      state.closeButton = action.payload;
      state.isOpen = false;
    },
  },
  extraReducers: (builder) => {

  },
});

export default slice.reducer;

export const {
  showSnackbarAction,
  hideSnackbarAction,
} = slice.actions;
