import * as React from 'react'
import { memo } from 'react'
const SvgIconGreenCheck = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='1em'
    height='1em'
    viewBox='0 0 12.85 12.85'
    fill='currentColor'
    {...props}
  >
    <g
      data-name='Group 4402'
      transform='translate(-143.856 -225.856)'
    >
      <circle
        cx={6.425}
        cy={6.425}
        r={6.425}
        fill='#28a745'
        data-name='Ellipse 79'
        transform='translate(143.856 225.856)'
      />
      <path
        fill='#fff'
        d='m153.637 230.918-3.85 3.85a.743.743 0 0 1-1.053 0l-1.8-1.8a.744.744 0 1 1 1.053-1.052l1.278 1.278 3.324-3.324a.744.744 0 1 1 1.052 1.052Z'
        data-name='Path 762'
      />
    </g>
  </svg>
)
const Memo = memo(SvgIconGreenCheck)
export default Memo
