/* eslint-disable quote-props,guard-for-in,max-len,no-54beee44d0abf15fad246f1541548b660e15e23dunneeded-ternary */
import Layer from '@ziylan/gtm-datalayer';
import analytics from './analytics';
import productMapping from './productMapping';

window.analytics = analytics;

export default (events) => {
  // gtmDataLayer.init(analytics, events);
  const layer = new Layer(analytics, events, productMapping);
  window.layer = layer;
};
