import React from 'react';

const ToastfySuccess = ({ text, message }) => (
  <div className="d-flex">
    <span className="icon icon-icon-check">
      <span className="path1" />
      <span className="path2" />
      <span className="path3" />
    </span>
    <div className="text-area">
      <div className="title">{text}</div>
      <div className="message">{message}</div>
    </div>
  </div>
);

export default ToastfySuccess;
