/* eslint-disable no-param-reassign */
/* eslint-disable import/no-cycle */
import { createSlice } from '@reduxjs/toolkit';


const slice = createSlice({
  name: 'headerActive',
  initialState: {
    headerIsActive: false,
    paymentheaderIsActive: false,
  },
  reducers: {
    headerActiveAction: (state, action) => {
      state.headerIsActive = action.payload;
    },
    paymenHeaderActiveAction: (state, action) => {
      state.paymentheaderIsActive = action.payload;
    },
  },
});

export default slice.reducer;

export const {
  headerActiveAction,
  paymenHeaderActiveAction,
} = slice.actions;
