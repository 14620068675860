import * as React from 'react'
import { memo } from 'react'
const SvgPlusWarning = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='1em'
    height='1em'
    viewBox='0 0 26.756 24.245'
    fill='currentColor'
    {...props}
  >
    <g fill='#fff' data-name='warning (3)'>
      <path
        d='M22.745 24.245H4.01a4.014 4.014 0 0 1-3.474-6.018L9.899 2.002a4.024 4.024 0 0 1 6.958 0l9.363 16.224a4.014 4.014 0 0 1-3.474 6.018Zm-9.367-22.32a2.06 2.06 0 0 0-1.807 1.041L2.203 19.19a2.086 2.086 0 0 0 1.807 3.127h18.735a2.086 2.086 0 0 0 1.807-3.127L15.185 2.966a2.06 2.06 0 0 0-1.807-1.041m-2.641.559'
        data-name='Path 13851'
      />
      <path
        d='M13.378 15.61a.964.964 0 0 1-.964-.964V7.505a.964.964 0 1 1 1.927 0v7.141a.964.964 0 0 1-.963.964m0 4.221a.964.964 0 0 1-.964-.964v-.834a.964.964 0 0 1 1.927 0v.834a.964.964 0 0 1-.963.964'
        data-name='Path 13852'
      />
    </g>
  </svg>
)
const Memo = memo(SvgPlusWarning)
export default Memo
