/* eslint-disable no-param-reassign */
/* eslint-disable import/no-cycle */
import { createSlice } from '@reduxjs/toolkit';

const INITIAL_STATE = {
  isOpen: false,
  isChildOK: false,
  isTopPopup: false,
  isDisaster: false,
};

const slice = createSlice({
  name: 'popup',
  initialState: INITIAL_STATE,
  reducers: {
    showPopupAction: (state, action) => {
      state.isOpen = true;
      state.isChildOK = true;
      state.Content = action.payload;
    },
    isTopPopupAction: (state, action) => {
      state.isTopPopup = action.payload;
    },
    isDisasterPopupAction: (state, action) => {
      state.isDisaster = action.payload;
    },
    hidePopupAction: (state, action) => {
      state.isOpen = false;
      state.isChildOK = false;
      state.Content = null;
    },
  },
  extraReducers: (builder) => {},
});

export default slice.reducer;

export const { showPopupAction, hidePopupAction, isTopPopupAction, isDisasterPopupAction } = slice.actions;
