import * as React from 'react'
import { memo } from 'react'
const SvgReebokAngle = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='1em'
    height='1em'
    viewBox='0 0 10.241 7.681'
    fill='currentColor'
    {...props}
  >
    <path
      d='M10.241 0H0l5.12 7.681Z'
      data-name='8304c60b1afb91a33eaad09b18fbf027'
    />
  </svg>
)
const Memo = memo(SvgReebokAngle)
export default Memo
