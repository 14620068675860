import * as React from 'react'
import { memo } from 'react'
const SvgBackButton = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='1em'
    height='1em'
    data-name='noun_Next_1135493 (1)'
    viewBox='0 0 7.63 13.362'
    fill='currentColor'
    {...props}
  >
    <path
      d='m.336 7.484 5.584 5.584a1.002 1.002 0 1 0 1.416-1.419L2.368 6.681l4.968-4.967A1.003 1.003 0 1 0 5.917.295L.336 5.878a1.135 1.135 0 0 0 0 1.606'
      data-name='Path 139'
    />
  </svg>
)
const Memo = memo(SvgBackButton)
export default Memo
