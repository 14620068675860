import * as React from 'react'
import { memo } from 'react'
const SvgForgotPasswordBlack = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='1em'
    height='1em'
    data-name='Group 5545'
    viewBox='0 0 97.607 58.595'
    fill='currentColor'
    {...props}
  >
    <defs>
      <clipPath id='_forgot_password_black_svg__a'>
        <path
          fill='#d3d3d3'
          d='M0 0h97.607v58.595H0z'
          data-name='Rectangle 3093'
        />
      </clipPath>
      <clipPath id='_forgot_password_black_svg__b'>
        <path
          fill='none'
          d='M41.04.091 3.965 7.298a4.9 4.9 0 0 0-3.767 6.185c2.029 6.94 5.6 19.136 7.441 25.441a4.9 4.9 0 0 0 5.337 3.483l37-4.832a4.9 4.9 0 0 0 4.1-6.113L46.707 3.645A4.9 4.9 0 0 0 41.035.09'
          data-name='Path 13404'
        />
      </clipPath>
      <clipPath id='_forgot_password_black_svg__c'>
        <path
          fill='none'
          d='M0 0h97.607v58.595H0z'
          data-name='Rectangle 3094'
        />
      </clipPath>
    </defs>
    <g data-name='Group 5503'>
      <g
        clipPath='url(#_forgot_password_black_svg__a)'
        data-name='Group 5502'
      >
        <path
          fill='#d3d3d3'
          fillRule='evenodd'
          d='M53.523 10.048a4.9 4.9 0 0 0-5.672-3.555L10.776 13.7a4.9 4.9 0 0 0-3.768 6.185c2.029 6.94 5.6 19.136 7.441 25.441a4.9 4.9 0 0 0 5.337 3.484l37-4.832a4.9 4.9 0 0 0 4.1-6.113l-7.369-27.817'
          data-name='Path 13402'
        />
      </g>
    </g>
    <g data-name='Group 5505'>
      <g
        clipPath='url(#_forgot_password_black_svg__b)'
        data-name='Group 5504'
        transform='translate(6.811 6.401)'
      >
        <path
          fill='#adadad'
          fillRule='evenodd'
          d='m-6.811 9.847 32.318 15.4a3.03 3.03 0 0 0 3.534-.687L55.052-3.796C31.531-9.462 12.284-6.311-6.811 9.847'
          data-name='Path 13403'
        />
      </g>
    </g>
    <g data-name='Group 5507'>
      <g
        fillRule='evenodd'
        clipPath='url(#_forgot_password_black_svg__c)'
        data-name='Group 5506'
      >
        <path
          fill='#b2b2b2'
          d='M60.8 42.162 30.686 57.488l-2.445-12.712Z'
          data-name='Path 13405'
        />
        <path
          fill='#adadad'
          d='M60.8 42.162 30.686 57.488l-5.923-8.875Z'
          data-name='Path 13406'
        />
        <path
          d='M60.8 42.163 32.753 39.58a.88.88 0 0 0-.778.34l-3.73 4.857Z'
          data-name='Path 13407'
        />
        <path
          d='M60.791 42.145 18.606 57.409a.437.437 0 0 1-.487-.688l6.642-8.111Z'
          data-name='Path 13408'
        />
        <path
          d='M56.442 1.836a7.617 7.617 0 1 1-10.589 1.978 7.62 7.62 0 0 1 10.589-1.978'
          data-name='Path 13409'
        />
        <path
          fill='#fff'
          d='M54.85 6.183a2.09 2.09 0 0 0-1.84-2.2 2.3 2.3 0 0 0-2.482 1.667l-.481 1.734a1.36 1.36 0 0 1-.719.861 1.314 1.314 0 0 0 .281 2.465 31 31 0 0 0 4.8.691 1.02 1.02 0 0 0 1.069-.6 1.62 1.62 0 0 0-.391-1.461 1.8 1.8 0 0 1-.333-1.526c.028-.47.064-1.064.1-1.635'
          data-name='Path 13410'
        />
      </g>
    </g>
  </svg>
)
const Memo = memo(SvgForgotPasswordBlack)
export default Memo
