import * as React from 'react'
import { memo } from 'react'
const SvgBlackCheck = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='1em'
    height='1em'
    viewBox='0 0 76 76'
    fill='currentColor'
    {...props}
  >
    <defs>
      <clipPath id='black-check_svg__a'>
        <path
          fill='none'
          d='M0 0h76v76H0z'
          data-name='Rectangle 7961'
        />
      </clipPath>
    </defs>
    <g clipPath='url(#black-check_svg__a)' data-name='Group 15617'>
      <path
        fill='#fff'
        d='M38 76a38 38 0 1 1 37.929-40.229 38.017 38.017 0 1 0-.012 4.621A38.043 38.043 0 0 1 38 76'
        data-name='Path 13754'
      />
      <path
        fill='#fff'
        d='M38 2a36 36 0 1 0 36 36A36.04 36.04 0 0 0 38 2m-7.812 51.753L17.967 41.514l3.664-3.666 8.557 8.552 24.181-24.15 3.664 3.667Z'
        data-name='Path 13755'
      />
      <path
        d='M75.917 40.391a38.723 38.723 0 0 1 0 0'
        data-name='Path 13756'
      />
      <path
        d='M38 0a38 38 0 1 0 37.917 40.392C75.966 39.6 76 38.8 76 38c0-.749-.028-1.491-.071-2.229A38.045 38.045 0 0 0 38 0m0 74a36 36 0 1 1 36-36 36.04 36.04 0 0 1-36 36'
        data-name='Path 13757'
      />
      <path
        d='m30.188 46.4-8.557-8.549-3.664 3.666 12.221 12.236 27.845-27.84-3.664-3.666Z'
        data-name='Path 13758'
      />
    </g>
  </svg>
)
const Memo = memo(SvgBlackCheck)
export default Memo
