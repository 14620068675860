import * as React from 'react'
import { memo } from 'react'
const SvgBag = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='1em'
    height='1em'
    viewBox='0 0 41.87 41.87'
    fill='currentColor'
    {...props}
  >
    <path
      fill='#f60'
      fillRule='evenodd'
      d='M41.87 36.161a1.9 1.9 0 0 1-1.9 1.9h-1.9c0 1.9.2 3.806-1.9 3.806-2.08 0-1.9-1.9-1.9-3.806-1.9 0-3.806.2-3.806-1.9 0-2.08 1.9-1.9 3.806-1.9 0-1.9-.2-3.806 1.9-3.806s1.9 1.9 1.9 3.806c1.897-.004 3.8-.203 3.8 1.9M24.742 11.419h-11.42v-1.9a5.717 5.717 0 0 1 5.71-5.71 5.336 5.336 0 0 1 5.71 5.71Zm7.03 5.441 1.511 10.58a5.57 5.57 0 0 1 3.742-.723l-1.719-12.03a3.806 3.806 0 0 0-3.768-3.268H28.55v-1.9A9.244 9.244 0 0 0 19.034 0a9.526 9.526 0 0 0-9.516 9.516v1.9H7.109c-1.9 0-4.082 1.393-4.351 3.268L.038 33.719a3.806 3.806 0 0 0 3.768 4.345h22.839v-3.807H6a1.9 1.9 0 0 1-1.884-2.172L6.292 16.86a1.905 1.905 0 0 1 1.884-1.635h1.34v3.806a1.903 1.903 0 0 0 3.806 0v-3.805h11.42v3.806a1.903 1.903 0 0 0 3.806 0v-3.806h1.34a1.9 1.9 0 0 1 1.884 1.635Z'
    />
  </svg>
)
const Memo = memo(SvgBag)
export default Memo
