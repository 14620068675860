import * as React from 'react'
import { memo } from 'react'
const SvgSecurePayment = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='1em'
    height='1em'
    viewBox='0 0 104.642 19.192'
    fill='currentColor'
    {...props}
  >
    <g data-name='Group 5210'>
      <text
        fill='#18a861'
        data-name='G\xFCvenli Al\u0131\u015Fveri\u015F'
        fontFamily='Roboto-Regular, Roboto'
        fontSize={12}
        letterSpacing='-.025em'
        transform='translate(104.642 16.192)'
      >
        <tspan x={-82} y={0}>
          {'G\xFCvenli Al\u0131\u015Fveri\u015F'}
        </tspan>
      </text>
      <g data-name='Group 2303'>
        <path
          fill='#00a861'
          d='M12.992 7.087h-.342v-1.6a5.86 5.86 0 0 0-5.74-5.49h-.321a5.86 5.86 0 0 0-5.74 5.49v1.6H.507c-.527 0-.506.544-.506 1.216v8.033a1.116 1.116 0 0 0 .958 1.221h11.583a1.116 1.116 0 0 0 .958-1.221V8.303c0-.672.021-1.216-.507-1.216Zm-2.6 0h-7.29v-1.6c0-1.765 1.885-3.224 3.645-3.224s3.645 1.459 3.645 3.224Zm0 0'
          data-name='Path 313'
        />
        <g data-name='Group 24' transform='translate(2.781 8.373)'>
          <circle
            cx={3.969}
            cy={3.969}
            r={3.969}
            fill='#fff'
            data-name='Ellipse 2'
          />
          <path
            fill='#00a861'
            d='M6.529 1.989a.65.65 0 0 0-.923 0L4.032 3.563l-1 1-.7-.7a.653.653 0 0 0-.924.923l1.16 1.16a.653.653 0 0 0 .923 0l.54-.539 2.5-2.5a.653.653 0 0 0-.002-.918'
            data-name='Path 314'
          />
        </g>
      </g>
    </g>
  </svg>
)
const Memo = memo(SvgSecurePayment)
export default Memo
