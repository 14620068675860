import * as React from 'react'
import { memo } from 'react'
const SvgOutOfStockIconBlack = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='1em'
    height='1em'
    viewBox='0 0 55.251 45.623'
    fill='currentColor'
    {...props}
  >
    <g
      data-name='Group 4537'
      transform='translate(-10611.446 -19783.615)'
    >
      <g data-name='Group 4530'>
        <path
          d='M10657.238 19795.819a.79.79 0 0 0-.65-.346h-32.977a1 1 0 0 0-.124.025l-.881-2.208c-.748-2.945-2.912-3.606-3.9-3.536h-6.475a.785.785 0 1 0 0 1.57h6.516c.187 0 1.746.012 2.341 2.351l5.882 22.279c.034.157.853 3.861 3.909 3.861h18.642a.785.785 0 0 0 0-1.57h-18.647c-1.782 0-2.367-2.593-2.379-2.644l-.711-2.853h22.523a.79.79 0 0 0 .729-.493l6.281-15.7a.79.79 0 0 0-.079-.736m-7.463 15.359h-22.238a1 1 0 0 0-.137.027l-3.528-14.16h31.559Z'
          data-name='Path 339'
        />
        <path
          d='M10631.855 19829.238a4.318 4.318 0 1 1 4.318-4.318 4.32 4.32 0 0 1-4.318 4.318m0-7.067a2.748 2.748 0 1 0 2.748 2.748 2.75 2.75 0 0 0-2.748-2.748'
          data-name='Path 340'
        />
        <path
          d='M10646.773 19829.238a4.318 4.318 0 1 1 4.318-4.318 4.32 4.32 0 0 1-4.318 4.318m0-7.067a2.748 2.748 0 1 0 2.748 2.748 2.75 2.75 0 0 0-2.748-2.748'
          data-name='Path 341'
        />
      </g>
      <circle
        cx={10.5}
        cy={10.5}
        r={10.5}
        data-name='Ellipse 143'
        transform='translate(10645.697 19783.615)'
      />
      <g fill='#fff' data-name='Group 4533'>
        <path
          d='M10660.978 19788.983h-2.857a.41.41 0 0 0-.407-.368h-2.344a.41.41 0 0 0-.407.368h-2.857a.41.41 0 0 0-.409.409v.777a.41.41 0 0 0 .409.409h8.872a.41.41 0 0 0 .409-.409v-.777a.41.41 0 0 0-.409-.409'
          data-name='Path 641'
        />
        <path
          d='M10652.554 19791.363v8.433a.41.41 0 0 0 .409.409h7.154a.41.41 0 0 0 .409-.409v-8.428Zm2.993 6.326a.451.451 0 1 1-.9 0v-4.469a.451.451 0 0 1 .9 0Zm2.89 0a.451.451 0 1 1-.9 0v-4.469a.451.451 0 0 1 .9 0Z'
          data-name='Path 642'
        />
      </g>
    </g>
  </svg>
)
const Memo = memo(SvgOutOfStockIconBlack)
export default Memo
