import * as React from 'react'
import { memo } from 'react'
const SvgHomeIcon = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='1em'
    height='1em'
    data-name='Group 4161'
    viewBox='0 0 32.198 29.646'
    fill='currentColor'
    {...props}
  >
    <path
      fill='#cecece'
      d='M6.967 6.344v4.228a2.21 2.21 0 0 0 2.067 2.231 2.166 2.166 0 0 0 2.264-2.163V6.152a1 1 0 0 1 .006-.092L13.012.782a.69.69 0 0 0-.683-.781h-1.666a.69.69 0 0 0-.671.532L7.021 5.874a2 2 0 0 0-.054.47'
      data-name='Path 856'
    />
    <path
      fill='#cecece'
      d='M15.968 12.801h.262a2.206 2.206 0 0 0 2.035-2.222V6.08l-.96-5.442A.69.69 0 0 0 16.618 0h-1.032a.69.69 0 0 0-.687.638l-.96 5.442v4.499a2.206 2.206 0 0 0 2.029 2.222'
      data-name='Path 857'
    />
    <path
      fill='#cecece'
      d='M20.894 6.06a1 1 0 0 1 .006.092v4.488a2.166 2.166 0 0 0 2.264 2.163 2.21 2.21 0 0 0 2.067-2.231V6.344a2 2 0 0 0-.054-.47L22.206.533a.69.69 0 0 0-.671-.532h-1.667a.69.69 0 0 0-.683.781Z'
      data-name='Path 858'
    />
    <path
      fill='#cecece'
      d='M30.032 14.183a3.55 3.55 0 0 1-3.488-2.919 3.554 3.554 0 0 1-3.32 2.915h-.159a3.55 3.55 0 0 1-3.487-2.918 3.56 3.56 0 0 1-3.267 2.911c-.07 0-.141.006-.212.006s-.142 0-.213-.006a3.56 3.56 0 0 1-3.266-2.909 3.54 3.54 0 0 1-3.487 2.916h-.159a3.55 3.55 0 0 1-3.32-2.913 3.54 3.54 0 0 1-3.488 2.917h-.159a3.5 3.5 0 0 1-.66-.093v15.021a.54.54 0 0 0 .538.538h18.012V18.318a.69.69 0 0 1 .689-.689h5.611a.69.69 0 0 1 .689.689v11.328h3.426a.54.54 0 0 0 .538-.538V14.087a3.5 3.5 0 0 1-.659.093zm-14.746 9.044a.69.69 0 0 1-.689.689h-9.74a.69.69 0 0 1-.689-.689v-4.351a.69.69 0 0 1 .689-.689h9.74a.69.69 0 0 1 .689.689Z'
      data-name='Path 859'
    />
    <path
      fill='#cecece'
      d='M32.055 5.741 27.08.441a.69.69 0 0 0-.641-.437h-1.61a.69.69 0 0 0-.661.883l3.672 5.127a.7.7 0 0 1 .028.194v4.435a2.166 2.166 0 0 0 2.263 2.163 2.21 2.21 0 0 0 2.068-2.231V6.497a2.1 2.1 0 0 0-.144-.756'
      data-name='Path 860'
    />
    <path
      fill='#cecece'
      d='M4.331 10.639V6.204a.7.7 0 0 1 .028-.194L8.031.887A.69.69 0 0 0 7.37.004H5.76a.69.69 0 0 0-.642.437l-4.975 5.3A2.1 2.1 0 0 0 0 6.496v4.075a2.21 2.21 0 0 0 2.068 2.231 2.166 2.166 0 0 0 2.263-2.163'
      data-name='Path 861'
    />
  </svg>
)
const Memo = memo(SvgHomeIcon)
export default Memo
