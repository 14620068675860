import * as React from 'react'
import { memo } from 'react'
const SvgIconEyeOpen = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='1em'
    height='1em'
    viewBox='0 0 25.382 15.221'
    fill='currentColor'
    {...props}
  >
    <defs>
      <clipPath id='icon-eye-open_svg__a'>
        <path
          fill='#9a9a9a'
          d='M0 0h25.382v15.221H0z'
          data-name='Rectangle 1861'
        />
      </clipPath>
    </defs>
    <g data-name='Group 4074'>
      <g
        fill='#9a9a9a'
        clipPath='url(#icon-eye-open_svg__a)'
        data-name='Group 4073'
      >
        <path
          d='M12.83 0a12.5 12.5 0 0 1 5.886 1.657 22.2 22.2 0 0 1 6.374 5.309.86.86 0 0 1 0 1.3 21.2 21.2 0 0 1-7.778 5.975 11.6 11.6 0 0 1-4.718.983 12.2 12.2 0 0 1-5.3-1.339A21.7 21.7 0 0 1 .251 8.2a.823.823 0 0 1-.012-1.168 21.4 21.4 0 0 1 7.486-5.894A12 12 0 0 1 12.83 0m10.587 7.633c-.043-.06-.06-.09-.083-.115a20.9 20.9 0 0 0-5.639-4.478 11.1 11.1 0 0 0-4.442-1.328 9.95 9.95 0 0 0-4.671.9A18.3 18.3 0 0 0 3.4 6.155c-.492.462-.957.953-1.448 1.444.21.224.387.419.57.607a19.4 19.4 0 0 0 6.074 4.407 9.5 9.5 0 0 0 6.618.559c3.325-.96 5.871-3.073 8.206-5.539'
          data-name='Path 2265'
        />
        <path
          d='M16.918 7.609a4.227 4.227 0 1 1-4.216-4.225 4.24 4.24 0 0 1 4.216 4.225m-6.764-.007a2.536 2.536 0 1 0 2.545-2.527 2.54 2.54 0 0 0-2.545 2.527'
          data-name='Path 2266'
        />
      </g>
    </g>
  </svg>
)
const Memo = memo(SvgIconEyeOpen)
export default Memo
