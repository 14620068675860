import * as React from 'react'
import { memo } from 'react'
const SvgMissionsArrow = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='1em'
    height='1em'
    data-name='Group 17481'
    viewBox='0 0 24 24'
    fill='currentColor'
    {...props}
  >
    <defs>
      <clipPath id='missions-arrow_svg__a'>
        <path
          fill='none'
          d='M0 0h24v24H0z'
          data-name='Rectangle 15076'
        />
      </clipPath>
    </defs>
    <g clipPath='url(#missions-arrow_svg__a)' data-name='Group 17480'>
      <path
        fill='#fff'
        d='M12 0a12 12 0 1 0 12 12A12 12 0 0 0 12 0m3.668 13.158-4.6 4.6a.827.827 0 0 1-1.168-1.17l4.092-4.088L9.9 8.4q-.031-.029-.06-.06a.827.827 0 1 1 1.23-1.106l4.6 4.6a.935.935 0 0 1 0 1.322'
        data-name='Path 14432'
      />
      <path
        fill='#040405'
        d='m15.669 11.834-4.6-4.6a.827.827 0 1 0-1.23 1.106q.029.031.06.06l4.093 4.1L9.9 16.588a.827.827 0 0 0 1.169 1.17l4.6-4.6a.935.935 0 0 0 0-1.322Z'
        data-name='Path 14433'
      />
    </g>
  </svg>
)
const Memo = memo(SvgMissionsArrow)
export default Memo
