import * as React from 'react'
import { memo } from 'react'
const SvgToltip = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='1em'
    height='1em'
    viewBox='0 0 20 20'
    fill='currentColor'
    {...props}
  >
    <defs>
      <clipPath id='toltip_svg__a'>
        <path
          fill='#b5b5b5'
          d='M0 0h20v20H0z'
          data-name='Rectangle 3028'
        />
      </clipPath>
    </defs>
    <g data-name='Group 5575'>
      <g
        fill='#b5b5b5'
        clipPath='url(#toltip_svg__a)'
        data-name='Group 5570'
      >
        <path
          d='M10.003 19.498a9.5 9.5 0 1 1 9.5-9.5 9.51 9.51 0 0 1-9.5 9.5m0-17.57a8.073 8.073 0 1 0 8.07 8.075 8.08 8.08 0 0 0-8.07-8.075'
          data-name='Path 2256'
        />
        <path
          d='M9.995 11.304a.713.713 0 0 1-.712-.712v-4.2a.712.712 0 1 1 1.425 0v4.2a.713.713 0 0 1-.712.712'
          data-name='Path 2257'
        />
        <path
          d='M10 14.559a.98.98 0 0 1-.674-.275.948.948 0 0 1 1.035-1.548 1.1 1.1 0 0 1 .313.2.963.963 0 0 1 0 1.349 1.1 1.1 0 0 1-.313.2.95.95 0 0 1-.361.076'
          data-name='Path 2258'
        />
      </g>
    </g>
  </svg>
)
const Memo = memo(SvgToltip)
export default Memo
