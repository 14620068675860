import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Countdown from 'react-countdown';
import API from '@API';
import config from '@Config';
import {
  IsArray
 } from '../../../../../utils/IsArray';
import {
  hidePopupAction,
  getOrderSummaryAction,
  setPaymentMethodAction,
  setDijitalPaymentMethodAction,
  setInstallmentAction,
  setHepsipayLinkAccountLoading
} from '@Actions'
import {
  gettext
} from '@Translate';
import OTPInput from '../../../../OTPInput';
import IconTime from '../../../../../assets/icons/time-icon.svg';
import IconTimeBlack from '../../../../../assets/icons/time-icon-black.svg';
import IconTimePurple from '../../../../../assets/icons/time-icon-purple.svg';
import HepsiPayLogo from '../../../../../assets/icons/hepsipay.svg';

const OTPForm = ({
  formDetail,
}) => {
  const dispatch = useDispatch();
  const startDate = useRef(Date.now());
  const [OTP, setOTP] = useState('');
  const [phone, setPhone] = useState('');
  const [refCode, setRefcode] = useState('');
  const [err, setErr] = useState(false);
  const [errMessage, setErrMessage] = useState('');
  const [disabled, setDisabled] = useState(true);
  const [reSendCode, setReSendCode] = useState(true);
  const [time, setTime] = useState('');
  const [timeReset, setTimeReset] = useState(true);

  const { project } = config;

  const {
    hepsiPaySavedCardList,
  } = useSelector((state) => state.order);

  const renderer = ({ completed, formatted }) => {
    if (completed) {
      setReSendCode(false);
      // kodu tekrar gönderince sürenin yenilenmemesinden dolayı eklendi
      setTimeReset(true);
      return (
        <span>
          00:00
        </span>
      );
    }
    return (
      <span>
        {formatted?.minutes}
        :
        {formatted?.seconds}
      </span>
    );
  };

  const handleChange = (otp) => {
    if (otp.match(/[^0-9]/g)) {
      setErr(() => (prevState = true));
      return false;
    }
    setOTP(otp);
    setErrMessage('');
  };

  useEffect(() => {
    setPhone(formDetail?.phone);
    setRefcode(formDetail?.refCode);
    setTime(formDetail?.time * 1000);
  }, []);


  const sendReOtp = () => {
    setOTP('');
    dispatch(setHepsipayLinkAccountLoading(true));
    API.hepsiPaylinkAccount()
    .then(({ payload }) => {
      const { showVerifiedCode, phone, refCode, time } = payload;
      if (showVerifiedCode === true) {
        setPhone(phone);
        setRefcode(refCode);
        setTime(time * 1000);
        dispatch(showPopupAction(() => <OTPForm formDetail={payload.data} />))
        dispatch(setHepsipayLinkAccountLoading(false))
      }
    })
    .catch((err) => {
      console.log(err);
      dispatch(setHepsipayLinkAccountLoading(false))
    });

     // kodu tekrar gönderince sürenin yenilenmemesinden dolayı eklendi
     if (timeReset) {
      startDate.current = Date.now();
      setTimeReset(false);
    }

  };
  const sendOTP = () => {
    if (OTP.length < 5) {
      setErr(() => (prevState = true));
      setErrMessage(() => (prevState = true));
    }
    dispatch(setHepsipayLinkAccountLoading(true))
    API.hepsiPaylinkAccount({
      otpCode: OTP,
    }).then(async(payload) => {
      const { status } = payload.data;
      if(status.code === 200) {
        dispatch(hidePopupAction());
        await dispatch(getOrderSummaryAction());
        dispatch(setHepsipayLinkAccountLoading(false))
      } else {
        setErrMessage(status?.message);
        setErr((prevState) => (prevState = true));
        setOTP('');
        dispatch(setHepsipayLinkAccountLoading(false))
        const errorTimer = setTimeout(() => {
          setErr((prevState) => (prevState = false));
        }, 1000);
        return () => clearTimeout(errorTimer);
      }
    })
    .catch((err) => {
      console.log(err);
      dispatch(setHepsipayLinkAccountLoading(false))
    });
  };
    useEffect(() => {
      if (OTP.length > 5) {
        if (err === true) {
          setErr(() => (prevState = false));
        } else {
          setDisabled(false);
        }
      } else {
        setDisabled(true);
      }
    }, [OTP]);
    return (
      <div className={'otp-container otp-container-box'}>
        <h2 className={`otp-content__head  ${project}-otp-content__head`}>
          {gettext('Telefon Numaranızı Doğrulama')}
        </h2>

        <div className={`otp-content otp-content-box ${project}`}>
          <img className="otp-content__logo" src={HepsiPayLogo} alt="timer icon" />
          <div className="otp-content__title">
          {gettext('SMS Kodu')} <strong>{phone}</strong> {gettext('no’lu telefon numaranıza başarıyla gönderildi. Lütfen size gelen')} <strong>{gettext('6 haneli kodu')}</strong> {gettext('aşağıdaki kutucuklara girerek onaylayınız.')} {refCode !== '' ? gettext(`Ref:${refCode}`): ''}
          </div>
          <div className="otp-content__input-container">
            <div className={`otp-content__count-section ${project}`}>
              <div className="otp-content__timer-section--title">
                {gettext('Doğrulama Kodu')}
              </div>
              <div className="otp-content__timer-section--count">
                <span className="icon-area">
                  <img
                    src={project === 'lumberjack' || project === 'ninewest' || project === 'reebok' ? IconTimeBlack
                        : project === 'butigo' ? IconTimePurple
                        : IconTime}
                    alt="timer icon"
                  />
                </span>
                <Countdown key={startDate?.current} date={startDate?.current + time} renderer={renderer} />
              </div>
            </div>
            <OTPInput
              value={OTP}
              onChange={handleChange}
              numInputs={6}
              separator={<span>&nbsp;</span>}
              inputStyle="otp-content-in--item"
              shouldAutoFocus
              focusStyle="active"
              containerStyle="otp-content-in"
              errorStyle="form-error"
              hasErrored={!!err}
              isInputNum
            />
            {errMessage && (
              <div className="error-message">
                {errMessage}
              </div>
            )}
            <div className="otp-content__re-send-title">
              <a onClick={sendReOtp} className={reSendCode ? 'disabled' : ''} data-test="checkout-otp-form-again-button">{gettext('Kodu Tekrar Gönder')}</a>
            </div>
          </div>
          <div className={`otp-content__button-container ${project}-otp-content__button-container`}>
            <button
              disabled={!!disabled}
              type="button"
              className="otp-content__btn"
              data-test="submit-otp-button-verify"
              onClick={sendOTP}
            >
              {gettext('Onayla')}
            </button>
          </div>
          <div className="otp-content__bottom-message">
            {phone} {gettext('no’lu cep telefonunla oluşturduğun HepsiPay hesabın üzerinden Hepsiburada’da kayıtlı kartlarınızı FLO’da kullanmak için onay vermektesiniz. Kart kullanım ayarlarının değiştirmek istenmesi halinde HepsiPay ile iletişime geçiniz.')}
          </div>
        </div>
      </div>
    );
  };

export default OTPForm;
