import qs from 'querystring';
import {
  axiosInstance,
  axiosDefault,
  axiosCompleteOrder,
  axiosBunsar,
  axiosWalletTopup,
  axiosDefaultCreditCard,
} from './index';

const handlerEnabled = false;

// ** --------------------- POST REQUESTS ------------------- **

/* ** --------------------- AXIOS POST EXAMPLE -------------- **

  const example = async (params) => {
    const response = await.axiosInstance.post(
      url,
      data:{},
      config:{},
    );}
  }

   ** --------------------- AXIOS POST EXAMPLE -------------- ** */

const getTransactions = async (page) => {
  const response = await axiosInstance.post(
    '/v1/wallet/transactions.json',
    page,
  );
  return response;
};

const getCheckCustomerVerification = async () => {
  const response = await axiosInstance.post('/v1/checkCustomerVerification');
  return response;
};

const getBonusExpirationTime = async () => {
  const response = await axiosInstance.post(
    '/v1/wallet/bonusExpirationTime.json',
  );
  return response;
};

const getRefundToCardPreview = async (refundAmount) => {
  const response = await axiosInstance.post(
    '/v1/wallet/refundToCardPreview.json',
    refundAmount,
  );
  return response;
};
const getRefundToCard = async (refundAmount) => {
  const response = await axiosInstance.post(
    '/v1/wallet/refundToCard.json',
    refundAmount,
  );
  return response;
};
const getRefundToIban = async (amountValue) => {
  const response = await axiosInstance.post(
    '/v1/wallet/refundToIban.json',
    amountValue,
  );
  return response;
};

const topupCheque = async (chequeValue) => {
  const response = await axiosInstance.post(
    '/v1/wallet/topupchequecheckout.json',
    chequeValue,
  );
  return response;
};

const walletCheque = async (chequeValue) => {
  const response = await axiosInstance.post(
    '/v1/wallet/topupcheque.json',
    chequeValue,
  );
  return response;
};

// getShoppingCart
const getShoppingCart = async (data) => {
  const response = await axiosInstance.post(
    '/webservice/v1/getShoppingCart.json',
    data,
  );
  return response;
};

// addToShoppingCart
const addToShoppingCart = async ({
  productId,
  quantity,
  sku,
  merchantId,
  basketType,
  storeCode,
}) => {
  const response = await axiosInstance.post(
    '/webservice/v1/addToShoppingCart.json',
    {
      productId,
      quantity,
      sku,
      merchantId,
      basketType,
      storeCode,
    },
  );
  return response;
};

/**
 * @returns {Promise<AxiosResponse<any>>}
 */
const setCustomerAddress = async (data) => {
  const response = await axiosInstance.post(
    '/webservice/v1/setCustomerAddress.json',
    data,
  );
  return response;
};

const getOrderSummary = async (data) => {
  const response = await axiosInstance.post(
    '/webservice/v1/orderSummary.json',
    data,
  );
  return response;
};

const setInstallment = async (data) => {
  const response = await axiosInstance.post(
    '/webservice/v1/setInstallment.json',
    data,
  );
  return response;
};

// Get Country List
const getCountry = async () => {
  const response = await axiosInstance.post(
    '/webservice/v1/getCountries.json',
  );
  return response;
};

// Get City List
const getCities = async ({ countryId }) => {
  const response = await axiosInstance.post(
    '/webservice/v1/cities.json', { countryId },
  );
  return response;
};

// Get County List
const getCounties = async ({ cityId }) => {
  const response = await axiosInstance.post(
    '/webservice/v1/counties.json',
    { cityId },
  );
  return response;
};

// Get Neighbourhood List
const getNeighbourhood = async ({ districtId }) => {
  const response = await axiosInstance.post(
    '/webservice/v1/neighborhoods.json',
    { districtId },
  );
  return response;
};

// Get Order Customer Address
const getOrderCustomerAddress = async () => {
  const response = await axiosInstance.post(
    '/webservice/v1/orderCustomerAddress.json',
  );
  return response;
};
const postShareBasket = async () => {
  const response = await axiosInstance.post(
    'v1/insertBasketShareLink',
  );
  return response;
};

// Update Customer Address
const updateCustomerAddress = async (data) => {
  const response = await axiosDefault.post(
    '/webservice/v1/updateCustomerAddress.json',
    { ...data, ...{ version: '3' } },
  );
  return response;
};

// Update Customer Address
const insertCustomerAddress = async (data) => {
  const response = await axiosDefault.post(
    '/webservice/v1/insertCustomerAddress.json',
    { ...data, ...{ version: '3' } },
  );
  return response;
};

const sendActivationSms = async (data) => {
  const response = await axiosInstance.post(
    '/webservice/v1/sendActivationSms.json',
    data,
  );
  return response;
};

const checkActivationSms = async ({ code }) => {
  const response = await axiosInstance.post(
    '/webservice/v1/checkActivationSms.json',
    { code },
  );
  return response;
};

const checkPaymentStatus = async (data) => {
  const response = await axiosInstance.post('/v1/checkPaymentStatus.json', data);
  return response;
};

// TODO bu request te base url ezilecek ${CHECKOUT_URL}
const completeOrder = async (data) => {
  const response = await axiosCompleteOrder.post(
    '/webservice/v1/completeOrder.json',
    data,
  );
  return response;
};

// addFavouriteProduct
// {
//     "productId" : "10000"
// }
const addFavouriteProduct = async (data) => {
  const response = await axiosInstance.post(
    '/webservice/v1/favouriteProduct.json',
    data,
  );
  return response;
};

// removeFavouriteProduct
// {
//     "productId" : "10000"
// }
const removeFavouriteProduct = async ({ productId }) => {
  const response = await axiosInstance.post(
    '/webservice/v1/removeFavouriteProduct.json',
    { productId },
  );
  return response;
};

// Update Shopping Cart Item
const updateShoppingCartItem = async (data) => {
  const response = await axiosInstance.post(
    '/webservice/v1/updateShoppingCartItem.json',
    data,
  );
  return response;
};

// Update Shopping Cart Item
const deleteShoppingCartItem = async (data) => {
  const response = await axiosInstance.post(
    '/webservice/v1/deleteShoppingCartItem.json',
    data,
  );
  return response;
};

// installments
// {
//     "creditCardPlatform": "axess"
// }
const getInstallments = async ({
  creditCardPlatform,
  creditCartNumber,
  basketType,
  storeCode
}) => {
  const response = await axiosInstance.post(
    '/webservice/v1/installments.json',
    { creditCardPlatform,
      creditCartNumber,
      basketType,
      storeCode
    }
  );
  return response;
};

// getOrderDetail
const getOrderDetail = async ({ orderId, hsh }) => {
  const response = await axiosDefault.post(
    `/webservice/v1/orderDetail.json?orderId=${orderId}&hsh=${hsh}`,
  );
  return response;
};

// Post Login Request
const postLoginRequest = async (data) => {
  const response = await axiosDefault.post(
    '/webservice/v1/login.json',
    qs.stringify(data),
  );
  return response;
};

const logoutRequest = async (data) => {
  const response = await axiosInstance.post(
    '/webservice/v1/logout.json',
    data,
  );
  return response;
};

const postRegisterRequest = async (data) => {
  const response = await axiosDefault.post(
    '/webservice/v1/register.json',
    qs.stringify(data),
  );
  return response;
};

// Post Login Aggrement Request
const postLoginAggrementsRequest = async (data) => {
  const response = await axiosDefault.post(
    '/webservice/v1/getAgreementPage?entity=register_form',
    qs.stringify(data),
  );
  return response;
};

const guestRequest = async (data) => {
  const response = await axiosDefault.post(
    '/v1/guestCookieSet',
    qs.stringify(data),
  );
  return response;
};

const getAgreementsRequest = async (data) => {
  const response = await axiosDefault.post(
    '/webservice/v1/agreements.json',
    qs.stringify(data),
  );
  return response;
};

const getSavedCards = async () => {
  const response = await axiosInstance.post(
    '/webservice/v1/getSavedCards.json',
  );
  return response;
};

const defaultCreditCard = async (data) => {
  const response = await axiosDefaultCreditCard.post('/v1/setDefaultCreditCard', data);
  return response;
};

const deleteCreditCard = async (data) => {
  const response = await axiosInstance.post('/v1/deleteSavedCard', data);
  return response;
};

const addCreditCard = async (data) => {
  const response = await axiosDefaultCreditCard.post('/v1/addCreditCard', data);
  return response;
};

const validateActivationOtp = async (data) => {
  const response = await axiosInstance.post(
    '/webservice/v1/wallet/validateActivationOtp.json',
    data,
  );
  return response;
};

const sendActivationOtp = async (data) => {
  const response = await axiosInstance.post(
    '/webservice/v1/wallet/sendActivationOtp.json',
    data,
  );
  return response;
};

const getSummoningShoppingCartId = async (data) => {
  const response = await axiosDefault.post(
    '/webservice/v1/summoningShoppingCartId.json',
    data,
  );
  return response;
};

const getCouponsRequest = async () => {
  const response = await axiosInstance.post(
    '/webservice/v1/coupons.json',
  );
  return response;
};

const applyCouponRequest = async (data) => {
  const response = await axiosInstance.post(
    '/webservice/v1/applyCoupon.json',
    data,
  );
  return response;
};

const removeCouponRequest = async (data) => {
  const response = await axiosInstance.post(
    '/webservice/v1/removeCoupon.json',
    data,
  );
  return response;
};

const getBalance = async (data) => {
  const response = await axiosInstance.post(
    '/webservice/v1/wallet/getBalance.json',
    data,
  );
  return response;
};

// self checkout
const getBags = async ({ storeCode }) => {
  const response = await axiosInstance.post(
    '/webservice/v1/getProductBags.json',
    { storeCode },
  );
  return response;
};

const activeQrCode = async ({ storeCode }) => {
  const response = await axiosInstance.post(
    '/webservice/v1/selfCheckoutActiveQrCode',
    { storeCode }
  );
  return response;
};

const createQrCode = async (data) => {
  const response = await axiosInstance.post(
    '/webservice/v1/selfCheckoutCreateQrCode.json',
    data,
  );
  return response;
};

const cancelOrder = async (data) => {
  const response = await axiosInstance.post(
    '/webservice/v1/selfCheckoutCancelOrder.json',
    data,
  );
  return response;
};

const qrCodeCheck = async (data) => {
  const response = await axiosInstance.post(
    '/webservice/v1/selfCheckoutQrCodeCheck.json',
    data,
  );
  return response;
};


//----------

const logRequest = async (data) => {
  const response = await axiosInstance.post('/v1/react', data);
  return response;
};

const getCmsPage = async (data, params) => {
  const response = await axiosInstance.post('/v1/getCmsPage', data, {
    params,
  });
  return response;
};

const walletTopup = async (data) => {
  const response = await axiosWalletTopup.post('/v1/wallet/topup', data);
  return response;
};

const facebookConnection = async (data) => {
  const response = await axiosDefault.post(
    '/webservice/v1/login.json',
    qs.stringify(data),
  );
  return response;
};

const signInWithGoogle = async (data, params) => {
  const response = await axiosDefault.post(
    '/webservice/v1/login.json',
    qs.stringify(data),
    { params },
  );
  return response;
};

const signInWithApple = async (data, params) => {
  const response = await axiosDefault.post(
    '/webservice/v1/login.json',
    qs.stringify(data),
    { params },
  );
  return response;
};

const forgotPasswordRequest = async (data) => {
  const response = await axiosDefault.post(
    '/webservice/v1/resetPassword.json',
    qs.stringify(data),
  );
  return response;
};

// customerVerification
// {
//     phone:+905535535212
//     code:123243
// }
const customerVerification = async (data) => {
  const response = await axiosInstance.post(
    '/webservice/v1/customerVerification.json',
    data,
  );
  return response;
};

const customerMailVerification = async (data) => {
  const response = await axiosInstance.post(
    '/webservice/v1/customerMailVerification.json',
    data,
  );
  return response;
};

const checkQr = async (data) => {
  const response = await axiosInstance.post(
    '/webservice/v1/geniusCheckQr',
    data,
  );
  return response;
};

const getQrPaymentDetail = async (data) => {
  const response = await axiosInstance.post(
    'v1/getQRPaymentDetail.json',
    data,
  );
  return response;
};

const rejectQrPayment = async (data) => {
  const response = await axiosInstance.post(
    'v1/rejectQRPayment.json',
    data,
  );
  return response;
};

const approveQrPayment = async (data) => {
  const response = await axiosInstance.post(
    'v1/approveQRPayment.json',
    data,
  );
  return response;
};

const checkQrComplate = async (data) => {
  const response = await axiosInstance.post(
    'v1/geniusCheckComplete',
    data,
  );
  return response;
};

// bekleyen flo para
const getWaitingMoney = async (data) => {
  const response = await axiosInstance.post(
    'v1/waitingMoneyPoints',
    data,
  );
  return response;
};

// ** --------------------- GET REQUESTS ------------------- **

// store qr
const getQr = async () => {
  const response = await axiosInstance.get(
    '/webservice/v1/geniusCreateQr',
  );
  return response;
};

// customerAddresses
const getDeliveryOptions = async (data) => {
  const response = await axiosInstance.post(
    '/v1/deliveryOptions.json',
    data
  );
  return response;
};

// customer
const getCustomer = async () => {
  const response = await axiosInstance.get(
    '/webservice/v1/getCustomer.json',
  );
  return response;
};

// customerAddresses
const getInitialize = async () => {
  const response = await axiosInstance.get(
    '/webservice/v1/initialize.json',
  );
  return response;
};

// shippingCompanies
const shippingCompanies = async () => {
  const response = await axiosInstance.get(
    '/webservice/v1/shippingCompanies.json',
  );
  return response;
};
// Check Customer Mail Verification
const checkCustomerMailVerification = async (customerIdInfo) => {
  const response = await axiosInstance.get(
    `/webservice/v1/checkcustomermailverification?customerId=${customerIdInfo}`
  );
  return response;
};

// Get endpoint
const getRequest = async (ENDPOINT) => {
  const response = await axiosInstance.get(ENDPOINT, {
    handlerEnabled,
  });
  return response;
};

const setActivateCard = async (data) => {
  const response = await axiosInstance.post(
    'v1/cardactivate',
    data,
  );
  return response;
};

const phoneLogin = async (data) => {
  const response = await axiosInstance.post(
    'v1/phoneLogin',
    data
  );
  return response;
}

//bunsar API
const bunsarRequest = async (data) => {
  const response = await axiosBunsar.post(
    `eventApiV1/event?${data}`
  );
  return response;
};

// Flopay görevler
const getCustomMissions = async () => {
  const response = await axiosInstance.get(
    'v1/loyalty/missions'
  )
  return response;
}

// HepsiPay hesap aktif etme
const hepsiPaylinkAccount = async (data) => {
  const response = await axiosInstance.post(
    'v1/linkAccount',
    data
  );
  return response;
}

// useLoyalty
const useLoyalty = async (data) => {
  const response = await axiosInstance.post(
    '/v1/mindbox/useloyalty',
    data,
  );
  return response;
};

// applyBonus
const applyBonus = async (data) => {
  const response = await axiosInstance.post(
    '/v1/loyalty360/applyBonus',
    data,
  );
  return response;
};

export default {
  getRequest,
  getShoppingCart,
  setActivateCard,
  updateShoppingCartItem,
  postLoginRequest,
  postRegisterRequest,
  guestRequest,
  signInWithGoogle,
  signInWithApple,
  logoutRequest,
  getOrderCustomerAddress,
  insertCustomerAddress,
  updateCustomerAddress,
  getOrderSummary,
  getCountry,
  getCities,
  getCounties,
  getNeighbourhood,
  getCouponsRequest,
  applyCouponRequest,
  removeCouponRequest,
  shippingCompanies,
  addToShoppingCart,
  getCustomer,
  getDeliveryOptions,
  setCustomerAddress,
  setInstallment,
  sendActivationSms,
  checkActivationSms,
  checkPaymentStatus,
  completeOrder,
  addFavouriteProduct,
  topupCheque,
  removeFavouriteProduct,
  getInstallments,
  getOrderDetail,
  getSavedCards,
  deleteShoppingCartItem,
  getInitialize,
  getSummoningShoppingCartId,
  getAgreementsRequest,
  postShareBasket,
  facebookConnection,
  validateActivationOtp,
  sendActivationOtp,
  getBalance,
  getQr,
  checkQr,
  rejectQrPayment,
  getQrPaymentDetail,
  approveQrPayment,
  checkQrComplate,
  getRefundToCard,
  getRefundToCardPreview,
  getBonusExpirationTime,
  getTransactions,
  logRequest,
  walletTopup,
  getRefundToIban,
  getCmsPage,
  postLoginAggrementsRequest,
  forgotPasswordRequest,
  getCheckCustomerVerification,
  customerVerification,
  customerMailVerification,
  getWaitingMoney,
  getBags,
  createQrCode,
  cancelOrder,
  qrCodeCheck,
  activeQrCode,
  walletCheque,
  phoneLogin,
  bunsarRequest,
  defaultCreditCard,
  deleteCreditCard,
  addCreditCard,
  checkCustomerMailVerification,
  getCustomMissions,
  hepsiPaylinkAccount,
  useLoyalty,
  applyBonus
};
