import React from 'react';
import PropTypes from 'prop-types';
import {
  gettext,
} from '@Translate';

import config from '@Config';

const OutOfStockCard = ({
  image_vertical, manufacturer, name, size, image
}) => {

  const { project } = config;

  return (
    <div data-test="card" className="out-of-stock-card">
      <div className="out-of-stock-card__content--img">
        <img
          src={`${project === 'reebok' ? image : image_vertical }`}
          alt={name}
        />
      </div>
      <div data-test="out-of-stock-card-content-container" className="out-of-stock-card__content--container">
        <span data-test="out-of-stock-card-content-brand" className="content-container__brand">{manufacturer}</span>
        <span className="content-container__product-name">
          {name}
        </span>
        <span className="content-container__size">
          <span className="size">
            {gettext('Beden')}
            :
          </span>
          <span className="size-number">{size}</span>
        </span>
      </div>
    </div>
  );
};

OutOfStockCard.defaultProps = {
  name: '',
  image_vertical: '',
  size: '',
  manufacturer: '',
};

OutOfStockCard.propTypes = {
  name: PropTypes.string,
  image_vertical: PropTypes.string,
  size: PropTypes.string,
  manufacturer: PropTypes.string,
};

export default OutOfStockCard;
