import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { gettext } from '@Translate';
import config from '@Config';
import { isMobile, logEvent } from '@Constants';
import { useLocation } from 'react-router';

// components
import { SizeWrapper, GalleryModal } from '../Basket';

const ProductDetail = ({
  brand, cargoDate, element, cargoText, project, wareHouseTitle, deliveryType, successPage, pageType
}) => {
  const [showModal, setShowModal] = useState(false);
  const { shoppingCart } = useSelector((state) => state.cart);
  const { webview } = useSelector((state) => state.config);
  const { step } = useSelector((state) => state.checkout);
  // config
  const { appDeepLink, BASE_URL } = config;
  const urlLocation = useLocation();

  // Function for opening modal dialog
  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const imgSrc = element?.thumbUrlVertical || element?.imageUrlVertical || element?.thumbUrl || element?.image;
  const reebokImgSrc = element?.thumbUrl || element?.image;

  const getProductLink = (element) => {
    if (step === 1) {
      if (webview) {
        return `${appDeepLink}product.detail?sku=${element?.parentSku}`;
      }
      return `${BASE_URL}${element?.link}`;
    }
  };

  const sendLongEvent = (element) => {
    logEvent('select_item', {
      ...element,
      value: shoppingCart?.grandTotal,
      currency: 'TRY',
    });
    window.location.href = `${appDeepLink}product.detail?sku=${element?.parentSku}`;
  };

  return (
    <div className="product-detail--container">
      <GalleryModal isOpen={showModal} onClick={closeModal} src={imgSrc} />
      <div className={`product-detail__img-wrapper ${project}-product-detail__img-wrapper`}>
        {deliveryType === 'SDD'
        && (
          <span className="product-detail__img-wrapper-fd-info">Hızlı Teslimat</span>
        )}
        <img className="" src={project === 'reebok' ? reebokImgSrc : imgSrc} alt="" onClick={openModal} style={{ width: 120, cursor: 'pointer' }} data-test="checkout-product-detail-image-link" />
      </div>
      <div className={`product-detail__content-wrapper ${project}-product-detail__content-wrapper`}>
        {/* <span className="brand">{brand}</span> */}
        <span className={`title ${element?.brand === 'Reebok' ? 'reebok-title' : ''}`}>
          {
            (element?.brand === 'Reebok' ? '' : (
              <>
                {element?.brand}
                <br />
              </>
            ))
          }
          {webview ? (
            <div data-href={getProductLink(element)} onClick={() => sendLongEvent(element)} className="cart__item-name" data-test="checkout-product-detail-element-link">
              {element?.name}
            </div>
          ) : (
            <a href={getProductLink(element)} className="cart__item-name" data-test="checkout-product-detail-element-link">
              {element?.name}
            </a>
          )}
        </span>
        {isMobile() && (
        <span className="product-detail__mobile-size-wrapper">
          <SizeWrapper size={element?.size} />
          {(successPage || pageType === 'self_checkout')&& (
            <span className="product-detail__mobile-size-wrapper quantity">
              <span className="size-title">
                {gettext('Adet')}
                :
              </span>
              <span className="size-number">{element?.quantity}</span>
            </span>
          )}
        </span>
        )}
        <span className="shipping-wrapper">
          { pageType !== 'self_checkout' && (
          <>
            {element?.type && element?.type !== 'BC' && (
              <span className="shipping-title">
                <span>{element?.type === 'SDD' ? 'Hızlı Teslimat:' : cargoText}</span>
                <span className="shipping-date">
                  {' '}
                  {element?.type === 'SDD' ? element?.title : cargoDate}
                </span>
              </span>
            )}
            {element?.bcInfo && element?.type === 'BC' ? (
              <span className="shipping-title">
                Mağaza:
                {' '}
                <span className="shipping-date">{`${element?.bcInfo?.store_name}`}</span>
              </span>
              ) : ''}
              {!isMobile() && (project === 'flo' || project === 'instreet') && element?.merchant?.id === 10000 && (element.type === 'SD' || element?.type === 'CC') && urlLocation?.pathname === '/success' && (
                <span className="shipping-title">
                  Gönderim Bölgesi:
                  <span className="shipping-date">
                    {' '}
                    {wareHouseTitle}
                    &nbsp;bölgesinden yola çıkacaktır.
                  </span>
                </span>
              )}
          </>
          )}

        </span>
      </div>
    </div>
  );
};

ProductDetail.defaultProps = {
  brand: '',
  cargoDate: '',
  cargoText: '',
  successPage: false,
  deliveryType: 'SD',
  openModal: () => {},
};

ProductDetail.propTypes = {
  brand: PropTypes.string,
  cargoDate: PropTypes.string,
  cargoText: PropTypes.string,
  successPage: PropTypes.bool,
  deliveryType: PropTypes.string,
  openModal: PropTypes.func,
};

export default ProductDetail;
