import * as React from 'react'
import { memo } from 'react'
const SvgXCloseBig = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='1em'
    height='1em'
    viewBox='0 0 50 50'
    fill='currentColor'
    {...props}
  >
    <path
      fill='#fff'
      d='M1 49h48V1H1Zm12.008-33.775L15.262 13 25 22.614 34.738 13 37 15.234l-9.738 9.613 9.652 9.524L34.839 37 25 27.29l-9.738 9.61L13 34.67l9.747-9.621Z'
      data-name='Path 13729'
    />
    <path
      fill='#d8d8d8'
      d='M1 1h48v48H1ZM0 50h50V0H0Z'
      data-name='Path 13730'
    />
    <path
      d='M15.262 36.9 25 27.29 34.839 37l2.075-2.631-9.652-9.524L37 15.234 34.738 13 25 22.614 15.262 13l-2.254 2.225 9.739 9.824L13 34.67Z'
      data-name='Path 13731'
    />
  </svg>
)
const Memo = memo(SvgXCloseBig)
export default Memo
