const data = {
  "‘si FLO Para’dır": "Si Flo is Para",
  "’den alışveriş yaptığınız için teşekkür ederiz.": "Thank you for shopping from.",
  "’ı Aktif edin siz d": "Activate you d",
  "’ı Aktif edin siz de": "Activate you too",
  "’ni kabul ediyorum.": "I agree to 'ni.",
  "’ni okudum.": "I read.",
  "’si Kapıda Ödeme işlemi olduğu için hesabınıza nakit olarak aktarılabilir. Ücret iadenizi nakit olarak almak istiyorsanız lütfen": "Since it is the payment process at the door, it can be transferred to your account in cash. If you want to get your payback in cash please",
  "’ten alışveriş yaptığınız için teşekkür ederiz.": "Thank you for shopping from.",
  "“Fatura Adresim” “Teslimat Adresim” ile Aynı": "My billing address is the same as my delivery address",
  "/aydinlatma-metni": "/aydinlatma-metni",
  "/flo-cuzdan-kullanici-sozlesmesi": "/flo-cuzdan-kullanici-sozlesmesi",
  "/page?url=sss-desktop&tab=cuzdan": "/page?url=sss-desktop&tab=cuzdan",
  "1- Siparişiniz kargo aracılığıyla ilgili mağazamıza teslim edilecektir.": "1- Your order will be delivered to our store through cargo.",
  "2- Mağazadan teslimat seçeneği sadece belirli mağazalarımızda geçerlidir.": "2- The delivery option from the store is valid only in our specific stores.",
  "3- Mağazadan teslimat seçeneğinde ödemelerinizi sadece kredi kartı ile yapabilirsiniz": "3- You can only make your payments on delivery option only by credit card",
  "4- Siparişiniz talep ettiğiniz mağazaya ulaştığında otomatik SMS ve e-mail gönderimi ile bilgi verilecektir.": "4- It will be informed by automatic SMS and e-mail submission when your order has reached the store you requested.",
  "5- Mağazalarımızdan siparişinizi teslim alırken sipariş no, ad, soyad, TC kimlik no ve size gönderilen güvenlik kodunuzu mağaza personeline bildirmeniz gerekmektedir.": "5- When you surrender your order from our stores, you need to report your order no, name, Soy Name, TC ID NO and your security code sent to you.",
  "6 haneli doğrulama kodunu almak için telefon numaranızı giriniz.": "Enter your phone number to receive the 6-digit verification code.",
  "6- Mağazalarımıza teslim edilen siparişleriniz '3 iş günü içerisinde teslim alınmalıdır.'": "6- Your orders delivered to our stores 'must be delivered within 3 business days.'",
  "7- Mağazalarımıza ulaşan siparişleriniz zamanında teslim alınmadığında iptal edilecektir.": "7- Your orders reaching our stores will be canceled when not received on time.",
  "8 karakter": "At least 8 characters",
  "8- Mağazalarımızdan teslimat hergün 10:00 - 20:00 saatleri arasında yapılmaktadır.": "8- Delivery from our stores is made between 10:00 - 8:00 every day.",
  "Ad": "Name",
  "AD SOYAD": "NAME SURNAME",
  "Ad ve Soyad": "Name and surname",
  "ADET:": "Qty:",
  "Adınız": "Your name",
  "Adres": "Address",
  "Adres Başlığı": "Address Title",
  "Adrese Teslimat": "Home Delivery",
  "Adrese Teslim": "Home Delivery",
  "adresine bir doğrulama e-postası gönderelim.": ".",
  "Ağustos": "August",
  "aktarabilirsiniz.": "can transfer.",
  "Aktarmak İstediğiniz Tutar Giriniz": "Enter the amount you want to transfer",
  "ALIŞVERİŞE BAŞLA": "START SHOPPING",
  "ALIŞVERİŞE DEVAM ET": "CONTINUE SHOPPING",
  "Alışveriş tutarınıza kargo firması tarafından talep edilen": "Requested by cargo firm to your shopping amount",
  "Apple bilgileriniz doğrulanamadı, lütfen tekrar deneyiniz.": "Your Apple information could not be verified, please try again.",
  "Apple ile Giriş Yap": "Log in with Apple",
  "Apple ile Kayıt Ol": "Register with Apple",
  "Aralık": "December",
  "Aşağıdaki ürün / ürünlerin stoğu tükendiği için, sepetinizden çıkarılmıştır.": "The following product/products have been removed from your cart as they are out of stock.",
  "Ay": "Moon",
  "aydinlatma-metni-mobile": "clarification-text-mobile",
  "Aynı Gün Mağazadan Teslimat": "Delivery from the store on the same day",
  "Bakiye": "Balance",
  "Bakiye": "Balance",
  "BAKİYE YÜKLE": "Upload balance",
  "Bakiye:": "Balance:",
  "Başka Bir Kart İle Ödeme Yap": "Pay with another card",
  "Başka Kredi Kartı İle Öde": "Pay with other credit card",
  "Beden": "Body",
  "Beden:": "Size:",
  "Beğendiğiniz ürünleri kolayca sepetinize ekleyebilir ve arkadaşınızla paylaşabilirsiniz.": "You can easily add items to yout cart and share them with your friends.",
  "binlerce ürün ve model seni bekliyor.": "Thousands of products and pattern are waiting for you.",
  "Bir tutar giriniz.": "Enter an amount.",
  "Bu alanın doldurulması zorunludur.": "This field is required.",
  "Bu ekranı gördüğünüz için üzgünüz!": "Sorry to see this screen!",
  "Bu ürünler": "These products",
  "Bu ürünün iadesini yalnızca Kargo ile gerçekleştirebilirsiniz.": "You can only perform the return of this product with cargo.",
  "buraya": "here",
  "büyük harf": "uppercase letter",
  "Cep Telefonu": "Mobile phone",
  "Cüzdan Detayları": "Wallet Details",
  "Cüzdana yüklemek istediğiniz tutarı giriniz.": "Enter the amount you want to upload to the wallet.",
  "CVV": "Cvv",
  "değerinde": "worth",
  "DEMO": "DEMO",
  "DENEME": "ATTEMPT",
  "DEVAM ET": "GO ON",
  "Devam Et": "Go on",
  "Devam etmek ister misiniz?": "Would you like to continue?",
  "Diğer Adreslerim": "My other addresses",
  "Düzenle": "Edit",
  "Doğrulama Kodu": "Verification Code",
  "Doğrulama E-postası Gönder": "Send Verification Email",
  "E-Posta Adresi": "E-mail address",
  "E-Posta Adresiniz": "E-Mail Address",
  "E-Posta Doğrulama": "Email Verification",
  "Ekim": "October",
  "eklenecektir.": "will be added.",
  "En az": "Minimum",
  "En fazla": "Maximum",
  "En fazla 20 adet ürün ekleyebiliriz": "We can add up to 20 products",
  "Erkek": "Boy",
  "Eylül": "September",
  "Facebook bilgileriniz doğrulanamadı, lütfen tekrar deneyiniz.": "Your Facebook information could not be verified, please try again.",
  "Facebook ile Giriş Yap": "Log in with Facebook",
  "Facebook ile Kayıt Ol": "Register with Facebook",
  "Fatura Adresi": "Billing Address",
  "Faturamı aynı adrese gönder": "Translate Yok (Faturamı aynı adrese gönder)",
  "Filtrele": "Filter",
  "FLO Cüzdan": "FLO Wallet",
  "FLO Cüzdan ile Öde": "FLO Pay with Wallet",
  "FLO Cüzdan ile Ödendi": "Paid with FLO Waller",
  "Flo Cüzdan Kullanıcı Sözleşme": "FLO Wallet User Agreement",
  "Flo Cüzdan Kullanıcı Sözleşmesi": "FLO Wallet User Agreement",
  "FLO Cüzdan Oluştur": "Create FLO Wallet",
  "FLO Cüzdana Yüklendi ve Ödendi": "has transfered and paid from FLO Wallet",
  "FLO Cüzdanı Aktif Et ve Öde": "Activate FLO Wallet and Pay",
  "Flo Cüzdanım": "My flo wallet",
  "FLO Cüzdanım": "My flo wallet",
  "FLO Cüzdanım’a Git": "Go to Flo Wallet",
  "FLO Cüzdanınız´a": "to your FLO Wallet",
  "FLO Cüzdanınızı aktif etmek için": "To activate your flo wallet",
  "FLO Mağazacılık ve Pazarlama A.Ş. Müşteri Aydınlatma Metni": "Flo Storing and Marketing Inc. Customer Lighting Text",
  "Flo Müşteri Aydınlatma Metni": "Flo customer lighting text",
  "FLO Para": "Flo",
  "FLO Para Geri Kazan": "Cash Back FLO Money",
  "Flo Para Kazan": "FLO MONEY",
  "FLO PARA KAZANDIN!": "YOU'VE WON FLO MONEY!",
  "FLO Para Kullan": "Use FLO Money",
  "FLO Paralarınız karta iade edilemez.": "Your Flo coins cannot be returned to the card.",
  "flo-cuzdan-kullanici-sozlesmesi-app": "Flo-Cuzdan-User-Sozlesation-App",
  "Geçerli telefon numarası giriniz.": "Enter the current phone number.",
  "Geçerliliğini Kaybedecek FLO Paralarım": "My flo coins to lose your validity",
  "geçerlilik": "validity",
  "Geçerlilik süresini yitirecek olan FLO Paralar aşağıda aylık olarak listelenmektedir.": "Flo coins to lose the validity time are listed in months below.",
  "Genel Toplam": "Grand Total",
  "Giriş": "Entrance",
  "Giriş Yap": "Login",
  "GİRİŞ YAP": "LOGIN",
  "GÖNDER": "SEND",
  "Google bilgileriniz doğrulanamadı, lütfen tekrar deneyiniz.": "Your Google information could not be verified, please try again.",
  "Google ile Giriş Yap": "Log in with Google",
  "Google ile Kayıt Ol": "Register with Google",
  "Güncel": "Current",
  "Hata! Lütfen tekrar deneyiniz.": "Error! Please try again.",
  "Haziran": "June",
  "Hemen Aktif Et": "Active İmmediately",
  "Hemen Üye Ol": "Sign Up Now",
  "Hesabınıza en fazla": "The most to your account",
  "Hesap Özetim": "My account summary",
  "Hızlı Yükle": "Load quickly",
  "İade Edilecek Kart": "Card to be Refunded",
  "İade İşlemini Onayla": "Confirm Return Process",
  "İade işlemlerinde fatura ibrazı zorunludur, faturasız iade kabul edilmemektedir.": "Invoice submission is required in return transactions, no billing returns are accepted.",
  "IBAN Numaranız": "Your IBAN Number",
  "IBAN numaranız yukarıda vermiş olduğunuz ‘’Ad ve Soyad’’ bilgileriyle eşleşmelidir.": "Your IBAN number must match the 'name and Surname' information you have given the above.",
  "İl Seçiniz": "Select Province",
  "İlçe Seçiniz": "Select County",
  "İndirim kodunuz varsa lütfen giriniz": "Please enter your discount coupon",
  "İptal": "Cancel",
  "İşlem Gerçekleştiriliyor...": "Processing...",
  "Kadın": "Woman",
  "Kalan Süreniz:": "Your remaining period:",
  "Kampanya": "Offer",
  "Kampanya dönemlerinde yapılan sürpriz indirimlerden, stoklar ve bedenler tükenmeden ilk sizin haberiniz olsun.": "Surprise discounts made during the campaign periods, stocks and bodies are the first to run out of your news.",
  "Kampanya Kodu": "Campaign Code",
  "Kapıda kredi kartı ile ödeme kabul edilmemektedir": "Payment by credit card is not accepted at the door",
  "Kapıda Nakit Ödeme": "Cash on Delivery",
  "Kapıda Ödeme": "Cash on Delivery",
  "Kapıda ödeme seçeneğinde sadece nakit ödeme kabul edilmektedir.": "Only cash payment is accepted in the payment option at the door.",
  "Kapıda ödeme seçeneğiyle alınan ürünlerin iadesi mağazalarımızdan veya iade adresimize gönderilerek yapılabilir": "Refund of products received with the payment option at the door can be made by our stores or return address to our return address",
  "kapıda ödeme ücreti": "Payment fee at the door",
  "Kargo Ücreti": "Shipping Cost",
  "Kargo ücreti ödememiz için sepetinizdeki tüm ürünlerin <strong>Kargo Bedava</strong> olması gerekmektedir.": "All items in your cart must be <strong>Free Shipping</strong> in order for you to not pay for shipping.",
  "Kart Bilgileri": "Card Information",
  "Kart Numarası": "Card number",
  "Karta Yüklenebilir FLO Para": "Loadable Flo Money on the Cart",
  "Kartıma Geri Yükle": "Restore to my card",
  "Kartınıza en fazla": "Up to your card",
  "Kartınıza geri yükleme süresi bankalara bağlı olarak 2-10 iş günü arasında sürmektedir.": "The restart time to your card is between 2-10 business days depending on the banks.",
  "Kartınızın arka yüzündeki son 3 rakamı yazınız.": "Write the last 3 digits on the back side of your card.",
  "Kasım": "November",
  "KAYDET": "SAVE",
  "Kayıtlı Kart İle Devam Et": "Continue with registered card",
  "kazanabilirdiniz.": "you could win.",
  "Kazandın!": "Won!",
  "Kazanmaya Başlayın!": "Start winning!",
  "Kendim": "Myself",
  "Kodu Tekrar Gönder": "Send the code again",
  "Kolay İade Seçeneği": "Easy return option",
  "Kolay İade' Butonuyla hızlıca yapabilirsiniz.": "You can quickly do with the easy return 'button.",
  "Kredi Kartı ile Yükleme Yap": "Load with credit card",
  "Kredi Kartımı Sakla": "Save my credit card",
  "Kupon Kodunu Yazınız": "Enter Coupon Code",
  "Kurumsal Fatura İstiyorum": "I want corporate invoice",
  "Lütfen 3 karakter uzunluğunda bir değer giriniz": "Please enter a value of 3 characters long",
  "Lütfen bilgileri eksiksiz doldurunuz": "Please complete the information complete",
  "Lütfen bu alanı doldurunuz": "Please fill in this field",
  "Lütfen geçerli bir telefon girin.": "Please enter a valid phone.",
  "Lütfen geçerli bir tutar giriniz.": "Please enter a valid amount.",
  "Lütfen IBAN Numaranızı Girin": "Please enter your IBAN number",
  "Lütfen Kredi Kart Bilgilerinizi Yazın": "Please type your credit card information",
  "Lütfen sayfayı yeniden yükleyin.": "Please reload the page.",
  "Lütfen T.C. kimlik numaranızı kontrol ediniz!": "Please check your ID number!",
  "Lütfen vergi kimlik numaranızı kontrol ediniz!": "Please check your tax identification number!",
  "Mağaza Adresi": "Store address",
  "Mağazadan": "From store",
  "Mağazadan nasıl teslim alırım?": "How do I pick up from the store?",
  "Mağazadan Teslimat Bilgileri": "Delivery information from the store",
  "Mağazalardan nakit iade yapılmamaktadır": "Cash returns are not made from stores",
  "Mağazasından": "From the store",
  "Mahalle, cadde, sokak ve diğer bilgilerinizi giriniz.": "Enter your district, street and other address information.",
  "Mart": "March",
  "Mayıs": "May",
  "Mesafeli Satış Sözleşmesini": "Distance Sales Agreement",
  "Mobil Uygulamamız ile Sürpriz Avantajlar": "Surprise advantages with our mobile app",
  "Mobil Uygulamasına özel yaptığımız etkinliklerden ilk sizin haberiniz olsun.": "Have your news about the activities we have special to mobile app.",
  "Nisan": "April",
  "numaralı telefonuza gönderilen 6 haneli doğrulama kodunu giriniz.": "Enter the 6-digit verification code sent to your numbered phone.",
  "Ocak": "January",
  "otp-form-text-1": "Please enter the 6-digit verification code sent to",
  "Ödeme": "Payment",
  "ÖDEME": "PAYMENT",
  "ÖDEME ADIMINA GEÇ": "GO TO PAYMENT",
  "Ödeme Seçenekleri": "Payment Options",
  "Ödeme Yöntemi": "Translate Yok (Ödeme Yöntemi)",
  "ÖDEMEYİ TAMAMLA": "COMPLETE",
  "Ödenecek Tutar": "Total Amount",
  "okudum onaylıyorum": "I have read and accept",
  "olması gerekmektedir.": "It is necessary to be.",
  "Ön Bilgilendirme Formunu": "The preliminary informational form",
  "Onay Kodu": "Confirmation code",
  "ONAYLA": "APPROVE",
  "Onayla": "APPROVE",
  "Önceden Sepete Eklediklerim": "Previosly Added Items",
  "rakam giriniz": "number",
  "Satın aldığınız ürünlerin iadelerini": "Their returns of the products you purchase",
  "Sayfa Bulunamadı": "Page not found",
  "Sepet": "Cart",
  "Sepete Ekle": "Add to Cart",
  "SEPETİ ONAYLA": "CONFIRM CART",
  "Sepetim": "Cart",
  "Sepetini Arkadaşınla Paylaş": "Share Your Basket With Your Friends",
  "Sepetinizde ürün bulunmamaktadır.": "No items are available in your cart",
  "Sepetinizdeki ürünü sepetten çıkardıktan sonra favorilerinize eklemek ister misiniz?": "Would you like to add this item to your favorites after removing it from the cart?",
  "Sepetinizdeki Ürünün Stoğu Tükendi!": "The product in your cart is out of stock!",
  "Siparişlerin ve güncel fırsatlarla ilgili e-postaları almaya devam etmek için": "To continue receiving emails about orders and current deals, we'll send a verification email to ",
  "Sepette": " ",
  "Şifre": "Password",
  "Şifremi Unuttum": "I forgot my password",
  "Şifreniz": "Your password",
  "Sil": "Remove",
  "Sil ve Favorilere Ekle": "Remove and Add to Favorites",
  "Sipariş No:": "Order No:",
  "Sipariş Özeti": "Order summary",
  "SİPARİŞİNİZ BAŞARI İLE TAMAMLANMIŞTIR": "YOUR ORDER HAS SUCCESSFULLY COMPLETED",
  "SİPARİŞLERİM": "MY ORDERS",
  "Şirket Adı": "Company Name",
  "Size Özel Kupon Fırsatları": "Special Coupon Opportunities to You",
  "Son Kullanma Tarihi": "Expiration date",
  "SONRAKİ ADIMA DEVAM ET": "Continue the next step",
  "Soyad": "Surname",
  "Soyadınız": "Your surname",
  "Standart Teslimat": "Translate Yok (Standart Teslimat)",
  "Şubat": "February",
  "Tahmini Kargoya Teslimat :": "ETA :",
  "Tahmini Kargoya Veriliş Tarihi: ": "Translate Yok (Tahmini Kargoya Veriliş Tarihi: )",
  "Taksit": "Installment",
  "Taksit Seçenekleri": "Payment Options",
  "Taksit seçenekleri kart bilgilerinizi girdikten sonra güncellenecektir.": "Installment options will be updated after you enter your card information.",
  "TAMAM": "OK",
  "tarafından gönderilecektir.": "will be sent by.",
  "Tek Çekim": "One-Off Payment",
  "Tekrar SMS Gönder": "Send SMS again",
  "Telefon Numaranız": "Your telephone number",
  "Telefon numaranızı kontrol ediniz.": "Phone number is not valid.",
  "telefon numarasına gönderilen 6 haneli kodu giriniz.": "Enter the 6-digit code sent to the phone number.",
  "Temmuz": "July",
  "Teşekkürler": "Thanks",
  "Teslim Alacağım": "I will take delivery",
  "teslim edilecektir": "It will be delivered",
  "Teslimat Adresi": "Delivery Address",
  "Teslimat Bilgileri": "Delivery Info",
  "Teslimat Seçenekleri": "Delivery Options",
  "TEST": "TEST",
  "tıklayın.": "Click.",
  "TL": "KZT",
  "TL ’den": "KZT to",
  "TL bakiye yükleyebilirsiniz.": "KZT can be loaded.",
  "TL en fazla": "KZT maximum",
  "TL hesabınıza iade edebilirsiniz.": "KZT can be returned to your account.",
  "TL iade edilebilir.": "KZT can be returned.",
  "TL kartınıza iade edebilirsiniz.": "KZT can be returned to your card.",
  "TL’ye yükselmiştir.": "KZT.",
  "tr": "en",
  "TR99 9999 9999 9999 9999 9999 99": "Tr99 9999 9999 9999 9999 99",
  "TRY": "TRY",
  "tutarında yükleme yapıldı ve ödeme işlemi tamamlandı.:": "The amount was done and the payment process has been completed.:",
  "ÜCRETSİZ": "FREE",
  "Ücretsiz": "Free",
  "Ücretsiz Kargo": "Free shipping",
  "Ürün": "Items",
  "ürün daha ekleyin Kargo Bedava olsun.": "The product can add more shipping free shipping.",
  "Ürün Var": "items in the cart",
  "Ürünlerinizi aşağıda adresi bulunan mağazamızdan teslim alabilirsiniz.": "You can pick up your products from our shop below.",
  "uyarınca işlenecektir.": "will be processed in accordance with.",
  "ÜYE OL": "SIGN UP",
  "HESAP OLUŞTUR": "Create Account",
  "Üye olmadan": "Without a member",
  "ÜYE OLMADAN ALIŞVERİŞ": "Shopping without a member",
  "Üye Olmadan Devam Et": "Continue without a member",
  "üyeleri kapıda nakit ödeme avantajından yararlanabilirler.": "Members can benefit from cash payment advantage at the door.",
  "Üyeleri, hiç kullanmadığı ürünleri 14 gün içerisinde hızlı ve kolay bir işlemle iade edebilme avantajından yararlanırlar.": "Members benefit from the advantage of able to return the products at all in 14 days with a quick and easy transaction.",
  "Üyelerimize özel yaptığımız kupon etkinliklerinden faydalanın": "Take advantage of coupon activities that we make our members special",
  "ÜYELİK AVANTAJLARI": "Membership Advantages",
  "uyelik_sozlesmesi": "uyelik_sozlesmesi",
  "Uygula": "Apply",
  "Uyguladığınız kupon, sepetinizdeki indirim ile geçerli olmadığından sepet tutarınız": "Since the coupon you apply is not valid with the discount in your cart, your cart amount went up from",
  "VAZGEÇ": "GIVE UP",
  "Vazgeç": "GIVE UP",
  "ve": "and",
  "ve üzeri ücretsiz kargo": " Translate Yok ( ve üzeri ücretsiz kargo)",
  "Vergi Dairesi": "Tax Office",
  "Vergi/ TC Kimlik No": "TAX/ID NO",
  "Vergi/ TC Kimlik No hatalı kontrol ediniz.": "Tax/ID NO is not valid.",
  "veya": "or",
  "ya Giriş Yapın": "Either sign in",
  "Yapmış olduğun alışverişten": "From shopping you've done",
  "Yeni Adres Ekle": "Add New Address",
  "Yıl": "Year",
  "Yüklediğin Para": "Money you have loaded",
  "Yükleniyor": "Loading",
  "Tahmini Teslimat :": "ETA :",
  "Kargo ücreti ödememiz için sepetinizdeki tüm ürünlerin": "In order to have free shipping, every item in your cart should be",
  "Kargo Bedava": "Free Shipping",
  "olması gerekmektedir.": ".",
  "SEPETE GİT": "VIEW CART",
  "SİPARİŞİ TAMAMLA": "COMPLETE ORDER",
  "Lütfen geçerli bir eposta adresi yazın": "Please write a valid email address",
  "Kayıtlı kullanıcı bulunamadı": "User not found",
  "istenilen miktarda ürün bulunmamaktadır.": "There is no desired amount of products.",
  "Kredi / Banka Kartı": "Credit / Debit Card",
  "Lütfen en az 3 karakter uzunluğunda bir değer giriniz.": "Please enter a value with a minimum of 3 characters."
};

export default data;
