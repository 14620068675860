import * as React from 'react'
import { memo } from 'react'
const SvgIconBottomArrow = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='1em'
    height='1em'
    viewBox='0 0 14.873 8.493'
    fill='currentColor'
    {...props}
  >
    <g data-name='noun_Next_1135493 (1)'>
      <path
        d='M6.543 8.123.327 1.907a1.117 1.117 0 0 1 1.58-1.58l5.529 5.53L12.965.328a1.117 1.117 0 0 1 1.58 1.58L8.33 8.123a1.264 1.264 0 0 1-1.787 0'
        data-name='Path 139'
      />
    </g>
  </svg>
)
const Memo = memo(SvgIconBottomArrow)
export default Memo
