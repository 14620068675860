/* eslint-disable import/prefer-default-export */
export const floDev = {
  BASE_URL: 'https://staging.flo.com.tr/',
  COOKIE_BASE_URL: 'localhost',
  CHECKOUT_URL: 'https://checkout-be.staging.flo.com.tr/',
  CHECKOUT_BASE_URL: 'https://checkout.staging.flo.com.tr/',
  googleClientId: '885834410672-oi3laoq34oe3oojsjilt8hn0ehtt1qds.apps.googleusercontent.com',
  facebookAppId: '1833248290026205',
  appleClientId: 'com.flo.appleClient',
  currency: 'TL',
  locale: 'tr',
  symbol: false,
  quoteitemtotal: 20,
  logoPath: 'pub/assets/flo-v2/images/flo-logo.svg',
  project: 'flo',
  title: 'FLO',
  appDeepLink: 'flo://',
  failuresNumber: '3',
  manifest: 'pub/pwa/manifest-flo.json',
  favicon: 'pub/assets/flo/img/flo-sq.png',
  loadingAnimation: 'pub/assets/flo/img/loading/flo_loading_white.gif',
  gtmAccountId: 'GTM-T4KVJH2',
  gtmMerchant: 'bl9F_wE2A5ZQqusL0JElPm8upiJi39UWSc-Voh8sg88',
  siteKey: '6LcPQ64UAAAAAJv489J59h58XQ6t2X8cHODjm9NR',
  twitterCreator: '@flo',
  appStoreId: '889027386',
  alternateIos: 'ios-app://889027386/https/www.flo.com',
  alternateAndroid: 'android-app://com.flo.ayakkabi/https/www.flo.com.tr',
  facebookDomainVerification:'j0qp5fko2grla15zhr9ihsq5d94bgo',
  robotoFont: 'https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700',
  efilliScript: 'https://bundles.efilli.com/staging.flo.com.tr.prod.js',
  segmentifyScript: '',
  siteName: 'FLO.COM.TR.',
  BUNSAR_URL: 'https://eventapi.bunsar.com/',
  COOKIE_ROOT_URL: 'localhost',
  phoneCode: [{ name: '+90', mask: '0(599) 999 99 99' }],
  isGlobal: false,
  cunda: true
};

export const floStaging = {
  BASE_URL: 'https://staging.flo.com.tr/',
  COOKIE_BASE_URL: 'staging.flo.com.tr',
  CHECKOUT_URL: 'https://checkout-be.staging.flo.com.tr/',
  CHECKOUT_BASE_URL: 'https://checkout.staging.flo.com.tr/',
  googleClientId: '885834410672-oi3laoq34oe3oojsjilt8hn0ehtt1qds.apps.googleusercontent.com',
  facebookAppId: '1833248290026205',
  appleClientId: 'com.flo.appleClient',
  currency: 'TL',
  locale: 'tr',
  symbol: false,
  quoteitemtotal: 20,
  logoPath: 'pub/assets/flo-v2/images/flo-logo.svg',
  project: 'flo',
  title: 'FLO',
  appDeepLink: 'flo://',
  failuresNumber: '3',
  manifest: 'pub/pwa/manifest-flo.json',
  favicon: 'pub/assets/flo/img/flo-sq.png',
  loadingAnimation: 'pub/assets/flo/img/loading/flo_loading_white.gif',
  gtmAccountId: 'GTM-T4KVJH2',
  gtmMerchant: 'bl9F_wE2A5ZQqusL0JElPm8upiJi39UWSc-Voh8sg88',
  siteKey: '6LcPQ64UAAAAAJv489J59h58XQ6t2X8cHODjm9NR',
  twitterCreator: '@flo',
  appStoreId: '889027386',
  androidPackage: 'app-id=tr.com.flo',
  alternateIos: 'ios-app://889027386/https/www.flo.com',
  alternateAndroid: 'android-app://com.flo.ayakkabi/https/www.flo.com.tr',
  facebookDomainVerification:'j0qp5fko2grla15zhr9ihsq5d94bgo',
  robotoFont: 'https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700',
  efilliScript: 'https://bundles.efilli.com/staging.flo.com.tr.prod.js',
  segmentifyScript: '',
  siteName: 'FLO.COM.TR.',
  BUNSAR_URL: 'https://eventapi.bunsar.com/',
  COOKIE_ROOT_URL: '.flo.com.tr',
  phoneCode: [{ name: '+90', mask: '0(599) 999 99 99' }],
  isGlobal: false,
  cunda: true
};

export const floPreprod = {
  BASE_URL: 'https://preprod.flo.com.tr/',
  COOKIE_BASE_URL: 'preprod.flo.com.tr',
  CHECKOUT_URL: 'https://checkout-be.preprod.flo.com.tr/',
  CHECKOUT_BASE_URL: 'https://checkout.preprod.flo.com.tr/',
  googleClientId: '885834410672-oi3laoq34oe3oojsjilt8hn0ehtt1qds.apps.googleusercontent.com',
  facebookAppId: '1833248290026205',
  appleClientId: 'com.flo.appleClient',
  currency: 'TL',
  locale: 'tr',
  symbol: false,
  quoteitemtotal: 20,
  logoPath: 'pub/assets/flo-v2/images/flo-logo.svg',
  project: 'flo',
  title: 'FLO',
  appDeepLink: 'flo://',
  failuresNumber: '3',
  manifest: 'pub/pwa/manifest-flo.json',
  favicon: 'pub/assets/flo/img/flo-sq.png',
  loadingAnimation: 'pub/assets/flo/img/loading/flo_loading_white.gif',
  gtmAccountId: 'GTM-T4KVJH2',
  gtmMerchant: 'bl9F_wE2A5ZQqusL0JElPm8upiJi39UWSc-Voh8sg88',
  siteKey: '6LcPQ64UAAAAAJv489J59h58XQ6t2X8cHODjm9NR',
  twitterCreator: '@flo',
  appStoreId: '889027386',
  androidPackage: 'app-id=tr.com.flo',
  alternateIos: 'ios-app://889027386/https/www.flo.com',
  alternateAndroid: 'android-app://com.flo.ayakkabi/https/www.flo.com.tr',
  facebookDomainVerification:'j0qp5fko2grla15zhr9ihsq5d94bgo',
  robotoFont: 'https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700',
  efilliScript: 'https://bundles.efilli.com/staging.flo.com.tr.prod.js',
  segmentifyScript: '',
  siteName: 'FLO.COM.TR.',
  BUNSAR_URL: 'https://eventapi.bunsar.com/',
  COOKIE_ROOT_URL: '.flo.com.tr',
  phoneCode: [{ name: '+90', mask: '0(599) 999 99 99' }],
  isGlobal: false,
  cunda: true
};

export const floProd = {
  BASE_URL: 'https://www.flo.com.tr/',
  COOKIE_BASE_URL: 'flo.com.tr',
  CHECKOUT_URL: 'https://checkout-be.flo.com.tr/',
  CHECKOUT_BASE_URL: 'https://checkout.flo.com.tr/',
  googleClientId: '885834410672-oi3laoq34oe3oojsjilt8hn0ehtt1qds.apps.googleusercontent.com',
  facebookAppId: '1833248290026205',
  appleClientId: 'com.flo.appleClient',
  currency: 'TL',
  locale: 'tr',
  symbol: false,
  quoteitemtotal: 20,
  logoPath: 'pub/assets/flo-v2/images/flo-logo.svg',
  project: 'flo',
  title: 'FLO',
  appDeepLink: 'flo://',
  failuresNumber: '3',
  manifest: 'pub/pwa/manifest-flo.json',
  favicon: 'pub/assets/flo/img/flo-sq.png',
  loadingAnimation: 'pub/assets/flo/img/loading/flo_loading_white.gif',
  gtmAccountId: 'GTM-T4KVJH2',
  gtmMerchant: 'bl9F_wE2A5ZQqusL0JElPm8upiJi39UWSc-Voh8sg88',
  siteKey: '6LcPQ64UAAAAAJv489J59h58XQ6t2X8cHODjm9NR',
  twitterCreator: '@flo',
  appStoreId: '889027386',
  androidPackage: 'app-id=tr.com.flo',
  alternateIos: 'ios-app://889027386/https/www.flo.com',
  alternateAndroid: 'android-app://com.flo.ayakkabi/https/www.flo.com.tr',
  facebookDomainVerification:'j0qp5fko2grla15zhr9ihsq5d94bgo',
  robotoFont: 'https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700',
  efilliScript: 'https://bundles.efilli.com/staging.flo.com.tr.prod.js',
  segmentifyScript: '',
  siteName: 'FLO.COM.TR.',
  BUNSAR_URL: 'https://eventapi.bunsar.com/',
  COOKIE_ROOT_URL: '.flo.com.tr',
  phoneCode: [{ name: '+90', mask: '0(599) 999 99 99' }],
  isGlobal: false,
  cunda: false
};
