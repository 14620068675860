import * as React from 'react'
import { memo } from 'react'
const LoyaltyStar = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20.474" height="19.644" viewBox="0 0 20.474 19.644">
    <path id="Path_14544" data-name="Path 14544" d="M15.318,18.629A.676.676,0,0,1,15,18.551L9.726,15.767,4.451,18.551a.679.679,0,0,1-.985-.717L4.473,11.94.205,7.769A.682.682,0,0,1,.581,6.607l5.9-.865L9.118.379a.678.678,0,0,1,1.217,0l2.638,5.363,5.9.865a.682.682,0,0,1,.375,1.162L14.979,11.94l1.007,5.893a.68.68,0,0,1-.669.8" transform="translate(0.51 0.5)" fill="#ee4e17" stroke="#fff" stroke-width="1"/>
  </svg>

)

const Memo = memo(LoyaltyStar)
export default Memo
