import React from 'react';
import PropTypes from 'prop-types';

const GalleryImage = ({
  className, src, alt, onClick,
}) => (
  <>
    <img className={className} src={src} alt={alt} onClick={onClick} style={{ width: 120, cursor: 'pointer' }} data-test="checkout-gallery-image-button" />
  </>
);

GalleryImage.defaultProps = {
  className: '',
  // eslint-disable-next-line react/default-props-match-prop-types
  src: '',
  alt: '',
  // eslint-disable-next-line react/default-props-match-prop-types
  onClick: () => {},
};

GalleryImage.propTypes = {
  className: PropTypes.string,
  // eslint-disable-next-line react/require-default-props
  src: PropTypes.string.isRequired,
  alt: PropTypes.string,
  // eslint-disable-next-line react/require-default-props
  onClick: PropTypes.func.isRequired,

};

export default GalleryImage;
