/* eslint-disable import/no-cycle */
import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import auth from './auth';
import config from './config';
import cart from './cart';
import checkout from './checkout';
import order from './order';
import popup from './popup';
import loading from './loading';
import otp from './otp';
import lang from './lang';
import language from './language';
import wallet from './wallet';
import redirect from './redirect';
import fModal from './fModal';
import login from './login';
import cardValidation from './cardValidation';
import headerActive from './headerActive';
import CCActive from './CCActive';
import prevPath from './navigation';
import selfCheckout from './storeBasket';
import dynamicBaseUrl from './dynamicBaseUrl'

const reducer = combineReducers({
  auth,
  config,
  cart,
  checkout,
  order,
  popup,
  loading,
  otp,
  lang,
  language,
  wallet,
  redirect,
  fModal,
  login,
  cardValidation,
  headerActive,
  CCActive,
  prevPath,
  selfCheckout,
  dynamicBaseUrl,
});

const store = configureStore({
  reducer,
  // middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger),
});

export default store;
